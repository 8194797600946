import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import vi from '../translate/vi.json';
import en from '../translate/en.json';

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

function getMessages(locale: string) {
  if (locale === 'en') {
    return en;
  }
  return vi;
}

function mapStateToProps(state: AppState) {
  return {
    locale: state.intl.locale,
    messages: flattenMessages(getMessages(state.intl.locale)),
  };
}

export default connect(mapStateToProps)(IntlProvider);
