import { CloseRounded } from '@mui/icons-material';
import { IconButton, Slide, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/vi';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router';
import './App.css';
import { development } from './api/API_App';
import ConfirmDialogProvider from './common/components/ConfirmDialogProvider';
import FetchErrorDialog from './common/components/FetchErrorDialog';
import MuiLoading from './common/components/MuiLoading';
import ProtectedRoute from './common/components/ProtectedRoute';
import RedirectRoute from './common/components/RedirectRoute';
import MessageDialogProvider from './common/components/MessageDialogProvider';
import { localeObject } from './common/constants';
import { MUITheme } from './config/setupTheme';
import { ROUTES_PATH } from './layout/constants';
import DefaultLayout from './layout/main/DefaultLayout';
import LoginPage from './modules/auth/page/LoginPage';
import { getUserInfo } from './redux/authReducer';
import { AppState } from './redux/store';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', localeObject);
dayjs.updateLocale('vi', localeObject);

export const localeMap: any = {
  vi: 'vi',
  en: 'en',
};

function App() {
  const dispatch: ThunkDispatch<AppState, null, Action> = useDispatch();
  const notiStackRef = React.useRef<any>(null);

  const mode = useSelector((state: AppState) => state.cache.mode, shallowEqual);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            gcTime: 0,
            refetchOnWindowFocus: false,
            retry: 0,
            placeholderData: keepPreviousData,
            // throwOnError: true,
          },
          mutations: {
            onError: (e: any) => {
              if (e?.response?.data?.message)
                notiStackRef.current.enqueueSnackbar({
                  message: e?.response?.data?.message,
                  variant: 'error',
                });
              else
                e.messag &&
                  notiStackRef.current.enqueueSnackbar({
                    message: e.message,
                    variant: 'error',
                  });
            },
          },
        },
        queryCache: new QueryCache({
          onError: (e: any) => {
            if (e?.response?.data?.message)
              notiStackRef.current.enqueueSnackbar({
                message: e?.response?.data?.message,
                variant: 'error',
              });
            else
              e.messag &&
                notiStackRef.current.enqueueSnackbar({
                  message: e.message,
                  variant: 'error',
                });
          },
        }),
      })
  );

  const locale = useSelector(
    (state: AppState) => state.intl.locale,
    shallowEqual
  );

  const onClickDismiss = (key: any) => () => {
    if (notiStackRef.current) {
      notiStackRef.current.closeSnackbar(key);
    }
  };

  const routes = useRoutes([
    {
      path: ROUTES_PATH.login,
      element: (
        <RedirectRoute>
          <LoginPage />
        </RedirectRoute>
      ),
    },
    {
      path: '*',
      element: (
        <ProtectedRoute>
          <DefaultLayout />
        </ProtectedRoute>
      ),
    },
  ]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MUITheme(mode)}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={localeMap[locale]}
        >
          <SnackbarProvider
            ref={notiStackRef}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            preventDuplicate
            autoHideDuration={2500}
            TransitionComponent={Slide}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseRounded style={{ color: 'white' }} />
              </IconButton>
            )}
          >
            <ConfirmDialogProvider>
              <MessageDialogProvider>
                <React.Suspense fallback={<MuiLoading />}>
                  {routes}
                </React.Suspense>
                <FetchErrorDialog />
              </MessageDialogProvider>
            </ConfirmDialogProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
      {development && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
