import {
  ExpandMoreOutlined,
  RefreshRounded,
  SearchRounded,
  TuneOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import SchemaForm from '../../../common/SchemaForm';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { StandardFoodFilterModel } from '../../standard-food/types';
import { FormattedMessage } from 'react-intl';

interface Props {
  hookPagination: HookPaginationProps<StandardFoodFilterModel>;
}
const StandardFoodFilterBox = (props: Props) => {
  const { hookPagination } = props;
  const { setParams, clearParams, filters } = hookPagination;
  const { listType, intl, isMobile } = useGeneralHook();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    setSearch(filters.name || '');
  }, [filters.name]);

  return (
    <>
      <Box className="flex items-center gap-2">
        {!isMobile && (
          <Typography className="whitespace-nowrap">
            {intl.formatMessage({
              id: 'search',
            })}
            :
          </Typography>
        )}
        <TextField
          fullWidth
          placeholder={intl.formatMessage({
            id: 'standardFood.search',
          })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              setParams({ name: search });
            }
          }}
        />
        <Button
          sx={{ flexShrink: 0 }}
          startIcon={<SearchRounded />}
          onClick={() => {
            setParams({ name: search });
          }}
        >
          {!isMobile && <FormattedMessage id="search" />}
        </Button>
        <Button
          variant="outlined"
          sx={{ flexShrink: 0 }}
          startIcon={
            <Badge
              badgeContent={Object.keys(filters).length}
              variant="standard"
              color="primary"
            >
              <TuneOutlined />
            </Badge>
          }
          endIcon={!isMobile && <ExpandMoreOutlined />}
          onClick={handleClick}
        >
          {!isMobile &&
            intl.formatMessage({
              id: 'filter',
            })}
        </Button>
        <Button
          variant="outlined"
          sx={{ flexShrink: 0 }}
          onClick={() => clearParams()}
        >
          <RefreshRounded />
        </Button>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: { borderColor: 'primary.main', p: 2, mt: 2, width: 280 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SchemaForm<StandardFoodFilterModel>
          formHookProps={{ defaultValues: filters }}
          globalProps={{
            noHelperText: true,
          }}
          onChange={(val) => setParams(val)}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                sourceType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'standardFood.sourceType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  hasAllOptions: true,
                  options: listType?.TPTC?.sourceType,
                  rawOptions: true,
                },
                type: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'standardFood.type',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  hasAllOptions: true,
                  options: listType?.TPTC?.type,
                  rawOptions: true,
                },
              };
            },
          }}
        />
      </Popover>
    </>
  );
};
export default StandardFoodFilterBox;
