import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Button, Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import FilterBox from './FilterBox';
import {
  OrderClassificationsFilterModel,
  OrderClassificationsModelList,
} from './types';
import { useParams } from 'react-router';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';

const OrderClassificationsPage = () => {
  const { dispatch, fetchThunk, API_PATHS, confirmDialog, intl, listType } =
    useGeneralHook();
  const { id } = useParams<{ id: string }>();

  const hookPagination = usePaginationHook<OrderClassificationsFilterModel>();

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<OrderClassificationsModelList>
  >({
    queryKey: [KEYS_QUERY.orderClassifications, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<
          ResponseDataList<OrderClassificationsModelList>,
          OrderClassificationsFilterModel
        >({
          url: API_PATHS.orderClassifications.index(id),
          params: params,
        })
      );
      return json.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (item: OrderClassificationsModelList) => {
      await confirmDialog.promptConfirmation({
        title: intl.formatMessage({ id: 'confirm' }),
        content: intl.formatMessage(
          { id: 'deleteConfirm' },
          { name: item.name }
        ),
        onAccept: async () => {
          await dispatch(
            fetchThunk({
              url: API_PATHS.orderClassifications.detail(id, item.id),
              method: 'delete',
            })
          );
          refetch();
        },
      });
    },
  });

  return (
    <>
      <Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 1,
            pb: 0,
          }}
        >
          <FilterBox hookPagination={hookPagination} />
          <MyLink
            to={ROUTES_PATH.orderClassifications.create}
            sx={{ alignSelf: 'end' }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRounded />}
            >
              <FormattedMessage id="add" />
            </Button>
          </MyLink>
        </Box>
        <MuiTable<OrderClassificationsModelList>
          data={data?.data ?? []}
          state={{
            isLoading: isLoading,
            sorting,
          }}
          onSortingChange={onSortingChange}
          paginationProps={{
            count: data?.meta.total,
            page: data?.meta.currentPage,
            rowsPerPage: data?.meta.perPage,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          enableRowActions
          renderRowActions={({ row }) => [
            <MyLink to={row.original.id.toString()}>
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              >
                <EditRounded />
              </IconButtonTooltip>
            </MyLink>,
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="delete" /> }}
              onClick={() => deleteMutation.mutateAsync(row.original)}
            >
              <DeleteRounded />
            </IconButtonTooltip>,
          ]}
          columns={[
            {
              accessorKey: 'id',
              header: 'id',
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'operationCode',
              header: 'operationCode',
              Header: () => (
                <FormattedMessage id="orderClassifications.operationCode" />
              ),
            },
            {
              accessorKey: 'name',
              header: 'name',
              Header: () => <FormattedMessage id="orderClassifications.name" />,
            },
            {
              accessorKey: 'type',
              header: 'type',
              Header: () => <FormattedMessage id="orderClassifications.type" />,
              Cell: ({ renderedCellValue }) => {
                return listType.PLDH?.type?.find(
                  (v) => v.id === renderedCellValue
                )?.name;
              },
            },
            {
              accessorKey: 'orderType',
              header: 'orderType',
              Header: () => (
                <FormattedMessage id="orderClassifications.orderType" />
              ),
              Cell: ({ renderedCellValue }) => {
                return listType.PLDH?.orderType?.find(
                  (v) => v.id === renderedCellValue
                )?.name;
              },
            },
            {
              accessorKey: 'nutritionRequired',
              header: 'nutritionRequired',
              Header: () => (
                <FormattedMessage id="orderClassifications.nutritionRequired" />
              ),
              Cell: ({ renderedCellValue }) => {
                return (
                  <FormattedMessage id={renderedCellValue ? 'yes' : 'no'} />
                );
              },
            },
            {
              accessorKey: 'status',
              header: 'status',
              Header: () => <FormattedMessage id="status" />,
              Cell: ({ renderedCellValue }) => {
                const status = STATUS_OPTIONS.find(
                  (v) => v.id === renderedCellValue
                );

                return (
                  status && (
                    <Chip
                      label={<FormattedMessage id={status.name} />}
                      variant="outlined"
                      color={status.color as any}
                      size="small"
                    />
                  )
                );
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};
export default OrderClassificationsPage;
