import { Box, Button, Typography } from '@mui/material';
import MuiDialog from '../MuiDialog';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface Props {
  open: boolean;
  onClose: (val: boolean) => void;
  title: string;
  infos?: { value: React.ReactNode; label: string }[];
  onSubmit?: () => Promise<void>;
}
const RestartProgressDialog = (props: Props) => {
  const { open, onClose, title, infos = [], onSubmit } = props;
  const [loading, setLoading] = useState(false);

  const renderInfo = () => {
    if (!infos.length) return null;
    return (
      <Box className="flex flex-col gap-2">
        {infos?.map((item, index) => {
          return (
            <Typography variant="body2" key={index} className="flex gap-1">
              <Box sx={{ minWidth: 160 }}>
                <FormattedMessage id={item.label} />
              </Box>
              :&emsp;{item.value}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const onSubmitFunc = async () => {
    try {
      setLoading(true);
      await onSubmit?.();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MuiDialog open={open} onClose={onClose} fullWidth maxWidth="tablet">
        <Typography variant="h5" color="primary" className="text-center py-4">
          <FormattedMessage id={title} />
        </Typography>
        <Box px={2}>{renderInfo()}</Box>
        <Box p={2} className="flex justify-center gap-4">
          <Button
            variant="outlined"
            sx={{ minWidth: 100 }}
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 100 }}
            loading={loading}
            onClick={onSubmitFunc}
          >
            <FormattedMessage id="confirm" />
          </LoadingButton>
        </Box>
      </MuiDialog>
    </>
  );
};
export default RestartProgressDialog;
