import { PaletteMode } from '@mui/material';
import { blueGrey, grey, red } from '@mui/material/colors';
import { unstable_createMuiStrictModeTheme as createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';

const LightTheme = (mode) => {
  const background =
    mode === 'dark'
      ? {
          paper: '#011e3c',
          default: '#173352',
        }
      : {
          paper: '#fff',
          default: '#F7F5F7',
        };
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        laptop: 1024,
        tablet: 640,
        mobile: 0,
        desktop: 1280,
      },
    },
    components: {
      MuiLink:{
        styleOverrides:{root:{},}
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            fontSize: 12,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiBadge: {
        styleOverrides: { badge: { height: 16, minWidth: 16, fontSize: 10 } },
      },
      MuiDialog: {
        styleOverrides: {
          paperFullWidth: {
            '@media (max-width: 600px)': {
              margin: 16,
              maxHeight: 'calc(100% - 32px)',
              maxWidth: 'calc(100% - 32px)',
              width: 'calc(100% - 32px)',
            },
          },
          paper: {
            borderRadius: 16,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '@media (max-width: 600px)': {
              minHeight: 40,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '@media (max-width: 600px)': {
              padding: '0px 8px',
              minHeight: 40,
            },
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: { padding: 4 },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            marginLeft: 8,
            marginRight: 0,
          },
          positionEnd: {
            marginLeft: 0,
            marginRight: 12,
          },
        },
      },
      MuiTooltip: {
        defaultProps: { arrow: true },
      },
      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'small',
        },
      },
      MuiTypography: {
        defaultProps: {
          color: 'textPrimary',
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: { height: 1 },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: { title: 'button' },
        styleOverrides: {
          root: {
            '& @media (max-width: 640px) .MuiTab-root': {
              padding: '0px 8px',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
            fontSize: 14,
            fontWeight: 700,
            '@media (max-width: 600px)': {
              display: 'flex',
              gap: 2,
              minWidth: 'unset',
            },
          },
          contained: {},
          text: {},
          sizeLarge: {
            minHeight: 48,
            minWidth: 48,
          },
          sizeMedium: {
            minHeight: 40,
            minWidth: 40,
          },
          sizeSmall: {
            height: 32,
            minWidth: 32,
          },
          outlined: {
            background: background.paper,
          },
          startIcon: {
            '@media (max-width: 640px)': {
              margin: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          sizeLarge: {
            minHeight: 40,
          },
          sizeMedium: {
            minHeight: 32,
          },
          sizeSmall: {
            height: 28,
            width: 28,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: { height: 24 },
          icon: {
            right: 8,
          },
          outlined: {
            '&.MuiInputBase-readOnly ': {
              background: mode === 'dark' ? grey[800] : grey[200],
              cursor: 'not-allowed',
            },
          },
        },
      },
      MuiToolbar: {
        defaultProps: {
          style: {
            backgroundColor: 'inherit',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // borderRadius: 8,
            backgroundImage: 'none',
          },
        },
      },
      MuiFormLabel: {
        defaultProps: { sx: { color: 'text.primary' } },
        styleOverrides: {
          root: {
            fontSize: 12,
            paddingBottom: 8,
            lineHeight: 1,
            margin: 0,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: true,
        },
        styleOverrides: {
          root: {
            paddingBottom: 8,
            '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
              transform: 'unset',
              position: 'relative',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'unset',
              position: 'relative',
            },
          },
          asterisk: {
            color: red[600],
            '&::before': {
              color: blueGrey[400],
              content: '" ("',
            },
            '&::after': {
              color: blueGrey[400],
              content: '" )"',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: { height: 8 },
          contained: { marginLeft: 0 },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { padding: 0, borderRadius: 8 },
          multiline: { padding: 0 },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '4px 8px',
            },
            '& .MuiInputBase-readOnly': {
              background: mode === 'dark' ? grey[800] : grey[200],
              cursor: 'not-allowed',
            },
          },
          inputRoot: {
            padding: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-readOnly': {
              background: mode === 'dark' ? grey[800] : grey[200],
              cursor: 'not-allowed',
            },
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          sx: { backgroundColor: 'background.paper' },
        },
        styleOverrides: {
          input: {
            minHeight: 24,
          },
          root: {
            borderRadius: 8,
            fontSize: 14,
            padding: 0,
            '& .MuiInputBase-input': { padding: '8px 12px' },
            '& .MuiAutocomplete-input': { padding: '8px 12px', height: 32 },
            '& fieldset': {
              top: 0,
              '& legend': {
                display: 'none',
              },
            },
            '& .MuiAutocomplete-popper.MuiAutocomplete-paper': {
              padding: 0,
            },
          },
        },
      },
    },
    typography: {
      htmlFontSize: 14,
      fontSize: 14,
      button: { textTransform: 'none' },
      fontFamily: 'Be Vietnam Pro',
      h1: { fontSize: 64, lineHeight: '72px', fontWeight: 900 },
      h2: { fontSize: 52, lineHeight: '64px', fontWeight: 900 },
      h3: { fontSize: 48, lineHeight: '56px', fontWeight: 700 },
      h4: { fontSize: 32, lineHeight: '44px', fontWeight: 700 },
      h5: { fontSize: 28, lineHeight: '36px', fontWeight: 700 },
      h6: { fontSize: 20, lineHeight: '30px', fontWeight: 700 },
      subtitle1: {
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 600,
      },
      body1: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '18px',
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      fontWeightBold: 600,
    },
    shadows: [
      'none',
      '2px 4px 10px rgba(0, 0, 0, 0.05)',
      'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
      '0px 10px 16px rgba(0, 0, 0, 0.1)',
      '0px 16px 22px rgba(0, 0, 0, 0.08)',
      '0px 28px 32px rgba(0, 0, 0, 0.08)',
      '0px 32px 64px rgba(0, 0, 0, 0.08)',
      ...shadows.slice(7),
    ] as any,
    palette: {
      mode: mode,
      text:
        mode === 'dark'
          ? {
              primary: '#fff',
              secondary: '#888993',
              disabled: 'rgba(255, 255, 255, 0.5)',
            }
          : {
              primary: '#31211B',
              secondary: '#637381',
            },
      background: background,
      primary: {
        dark: '#E33D3D',
        main: '#FF490C',
        light: '#FDEBE5',
      },
      secondary: {
        main: '#46B52B',
        light: '#D4FFCD',
      },
      grey: {
        900: '#16191D',
        800: '#2E3338',
        700: '#464C53',
        600: '#5E656E',
        500: '#777F88',
        400: '#93999F',
        300: '#AFB2B6',
        200: '#CACCCE',
        100: '#E5E5E6',
        50: '#F2F2F3',
      },
      success: {
        main: '#46B52B',
        light: '#D4FFCD',
      },
      info: {
        main: '#00ADED',
        400: '#3DCFF4',
        300: '#63E5F9',
        200: '#97F6FD',
        100: '#CBFEFD',
      },
      error: {
        main: '#FC4C46',
        400: '#FD8474',
        300: '#FEA68F',
        200: '#FECAB5',
        100: '#FEE7DA',
      },
      warning: {
        main: '#FFAE00',
      },
    },
  });
};

export const MUITheme = (mode?: PaletteMode) => {
  return LightTheme(mode);
};
