import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import SchemaForm from '../../../common/SchemaForm';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { loginFnc } from '../../../redux/authReducer';
import { AppState } from '../../../redux/store';
import { EMAIL_REGEX } from '../../../config/regex';
import { LoginBG, LogoImage, LogoText } from '../../../svg';
import { FormattedMessage } from 'react-intl';
import MyLink from '../../../common/components/MyLink';
import { ROUTES_PATH } from '../../../layout/constants';
import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';

interface Props {}

const LoginPage = (props: Props) => {
  const { loading, validate } = useSelector(
    (state: AppState) => state.auth,
    shallowEqual
  );
  const { dispatch, isMobile } = useGeneralHook();

  const login = (values) => {
    dispatch(loginFnc(values));
  };

  if (validate) {
    return <MuiLoading sx={{ height: '100vh' }} />;
  }

  return (
    <>
      <Box
        display="flex"
        height="100vh"
        width={'100vw'}
        bgcolor="background.paper"
      >
        <Box display="flex" flexDirection="column" height="100%" flex={1}>
          <Box
            display="flex"
            alignItems={'center'}
            height="100%"
            flex={1}
            sx={(theme) => ({
              [theme.breakpoints.down('tablet')]: {
                px: 4,
                pb: 12,
                pt: 4,
                justifyContent: 'flex-start',
              },
            })}
          >
            {!isMobile && (
              <Box flex={1}>
                <img src={LoginBG} alt="login_bg" className="w-full" />
              </Box>
            )}
            <Box className="flex-1 shrink-0 flex flex-col justify-center items-center px-10 py-2 h-full">
              <Box flex={1} />
              <SchemaForm
                onSubmit={login}
                formHookProps={{
                  defaultValues: {
                    email: 'admin@huongvietsinh.com',
                    password: '12344321',
                  },
                }}
                formProps={{ sx: { maxWidth: 360 } }}
                schema={{
                  fields: ({ formProps: { intl, state, setState } }) => {
                    return {
                      title: {
                        mode: 'node',
                        render: () => (
                          <Box className="flex flex-col items-center justify-center">
                            <Box className="flex gap-2 items-center  mb-10">
                              <LogoImage style={{ height: 50, width: 50 }} />
                              <LogoText />
                            </Box>
                            <Typography variant="h4" color="primary">
                              <FormattedMessage id="login" />
                            </Typography>
                          </Box>
                        ),
                      },
                      email: {
                        mode: 'text-field',
                        placeholder: intl.formatMessage({ id: 'email' }),
                        rules: {
                          required: true,
                          pattern: {
                            value: EMAIL_REGEX,
                            message: intl.formatMessage({
                              id: 'invalid_email',
                            }),
                          },
                        },
                      },
                      password: {
                        mode: 'text-field',
                        type: state.showPassword ? 'text' : 'password',
                        placeholder: intl.formatMessage({ id: 'password' }),
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setState({
                                    showPassword: !state.showPassword,
                                  });
                                }}
                                color="primary"
                              >
                                {state.showPassword ? (
                                  <VisibilityOffRounded />
                                ) : (
                                  <VisibilityRounded />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      },
                      forgotPassword: {
                        mode: 'node',
                        render: () => (
                          <Box className="flex items-center justify-end">
                            <MyLink to={ROUTES_PATH.forgotPassword}>
                              <Typography variant="caption">
                                <FormattedMessage id="forgotPassword" />
                              </Typography>
                            </MyLink>
                          </Box>
                        ),
                      },
                      submit: {
                        mode: 'button',
                        type: 'submit',
                        children: intl.formatMessage({ id: 'login' }),
                        variant: 'outlined',
                        fullWidth: true,
                        loading: loading,
                      },
                    };
                  },
                }}
              />
              <Box flex={1} />
              <Typography className="">
                <FormattedMessage id="footer" />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LoginPage;
