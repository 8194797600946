import Box, { BoxProps } from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { uniq } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import SelectCustom from '../SchemaForm/element/select/SelectCustom';
import { DEFAULT_PAGESIZE } from '../constants';
import useGeneralHook from '../hook/useGeneralHook';
export interface MuiPaginationCustomProps {
  count?: number;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange: (value: number) => void;
  onRowsPerPageChange: (value) => void;
  boxProps?: BoxProps;
}
function MuiPaginationCustom(props: MuiPaginationCustomProps) {
  const {
    count = 0,
    page = 0,
    rowsPerPage = DEFAULT_PAGESIZE,
    rowsPerPageOptions = [20, 50, 75, 100],
    onPageChange,
    onRowsPerPageChange,
    boxProps,
  } = props;
  const { isMobile } = useGeneralHook();

  const controlKeyDown = useCallback(
    (event) => {
      event.stopPropagation();
      if (document.activeElement !== document.body) {
        return;
      }
      const key = event.keyCode;
      if (key === 37) {
        if (page <= 1) {
          return;
        }
        onPageChange(page - 1);
        // left arrow
      } else if (key === 39) {
        if (page === Math.ceil(count / rowsPerPage)) {
          return;
        }
        onPageChange(page + 1);
        // right arrow
      }
    },
    [count, onPageChange, page, rowsPerPage]
  );

  useEffect(() => {
    window.addEventListener('keydown', controlKeyDown);
    return () => window.removeEventListener('keydown', controlKeyDown);
  }, [controlKeyDown]);

  return (
    <Box sx={{ mb: 1, mt: 2 }} {...boxProps}>
      <Typography
        variant="caption"
        component={'div'}
        className="flex items-center gap-2"
      >
        {!isMobile && <FormattedMessage id="display" />}
        <SelectCustom
          value={rowsPerPage}
          formControlProps={{ sx: { width: 70 } }}
          style={{ height: 24, paddingTop: 4 }}
          options={uniq([...rowsPerPageOptions, rowsPerPage])
            ?.map((v) => ({
              id: v,
              name: v,
            }))
            .sort((a, b) => a.id - b.id)}
          disableClearBtn
          onChange={onRowsPerPageChange}
        />
        {!isMobile && (
          <>
            <FormattedMessage id="of_result" values={{ num: count }} />
          </>
        )}
      </Typography>
      <Box className="flex justify-center mt-2">
        <Pagination
          showFirstButton
          showLastButton
          page={page}
          count={Math.ceil(count / rowsPerPage)}
          onChange={(e, page) => {
            onPageChange(page);
          }}
          variant="outlined"
          color="secondary"
          shape="rounded"
          size={isMobile ? 'small' : 'medium'}
        />
      </Box>
    </Box>
  );
}
export default MuiPaginationCustom;
