import { DeleteRounded } from '@mui/icons-material';
import {
  Box,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import SchemaElement from '../../../../common/SchemaForm/SchemaElement';
import MuiTable from '../../../../common/components/MuiTable';
import { some } from '../../../../common/constants';
import FilterBox from './FilterBox';

interface Props {}
const ListFoodSelector = (props: Props) => {
  const methods = useFormContext();
  const { control, formState, getFieldState, getValues } = methods;
  const arrayField = useFieldArray({
    control,
    name: 'disks',
    keyName: 'key',
    rules: { validate: (val) => val?.length > 0 },
  });
  const { remove } = arrayField;
  const { error } = getFieldState('disks', formState);

  const mappedFields = getValues('disks')?.map((item: some, index) => {
    const { ingredient = [], usageRatio, ...rest } = item;
    const ingredientSummary = ingredient.map((val, idx) => ({
      ...val,
      pathName: `disks[${index}].ingredient.${idx}`,
      protein: ((val.amount || 0) * val.protein * val.usageRatio) / 100,
      lipid: ((val.amount || 0) * val.lipid * val.usageRatio) / 100,
      glucid: ((val.amount || 0) * val.glucid * val.usageRatio) / 100,
      calorie: ((val.amount || 0) * val.calorie * val.usageRatio) / 100,
    }));
    return {
      ...rest,
      ingredient: ingredientSummary,
      pathName: `disks[${index}]`,
      amount: ingredient?.reduce((v, c) => v + Number(c.amount), 0),
      usageRatio: usageRatio,
      protein:
        (ingredientSummary?.reduce((v, c) => v + c.protein, 0) * usageRatio) /
        100,
      lipid:
        (ingredientSummary?.reduce((v, c) => v + c.lipid, 0) * usageRatio) /
        100,
      glucid:
        (ingredientSummary?.reduce((v, c) => v + c.glucid, 0) * usageRatio) /
        100,
      calorie:
        (ingredientSummary?.reduce((v, c) => v + c.calorie, 0) * usageRatio) /
        100,
    };
  });
  return (
    <>
      <Box sx={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 1,
          }}
        >
          <FilterBox arrayField={arrayField} />
        </Box>
        <MuiTable<some>
          boxProps={{ sx: { p: 0 } }}
          data={mappedFields ?? []}
          initialState={
            {
              // expanded: true,
            }
          }
          // enableExpandAll
          enableExpanding
          enableRowActions
          enableTableFooter={mappedFields?.length > 0}
          getRowId={(originalRow) => originalRow.key}
          renderRowActions={({ row }) => [
            row.depth === 0 && (
              <Tooltip title={<FormattedMessage id="delete" />} key={'delete'}>
                <IconButton onClick={() => remove(row.index)}>
                  <DeleteRounded />
                </IconButton>
              </Tooltip>
            ),
          ]}
          manualSorting={false}
          muiTableProps={{
            sx: {
              '& .Mui-TableBodyCell-DetailPanel': {
                '& div': {
                  padding: 0,
                },
              },
            },
          }}
          getSubRows={(originalRow) => originalRow.ingredient}
          columns={[
            {
              accessorKey: 'id',
              header: 'id',
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'name',
              header: 'name',
              Header: () => 'Tên món ăn chế biến',
            },
            {
              accessorKey: 'amount',
              header: 'amount',
              Header: () => `Định lượng (g / suất ăn)`,
              size: 50,
              Cell: ({ row }) => {
                return (
                  <>
                    {row.depth ? (
                      <SchemaElement
                        fieldName={`${row.original.pathName}.amount`}
                        propsElement={{
                          mode: 'text-field',
                          type: 'number',
                          noHelperText: true,
                          rules: { required: true },
                          sx: { width: 65 },
                          inputProps: {
                            style: { textAlign: 'center', padding: 4 },
                          },
                        }}
                      />
                    ) : (
                      <Box sx={{ width: 60 }}>
                        <FormattedNumber value={row.original.amount} />
                      </Box>
                    )}
                  </>
                );
              },
            },
            {
              accessorKey: 'usageRatio',
              header: 'usageRatio',
              Header: () => `% ăn được`,
              size: 50,
              Cell: ({ row }) => {
                return (
                  <>
                    <SchemaElement
                      fieldName={`${row.original.pathName}.usageRatio`}
                      propsElement={{
                        mode: 'text-field',
                        type: 'number',
                        noHelperText: true,
                        rules: { required: true, max: 100 },

                        sx: { width: 65 },
                        inputProps: {
                          style: { textAlign: 'center', padding: 4 },
                        },
                        mapperValue: (e) => {
                          return e.target.value
                            ? Number(e.target.value) < 100
                              ? Math.abs(Math.floor(Number(e.target.value)))
                              : 100
                            : '';
                        },
                      }}
                    />
                  </>
                );
              },
            },
            {
              accessorKey: 'dinhluong',
              header: 'dinhluong',
              Header: () => `Định lượng sống`,
              size: 50,
            },
            {
              accessorKey: 'protein',
              header: 'protein',
              enableSorting: true,
              Header: () => <FormattedMessage id="order.protein" />,
              size: 50,
              Cell: ({ row }) => {
                return <FormattedNumber value={row.original.protein} />;
              },
              Footer: ({ column }) => {
                return (
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ textAlign: 'center' }}
                  >
                    <FormattedNumber
                      value={
                        mappedFields?.reduce((v, c) => v + c.protein, 0) || 0
                      }
                    />
                  </Typography>
                );
              },
            },
            {
              accessorKey: 'lipid',
              header: 'lipid',
              enableSorting: true,
              Header: () => <FormattedMessage id="order.lipid" />,
              size: 50,
              Cell: ({ row }) => {
                return <FormattedNumber value={row.original.lipid} />;
              },
              Footer: ({ column }) => {
                return (
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ textAlign: 'center' }}
                  >
                    <FormattedNumber
                      value={
                        mappedFields?.reduce((v, c) => v + c.lipid, 0) || 0
                      }
                    />
                  </Typography>
                );
              },
            },
            {
              accessorKey: 'glucid',
              header: 'glucid',
              enableSorting: true,
              Header: () => <FormattedMessage id="order.glucid" />,
              size: 50,
              Cell: ({ row }) => {
                return <FormattedNumber value={row.original.glucid} />;
              },
              Footer: ({ column }) => {
                return (
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ textAlign: 'center' }}
                  >
                    <FormattedNumber
                      value={
                        mappedFields?.reduce((v, c) => v + c.glucid, 0) || 0
                      }
                    />
                  </Typography>
                );
              },
            },

            {
              accessorKey: 'calorie',
              header: 'calorie',
              enableSorting: true,
              Header: () => <FormattedMessage id="order.calorie" />,
              size: 50,
              Cell: ({ row }) => {
                return <FormattedNumber value={row.original.calorie} />;
              },
              Footer: ({ column }) => {
                return (
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    sx={{ textAlign: 'center' }}
                  >
                    <FormattedNumber
                      value={
                        mappedFields?.reduce((v, c) => v + c.calorie, 0) || 0
                      }
                    />
                  </Typography>
                );
              },
            },
          ]}
        />
      </Box>
      {error?.root && (
        <FormHelperText sx={{ height: 8, mb: 1 }} error={true} component="div">
          <Typography variant="caption" color="inherit" noWrap component="div">
            {'Phải có ít nhất 1 món ăn'}
          </Typography>
        </FormHelperText>
      )}
    </>
  );
};
export default ListFoodSelector;
