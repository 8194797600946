import { LoadingButtonProps } from '@mui/lab';
import { BoxProps, GridProps, PaperProps } from '@mui/material';
import React, { ReactNode } from 'react';
import {
  BrowserNativeObject,
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  RegisterOptions,
  UseFormProps,
  UseFormReturn,
  UseFormStateReturn,
} from 'react-hook-form';
import { E164_REGEX } from '../../config/regex';
import useGeneralHook from '../hook/useGeneralHook';
import { PropsArrayElement } from './element/array-element/ArrayElement';
import { PropsCheckBoxElement } from './element/checkbox/CheckBoxElement';
import { PropsDateRangePickerElement } from './element/date-range/DateRangePickerElement';
import { PropsDatePickerElement } from './element/datepicker-element/DatePickerElement';
import { PropsDateTimePickerElement } from './element/datepickerTime-element/DateTimePickerElement';
import { PropsDropZoneElement } from './element/drop-zone/DropZoneElement';
import { PropsMultipleCheckBoxElement } from './element/multiple-checkbox/MultipleCheckBoxElement';
import { PropsMultipleRadioElement } from './element/multiple-radio/MultipleRadioElement';
import countryphonecodes from './element/phone-input/countryphonecodes.json';
import { PropsRadioElement } from './element/radio/RadioElement';
import { PropsSectionElement } from './element/section-element/SectionElement';
import { PropsSelectElement } from './element/select/SelectElement';
import { PropsSwitchElement } from './element/switch/SwitchElement';
import { TextEditorElementProps } from './element/text-editor/TextEditorElement';
import { TextFieldElementProps } from './element/text-field/TextFieldElement';
import { PropsTimePickerElement } from './element/timepicker-element/TimePickerElement';
import { UploadFileElementProps } from './element/uploadFile/UploadFileElement';
import { PropsAutoCompleteElement } from './element/autocomplete/AutoCompleteElement';
export interface IControllerRenderProps<
  T extends FieldValues = any,
  TName extends FieldPath<T> = FieldPath<T>
> {
  field: ControllerRenderProps<T, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

export type ElementMode =
  | 'node'
  | 'hidden'
  | 'text'
  | 'text-field'
  | 'phone-field'
  | 'auto-complete'
  | 'select'
  | 'datePicker'
  | 'button'
  | 'drop-zone'
  | 'switch'
  | 'checkbox'
  | 'multiple-checkbox'
  | 'radio'
  | 'multiple-radio'
  | 'array'
  | 'section'
  | 'dateTimePicker'
  | 'timePicker'
  | 'dateRange'
  | 'text-editor'
  | 'uploadImage'
  | 'uploadFile'
  | 'raw';

/*---------------------------*/

export interface ElementBaseProps {
  mode: ElementMode;
  key_element?: string;
  rules?: Omit<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  propsWrapper?: GridProps;
  defaultValue?: any;
  tooltipError?: boolean;
  noHelperText?: boolean;
  hidden?: boolean;
  unregister?: boolean;
  shouldUnregister?: boolean;
  mapperValue?: (...parmas) => any;
}

interface IHiddenElement {
  type?: 'hiddenField';
}

export interface IDropZoneElement
  extends ElementBaseProps,
    PropsDropZoneElement {
  mode: 'drop-zone';
}
export interface INodeElement {
  mode: 'node';
  render?: (props: IControllerRenderProps) => React.ReactElement;
}
export interface IRawElement<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> {
  mode: 'raw';
  render?: (props: IControllerRenderProps<T, TName>) => React.ReactElement;
}

export interface IArrayElement extends ElementBaseProps, PropsArrayElement {
  mode: 'array';
}
export interface IButtonElement
  extends ElementBaseProps,
    Omit<LoadingButtonProps, 'defaultValue'> {
  mode: 'button';
  label?: React.ReactNode | string;
}
export interface ITextElement {
  mode: 'text';
  label?: React.ReactNode;
  boxProps: BoxProps;
}
export interface ITextFieldElement
  extends ElementBaseProps,
    TextFieldElementProps {
  mode: 'text-field';
}
export interface ISectionElement extends ElementBaseProps, PropsSectionElement {
  mode: 'section';
}
export interface IUploadFileElement
  extends ElementBaseProps,
    UploadFileElementProps {
  mode: 'uploadFile';
}
export interface ICheckBoxElement
  extends ElementBaseProps,
    PropsCheckBoxElement {
  mode: 'checkbox';
}

export interface IMultipleCheckBoxElement
  extends ElementBaseProps,
    PropsMultipleCheckBoxElement {
  mode: 'multiple-checkbox';
}

export interface IRadioElement extends ElementBaseProps, PropsRadioElement {
  mode: 'checkbox';
}
export interface ISelectElement extends ElementBaseProps, PropsSelectElement {
  mode: 'select';
}

export interface IMultipleRadioElement
  extends ElementBaseProps,
    PropsMultipleRadioElement {
  mode: 'multiple-radio';
}

export interface ISwitchElement extends ElementBaseProps, PropsSwitchElement {
  mode: 'switch';
}
export interface IDatePickerElement
  extends ElementBaseProps,
    PropsDatePickerElement {
  mode: 'datePicker';
}
export interface IDateTimePickerElement
  extends ElementBaseProps,
    PropsDateTimePickerElement {
  mode: 'dateTimePicker';
}
export interface ITimePickerElement
  extends ElementBaseProps,
    PropsTimePickerElement {
  mode: 'timePicker';
}
export interface IDateRangePickerElement
  extends ElementBaseProps,
    PropsDateRangePickerElement {
  mode: 'dateRange';
}

export interface IAutoCompleteElement
  extends ElementBaseProps,
    Omit<PropsAutoCompleteElement, 'defaultValue'> {
  mode: 'auto-complete';
}
export interface ITextEditorElement
  extends ElementBaseProps,
    TextEditorElementProps {
  mode: 'text-editor';
}

export type ElementFormProps<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
> =
  | ElementBaseProps
  | IDropZoneElement
  | IHiddenElement
  | IAutoCompleteElement
  | ITextFieldElement
  | ITextElement
  | IUploadFileElement
  | ICheckBoxElement
  | IMultipleCheckBoxElement
  | IRadioElement
  | ISelectElement
  | IMultipleRadioElement
  | ISwitchElement
  | IDatePickerElement
  | IDateTimePickerElement
  | ITimePickerElement
  | IDateRangePickerElement
  | IButtonElement
  | ISectionElement
  | ITextEditorElement
  | IArrayElement
  | IRawElement<T, TName>
  | INodeElement;

type SchemaTypeTmp<T extends FieldValues = FieldValues> = {
  [key in keyof Partial<T>]: ElementFormProps<T, FieldPath<T>>;
} & {
  [key: string]: ElementFormProps<T>;
};

export type SchemaType<T extends FieldValues = FieldValues> = SchemaTypeTmp<T>;

/*---------------------------*/

export interface FormProps
  extends ReturnType<typeof useGeneralHook>,
    FieldValues {}

export type ISchemaFields<T extends FieldValues = FieldValues> = (params: {
  methods: UseFormReturn<T>;
  fieldName?: string;
  formProps: FormProps;
  valuesField: T;
}) => SchemaType<T>;

export interface FieldsElementType {
  fields: ElementFormProps[];
  id: string;
  title?: ReactNode;
  paper?: boolean;
  paperProps?: PaperProps;
  propsWrapper?: GridProps;
  propsGridContainer?: GridProps;
  hidden?: boolean;
  [key: string]: any;
}
export type IUIKey = string;

export interface IUIField<
  T extends FieldValues = FieldValues,
  K extends IUIKey = string
> {
  id: K;
  fields?: (keyof T)[] | (string | undefined)[];
  title?: ReactNode;
  paper?: boolean;
  paperProps?: PaperProps;
  propsWrapper?: GridProps;
  propsGridContainer?: GridProps;
  hidden?: boolean;
  [key: string]: any;
}

export type IUIFieldFnc<
  T extends FieldValues = FieldValues,
  K extends IUIKey = string
> = (params: {
  methods: UseFormReturn<T>;
  formProps: FormProps;
  valuesField?: any;
}) => IUIField<T, K>[];

export type IUIFieldSchema<
  T extends FieldValues = FieldValues,
  K extends IUIKey = string
> = IUIFieldFnc<T, K> | IUIField<T, K>[];

type ILayoutFieldParam<T extends FieldValues, Keys extends IUIKey = string> = {
  [K in Keys]: Record<keyof T, ReactNode> & Record<string, ReactNode>;
};

interface ILayoutParams<
  T extends FieldValues,
  K extends IUIKey = string & 'default'
> {
  valuesField: T;
  fields: ILayoutFieldParam<T, K>;
  methods: UseFormReturn<T>;
  formProps: FormProps;
  view: ReactNode;
  listElement: ReactNode[];
}

type ILayoutFields<T extends FieldValues, K extends IUIKey = string> = (
  params: ILayoutParams<T, K>
) => ReactNode;

export interface ISchemaForm<
  T extends FieldValues = FieldValues,
  K extends IUIKey = string
> {
  // propsWrapperContainer?: GridProps;
  propsGridContainer?: GridProps;
  fields: ISchemaFields<T> | SchemaType<T>;
  ui?: IUIFieldSchema<T, K>;
  layout?: ILayoutFields<T, K>;
  changeDataBeforeSubmit?: (values: T, props: FormProps) => any;
}

/*---------------------------*/
declare const $NestedValue: unique symbol;

type NestedValue<TValue extends object = object> = {
  [$NestedValue]: never;
} & TValue;

export type FieldValues = Record<string, any>;

export type DeepPartial<T> = T extends BrowserNativeObject | NestedValue
  ? T
  : {
      [K in keyof T]?: DeepPartial<T[K]>;
    };

type AsyncDefaultValues<T> = (payload?: unknown) => Promise<T>;

export type DefaultValues<T> = T extends AsyncDefaultValues<T>
  ? DeepPartial<Awaited<T>>
  : DeepPartial<T>;

export type SchemaFieldName =
  | `${string}`
  | `${string}.${string}`
  | `${string}.${number}`;
export interface PropsForm<
  T extends FieldValues = FieldValues,
  K extends IUIKey = string
> {
  schema?: ISchemaForm<T, K>;
  formData?: T;
  onChange?: (value: T, methods: UseFormReturn<T>) => void;
  formHookProps?: UseFormProps<T>;
  formId?: string;
  fieldName?: SchemaFieldName;
  onSubmit?: (data: any, methods: UseFormReturn<T>) => void | Promise<void>;
  methods?: UseFormReturn<T>;
  hiddenField?: FieldValues;
  notForm?: boolean;
  loading?: boolean;
  formProps?: BoxProps;
  showConfirmBeforeLeave?: boolean | React.ReactNode;
  propsGridContainer?: GridProps;
  globalProps?: FieldValues;
  [key: string]: any;
  initialState?: any;
}

export const mergeFieldName = (params: {
  name: string;
  parent?: string;
  index?: number;
}) => {
  const { name, parent, index } = params;
  if (parent && typeof index === 'number') {
    return `${parent}.${index}.${name}`;
  }
  if (parent) {
    return `${parent}.${name}`;
  }
  if (typeof index === 'number') {
    return `${name}.${index}`;
  }
  return name;
};

/*---------------------------*/
export const submitForm = (id: string) => {
  const submitBtn = document.getElementById(id);
  if (
    submitBtn &&
    submitBtn.tagName === 'INPUT' &&
    submitBtn.getAttribute('type') === 'submit'
  ) {
    submitBtn.click();
  }
};

export const validatePhoneNumber = (value: string, intl: any) => {
  return value
    ? !!countryphonecodes.find((v) => v.code === value)
      ? intl.formatMessage({ id: 'required' })
      : E164_REGEX.test(value)
      ? true
      : intl.formatMessage({ id: 'invalidPhoneNumber' })
    : intl.formatMessage({ id: 'required' });
};
