import { ExpandMoreOutlined, TuneOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import SchemaForm from '../../../../common/SchemaForm';
import FormControlAutoComplete from '../../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import { STATUS_OPTIONS, some } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { SelectFoodFilterModel } from './types';
import { ORDER_DATA } from '../../list/types';
import { uniqBy } from 'lodash';

interface Props {
  arrayField: UseFieldArrayReturn<FieldValues, 'disks', 'key'>;
}
const FilterBox = (props: Props) => {
  const { arrayField } = props;
  const { fields, prepend } = arrayField;
  const [filter, setParams] = useState<SelectFoodFilterModel>({});
  const { listType, intl, dispatch, fetchThunk, API_PATHS, isMobile } =
    useGeneralHook();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const options = uniqBy(
    ORDER_DATA.data.reduce((val: some[], cur: some) => {
      return [
        ...val,
        ...cur.listOrder.reduce((v: some[], c: some) => {
          return [...v, ...c.disks];
        }, []),
      ];
    }, []),
    'id'
  );

  return (
    <>
      <Grid container sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {!isMobile && (
          <Grid item mobile={undefined}>
            <Typography>
              {intl.formatMessage({
                id: 'search',
              })}
              :
            </Typography>
          </Grid>
        )}
        <Grid item mobile={true} tablet={6}>
          <FormControlAutoComplete
            value={null}
            placeholder={'Tìm kiếm theo mã, tên món chế biến đã xác nhận'}
            inputMode="text"
            options={options}
            filterOptions={(options) => options}
            disableCloseOnSelect
            // loadOptions={{
            //   queryKey: (text) => [text, 'search'],
            //   queryFn: async ({ pageParam = 1, queryKey }) => {
            //     const json = await dispatch(
            //       fetchThunk({
            //         url: API_PATHS.qualityStandard.index,
            //         params: {
            //           pageIndex: pageParam,
            //           filters: { name: queryKey[0] },
            //         },
            //       })
            //     );
            //     return json;
            //   },
            // }}
            renderOption={(props, option) => {
              return (
                <Box component={'li'} {...props}>
                  {option.name}
                </Box>
              );
            }}
            onChange={(_, value: any) => {
              value && prepend({ ...value });
            }}
            getOptionDisabled={(option) =>
              fields.some((v: some) => v.id === option.id)
            }
          />
        </Grid>
        <Grid item mobile={undefined}>
          <Button
            variant="outlined"
            startIcon={<TuneOutlined />}
            endIcon={!isMobile && <ExpandMoreOutlined />}
            onClick={handleClick}
          >
            {!isMobile &&
              intl.formatMessage({
                id: 'filter',
              })}
          </Button>
        </Grid>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: { borderColor: 'primary.main', p: 2, mt: 2, width: 280 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SchemaForm<SelectFoodFilterModel>
          formHookProps={{ defaultValues: filter }}
          globalProps={{
            noHelperText: true,
          }}
          onChange={(val) => setParams(val)}
          schema={{
            fields: ({ formProps: { intl, isMobile } }) => {
              return {
                status: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  options: STATUS_OPTIONS,
                  hasAllOptions: true,
                  disableClearBtn: true,
                },
                orderType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'orderClassifications.orderType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  hasAllOptions: true,
                  options: listType?.PLDH?.orderType,
                  rawOptions: true,
                },
                nutritionRequired: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'orderClassifications.nutritionRequired',
                  }),
                  hasAllOptions: true,
                  options: [
                    {
                      id: true,
                      name: 'yes',
                    },
                    {
                      id: false,
                      name: 'no',
                    },
                  ],
                },
              };
            },
          }}
        />
      </Popover>
    </>
  );
};
export default FilterBox;
