import {
  Action,
  combineReducers,
  configureStore,
  createAction,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer, { initialStateAuth } from './authReducer';
import commonReducer, { initialStateCommon } from './commonReducer';
import intlReducer, { initialStateIntl } from './intlReducer';
import cacheReducer, { initialStateCache } from './cacheReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const clearStoreAfterLogout = createAction('clearStoreAfterLogout');

const initialState = {
  auth: initialStateAuth,
  intl: initialStateIntl,
  common: initialStateCommon,
  cache: initialStateCache,
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['lead', 'form', 'common'],
};

export default function createRootReducer() {
  const reducers = combineReducers({
    auth: authReducer,
    intl: intlReducer,
    common: commonReducer,
    cache: cacheReducer,
  });

  return (state, action: any) => {
    if (state && action.type === clearStoreAfterLogout) {
      return reducers(initialState, action);
    }
    return reducers(state, action);
  };
}

const persistedReducer = persistReducer(persistConfig, createRootReducer());

export function makeStore(preloadedState?: any) {
  return configureStore({
    preloadedState,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredPaths: [],
        },
      }),
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

let persistor = persistStore(store);
export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<AppState, null, Action>>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store, persistor };
