import { IRouteObject } from '../../common/types';

export function checkPermission(
  permissions: string[],
  listRole?: string[][]
): boolean {
  if (!listRole?.length) {
    return true;
  }
  return !!listRole.find((groupRole) => {
    return groupRole.every((role) => {
      return permissions?.includes(role);
    });
  });
}

export const mappedRoutesPermission = (
  permissions: string[],
  list: IRouteObject[]
): IRouteObject[] => {
  return permissions
    ? (list
        .map((one) => {
          const children = one.children
            ? mappedRoutesPermission(permissions, one.children)
            : [];

          if (!checkPermission(permissions, one.listRole)) {
            return undefined;
          }
          if (
            one.children &&
            one.children?.length > 0 &&
            children?.length === 0
          ) {
            return undefined;
          }
          return {
            ...one,
            children:
              one.children && mappedRoutesPermission(permissions, one.children),
          };
        })
        .filter(Boolean) as IRouteObject[])
    : list;
};
