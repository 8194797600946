import React, { useContext, useEffect, useState } from 'react';
import MessageDialog, { MessageDialogParams } from './MessageDialog';

const DUMMY_VALUE: MessageDialogParams = {
  openDialog: () => {},
};

const WarningDialogContext =
  React.createContext<MessageDialogParams>(DUMMY_VALUE);

export const useMessageDialog = () => {
  const ref = useContext(WarningDialogContext);
  return ref;
};

const MessageDialogProvider: React.FunctionComponent<any> = ({ children }) => {
  const ref = React.useRef<MessageDialogParams>(DUMMY_VALUE);

  const [ref_state, setRefState] = useState<MessageDialogParams>(DUMMY_VALUE);

  useEffect(() => {
    if (!ref.current) {
      return;
    } else {
      setRefState(ref.current);
    }
  }, []);

  return (
    <WarningDialogContext.Provider value={ref_state}>
      {children}
      <MessageDialog ref={ref} />
    </WarningDialogContext.Provider>
  );
};
export default MessageDialogProvider;
