import { Box, Paper, Portal, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ProcessingDishModel } from '../types';
import OriginalDishInfoBox from '../detail/OriginalDishInfoBox';
import { useAppSelector } from '../../../redux/store';
interface Props {
  data: ProcessingDishModel;
  readOnly?: boolean;
}
const InfoBox = (props: Props) => {
  const { data, readOnly } = props;
  const container = document.getElementById('updateDishQuotaBreadcrumb');
  const containerTitle = document.getElementById('breadcrumbTitle');
  const listType = useAppSelector((state) => state.common.listType);
  return (
    <>
      {containerTitle && data && (
        <Portal container={containerTitle}>
          <FormattedMessage
            id={
              readOnly
                ? 'processingDish.viewQuota'
                : 'processingDish.updateQuota'
            }
            values={{ name: data?.name }}
          />
        </Portal>
      )}
      {container && data && <Portal container={container}>{data?.name}</Portal>}
      <Paper className="p-2 tablet:p-4 columns-1 tablet:columns-2 md:columns-3 gap-4">
        {[
          {
            label: <FormattedMessage id={'processingDish.name'} />,
            value: data?.name,
          },
          {
            label: <OriginalDishInfoBox data={data.originalDish} />,
          },
          {
            label: <FormattedMessage id={'processingDish.operationCode'} />,
            value: data?.operationCode,
          },
          {
            label: <FormattedMessage id={'processingDish.originalDish'} />,
            value: data?.originalDish?.name,
          },
          {
            label: <FormattedMessage id={'processingDish.displayName'} />,
            value: data?.displayName,
          },

          {
            label: <FormattedMessage id={'processingDish.nutritionRequired'} />,
            value: (
              <FormattedMessage id={data?.nutritionRequired ? 'yes' : 'no'} />
            ),
          },
          {
            label: <FormattedMessage id={'processingDish.mainFoodQuantity'} />,
            value: data?.mainFoodQuantity,
          },
          {
            label: <FormattedMessage id={'processingDish.objectType'} />,
            value: listType.MCB?.objectType?.find(
              (v) => v.id === data.objectType
            )?.name,
          },
          {
            label: <FormattedMessage id={'description'} />,
            value: data?.description,
          },
        ].map((item, index) => {
          return (
            <Box key={index} className="flex break-inside-avoid">
              <Typography variant="subtitle2" component={'div'} flexShrink={0}>
                {item.label}
              </Typography>
              <Typography
                variant="body2"
                sx={{ wordBreak: 'break-all', display: 'flex' }}
              >
                {item.value ? <>:&nbsp;{item.value}</> : item.value}
              </Typography>
            </Box>
          );
        })}
      </Paper>
    </>
  );
};
export default InfoBox;
