import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { AppState } from '../../redux/store';
import { ROUTES_PATH } from '../../layout/constants';

function RedirectRoute({ children }: { children: JSX.Element }) {
  const { isLogin } = useSelector((state: AppState) => state.auth);
  let location = useLocation();
  if (isLogin) {
    let from = '/' + ROUTES_PATH.dashboard;

    if (location.search && location.pathname !== ROUTES_PATH[404]) {
      const params = new URLSearchParams(location.search);
      const fromParamValue = params.get('from');
      if (fromParamValue && !fromParamValue.includes(ROUTES_PATH[404])) {
        from = fromParamValue;
      }
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={from} state={{ from: location }} replace />;
  }

  return children;
}

export default RedirectRoute;
