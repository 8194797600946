import { ForwardRounded, SaveRounded, UndoRounded } from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import ListFoodSelector from './ListFoodSelector';
import { OrderDetailModel } from './types';
import { useMessageDialog } from '../../../common/components/MessageDialogProvider';

interface Props {
  formData?: OrderDetailModel['listOrder'][0];
}
const OrderRegisterForm = (props: Props) => {
  const { formData } = props;
  const { openDialog } = useMessageDialog();
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar } = useGeneralHook();

  const { id } = useParams<{ id: string }>();
  const { key } = useLocation();
  const navigate = useNavigate();

  const onCreateUpdate = useMutation({
    mutationFn: async (values: OrderDetailModel) => {
      openDialog({
        title: 'Thực đơn được lưu thành công',
        onClose: () => {
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.list.index,
                ROUTES_PATH.channelDistributions.index,
                id,
                ROUTES_PATH.orderClassifications.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        },
      });
      // if (!values.id) {
      //   await dispatch(
      //     fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
      //       url: API_PATHS.orderClassifications.index(id),
      //       method: 'post',
      //       data: values,
      //     })
      //   );
      //   enqueueSnackbar({
      //     message: <FormattedMessage id={'createSuccess'} />,
      //     variant: 'success',
      //   });
      // } else {
      //   await dispatch(
      //     fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
      //       url: API_PATHS.orderClassifications.detail(id, values.id),
      //       method: 'put',
      //       data: values,
      //     })
      //   );
      //   enqueueSnackbar({
      //     message: <FormattedMessage id={'updateSuccess'} />,
      //     variant: 'success',
      //   });
      // }
      // if (key === 'default') {
      //   navigate(
      //     [
      //       '',
      //       ROUTES_PATH.list.index,
      //       ROUTES_PATH.channelDistributions.index,
      //       id,
      //       ROUTES_PATH.orderClassifications.index,
      //     ].join('/')
      //   );
      // } else {
      //   navigate(-1);
      // }
    },
  });

  return (
    <SchemaForm<
      OrderDetailModel['listOrder'][0] & { activeStep?: number },
      'step1' | 'step2'
    >
      formData={{ ...(formData as any), activeStep: 1 }}
      onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
      schema={{
        fields: ({
          formProps: { intl, isMobile, listType },
          methods: { trigger, setValue },
          valuesField,
        }) => {
          return {
            title: {
              mode: 'node',
              render: () => (
                <Stepper
                  nonLinear
                  activeStep={valuesField.activeStep}
                  sx={{ maxWidth: 630, mx: 'auto' }}
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                >
                  {[
                    'Chọn kết cấu thực đơn và số lượng món',
                    'Thiết kế thực đơn',
                  ].map((label, index) => (
                    <Step
                      key={label}
                      completed={(valuesField.activeStep || 0) > index}
                    >
                      <StepLabel color="inherit">{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ),
              propsWrapper: { sx: { textAlign: 'center' } },
            },
            type: {
              mode: 'select',
              label: 'Phân loại kết cấu thực đơn',
              rules: { required: true },
              propsWrapper: { tablet: 4 },
              options: listType?.PNTPTC?.resourceType,
              rawOptions: true,
            },
            blank: {
              mode: 'node',
              hidden: isMobile,
              propsWrapper: { mobile: 0, tablet: 8 },
            },
            quantityCooking: {
              mode: 'text-field',
              type: 'number',
              label: 'Số lượng nấu món ăn',
              rules: { required: true },
              propsWrapper: { tablet: 4 },
            },
            quantityImproved: {
              mode: 'text-field',
              type: 'number',
              label: 'Số lượng món cải thiện',
              rules: { required: true },
              propsWrapper: { tablet: 4 },
            },
            quantityDessert: {
              mode: 'text-field',
              type: 'number',
              label: 'Số lượng món tráng miệng',
              rules: { required: true },
              propsWrapper: { tablet: 4 },
            },
            disks: {
              mode: 'node',
              render: () => <ListFoodSelector />,
            },
            back: {
              mode: 'button',
              variant: 'outlined',
              startIcon: <UndoRounded />,
              children: intl.formatMessage({
                id: 'back',
              }),
              onClick: () => {
                if (valuesField.activeStep !== 1) {
                  if (key === 'default') {
                    navigate(
                      [
                        '',
                        ROUTES_PATH.norms.index,
                        ROUTES_PATH.order.index,
                      ].join('/')
                    );
                  } else {
                    navigate(-1);
                  }
                } else {
                  setValue('activeStep', 0);
                }
              },
              propsWrapper: {
                mobile: true,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
            continue: {
              mode: 'button',
              variant: 'contained',
              startIcon: <ForwardRounded />,
              children: intl.formatMessage({
                id: 'continue',
              }),
              propsWrapper: {
                mobile: undefined,
              },
              onClick: async () => {
                const valid = await trigger();
                if (valid) {
                  setValue('activeStep', 1);
                }
              },
            },
            submit: {
              mode: 'button',
              type: 'submit',
              variant: 'contained',
              startIcon: <SaveRounded />,
              children: intl.formatMessage({
                id: 'save',
              }),
              propsWrapper: {
                mobile: undefined,
              },
            },
          };
        },
        ui: ({ valuesField }) => {
          return [
            {
              id: 'step1',
              // hidden: !valuesField.activeStep,
              hidden: true,
              fields: [
                'title',
                'type',
                'blank',
                'quantityCooking',
                'quantityImproved',
                'quantityDessert',
                'back',
                'continue',
              ],
            },
            {
              id: 'step2',
              // hidden: valuesField.activeStep === 1,
              fields: ['title', 'disks', 'back', 'submit'],
            },
          ];
        },
      }}
    />
  );
};
export default OrderRegisterForm;
