import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { unstable_usePrompt } from 'react-router-dom';
import useGeneralHook from '../hook/useGeneralHook';
import SchemaView from './SchemaView';
import { FieldValues, IUIKey, PropsForm } from './utils';

export const FormMain = <
  T extends FieldValues = FieldValues,
  K extends IUIKey = 'default'
>(
  props: PropsForm<T, K>
) => {
  const {
    schema,
    formId,
    fieldName = '',
    onSubmit,
    formData,
    hiddenField,
    methods: methodsProps,
    formProps,
    notForm,
    showConfirmBeforeLeave,
    propsGridContainer,
    readOnly,
    formHookProps,
    initialState,
  } = props;
  const mainProps = useGeneralHook({ initialState });
  const defaultMethods = useForm<T>({
    values: formData,
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    ...formHookProps,
  });

  const methods = useMemo((): UseFormReturn<any, object> => {
    return methodsProps || defaultMethods;
  }, [methodsProps, defaultMethods]);

  const onSubmitForm = async (data: any) => {
    if (readOnly) {
      return;
    }
    try {
      let dataFilter = { ...hiddenField, ...data };

      let dataMerge = schema?.changeDataBeforeSubmit
        ? schema?.changeDataBeforeSubmit(dataFilter, {
            ...props,
            ...mainProps,
          })
        : dataFilter;

      onSubmit && (await onSubmit(dataMerge, methods));
    } finally {
    }
  };

  const content = useMemo(() => {
    return (
      schema && (
        <SchemaView<T>
          schema={schema}
          fieldName={fieldName}
          propsGridContainer={propsGridContainer}
          formProps={{
            ...mainProps,
            ...props,
            ...props.globalProps,
          }}
        />
      )
    );
  }, [fieldName, mainProps, props, propsGridContainer, schema]);

  unstable_usePrompt({
    message:
      typeof showConfirmBeforeLeave === 'string'
        ? showConfirmBeforeLeave
        : mainProps.intl.formatMessage({ id: 'youHaveUnsavedData' }),
    when:
      methods?.formState.isDirty &&
      Object.keys(methods?.formState.dirtyFields)?.length > 0 &&
      !!showConfirmBeforeLeave,
  });

  useEffect(() => {
    methods.reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  if (notForm) {
    return content || null;
  }

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component={'form'}
          {...formProps}
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            ...formProps?.style,
          }}
          onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
            // this part is for stopping parent forms to trigger their submit
            if (event) {
              // sometimes not true, e.g. React Native
              if (typeof event.preventDefault === 'function') {
                event.preventDefault();
              }
              if (typeof event.stopPropagation === 'function') {
                // prevent any outer forms from receiving the event too
                event.stopPropagation();
              }
            }

            return methods.handleSubmit(onSubmitForm, (errors) => {
              console.log(errors);
            })(event);
          }}
        >
          {content}
          <input type="submit" id={formId} style={{ display: 'none' }} />
        </Box>
      </FormProvider>
    </>
  );
};

export default FormMain;
