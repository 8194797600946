import { Box, Button, Paper } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import MuiLoading from '../../../common/components/MuiLoading';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { OrderDetailModel } from './types';
import { ResponseDataDetail } from '../../../common/types';
import { StaticDatePicker } from '@mui/x-date-pickers';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import dayjs, { Dayjs } from 'dayjs';
import OrderInfoForm from './OrderInfoForm';
import OrderRegisterForm from './OrderRegisterForm';
import { ORDER_DATA } from '../list/types';

const OrderDetailPage = () => {
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar, isMobile } =
    useGeneralHook();
  const { filters, setParams } = usePaginationHook<{
    serveDate?: string;
    categoryId?: string;
  }>({
    defaultFilter: {
      serveDate: dayjs().toISOString(),
    },
    disablePagination: true,
  });
  const { id } = useParams<{ id: string }>();
  const { key } = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<OrderDetailModel>({
    queryKey: [id, filters.serveDate, KEYS_QUERY.orderDetail],
    queryFn: async ({ queryKey }) => {
      // const json = await dispatch(
      //   fetchThunk<ResponseDataDetail<OrderDetailModel>>({
      //     url: API_PATHS.orderClassifications.detail(queryKey[0], queryKey[1]),
      //   })
      // );
      return ORDER_DATA.data.find((v) =>
        dayjs(v.serveDate).isSame(dayjs(filters.serveDate))
      ) as any;
    },
    enabled: !!id,
  });

  const onCreateUpdate = useMutation({
    mutationFn: async (values: OrderDetailModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
            url: API_PATHS.orderClassifications.index(id),
            method: 'post',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
      } else {
        await dispatch(
          fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
            url: API_PATHS.orderClassifications.detail(id, values.id),
            method: 'put',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
      }
      if (key === 'default') {
        navigate(
          [
            '',
            ROUTES_PATH.list.index,
            ROUTES_PATH.channelDistributions.index,
            id,
            ROUTES_PATH.orderClassifications.index,
          ].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  // if (!isLoading && !data && !!filters.serveDate) {
  //   return <Navigate to={ROUTES_PATH[404]} replace />;
  // }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexDirection: isMobile ? 'column' : 'row',
      }}
    >
      <Paper
        sx={{
          pb: 2,
          width: 320,
          height: 'fit-content',
        }}
        className="tablet:sticky top-4"
      >
        <StaticDatePicker<Dayjs>
          value={filters?.serveDate ? dayjs(filters?.serveDate) : null}
          onChange={(val) => {
            setParams({ serveDate: val?.toISOString() }, true);
          }}
          slotProps={{
            actionBar: { sx: { display: 'none' } },
            toolbar: { sx: { display: 'none' } },
            layout: {
              sx: {
                '& .MuiPickersDay-root.Mui-selected': {
                  bgcolor: (theme) => `${theme.palette.info.main} !important`,
                },
              },
            },
            day: ({ day }) => {
              const list = ORDER_DATA.data?.find((one) =>
                dayjs(one.serveDate)
                  .startOf('date')
                  .isSame(dayjs(day).startOf('date'))
              )?.listOrder;
              const created = !!list ? list?.length > 0 : undefined;
              return {
                sx: {
                  borderRadius: 2,
                  border: 2,
                  borderColor:
                    created === true ? 'success.main' : 'warning.main',
                  bgcolor: created === true ? 'success.main' : 'warning.main',
                  color: created === false ? undefined : 'white',
                  '&:hover': {
                    bgcolor: created === true ? 'success.main' : 'warning.main',
                  },
                },
              };
            },
          }}
          onMonthChange={(val) => {
            console.log(val);
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 2 }}>
          <Button
            variant={!filters.categoryId ? 'contained' : 'outlined'}
            onClick={() => setParams({ categoryId: '' })}
          >
            {'Bộ thực đơn'}
          </Button>
          {(data ? data?.listOrder : ORDER_DATA.data[0].listOrder)?.map(
            (order) => {
              return (
                <Button
                  key={order.id}
                  variant={
                    Number(order.id) === Number(filters.categoryId)
                      ? 'contained'
                      : 'outlined'
                  }
                  onClick={() => setParams({ categoryId: order.id })}
                >
                  <span className="line-clamp-1 w-full">
                    {order.codeOrder.split('-').slice(3, -1).join('-')}
                  </span>
                </Button>
              );
            }
          )}
        </Box>
      </Paper>
      <Paper sx={{ p: 2, flex: 1, overflow: 'hidden' }}>
        {isLoading ? (
          <MuiLoading />
        ) : filters.categoryId ? (
          <OrderRegisterForm
            formData={data?.listOrder.find(
              (v) => Number(v.id) === Number(filters.categoryId)
            )}
          />
        ) : (
          <OrderInfoForm
            formData={
              data || {
                customerName: '',
                orderName: '',
                customerCode: '',
                operationCode: '',
                createAt: '',
                serveAt: '',
                approveAt: '',
                createBy: '',
                status: '',
              }
            }
          />
        )}
      </Paper>
    </Box>
  );
};
export default OrderDetailPage;
