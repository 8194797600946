import { useCallback, useMemo, useState } from 'react';
import { NavigateOptions, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_PAGESIZE, DEFAULT_PAGESIZE_MOBILE, some } from '../constants';
import { MRT_SortingState } from 'material-react-table';
import { PaginationParams, RequestParams, SortFilter } from '../types';
import QueryString, { IParseOptions, IStringifyOptions } from 'qs';
import { removeEmptyKeys } from '../utils';

interface IParams<T> {
  pagination: PaginationParams;
  filters: Partial<T>;
  params: RequestParams<T>;
  tab: string;
  sorting: MRT_SortingState;
  sort?: string;
}
export interface HookPaginationProps<T extends SortFilter | some = any>
  extends ReturnType<typeof usePaginationHook<T>> {}

interface Props<T = some> {
  defaultFilter?: Partial<T & PaginationParams>;
  disableLink?: boolean;
  disablePagination?: boolean;
  options?: IStringifyOptions;
  parseOptions?: IParseOptions & { decoder?: never | undefined };
  rawFields?: string[];
}
const usePaginationHook = <T extends some>(props?: Props<T>) => {
  const {
    defaultFilter = {},
    disableLink,
    disablePagination,
    options,
    parseOptions,
    rawFields,
  } = props || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const optionsTmp = useMemo(
    () =>
      ({
        arrayFormat: 'bracket', // To handle arrays
        encode: true, // To encode the URI components
        sort: true, // Sorting keys alphabetically,
        skipNulls: true,
        ...options,
      } as IStringifyOptions),
    [options]
  );

  const defaultPagination = useMemo(
    () =>
      disablePagination
        ? {}
        : {
            pageIndex: 1,
            pageSize:
              window.innerWidth < 1000
                ? DEFAULT_PAGESIZE_MOBILE
                : DEFAULT_PAGESIZE,
          },
    [disablePagination]
  );

  const location = useLocation();

  const [filter, setFilter] = useState<some>(defaultFilter);

  const paramsUrl = useMemo(() => {
    if (disableLink) {
      return filter;
    }
    const tmp = QueryString.parse(searchParams.toString(), {
      parseArrays: true,
      decoder(str, decoder, charset) {
        const strWithoutPlus = str.replace(/\+/g, ' ');
        if (charset === 'iso-8859-1') {
          // unescape never throws, no try...catch needed:
          return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
        }

        if (/^(\d+|\d*\.\d+)$/.test(str)) {
          return parseFloat(str);
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined,
        };
        if (str in keywords) {
          return keywords[str];
        }

        // utf-8
        try {
          return decodeURIComponent(strWithoutPlus);
        } catch (e) {
          return strWithoutPlus;
        }
      },
      ...parseOptions,
    });

    return {
      ...defaultPagination,
      ...defaultFilter,
      ...tmp,
      name: searchParams.get('name'),
      ...rawFields?.reduce((v, c) => {
        return { ...v, [c]: searchParams.get(c) };
      }, {}),
    } as some;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchParams]);

  const hookParams = useMemo((): IParams<T> => {
    const {
      pageIndex = 1,
      pageSize = DEFAULT_PAGESIZE,
      tab = '',
      sort,
      ...rest
    } = paramsUrl;

    const pagination = {
      pageIndex: Number(pageIndex),
      pageSize: Number(pageSize),
    };
    const sorting: MRT_SortingState = (sort ? sort?.split(',') : []).map(
      (val: string) => {
        if (val.includes('-')) {
          return {
            desc: true,
            id: val.substring(1),
          };
        } else {
          return {
            desc: false,
            id: val,
          };
        }
      }
    );
    return {
      sort,
      sorting,
      pagination: pagination,
      filters: Object.fromEntries(
        Object.entries(rest)
          .filter(([_, v]) => v !== null && v !== '' && v !== undefined)
          .map(([_, v]) => {
            try {
              return [_, JSON.parse(v)];
            } catch (e) {
              return [_, v];
            } finally {
              return [_, v];
            }
          })
      ) as Partial<T>,
      params: {
        ...pagination,
        sort,
        filters: Object.fromEntries(
          Object.entries(rest)
            .filter(([_, v]) => v !== null && v !== '' && v !== undefined)
            .map(([_, v]) => {
              try {
                return [_, JSON.parse(v)];
              } catch (e) {
                return [_, v];
              } finally {
                return [_, v];
              }
            })
        ),
      } as RequestParams<T>,
      tab,
    };
  }, [paramsUrl]);

  const clearParams = useCallback(
    (value?: some) => {
      if (disableLink) {
        setFilter((old) => ({
          tab: hookParams.tab,
          ...defaultPagination,
          ...defaultFilter,
          ...value,
        }));
      } else {
        setSearchParams(
          QueryString.stringify(
            removeEmptyKeys({
              tab: hookParams.tab,
              ...defaultPagination,
              ...defaultFilter,
              ...value,
            }),
            optionsTmp
          ),
          { replace: true, state: location.state }
        );
      }
    },
    [
      disableLink,
      hookParams.tab,
      defaultPagination,
      defaultFilter,
      setSearchParams,
      optionsTmp,
      location.state,
    ]
  );

  const setParams = useCallback(
    (
      form: Partial<T> | SortFilter,
      strict?: boolean,
      navigateOpts?: NavigateOptions | undefined
    ) => {
      if (disableLink) {
        setFilter((old) => (strict ? form : { ...old, pageIndex: 1, ...form }));
      } else {
        const params = disablePagination
          ? strict
            ? form
            : {
                tab: hookParams.tab,
                sort: hookParams.sort,
                ...hookParams.filters,
                ...form,
              }
          : strict
          ? form
          : {
              tab: hookParams.tab,
              sort: hookParams.sort,
              ...hookParams.pagination,
              ...hookParams.filters,
              pageIndex: 1,
              ...form,
            };

        setSearchParams(
          QueryString.stringify(removeEmptyKeys(params), optionsTmp),
          {
            replace: true,
            state: location.state,
            ...navigateOpts,
          }
        );
      }
    },
    [
      disableLink,
      setSearchParams,
      disablePagination,
      hookParams.tab,
      hookParams.sort,
      hookParams.pagination,
      hookParams.filters,
      optionsTmp,
      location.state,
    ]
  );

  const onPageChange = useCallback(
    (newPage: number, navigateOpts?: NavigateOptions | undefined) => {
      if (disableLink) {
        setFilter((old) => ({ ...old, pageIndex: newPage }));
      } else {
        setSearchParams(
          QueryString.stringify(
            { ...hookParams.params, pageIndex: newPage },
            optionsTmp
          ),
          { replace: true, state: location.state, ...navigateOpts }
        );
      }
    },
    [
      disableLink,
      setSearchParams,
      hookParams.params,
      optionsTmp,
      location.state,
    ]
  );

  const onRowsPerPageChange = useCallback(
    (pageSize, navigateOpts?: NavigateOptions | undefined) => {
      if (disableLink) {
        setFilter((old) => ({
          ...old,
          pageSize,
          pageIndex: 1,
        }));
      } else {
        setSearchParams(
          QueryString.stringify(
            {
              tab: hookParams.tab,
              sort: hookParams.sort,
              ...hookParams.filters,
              pageSize,
              pageIndex: 1,
            },
            optionsTmp
          ),
          { replace: true, state: location.state, ...navigateOpts }
        );
      }
    },
    [
      disableLink,
      setSearchParams,
      hookParams.tab,
      hookParams.sort,
      hookParams.filters,
      optionsTmp,
      location.state,
    ]
  );

  const onSortingChange = (sortState: MRT_SortingState) => {
    setParams({
      sort: sortState
        .map((value) => {
          return value.desc ? `-${value.id}` : value.id;
        })
        .join(','),
    });
  };
  return {
    ...hookParams,
    setParams,
    clearParams,
    onPageChange,
    onRowsPerPageChange,
    onSortingChange,
  };
};

export default usePaginationHook;
