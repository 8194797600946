import { Box, Button, TextField, Typography } from '@mui/material';
import MuiDialog from '../MuiDialog';
import { FormattedMessage, useIntl } from 'react-intl';
import { Fragment, useState } from 'react';
import MultipleRadioElement from '../../SchemaForm/element/multiple-radio/MultipleRadioElement';
import { LoadingButton } from '@mui/lab';
import { ReasonDialogProps } from './types';

const ReasonDialog = (props: ReasonDialogProps) => {
  const {
    open = false,
    onClose,
    title,
    reasons = [],
    infos = [],
    onSave,
    ...rest
  } = props;

  const intl = useIntl();
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);

  const renderInfo = () => {
    if (!infos.length) return null;
    return (
      <Box className="flex flex-col gap-2">
        {infos?.map((item, index) => {
          if (item.node) return <Fragment key={index}>{item.node}</Fragment>;
          return (
            <Typography
              key={index}
              variant="body2"
              component={'div'}
              className="flex gap-1"
            >
              <Box sx={{ minWidth: 160 }}>
                {item.label && <FormattedMessage id={item.label} />}
              </Box>
              :&emsp;{item.value}
            </Typography>
          );
        })}
      </Box>
    );
  };

  const renderReason = () => {
    return (
      <>
        <Typography variant="subtitle1" mt={2} mb={1}>
          <FormattedMessage id="processingFood.reason" />
        </Typography>
        <MultipleRadioElement
          value={reason}
          onChange={setReason}
          radioProps={{ size: 'small' }}
          rawOptions
          options={reasons || []}
        />
        {reason === 'OTHER' && (
          <TextField
            autoFocus
            sx={{ mt: 1 }}
            fullWidth
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            placeholder={intl.formatMessage({
              id: 'processingFood.reason_enter',
            })}
          />
        )}
      </>
    );
  };

  const onSaveFunc = async () => {
    try {
      setLoading(true);
      await onSave?.({ reasonId: reason, reasonText: otherReason });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MuiDialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="tablet"
        TransitionProps={{
          onEnter: () => {
            setReason(reasons.length === 1 ? reasons[0]?.id : '');
            setOtherReason('');
          },
        }}
        {...rest}
      >
        <Typography variant="h5" color="primary" className="text-center py-4">
          {title && <FormattedMessage id={title} />}
        </Typography>
        <Box px={2}>{renderInfo()}</Box>
        <Box px={2}>{renderReason()}</Box>
        <Box p={2} className="flex justify-center gap-4">
          <Button
            variant="outlined"
            sx={{ minWidth: 100 }}
            onClick={() => onClose && onClose(false)}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 100 }}
            loading={loading}
            onClick={onSaveFunc}
            disabled={reason === 'OTHER' ? !otherReason.trim() : !reason}
          >
            <FormattedMessage id="confirm" />
          </LoadingButton>
        </Box>
      </MuiDialog>
    </>
  );
};

export * from './types';
export default ReasonDialog;
