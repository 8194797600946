import { ListTypeModel } from '../redux/commonReducer';
import useHookQuery from './hook/useHookQuery';

declare global {
  interface Window {
    LS: ListTypeModel;
  }
}

declare module '@mui/system' {
  interface BreakpointOverrides {
    // Your custom breakpoints
    tablet: true;
    mobile: true;
    laptop: true;
    desktop: true;

    // Remove default breakpoints
    xs: true;
    sm: false;
    md: false;
    lg: false;
    xl: false;
  }
}

export interface IRouteObject {
  caseSensitive?: boolean;
  children?: IRouteObject[];
  icon?: React.ReactNode;
  element?: React.ReactNode;
  index?: boolean;
  hidden?: boolean;
  path?: string;
  absolutePath?: string;
  label?: string;
  title?: string;
  portalId?: string;
  listRole?: string[][];
  collapseWhenIn?: (params: ReturnType<typeof useHookQuery>) => boolean;
  breadcrumb?: React.ReactNode;
  blockBreadcrumb?: boolean;
}

export interface ResponseDataList<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    path: string;
    perPage: number;
    to: number;
    total: number;
  };
  status?: string;
  code: number;
  message: string;
}
export interface ResponseDataDetail<T> {
  data: T;
}
export interface SortFilter {
  sort?: string;
}
export interface PaginationParams {
  pageIndex: number;
  pageSize: number;
}

export interface RequestParams<T> extends PaginationParams, SortFilter {
  filters: T;
}

export enum OperationStatus {
  'create' = 'create',
  'cancel' = 'cancel',
  'stop' = 'stop',
  'deny' = 'deny',
  'confirm' = 'confirm',
}
