import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { CommodityClassificationModel } from './types';
import { ResponseDataDetail } from '../../../common/types';

const useCommodityClassificationsDetail = (id?: string) => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isLoading } = useQuery<CommodityClassificationModel>({
    queryKey: [id, KEYS_QUERY.commodityClassificationDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<CommodityClassificationModel>>({
          url: API_PATHS.commodityClassifications.detail(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  return { data, isLoading };
};
export default useCommodityClassificationsDetail;
