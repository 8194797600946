import { BoxProps, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import {
  MRT_RowData,
  MRT_SortingState,
  MRT_TableOptions,
  MRT_TableContainer as Table,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
  MRT_Cell,
} from 'material-react-table';
import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MuiLoading from './MuiLoading';
import MuiPaginationCustom, {
  MuiPaginationCustomProps,
} from './MuiPaginationCustom';

interface Props<T extends MRT_RowData = MRT_RowData> {
  paginationProps?: MuiPaginationCustomProps;
  onSortingChange?: (value: MRT_SortingState) => void;
  renderRowActions?: (props: {
    isTablet: boolean;
    closeMenu?: () => void;
    row: MRT_Row<T>;
    cell?: MRT_Cell<T>;
    staticRowIndex?: number;
    table: MRT_TableInstance<T>;
  }) => ReactNode[];
  boxProps?: BoxProps<'div'>;
}
const MuiTable = <T extends MRT_RowData = MRT_RowData>(
  props: Props<T> &
    Omit<MRT_TableOptions<T>, 'onSortingChange' | 'renderRowActions'>
) => {
  const {
    boxProps,
    paginationProps,
    state,
    muiSearchTextFieldProps,
    muiTablePaperProps,
    muiTopToolbarProps,
    onSortingChange,
    muiTableBodyCellProps,
    muiTableHeadCellProps,
    enableRowVirtualization,
    renderBottomToolbarCustomActions,
    columns,
    renderRowActions,
    ...rest
  } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('laptop'));
  const [sorting, setSort] = useState<MRT_SortingState>(state?.sorting || []);

  const mappedColumns = useMemo(() => {
    return [...columns].map((column) => ({
      enableSorting: false,
      ...column,
    })) as MRT_ColumnDef<T, any>[];
  }, [columns]);

  useEffect(() => {
    state?.sorting && setSort(state?.sorting);
  }, [state?.sorting]);

  const table = useMaterialReactTable<T>({
    renderRowActions: isTablet
      ? undefined
      : (params) =>
          (renderRowActions &&
            renderRowActions({ isTablet, ...params }).map((value, index) => (
              <Fragment key={index}>{value}</Fragment>
            ))) ||
          [],
    renderRowActionMenuItems: (params) => {
      return isTablet
        ? renderRowActions?.({ isTablet, ...params }).map((value, index) => (
            <Box key={index}>{value}</Box>
          )) || []
        : [];
    },
    getRowId: (originalRow) => originalRow.id,
    columns: mappedColumns,
    state: {
      density: 'compact',
      // pagination: !!paginationProps
      //   ? {
      //       pageIndex: (paginationProps?.page ?? 0) - 1,
      //       pageSize: paginationProps?.rowsPerPage ?? 0,
      //     }
      //   : undefined,
      ...state,
      sorting,
      isLoading: false,
    },
    renderEmptyRowsFallback: () => (
      <Typography
        variant="body1"
        color="textSecondary"
        sx={(theme) => ({
          margin: 0,
          fontStyle: 'italic',
          height: 120,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          [theme.breakpoints.up('tablet')]: {
            height: 240,
          },
        })}
        component={'div'}
      >
        {props.state?.isLoading ? (
          <MuiLoading />
        ) : (
          <FormattedMessage id="emptyData" />
        )}
      </Typography>
    ),
    muiDetailPanelProps: () => ({
      sx: () => ({
        p: 0,
      }),
    }),
    muiTableProps: {
      sx: {
        '& tr:not(.MuiTableRow-footer)': {
          backgroundColor: 'inherit !important',
        },
        '& .Mui-action-cell-header': {
          bgcolor: 'background.paper',
        },
        '& .Mui-TableBodyCell-DetailPanel': {
          border: 'none',
          '& div': {
            // padding: 0,
          },
        },
        '& .MuiTableCell-stickyHeader': {
          bgcolor: 'background.paper',
          zIndex: 10,
        },
        '& .MuiTableRow-footer': {
          bgcolor: 'grey.50',
        },
      },
    },
    muiTableHeadCellProps: {
      align: 'center',
      sx: {
        minWidth: 'unset',
        whiteSpace: 'pre-wrap',
        '& .Mui-TableHeadCell-Content-Wrapper': {
          whiteSpace: 'pre-wrap',
          lineHeight: 1,
        },
      },
      size: 'small',
    },
    muiTableBodyCellProps: {
      align: 'center',
      sx: {
        minWidth: 'unset',
        whiteSpace: 'pre-wrap',
        lineHeight: 1,
        wordBreak: 'break-word',
      },
      size: 'small',
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 10,
        muiTableHeadCellProps: {
          sx: {
            maxWidth: 40,
          },
        },
        muiTableBodyCellProps: {
          sx: {
            maxWidth: 40,
          },
        },
      },
      'mrt-row-actions': {
        header: 'action',
        Header: isTablet ? <Box /> : <FormattedMessage id="action" />,
        size: isTablet ? 50 : 70,
        muiTableBodyCellProps: {
          sx: {
            position: 'sticky',
            right: 0,
            zIndex: 10,
            borderLeft: isTablet ? '1px dashed' : undefined,
            bgcolor: 'background.paper',
            padding: 0,
            '& > button': { margin: 0 },
          },
        },
        muiTableHeadCellProps: {
          className: 'Mui-action-cell-header',
          sx: {
            position: 'sticky',
            right: 0,
            zIndex: 10,
            borderLeft: isTablet ? '1px dashed' : undefined,
          },
        },
      },
    },
    enableStickyHeader: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTableFooter: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    // enableSorting: false,
    manualSorting: true,
    isMultiSortEvent: () => true,
    enableRowNumbers: false,
    positionActionsColumn: 'last',
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.background.paper,
      draggingBorderColor: theme.palette.secondary.main,
    }),
    onSortingChange: (sortingUpdater: any) => {
      const newSortVal = sortingUpdater(sorting);
      setSort(newSortVal);
      onSortingChange && onSortingChange(newSortVal);
    },
    ...rest,
  });

  return (
    <Box
      sx={(theme) => ({
        p: 1,
        [theme.breakpoints.up('tablet')]: { p: 2 },
      })}
      {...(boxProps as any)}
    >
      <Table table={table} />
      <>{paginationProps && <MuiPaginationCustom {...paginationProps} />}</>
    </Box>
  );
};
export default MuiTable;
