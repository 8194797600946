import {
  CheckCircleOutline,
  SaveRounded,
  UndoRounded,
} from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import useUnProcessingDishDetail from './useUnProcessingDishDetail';
import { KEYS_QUERY } from '../../../common/constants';
import { UnProcessingDishModel } from '../types';
import ConfirmDataBox from './ConfirmDataBox';
import { OperationStatus, ResponseDataDetail } from '../../../common/types';
import OriginalDishInfoBox from '../../processing-dish/detail/OriginalDishInfoBox';
import { CommodityGroupsModel } from '../../commodity-groups/detail/types';
import StandardFoodSelectTable from './StandardFoodSelectTable';
import { checkReadOnlyUnProcessingFood } from '../utils';

const UnProcessingDishDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar, confirmDialog } =
    useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useUnProcessingDishDetail(id);

  const onCreateUpdate = useMutation({
    mutationFn: async (values: UnProcessingDishModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<
            ResponseDataDetail<UnProcessingDishModel>,
            UnProcessingDishModel
          >({
            url: API_PATHS.unProcessingDish.index,
            method: 'post',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
        if (key === 'default') {
          navigate(
            [
              '',
              ROUTES_PATH.norms.index,
              ROUTES_PATH.unProcessingDish.index,
            ].join('/')
          );
        } else {
          navigate(-1);
        }
      } else {
        await dispatch(
          fetchThunk({
            url: API_PATHS.unProcessingDish.detail(values.id),
            method: 'put',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
        if (key === 'default') {
          navigate(
            [
              '',
              ROUTES_PATH.norms.index,
              ROUTES_PATH.unProcessingDish.index,
            ].join('/')
          );
        } else {
          navigate(-1);
        }
      }
    },
  });

  const onConfirmBeforeSubmit = useMutation({
    mutationFn: async (values: UnProcessingDishModel) => {
      confirmDialog.promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: <ConfirmDataBox data={values} />,
        slotProps: {
          title: { justifyContent: 'center', mb: 2, variant: 'h5' },
          dialog: { maxWidth: 'laptop' },
        },
        onAccept: () => onCreateUpdate.mutateAsync(values),
      });
    },
  });

  //____________Popper_______________

  if (isLoading) {
    return <MuiLoading />;
  }
  if (!data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SchemaForm<UnProcessingDishModel>
          formHookProps={{
            values: data,
            defaultValues: {
              status: true,
              operationStatus: OperationStatus.create,
              isProcessingFood: false,
              nutritionRequired: false,
            },
          }}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          globalProps={{
            readOnly: data ? checkReadOnlyUnProcessingFood(data) : false,
          }}
          schema={{
            fields: ({
              formProps: { intl, listType, isMobile, readOnly },
              valuesField,
              methods: { setValue, trigger, getValues },
            }) => {
              return {
                name: {
                  mode: 'text-field',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  onBlur: () => {
                    if (!valuesField.displayName) {
                      setValue('displayName', valuesField.name);
                    }
                  },
                  rules: { required: true },
                },
                operationCode: {
                  mode: 'raw',
                  propsWrapper: { tablet: 6 },
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      name={valuesField?.name}
                      commodityGroup={valuesField.commodityGroup?.name}
                      module="MKCB"
                      label={intl.formatMessage({
                        id: 'unProcessingDish.operationCode',
                      })}
                    />
                  ),
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                commodityClassification: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityClassification',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityClassifications.index,
                      text,
                      KEYS_QUERY.commodityClassification,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                  },
                  onChange: () => {
                    setValue('commodityGroup', null);
                  },
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                commodityGroup: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityGroup',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityGroups.index(
                        valuesField.commodityClassification?.id
                      ),
                      text,
                      KEYS_QUERY.commodityGroups,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                    config: {
                      enabled: !!valuesField.commodityClassification?.id,
                    },
                  },
                  getOptionLabel: (option: CommodityGroupsModel) =>
                    [
                      option.name,
                      listType.NMH?.type.find((v) => v.id === option.type)
                        ?.name,
                    ]
                      .filter(Boolean)
                      .join(' - '),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                originalDish: {
                  mode: 'auto-complete',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.originalDish',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [API_PATHS.originDish.index, text],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                  },
                  rules: { required: true },
                },
                originalDishNoteMobile: {
                  mode: 'node',
                  hidden: !isMobile,
                  propsWrapper: { tablet: 6 },
                  render: () => {
                    return (
                      <OriginalDishInfoBox data={valuesField.originalDish} />
                    );
                  },
                },
                displayName: {
                  mode: 'text-field',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.displayName',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                },
                originalDishNote: {
                  mode: 'node',
                  hidden: isMobile,
                  propsWrapper: { tablet: 6 },
                  render: () => {
                    return (
                      <OriginalDishInfoBox data={valuesField.originalDish} />
                    );
                  },
                },
                objectType: {
                  mode: 'select',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.objectType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  options: listType.MKCB?.objectType,
                  rawOptions: true,
                  rules: { required: true },
                },

                mainFoodQuantity: {
                  mode: 'text-field',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.mainFoodQuantity',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  type: 'number',
                  rules: { required: true, valueAsNumber: true },
                },
                usageRatio: {
                  mode: 'text-field',
                  propsWrapper: { tablet: 6 },
                  type: 'number',
                  label: intl.formatMessage({
                    id: 'unProcessingDish.usageRatio',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: {
                    required: true,
                    min: {
                      value: 0,
                      message: intl.formatMessage({
                        id: 'unProcessingDish.invalidUsageRatio',
                      }),
                    },
                    max: {
                      value: 100,
                      message: intl.formatMessage({
                        id: 'unProcessingDish.invalidUsageRatio',
                      }),
                    },
                  },
                },

                operationStatus: {
                  mode: 'select',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  options: listType.MKCB?.operationStatus,
                  rawOptions: true,
                  rules: { required: true },
                  readOnly: true,
                },
                status: {
                  mode: 'switch',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'statusAdmin',
                  }),
                  labelPlacement: 'top',
                  rules: { required: true },
                },
                // blank: { mode: 'node', propsWrapper: { tablet: 12 } },
                description: {
                  mode: 'text-field',
                  propsWrapper: { tablet: 6 },
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                },
                nutritionRequired: {
                  mode: 'checkbox',
                  propsWrapper: { mobile: 6, tablet: true },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.nutritionRequired',
                  }),
                  labelPlacement: 'top',
                  defaultValue: false,
                  noHelperText: true,
                },
                isProcessingFood: {
                  mode: 'checkbox',
                  propsWrapper: { mobile: 6, tablet: true },
                  label: intl.formatMessage({
                    id: 'unProcessingDish.isProcessingFood',
                  }),
                  labelPlacement: 'top',
                  defaultValue: false,
                  noHelperText: true,
                },
                standardFood: {
                  mode: 'raw',
                  render: (params) => {
                    return (
                      <StandardFoodSelectTable<UnProcessingDishModel>
                        {...params}
                        readOnly={readOnly}
                      />
                    );
                  },
                },
                blank2: { mode: 'node' },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.norms.index,
                          ROUTES_PATH.unProcessingDish.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },

                submit: {
                  mode: 'button',
                  type: 'submit',
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  hidden: readOnly,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
                confirm: {
                  mode: 'button',
                  variant: 'contained',
                  startIcon: <CheckCircleOutline />,
                  hidden: readOnly,
                  disabled: !valuesField.standardFood,
                  children: intl.formatMessage({
                    id: 'confirm',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                  onClick: async () => {
                    const valid = await trigger();
                    if (valid) {
                      onConfirmBeforeSubmit.mutateAsync({
                        ...getValues(),
                        operationStatus: OperationStatus.confirm,
                      });
                    }
                  },
                },
              };
            },
          }}
        />
      </Paper>
    </>
  );
};
export default UnProcessingDishDetailPage;
