import { Action, ThunkAction, createSlice } from '@reduxjs/toolkit';
import { some } from '../common/constants';
import { AppState } from './store';
import fetchThunk from '../common/fetchThunk';
import { API_PATHS } from '../api/API_App';
import { ModuleCode } from '../common/components/GenerateCodeBox/types';
import { ResponseDataDetail } from '../common/types';

export enum ReasonType {
  'activate' = 'activate',
  'cancel' = 'cancel',
  'stop' = 'stop',
  'deny' = 'deny',
}

export type ListTypeModel = Partial<
  Record<
    ModuleCode,
    Record<string, some[]> & Record<'reasons', Record<ReasonType, some[]>>
  >
>;
export interface CommonState {
  networkErrorMsg: string;
  openErrorDialog: boolean;
  loading: boolean;
  listType: ListTypeModel;
}

export const initialStateCommon: CommonState = {
  networkErrorMsg: '',
  openErrorDialog: false,
  loading: false,
  listType: {},
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialStateCommon,
  reducers: {
    setNetworkError: (state, action) => {
      state.networkErrorMsg = action.payload.networkErrorMsg;
      state.openErrorDialog = action.payload.openErrorDialog;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setListType: (state, action) => {
      state.listType = action.payload;
    },
  },
});

export const { setNetworkError, setLoading, setListType } = commonSlice.actions;

export default commonSlice.reducer;

function mappedObjectType(tmp) {
  return Object.keys(tmp).reduce((value: any, key: string) => {
    return { ...value, [key]: Object.values(tmp[key]) };
  }, {});
}
export function getListType(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  Action
> {
  return async (dispatch) => {
    const json = await dispatch(
      fetchThunk<ResponseDataDetail<ListTypeModel>>({
        url: API_PATHS.listType,
        method: 'get',
      })
    );
    let tmp = json.data.data;
    tmp = Object.keys(tmp).reduce((value, key) => {
      return {
        ...value,
        [key]: Object.keys(tmp[key]).reduce((val: some, k) => {
          if (k === 'reasons') {
            return { ...val, [k]: mappedObjectType(tmp[key][k]) };
          }
          return { ...val, [k]: Object.values(tmp[key][k]) };
        }, {}),
      };
    }, {});
    window.LS = tmp;
    dispatch(setListType(tmp));
  };
}
