import { Logout } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MyLink from '../../common/components/MyLink';
import useGeneralHook from '../../common/hook/useGeneralHook';
import LanguageSelect from '../../intl/component/LanguageSelect';
import { logoutFnc } from '../../redux/authReducer';
import { AppState } from '../../redux/store';
import { ROUTES_PATH } from '../constants';
import ThemeSwitch from './ThemeSwitch';
import { useSelector } from 'react-redux';
interface Props {
  sx?: BoxProps['sx'];
}
const ProfileBox = (props: Props) => {
  const { sx } = props;
  const { dispatch, isMobile } = useGeneralHook();
  const { userInfo } = useSelector((state: AppState) => state.auth);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'userInfo-popover' : undefined;
  const logout = () => {
    dispatch(logoutFnc());
  };

  return (
    <>
      <Button
        variant="text"
        className="items-center shrink-0 flex"
        sx={{
          gap: 0.5,
          display: 'flex',
          justifyContent: 'flex-start',
          textAlign: 'start',
          ...sx,
        }}
        style={{ gap: 8 }}
        onClick={handleClick}
      >
        <Avatar
          src={userInfo?.profile_photo_url}
          alt="avatar"
          sx={{ minHeight: 32, minWidth: 32 }}
        />
        <Box sx={{ flex: 1, overflow: 'hidden' }}>
          <Typography variant="subtitle1">{userInfo?.name}</Typography>
          <Typography variant="body2" className="line-clamp-1">
            {userInfo?.email}
          </Typography>
        </Box>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          isMobile
            ? {
                vertical: 'top',
                horizontal: 'center',
              }
            : { vertical: 'bottom', horizontal: 'center' }
        }
        transformOrigin={
          isMobile
            ? {
                vertical: 'bottom',
                horizontal: 'center',
              }
            : undefined
        }
        className="mb-4 tablet:mt-2"
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: {
            width: 260,
            p: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <MyLink to={ROUTES_PATH.profile}>
          <Button
            variant="text"
            className="items-center shrink-0 flex"
            fullWidth
            title="profile"
            sx={{
              gap: 0.5,
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'start',
              ...sx,
            }}
            style={{ gap: 8 }}
          >
            <Avatar
              src={userInfo?.profile_photo_url}
              alt="avatar"
              sx={{ minHeight: 32, minWidth: 32 }}
            />
            <Box>
              <Typography variant="subtitle1">{userInfo?.name}</Typography>
              <Typography variant="body2">{userInfo?.email}</Typography>
            </Box>
          </Button>
        </MyLink>
        <Box className="flex gap-2 justify-around">
          <LanguageSelect />
          <ThemeSwitch />
        </Box>
        <Button onClick={logout} fullWidth startIcon={<Logout />}>
          <FormattedMessage id="logout" />
        </Button>
      </Popover>
    </>
  );
};
export default ProfileBox;
