import {
  Box,
  Button,
  Card,
  IconButton,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import usePaginationHook from '../../../../common/hook/usePaginationHook';
import MuiTable from '../../../../common/components/MuiTable';
import { OperationStatus, ResponseDataList } from '../../../../common/types';
import { KEYS_QUERY } from '../../../../common/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackRounded,
  CancelRounded,
  CheckCircleOutlineRounded,
  UnpublishedOutlined,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES_PATH } from '../../../../layout/constants';
import {
  StandardFoodFilterModel,
  StandardFoodModel,
} from '../../../standard-food/types';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import StandardFoodFilterBox from '../../../standard-food/list/StandardFoodFilterBox';
import { ProcessingFoodModel } from '../../../processing-food/types';
import ConfirmDataBox from './ConfirmDataBox';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../../common/components/ReasonDialog';
import useMutationReason from '../../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../../common/components/GenerateCodeBox/types';

interface Props {
  processingFood: ProcessingFoodModel;
  setStep: (step: number) => void;
}
const Step2 = (props: Props) => {
  const { processingFood, setStep } = props;
  const { key } = useLocation();
  const navigate = useNavigate();
  const {
    dispatch,
    fetchThunk,
    API_PATHS,
    confirmDialog,
    enqueueSnackbar,
    listType,
  } = useGeneralHook();
  const { mutationReason } = useMutationReason();
  const [rowSelection, setRowSelection] = useState<
    StandardFoodModel | undefined
  >();
  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const hookPagination = usePaginationHook<StandardFoodFilterModel>({
    disableLink: true,
    defaultFilter: { pageSize: 10 },
  });

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading } = useQuery<ResponseDataList<StandardFoodModel>>({
    queryKey: [KEYS_QUERY.standardFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<StandardFoodModel>>({
          url: API_PATHS.standardFood.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  const onUpdateOriginFood = useMutation({
    mutationFn: async () => {
      await dispatch(
        fetchThunk({
          url: API_PATHS.processingFood.detail(processingFood.id),
          method: 'put',
          data: {
            ...processingFood,
            standardFood: rowSelection,
            operationStatus: OperationStatus.confirm,
          },
        })
      );
      enqueueSnackbar({
        message: <FormattedMessage id={'updateSuccess'} />,
        variant: 'success',
      });
      if (key === 'default') {
        navigate(
          [
            '',
            ROUTES_PATH.norms.index,
            ROUTES_PATH.processingDishConfirm.index,
          ].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  const onSubmit = useMutation({
    mutationFn: async () => {
      if (!rowSelection) {
        return;
      }
      await confirmDialog.promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: (
          <ConfirmDataBox
            processingFood={processingFood}
            standardFood={rowSelection}
          />
        ),
        slotProps: {
          title: { justifyContent: 'center', mb: 2, variant: 'h5' },
          dialog: { maxWidth: 'laptop' },
        },
        onAccept: () => onUpdateOriginFood.mutateAsync(),
      });
    },
  });

  const onReject = () => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        if (!processingFood) {
          return;
        }
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingFood.detail(processingFood?.id),
              method: 'put',
              data: {
                ...processingFood,
                operationStatus: OperationStatus.deny,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingFood.reason(processingFood?.id),
            params: {
              objectId: processingFood?.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.TPCB,
              statusBefore: processingFood?.operationStatus,
              statusAfter: OperationStatus.deny,
            },
          }),
        ]).then(() => {
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.norms.index,
                ROUTES_PATH.processingFoodConfirm.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'denyText',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingFood.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {processingFood?.name} <br />
              {processingFood?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: processingFood?.operationStatus && (
            <FormattedMessage id={processingFood?.operationStatus} />
          ),
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.deny} />,
        },
      ],
      reasons: listType.TPCB?.reasons?.deny,
    });
  };

  return (
    <>
      <Paper>
        <Box className="p-2">
          <StandardFoodFilterBox hookPagination={hookPagination} hiddenStatus />
          {rowSelection && (
            <Card
              variant="outlined"
              className="flex flex-col justify-center items-center my-4 w-fit mx-auto py-4 px-8 relative"
            >
              <IconButton
                color="primary"
                sx={{ position: 'absolute', top: 1, right: 1 }}
                onClick={() => setRowSelection(undefined)}
              >
                <CancelRounded />
              </IconButton>
              <Typography>
                <FormattedMessage id="processedFoodConfirm.selectedFood" />
                :&nbsp;
                {[
                  rowSelection.operationCode,
                  rowSelection.name,
                  listType?.TPTC?.type?.find(
                    (val) => val.id === rowSelection.sourceType
                  )?.name,
                ].join(' - ')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  px: 6,
                  mt: 1,
                }}
              >
                {['usageRatio', 'protein', 'lipid', 'glucid', 'calorie'].map(
                  (val, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          width: 200,
                        }}
                      >
                        <Box
                          sx={{
                            borderBottom: '1px dashed',
                            borderColor: 'primary.main',
                            py: 1,
                            height: 40,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="subtitle2">
                            <FormattedMessage id={`standardFood.${val}`} />
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            borderBottom: '1px dashed',
                            borderColor: 'primary.main',
                            px: 2,
                            py: 1,
                            textAlign: 'center',
                          }}
                        >
                          {rowSelection[val]}
                        </Box>
                      </Box>
                    );
                  }
                )}
              </Box>
            </Card>
          )}
        </Box>

        <MuiTable<StandardFoodModel>
          data={data?.data ?? []}
          state={{
            isLoading: isLoading,
            sorting,
          }}
          onSortingChange={onSortingChange}
          paginationProps={{
            count: data?.meta.total,
            page: data?.meta.currentPage,
            rowsPerPage: data?.meta.perPage,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
            rowsPerPageOptions: [10, 20, 50, 100],
          }}
          columns={[
            {
              header: 'select',
              Header: () => (
                <FormattedMessage id="processedFoodConfirm.select" />
              ),
              size: 80,
              Cell: ({ row }) => {
                const checked = rowSelection?.id === row.original.id;
                return (
                  <Radio
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRowSelection(row.original);
                      } else {
                        setRowSelection(undefined);
                      }
                    }}
                    sx={{ p: 0.5 }}
                  />
                );
              },
            },
            {
              accessorKey: 'id',
              header: 'id',
              enableSorting: true,
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'operationCode',
              header: 'operationCode',
              enableSorting: true,
              Header: () => (
                <FormattedMessage id="standardFood.operationCode" />
              ),
              size: 240,
            },
            {
              accessorKey: 'name',
              header: 'name',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.name" />,
            },
            {
              accessorKey: 'sourceType',
              header: 'sourceType',
              Header: () => <FormattedMessage id="standardFood.sourceType" />,
              Cell: ({ renderedCellValue }) => {
                return listType?.TPTC?.sourceType?.find(
                  (val) => val.id === renderedCellValue
                )?.name;
              },
            },
            {
              accessorKey: 'usageRatio',
              header: 'usageRatio',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.usageRatio" />,
            },
            {
              accessorKey: 'protein',
              header: 'protein',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.protein" />,
              size: 100,
            },
            {
              accessorKey: 'lipid',
              header: 'lipid',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.lipid" />,
              size: 100,
            },
            {
              accessorKey: 'glucid',
              header: 'glucid',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.glucid" />,
              size: 100,
            },

            {
              accessorKey: 'calorie',
              header: 'calorie',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.calorieList" />,
              size: 100,
            },
          ]}
        />
        <Box className="flex p-2 gap-2 tablet:gap-4 tablet:p-4 tablet:pt-0">
          <LoadingButton
            variant="outlined"
            startIcon={<UnpublishedOutlined />}
            onClick={onReject}
          >
            <FormattedMessage id="processedFoodConfirm.reject" />
          </LoadingButton>
          <Box flex={1} />
          <Button
            variant="outlined"
            startIcon={<ArrowBackRounded />}
            onClick={() => {
              if (key === 'default') {
                navigate(
                  [
                    '',
                    ROUTES_PATH.norms.index,
                    ROUTES_PATH.processingFoodConfirm.index,
                  ].join('/')
                );
              } else {
                navigate(-1);
              }
            }}
          >
            <FormattedMessage id="back" />
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!rowSelection}
            startIcon={<CheckCircleOutlineRounded />}
            loading={onSubmit.isPending}
            onClick={() => onSubmit.mutateAsync()}
          >
            <FormattedMessage id="confirm" />
          </LoadingButton>
        </Box>
      </Paper>
      <ReasonDialog {...model} />
    </>
  );
};
export default Step2;
