import { OperationStatus } from '../../common/types';
import { ProcessingDishModel } from '../processing-dish/types';
export const ProcessingDishConfirmReadOnlyStatus = [
  OperationStatus.create,
  OperationStatus.stop,
];
export const checkReadOnlyProcessingDishConfirm = (
  data: ProcessingDishModel
) => {
  return !ProcessingDishConfirmReadOnlyStatus.includes(data.operationStatus);
};
