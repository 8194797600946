export const ROUTES_PATH = {
  404: '404',
  login: 'login',
  forgotPassword: '/forgotPassword',
  profile: 'profile',
  dashboard: 'dashboard',
  list: { index: 'list' },
  qualityStandard: {
    index: 'quality-standard',
    create: 'create',
    detail: ':id',
  },
  groupFood: {
    index: 'food-group',
    create: 'create',
    detail: ':id',
  },
  standardFood: {
    index: 'standard-food',
    create: 'create',
    detail: ':categoryId',
  },
  originDish: { index: 'origin-dish', create: 'create', detail: ':id' },
  commodityClassifications: {
    index: 'commodity-classifications',
    create: 'create',
    detail: ':id',
  },
  commodityGroups: {
    index: 'commodity-groups',
    create: 'create',
    detail: ':categoryId',
  },
  channelDistributions: {
    index: 'channel-distributions',
    create: 'create',
    detail: ':id',
  },
  orderClassifications: {
    index: 'order-classifications',
    create: 'create',
    detail: ':categoryId',
  },
  units: {
    index: 'units',
    create: 'create',
    detail: ':id',
  },
  techniques: { index: 'techniques' },
  processingFood: { index: 'processing-food', create: 'create', detail: ':id' },
  processingDish: {
    index: 'processing-dish',
    create: 'create',
    detail: ':id',
    quota: 'quota/:id',
  },
  norms: { index: 'norms' },
  processingFoodConfirm: { index: 'processing-food-confirm', detail: ':id' },
  unProcessingDish: {
    index: 'un-processing-dish',
    create: 'create',
    detail: ':id',
    confirm: ':id/confirm',
  },
  processingDishConfirm: {
    index: 'processing-dish-confirm',
    detail: ':id',
  },
  price: {
    index: 'price',
  },
  setupPrice: { index: 'setup' },
  quantitative: { index: 'quantitative' },

  documentation: { index: 'documentation' },
  menu: { index: 'menu' },
  order: { index: 'order', detail: ':id', create: 'create' },
};
