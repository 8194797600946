import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { ProcessingDishModel } from '../../../processing-dish/types';
import { useAppSelector } from '../../../../redux/store';

interface Props {
  data: ProcessingDishModel;
}
const InfoBox = (props: Props) => {
  const { data } = props;
  const listType = useAppSelector((state) => state.common.listType);
  return (
    <Box className={'flex gap-1 w-full'}>
      {[
        {
          value: data?.name,
          className: 'w-1/6',
        },
        {
          value: data?.operationCode,
          className: 'w-1/6',
        },
        {
          value: data?.mainFoodQuantity,
          className: 'w-1/6',
        },
        {
          value: data?.originalDish.name,
          className: 'w-1/6',
        },
        {
          value: listType.MCB?.objectType?.find(
            (v) => v.id === data?.objectType
          )?.name,
          className: 'flex-1',
        },
        {
          value: (
            <FormattedMessage id={data?.nutritionRequired ? 'yes' : 'no'} />
          ),
          className: 'w-20',
        },
      ].map((item, index) => {
        return (
          <Typography
            key={index}
            variant="body2"
            className={clsx(item.className, 'text-center shrink-0')}
          >
            {item.value}
          </Typography>
        );
      })}
    </Box>
  );
};
export default InfoBox;
