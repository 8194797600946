import { Avatar, Box, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../redux/store';

const ProfilePage = () => {
  const userInfo = useSelector(
    (state: AppState) => state.auth.userInfo,
    shallowEqual
  );
  const content = [
    {
      title: 'name',
      content: userInfo?.name,
    },

    {
      title: 'email',
      content: userInfo?.email,
    },

    {
      title: '',
      content: <></>,
      className: '',
    },
  ];
  return (
    <Box p={2} overflow={'hidden'}>
      <Paper
        sx={{ p: 2, gap: 2 }}
        className="flex flex-col overflow-auto h-full"
      >
        <Box className="w-fit mx-auto flex flex-col items-center justify-center gap-2">
          <Avatar
            src={userInfo?.profile_photo_url}
            sx={{ height: 180, width: 180 }}
          />
          <Typography variant="h3">{userInfo?.name}</Typography>
        </Box>

        <Box className="flex flex-col gap-2">
          <Box className="grid grid-cols-1 laptop:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-2">
            {content.map((item, index) => {
              return (
                <Box key={index} className={item.className}>
                  <Typography variant="subtitle1">
                    <FormattedMessage id={item.title} />
                  </Typography>
                  <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                    {item?.content || '-'}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default ProfilePage;
