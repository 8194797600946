import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMatch } from 'react-router';
import LinkPM from '../../common/components/LinkPM';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { IRouteObject } from '../../common/types';

interface Props {
  data: IRouteObject;
  pathname: string;
  open?: boolean;
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, pathname, open } = props;
  const { isMobile } = useGeneralHook();

  const match = useMatch(
    data.index
      ? '/' + (data.path?.replace('/*', '') || '')
      : (data.path?.replace('/*', '') || '') + '/*'
  );

  const checkIsActive = !!match;

  if (data.hidden || !data.path) {
    return null;
  }
  if (
    data.children &&
    (data.children?.length === 1
      ? !data.children[0]?.index
      : data.children?.length > 0)
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Typography
          className="aside-bar-title"
          variant={'subtitle1'}
          noWrap
          sx={{ flex: 1, textAlign: 'start', px: 1, py: 1 }}
          color={checkIsActive ? 'primary' : undefined}
        >
          {typeof data.title === 'string' ? (
            <FormattedMessage id={data.title} />
          ) : (
            data.title
          )}
        </Typography>

        {data.children?.map((item) => {
          if (!item.path || item.hidden) {
            return null;
          }
          const checkIsActiveSub = item.path && pathname.includes(item.path);

          return (
            <LinkPM
              key={item.path?.replace('/*', '')}
              to={{
                pathname: item.path,
              }}
              style={{ display: 'flex', flex: 1 }}
            >
              <Button
                variant="text"
                fullWidth
                disableElevation
                sx={{
                  bgcolor: checkIsActiveSub ? 'primary.light' : undefined,
                  color: checkIsActiveSub ? 'primary.main' : 'text.secondary',
                  ':hover': {
                    bgcolor: checkIsActiveSub ? 'primary.light' : 'grey.100',
                  },
                  minWidth: '-webkit-fill-available',
                }}
                style={{
                  padding: isMobile ? '8px' : '8px 12px',
                  justifyContent: 'flex-start',
                }}
              >
                <Box height={24} marginRight={2}>
                  {item.icon || (
                    <FiberManualRecordIcon
                      sx={{
                        color: checkIsActiveSub ? 'common.white' : 'info.light',
                        transform: checkIsActiveSub ? 'scale(.6)' : 'scale(.3)',
                        fontSize: 24,
                        transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                      }}
                    />
                  )}
                </Box>
                <Typography
                  noWrap
                  variant={checkIsActiveSub ? 'subtitle2' : 'body2'}
                  color="inherit"
                >
                  {item.title && <FormattedMessage id={item.title} />}
                </Typography>
              </Button>
            </LinkPM>
          );
        })}
      </Box>
    );
  }
  return (
    <>
      <LinkPM
        to={{
          pathname: data.path?.replace('/*', ''),
        }}
        style={{ display: 'flex', flex: 1 }}
      >
        <Tooltip
          title={
            !open && (
              <Typography color="inherit" noWrap>
                {data.title && <FormattedMessage id={data.title} />}
              </Typography>
            )
          }
          placement="right"
        >
          <Button
            variant={checkIsActive ? 'contained' : 'text'}
            fullWidth
            disableElevation
            sx={{
              bgcolor: checkIsActive ? 'primary.light' : undefined,
              color: checkIsActive ? 'primary.main' : 'text.secondary',
              ':hover': {
                bgcolor: checkIsActive ? 'primary.light' : 'grey.100',
              },
              minWidth: '-webkit-fill-available',
            }}
            style={{
              padding: isMobile ? '8px' : '8px 12px',
              justifyContent: 'flex-start',
            }}
          >
            <Box height={24} marginRight={2}>
              {data.icon ? (
                <Box
                  // color={checkIsActive ? 'white' : 'primary.main'}
                  height={24}
                >
                  {data.icon}
                </Box>
              ) : (
                <FiberManualRecordIcon
                  sx={{
                    color: checkIsActive ? 'common.white' : 'info.light',
                    transform: checkIsActive ? 'scale(.6)' : 'scale(.3)',
                    fontSize: 24,
                    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  }}
                />
              )}
            </Box>
            <Typography
              noWrap
              variant={checkIsActive ? 'subtitle2' : 'body2'}
              color="inherit"
            >
              {data.title && <FormattedMessage id={data.title} />}
            </Typography>
          </Button>
        </Tooltip>
      </LinkPM>
    </>
  );
};

export default DefaultAsideItems;
