import { Grid, GridProps } from '@mui/material';
import { isEqual } from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { some } from '../constants';
import GroupFields from './GroupFields';
import SchemaElement from './SchemaElement';
import useMappedFieldForm from './useMappedFieldForm';
import {
  FieldValues,
  FieldsElementType,
  FormProps,
  ISchemaForm,
  mergeFieldName,
} from './utils';

interface Props<T extends FieldValues = FieldValues> {
  schema: ISchemaForm<T>;
  fieldName?: `${string}` | `${string}.${string}` | `${string}.${number}`;
  showSubmitButton?: boolean;
  formProps: FormProps;
  propsGridContainer?: GridProps;
}

const SchemaView = <T extends FieldValues = FieldValues>(props: Props<T>) => {
  const { schema, fieldName, showSubmitButton = false, formProps } = props;
  const { onChange } = formProps;
  const { propsGridContainer } = schema;
  const previousValue = React.useRef(undefined);
  const methods = useFormContext<any>();

  const { watch } = methods;
  const tmp = fieldName ? watch(fieldName) : watch();

  React.useEffect(() => {
    if (previousValue.current !== undefined) {
      if (!isEqual(tmp, previousValue.current) && onChange) {
        onChange && onChange(tmp, methods);
        previousValue.current = tmp || null;
      }
    } else {
      previousValue.current = tmp;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, tmp]);

  const { groupFields } = useMappedFieldForm<T>({
    methods,
    formProps,
    schema,
    fieldName,
    showSubmitButton,
  });

  const listElement = React.useMemo(() => {
    const tmp: any[] = [];
    groupFields?.forEach((schemaFiled: FieldsElementType, index: number) => {
      if (!schemaFiled.hidden) {
        tmp.push(
          <GroupFields
            key={index}
            schema={{
              // propsGridContainer của schema sẽ bị ghi đề bởi propsGridContainer trong UI
              ...schemaFiled,
              propsGridContainer: {
                ...propsGridContainer,
                ...schemaFiled?.propsGridContainer,
              },
            }}
            fieldName={fieldName}
          />
        );
      }
    });
    return tmp;
  }, [fieldName, groupFields, propsGridContainer]);

  const listElementNoHidden = React.useMemo(() => {
    const tmp: any[] = [];
    groupFields?.forEach((schemaFiled: FieldsElementType, index: number) => {
      tmp.push(
        <GroupFields
          key={index}
          schema={{
            ...schemaFiled,
            propsGridContainer: {
              ...propsGridContainer,
              ...schemaFiled?.propsGridContainer,
            },
          }}
          fieldName={fieldName}
        />
      );
    });
    return tmp;
  }, [fieldName, groupFields, propsGridContainer]);

  const getFieldsElement = React.useMemo(() => {
    return groupFields?.reduce(
      (value: any, current: FieldsElementType, index: number) => {
        return {
          ...value,
          [current?.id || index]: current?.fields.reduce(
            (val: some, { key_element, ...cur }: some, idx: number) => {
              return {
                ...val,
                [key_element]: (
                  <SchemaElement
                    key={`${index}-${idx}`}
                    propsElement={cur}
                    fieldName={mergeFieldName({
                      name: key_element,
                      parent: fieldName,
                    })}
                    rawElement
                  />
                ),
              };
            },
            {}
          ),
        };
      },
      {}
    ) as any;
  }, [fieldName, groupFields]);

  if (!schema) {
    return null;
  }

  const view = (
    <Grid
      container
      columnSpacing={{ mobile: 0.5, tablet: 1, laptop: 2 }}
      rowSpacing={{ mobile: 1, tablet: 1, laptop: 2 }}
      wrap="wrap"
      {...props?.propsGridContainer}
    >
      {listElement}
    </Grid>
  );

  if (schema?.layout) {
    return (
      <>
        {schema.layout({
          valuesField: methods.getValues(),
          fields: getFieldsElement,
          methods,
          formProps,
          view,
          listElement: listElementNoHidden,
        })}
      </>
    );
  }

  return view;
};

export default SchemaView;
