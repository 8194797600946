import { isBoolean } from 'lodash';
import { some } from './constants';

export function scrollTo(id: string, offsetTop: number) {
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({ top: el.offsetTop - offsetTop, behavior: 'smooth' });
  }
}

export const countActiveFilter = (
  filter: some,
  exception?: string[]
): number => {
  const exceptionTmp = [
    'q',
    'pageSize',
    'pageIndex',
    'tab',
    'sort_by',
    'sort_order',
    ...(exception || []),
  ];
  const rs = Object.entries(filter).filter(([key, value]) => {
    if (exceptionTmp.includes(key)) {
      return false;
    }
    if (Array.isArray(value)) {
      if (value?.length > 0) {
        return true;
      }
      return false;
    }
    if (isBoolean(value)) {
      return true;
    }
    return !!value;
  })?.length;

  return rs;
};

export function titleCase(str) {
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str?.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export function removeEmptyKeys(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const filteredNestedObj = removeEmptyKeys(value);
      if (Object.keys(filteredNestedObj).length > 0) {
        acc[key] = filteredNestedObj;
      }
    } else if (value !== '' && value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function mappedParamsRequest(obj) {
  return Object.entries(obj).reduce((acc, [key, value]: any) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      acc[key] = value?.id;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}
