import { Typography, TypographyProps } from '@mui/material';
import { OriginalDishModel } from '../../origin-dish/detail/types';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { FormattedMessage } from 'react-intl';

interface Props extends TypographyProps {
  data?: OriginalDishModel;
}
const OriginalDishInfoBox = (props: Props) => {
  const { data, ...rest } = props;
  const { listType } = useGeneralHook();
  return (
    <Typography variant="caption" component={'div'} {...rest}>
      {[
        {
          label: 'originDish.type',
          value:
            data && listType.MG?.type?.find((v) => v.id === data?.type)?.name,
        },
        {
          label: 'originDish.cookingType',
          value:
            data &&
            listType.MG?.cookingType?.find((v) => v.id === data?.cookingType)
              ?.name,
        },
        {
          label: 'originDish.processingUnit',
          value:
            data &&
            listType.MG?.processingUnit?.find(
              (v) => v.id === data?.processingUnit
            )?.name,
        },
      ].map((item, index) => {
        return (
          <Typography
            key={index}
            variant="inherit"
            color="textSecondary"
            className="italic"
            component={'div'}
          >
            <FormattedMessage id={item.label} />
            :&nbsp;{item.value}
          </Typography>
        );
      })}
    </Typography>
  );
};
export default OriginalDishInfoBox;
