import {
  AddRounded,
  DeleteRounded,
  EditRounded,
  ListRounded,
} from '@mui/icons-material';
import { Button, Chip, IconButton, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import FilterBox from './FilterBox';
import { FoodGroupFilterModel, FoodGroupModelList } from './types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';

const GroupFoodPage = () => {
  const { dispatch, fetchThunk, API_PATHS, confirmDialog, intl, listType } =
    useGeneralHook();

  const hookPagination = usePaginationHook<FoodGroupFilterModel>();
  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<FoodGroupModelList>
  >({
    queryKey: [KEYS_QUERY.groupFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<FoodGroupModelList>>({
          url: API_PATHS.groupFood.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (item: FoodGroupModelList) => {
      await confirmDialog.promptConfirmation({
        title: intl.formatMessage({ id: 'confirm' }),
        content: intl.formatMessage(
          { id: 'deleteConfirm' },
          { name: item.name }
        ),
        onAccept: async () => {
          await dispatch(
            fetchThunk({
              url: API_PATHS.groupFood.detail(item.id),
              method: 'delete',
            })
          );
          refetch();
        },
      });
    },
  });

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <FilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.qualityStandard.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<FoodGroupModelList>
        data={data?.data ?? []}
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.meta.total,
          page: data?.meta.currentPage,
          rowsPerPage: data?.meta.perPage,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => [
          <MyLink to={row.original.id.toString()}>
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="edit" /> }}
            >
              <EditRounded />
            </IconButtonTooltip>
          </MyLink>,
          <IconButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="delete" /> }}
            onClick={() => deleteMutation.mutateAsync(row.original)}
          >
            <DeleteRounded />
          </IconButtonTooltip>,
          <MyLink
            to={[
              row.original.id.toString(),
              ROUTES_PATH.standardFood.index,
            ].join('/')}
          >
            <IconButton color="primary">
              <ListRounded />
            </IconButton>
          </MyLink>,
        ]}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => <FormattedMessage id="groupFood.operationCode" />,
          },
          {
            accessorKey: 'code',
            header: 'code',
            enableSorting: true,
            Header: () => <FormattedMessage id="groupFood.code" />,
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="groupFood.name" />,
          },
          {
            accessorKey: 'status',
            header: 'status',
            Header: () => <FormattedMessage id="status" />,
            Cell: ({ renderedCellValue }) => {
              const status = STATUS_OPTIONS.find(
                (v) => v.id === renderedCellValue
              );

              return (
                status && (
                  <Chip
                    label={<FormattedMessage id={status.name} />}
                    variant="outlined"
                    color={status.color as any}
                    size="small"
                  />
                )
              );
            },
          },
        ]}
      />
    </Paper>
  );
};
export default GroupFoodPage;
