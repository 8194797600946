import { Navigate, useParams } from 'react-router';
import MuiLoading from '../../../common/components/MuiLoading';
import { ROUTES_PATH } from '../../../layout/constants';
import ProcessingFoodInfoBox from './ProcessingFoodInfoBox';
import useProcessingFoodDetail from '../../processing-food/detail/useProcessingFoodDetail';
import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

const ProcessingFoodConfirmPage = () => {
  const { id } = useParams<{ id: string }>();
  const [step, setStep] = useState(0);
  const { data, isLoading } = useProcessingFoodDetail(id);

  if (isLoading) {
    return <MuiLoading />;
  }

  if (!data) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <ProcessingFoodInfoBox step={step} setStep={setStep} data={data} />
      {step === 0 ? (
        <Step1 setStep={setStep} processingFood={data} />
      ) : (
        <Step2 setStep={setStep} processingFood={data} />
      )}
    </>
  );
};
export default ProcessingFoodConfirmPage;
