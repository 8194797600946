import Close from '@mui/icons-material/Close';

import CircularProgress from '@mui/material/CircularProgress';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBaseProps } from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { some } from '../../../constants';
import fetchThunk from '../../../fetchThunk';
import { ResponseDataList } from '../../../types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';

const ALL_VALUE = 'all';
interface IOption {
  id?: string | number | undefined;
  disabled?: boolean;
  name?: React.ReactNode | string | number;
}

export interface SelectCustomProps
  extends Omit<SelectProps, 'defaultValue' | 'value' | 'onChange' | 'ref'> {
  label?: React.ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  required?: boolean;
  options?: (IOption | some)[];
  placeholder?: string;
  rawOptions?: boolean;
  disableClearBtn?: boolean;
  InputProps?: InputBaseProps;
  loadOptions?: {
    queryKey: QueryKey;
    mapped?: (json?: AxiosResponse | any) => some[];
    config?: Omit<
      UseQueryOptions<AxiosResponse<ResponseDataList<some>>>,
      'queryKey' | 'queryFn'
    >;
    queryFn?: QueryFunction<AxiosResponse<ResponseDataList<some>>>;
  };
  value?: any;
  onChange?: (val: any) => void;
  formControlProps?: FormControlProps;
  hasAllOptions?: boolean;
  name?: string;
}

const SelectCustom = React.forwardRef<HTMLDivElement | null, SelectCustomProps>(
  (props: SelectCustomProps, ref) => {
    const {
      label,
      required,
      options,
      placeholder,
      rawOptions,
      error,
      InputProps,
      loadOptions,
      value,
      onChange,
      disableClearBtn: disableCloseBtn,
      formControlProps,
      hasAllOptions,
      multiple,
      name,
      ...rest
    } = props;
    const dispatch: ThunkDispatch<AppState, null, Action> = useDispatch();
    const intl = useIntl();
    const { data, isLoading } = useQuery<AxiosResponse<ResponseDataList<some>>>(
      {
        queryKey: loadOptions?.queryKey || [],
        queryFn: loadOptions?.queryFn
          ? loadOptions?.queryFn
          : async ({ queryKey }) => {
              const json = await dispatch(
                fetchThunk<ResponseDataList<some>>({
                  url: queryKey[0] as string,
                  params: queryKey[1],
                })
              );
              return json;
            },
        enabled: !!loadOptions,
        ...loadOptions?.config,
      }
    );

    const optionsTmp = useMemo((): any => {
      if (loadOptions) {
        return loadOptions.mapped
          ? loadOptions.mapped(data)
          : data?.data.data || data;
      }
      return options || [];
    }, [data, loadOptions, options]);

    const isAllSelected = hasAllOptions
      ? multiple && optionsTmp
        ? value?.length === optionsTmp?.length || value?.length === 0
        : value === ''
      : false;

    const handleChange = (valueSelect: any) => {
      if (!onChange) {
        return;
      }
      if (multiple) {
        const tmp =
          typeof valueSelect === 'string'
            ? valueSelect.split(',')
            : valueSelect;
        if (isAllSelected) {
          onChange(tmp.filter((v) => v !== ALL_VALUE));
        } else if (hasAllOptions && (tmp || []).includes(ALL_VALUE)) {
          onChange((optionsTmp || []).map((v) => v.id));
        } else {
          onChange(tmp);
        }
        return;
      } else {
        onChange(valueSelect === ALL_VALUE ? '' : valueSelect);
      }
    };

    return (
      <FormControl error={!!error} fullWidth ref={ref} {...formControlProps}>
        {label && (
          <InputLabel required={required} shrink>
            {label}
          </InputLabel>
        )}
        <Select
          value={isAllSelected ? [ALL_VALUE] : value ?? ALL_VALUE}
          multiple={multiple}
          onChange={(event) => handleChange(event.target.value)}
          fullWidth
          variant="outlined"
          size="medium"
          displayEmpty
          {...(multiple
            ? {
                renderValue: (selected: any) => {
                  if (selected?.length === 0) {
                    return <em>{placeholder}</em>;
                  }
                  return selected
                    ?.map((one) => optionsTmp?.find((v) => v.id === one)?.name)
                    .join(', ');
                },
              }
            : {})}
          endAdornment={
            <>
              <InputAdornment
                position="end"
                sx={{ position: 'absolute', right: 16 }}
              >
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {(multiple
                  ? value?.length > 0 && !isAllSelected
                  : value !== '') &&
                !rest.readOnly &&
                !rest.disabled &&
                !disableCloseBtn ? (
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleChange(
                        multiple ? (hasAllOptions ? [ALL_VALUE] : []) : null
                      )
                    }
                    sx={{ display: 'none' }}
                    className="clear-btn"
                  >
                    <Close />
                  </IconButton>
                ) : null}
              </InputAdornment>
            </>
          }
          sx={{
            ':hover': {
              '& .clear-btn': {
                display: 'flex',
              },
            },
            ...rest.sx,
          }}
          {...rest}
        >
          {placeholder && (
            <MenuItem value={''} disabled={true} style={{ opacity: '.5' }}>
              <Typography
                variant="inherit"
                style={{ opacity: '.5' }}
                component="span"
              >
                {placeholder}
              </Typography>
            </MenuItem>
          )}
          {hasAllOptions && (
            <MenuItem value={ALL_VALUE}>
              {intl.formatMessage({ id: ALL_VALUE })}
            </MenuItem>
          )}

          {Array.isArray(optionsTmp) &&
            optionsTmp?.map((option: IOption, index: number) => {
              const { name, disabled, id, ...rest } = option;
              return (
                <MenuItem key={index} value={id} disabled={disabled} {...rest}>
                  {typeof name === 'string'
                    ? rawOptions || loadOptions
                      ? name
                      : name
                      ? intl.formatMessage({ id: name })
                      : ''
                    : name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }
);

export default memo(SelectCustom);
