import { OperationStatus } from '../../common/types';
import { ProcessingDishModel } from './types';
export const ProcessingDishQuotaReadOnlyStatus = [
  OperationStatus.confirm,
  OperationStatus.stop,
  OperationStatus.deny,
];
export const checkReadOnlyProcessingDishQuota = (
  data: ProcessingDishModel
): boolean => {
  return ProcessingDishQuotaReadOnlyStatus.includes(data.operationStatus);
};
