/* eslint-disable react/style-prop-object */
import { AddRounded, EditRounded } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import { ProcessingFoodFilterModel, ProcessingFoodModel } from './types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import ProcessingFoodFilterBox from './ProcessingFoodFilterBox';
import { useState } from 'react';
import UpdatePriceDialog, { UpdatePriceDialogProps } from './UpdatePriceDialog';

const SetupPriceProcessingFoodTab = () => {
  const { dispatch, fetchThunk, API_PATHS, listType } =
    useGeneralHook();
  const hookPagination = usePaginationHook<ProcessingFoodFilterModel>();
  const [form, setForm] = useState<UpdatePriceDialogProps>({
    open: false,
    onClose: () => setForm((old) => ({ ...old, open: false })),
  });
  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<ProcessingFoodModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.index,
          params: {
            ...params,
            filters: {
              ...params.filters,
              commodityGroup: params.filters.commodityGroup?.id,
              commodityClassification:
                params.filters.commodityClassification?.id,
            },
          },
        })
      );
      return json.data;
    },
  });

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <ProcessingFoodFilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.qualityStandard.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<ProcessingFoodModel>
        data={data?.data ?? []}
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.meta.total,
          page: data?.meta.currentPage,
          rowsPerPage: data?.meta.perPage,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => [
          <IconButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="edit" /> }}
            onClick={() => {
              setForm((old) => ({
                ...old,
                open: true,
                data: row.original,
              }));
            }}
          >
            <EditRounded />
          </IconButtonTooltip>,
        ]}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.code" />,
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.name" />,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <MyLink
                  to={row.original.id.toString()}
                  underline="always"
                  color="inherit"
                  className="break-all"
                >
                  {renderedCellValue}
                </MyLink>
              );
            },
          },
          {
            accessorKey: 'processingUnit.name',
            header: 'unit',
            Header: () => (
              <FormattedMessage id="processingFood.processingUnit" />
            ),
          },
          {
            accessorKey: 'commodityClassification.name',
            header: 'commodityClassification.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityClassification" />
            ),
          },
          {
            accessorKey: 'commodityGroup.name',
            header: 'commodityGroup.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityGroup" />
            ),
          },
          {
            accessorKey: 'preliminaryType',
            header: 'preliminaryType',
            Header: () => (
              <FormattedMessage id="processingFood.preliminaryType" />
            ),
            Cell: ({ renderedCellValue }) => {
              const status = listType.TPCB?.preliminaryType?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'price',
            header: 'price',
            Header: () => <FormattedMessage id="setupPrice.commonPrice" />,
            Cell: ({ renderedCellValue }) => {
              return (
                <FormattedNumber
                  value={80000}
                  style="currency"
                  currency="VND"
                />
              );
            },
          },
        ]}
      />
      <UpdatePriceDialog {...form} />
    </Paper>
  );
};
export default SetupPriceProcessingFoodTab;
