import { Navigate, useParams } from 'react-router';
import { ROUTES_PATH } from '../../../layout/constants';
import FormBox from './FormBox';
import useGetProcessingDishDetail from '../detail/useGetProcessingDishDetail';
import InfoBox from './InfoBox';
import MuiLoading from '../../../common/components/MuiLoading';
import { checkReadOnlyProcessingDishQuota } from '../utils';

const ProcessingDishQuotaDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetProcessingDishDetail(id);

  if (isLoading) {
    return <MuiLoading />;
  }
  if (!data) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  const readOnly = checkReadOnlyProcessingDishQuota(data);
  return (
    <>
      <InfoBox data={data} readOnly={readOnly} />
      <FormBox readOnly={readOnly} />
    </>
  );
};
export default ProcessingDishQuotaDetailPage;
