import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackRounded,
  CheckCircleOutlineRounded,
  UnpublishedOutlined,
} from '@mui/icons-material';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import ProcessingDishInfoBox from '../ProcessingDishInfoBox';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import SchemaElement from '../../../../common/SchemaForm/SchemaElement';
import MuiTable from '../../../../common/components/MuiTable';
import {
  ProcessingDishModel,
  QuotaModel,
} from '../../../processing-dish/types';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../../common/components/ReasonDialog';
import useMutationReason from '../../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../../common/components/GenerateCodeBox/types';
import { OperationStatus } from '../../../../common/types';
import { ROUTES_PATH } from '../../../../layout/constants';
import { useLocation, useNavigate } from 'react-router';
import useGetQuotaProcessingDishDetail from '../../../processing-dish/quota/useGetQuotaProcessingDishDetail';
import { ProcessingFoodModel } from '../../../processing-food/types';
import { mappedProcessingDishQuotaData } from '../../../processing-dish/quota/utils';
import ConfirmDataBox from './ConfirmDataBox';

interface Props {
  processingDish: ProcessingDishModel;
  setStep: (step: number) => void;
}
const Step2 = (props: Props) => {
  const { processingDish, setStep } = props;
  const {
    dispatch,
    fetchThunk,
    API_PATHS,
    listType,
    enqueueSnackbar,
    confirmDialog,
  } = useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();
  const { mutationReason } = useMutationReason();
  const { data } = useGetQuotaProcessingDishDetail(processingDish?.id);

  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const methods = useForm<QuotaModel>({
    values: data,
    defaultValues: { items: [] },
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const { watch } = methods;

  const fields = watch('items');

  const mappedData = mappedProcessingDishQuotaData(fields);

  const onUpdate = useMutation({
    mutationKey: ['update_dishes_quota'],
    mutationFn: async (values: QuotaModel) => {
      Promise.all([
        await dispatch(
          fetchThunk({
            url: API_PATHS.processingDish.detail(processingDish?.id),
            method: 'put',
            data: {
              ...processingDish,
              operationStatus: OperationStatus.confirm,
            },
          })
        ),
        await dispatch(
          fetchThunk({
            url: API_PATHS.processingDish.quota(processingDish?.id),
            method: 'put',
            data: {
              items: values.items?.map((v) => ({
                id: v.id,
                quantity: v.quantity,
                usageRatio: v.usageRatio,
              })),
            },
          })
        ),
      ]);
      enqueueSnackbar({
        message: <FormattedMessage id={'updateSuccess'} />,
        variant: 'success',
      });
      if (key === 'default') {
        navigate(
          [
            '',
            ROUTES_PATH.norms.index,
            ROUTES_PATH.processingDishConfirm.index,
          ].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  const onSubmit = useMutation({
    mutationFn: async (values: QuotaModel) => {
      await confirmDialog.promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: (
          <ConfirmDataBox processingDish={processingDish} quotas={values} />
        ),
        slotProps: {
          title: { justifyContent: 'center', mb: 2, variant: 'h5' },
          dialog: { maxWidth: 'laptop' },
        },
        onAccept: () => onUpdate.mutateAsync(values),
      });
    },
  });

  const onReject = () => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        if (!processingDish) {
          return;
        }
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingDish.detail(processingDish?.id),
              method: 'put',
              data: {
                ...processingDish,
                operationStatus: OperationStatus.deny,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingDish.reason(processingDish?.id),
            params: {
              objectId: processingDish?.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MCB,
              data: [],
              statusBefore: processingDish?.operationStatus,
              statusAfter: OperationStatus.deny,
            },
          }),
        ]).then(() => {
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.norms.index,
                ROUTES_PATH.processingDishConfirm.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'denyText',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {processingDish?.name}
              <br />
              {processingDish?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: processingDish?.operationStatus && (
            <FormattedMessage id={processingDish?.operationStatus} />
          ),
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.deny} />,
        },
      ],
      reasons: listType.MCB?.reasons?.deny,
    });
  };

  return (
    <>
      <Box className="p-2 tablet:p-4">
        <ProcessingDishInfoBox processingDish={processingDish} mode="info" />
      </Box>
      <FormProvider {...methods}>
        <Box
          className="p-2 tablet:p-4"
          component={'form'}
          onSubmit={methods.handleSubmit((val) => onSubmit.mutateAsync(val))}
        >
          <MuiTable<ProcessingFoodModel>
            boxProps={{
              sx: {
                p: 0,
              },
            }}
            data={mappedData || []}
            enableTableFooter={mappedData?.length > 0}
            getRowId={(originalRow: any) => originalRow.key}
            columns={[
              {
                accessorKey: 'name',
                header: 'name',
                Header: () => <FormattedMessage id="quota.name" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
                Cell: ({ row }) => {
                  return (
                    <Box className="text-center">
                      <Typography variant="inherit">
                        {row.original.name}
                      </Typography>
                      <Typography variant="inherit">
                        {row.original.operationCode}
                      </Typography>
                    </Box>
                  );
                },
              },
              {
                accessorKey: 'standardFood.name',
                header: 'standardFood.name',
                size: 100,
                Header: () => <FormattedMessage id="quota.standardFood" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'preliminaryType',
                header: 'preliminaryType',
                size: 100,
                Header: () => (
                  <FormattedMessage id="processingFood.preliminaryType" />
                ),
                Cell: ({ renderedCellValue }) => {
                  const preliminaryType = listType.TPCB?.preliminaryType?.find(
                    (v) => v.id === renderedCellValue
                  );
                  return preliminaryType?.name;
                },
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'usageRatio',
                header: 'usageRatio',
                Header: () => <FormattedMessage id="quota.usageRatio" />,
                size: 80,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
                Cell: ({ row }) => {
                  return (
                    <>
                      <SchemaElement
                        fieldName={`items.${row.index}.usageRatio`}
                        propsElement={{
                          mode: 'text-field',
                          type: 'number',
                          noHelperText: true,
                          rules: { required: true, max: 100 },
                          sx: { width: 80 },
                          inputProps: {
                            style: { textAlign: 'center', padding: 4 },
                          },
                          mapperValue: (e) => {
                            return e.target.value
                              ? Number(e.target.value) < 100
                                ? Math.abs(Math.floor(Number(e.target.value)))
                                : 100
                              : '';
                          },
                        }}
                      />
                    </>
                  );
                },
              },
              {
                accessorKey: 'quantity',
                header: 'quantity',
                size: 100,
                Header: () => <FormattedMessage id="quota.quantity_1" />,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={
                        ((row.original.quantity || 0) *
                          (row.original.processingUnit?.conversionRatio || 0)) /
                        100
                      }
                    />
                  );
                },
                muiTableFooterCellProps: {
                  colSpan: 5,
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      sx={{ textAlign: 'center', textTransform: 'uppercase' }}
                    >
                      <FormattedMessage id="total" />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.protein',
                header: 'standardFood.protein',
                Header: () => <FormattedMessage id="quota.protein" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.protein}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.protein) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.lipid',
                header: 'standardFood.lipid',
                Header: () => <FormattedMessage id="quota.lipid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber value={row.original.standardFood?.lipid} />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={mappedData?.reduce(
                          (v, c) => v + Number(c.standardFood?.lipid) || 0,
                          0
                        )}
                      />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.glucid',
                header: 'standardFood.glucid',
                Header: () => <FormattedMessage id="quota.glucid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.glucid}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.glucid) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },

              {
                accessorKey: 'standardFood.calorie',
                header: 'standardFood.calorie',
                Header: () => <FormattedMessage id="quota.calorie" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.calorie}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.calorie) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },
            ]}
          />
          <Box className="flex justify-end pt-1 pb-4">
            <Typography variant="caption">
              <FormattedMessage id="processingDishConfirm.note" />
            </Typography>
          </Box>
          <Box className="flex gap-2 tablet:gap-4 tablet:pt-0">
            <LoadingButton
              variant="outlined"
              startIcon={<UnpublishedOutlined />}
              onClick={onReject}
            >
              <FormattedMessage id="processedFoodConfirm.reject" />
            </LoadingButton>
            <Box flex={1} />{' '}
            <Button
              variant="outlined"
              startIcon={<ArrowBackRounded />}
              onClick={() => {
                setStep(0);
              }}
            >
              <FormattedMessage id="back" />
            </Button>
            <LoadingButton
              variant="contained"
              startIcon={<CheckCircleOutlineRounded />}
              type="submit"
            >
              <FormattedMessage id="confirm" />
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
      <ReasonDialog {...model} />
    </>
  );
};
export default Step2;
