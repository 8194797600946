import { Portal } from '@mui/base';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Outlet, useMatch, useParams, useRoutes } from 'react-router';
import { NavLink } from 'react-router-dom';
import { some } from '../../common/constants';
import useHookQuery from '../../common/hook/useHookQuery';
import { IRouteObject } from '../../common/types';
import { setOpenAsideBar } from '../../redux/cacheReducer';
import { PROJECT_LIST_ROUTES } from '../routes';

interface Props {
  hidden?: boolean;
}

function BreadcrumbsItem(props) {
  const {
    title,
    disabled,
    portalId = '',
    hidden,
    collapseWhenIn,
    path,
    depth,
  } = props;
  const params = useParams<some>();
  const queryScreen = useHookQuery();
  const { isMobile } = queryScreen;
  const intl = useIntl();
  const dispatch = useDispatch();
  const matchRoutes = useMatch(path) as any;
  const isActive = !!matchRoutes;

  function fillValues(str, obj) {
    return str.replace(/:\w+/g, (match) => {
      return obj[match.slice(1)] || match;
    });
  }

  React.useEffect(() => {
    if (isActive) {
      document.title =
        intl.formatMessage({ id: 'hvs' }) +
        (title ? ' | ' + intl.formatMessage({ id: title }) : '');
    }
  }, [intl, isActive, title]);

  React.useEffect(() => {
    if (isActive && collapseWhenIn && collapseWhenIn(queryScreen)) {
      dispatch(setOpenAsideBar(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseWhenIn, dispatch, isActive]);

  if (hidden) {
    return <Outlet />;
  }

  return (
    <>
      {isActive ? (
        <>
          <Portal container={document.getElementById('breadcrumbTitle')}>
            {title && <FormattedMessage id={title} />}
          </Portal>
          {portalId ? (
            <Box id={portalId} />
          ) : (
            <Typography variant="caption" color="textSecondary">
              {title && <FormattedMessage id={title} />}
            </Typography>
          )}
        </>
      ) : (
        <>
          {disabled ? (
            <Typography variant="caption" color="textSecondary">
              {portalId ? (
                <Box id={portalId} />
              ) : (
                <Typography variant="caption" color="textSecondary">
                  {title && <FormattedMessage id={title} />}
                </Typography>
              )}
            </Typography>
          ) : (
            <NavLink to={fillValues(path, params) || '/'}>
              {isMobile && depth ? (
                <Typography variant="caption">...</Typography>
              ) : (
                <Typography variant="caption">
                  {portalId ? (
                    <Box id={portalId} />
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                      {title && <FormattedMessage id={title} />}
                    </Typography>
                  )}
                </Typography>
              )}
            </NavLink>
          )}
          &nbsp;
          <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
          &nbsp;
        </>
      )}
      <Outlet />
    </>
  );
}

const DefaultBreadcrumb: React.FunctionComponent<Props> = (props) => {
  const { hidden } = props;
  const queryScreen = useHookQuery();
  const { isMobile } = queryScreen;
  const mappedRoutes = (
    list: IRouteObject[],
    depth: number,
    parent?: IRouteObject
  ) => {
    return list.map((one) => {
      let absolutePath = one.index
        ? parent?.absolutePath?.replace('/*', '')
        : (parent?.absolutePath?.replace('/*', '') || '') +
          '/' +
          one?.path?.replace('/*', '');

      if (one.blockBreadcrumb) {
        absolutePath = absolutePath + '/*';
      }

      return {
        ...one,
        path: one.index ? parent?.path : one?.path,
        element: (
          <BreadcrumbsItem
            title={one.label || one.title}
            portalId={one.portalId}
            disabled={
              (one?.children && one?.children?.length === 0) ||
              !one.children?.find((v) => v.index)
            }
            hidden={hidden}
            collapseWhenIn={one.collapseWhenIn}
            path={absolutePath}
            breadcrumb={one.breadcrumb}
            depth={depth}
          />
        ),
        children:
          one.children &&
          !one.blockBreadcrumb &&
          mappedRoutes(one.children, depth + 1, {
            ...one,
            absolutePath,
          }),
      };
    });
  };

  const breadcrumbsElements = useRoutes(mappedRoutes(PROJECT_LIST_ROUTES, 0));

  if (hidden) {
    return breadcrumbsElements;
  }

  return (
    <Box>
      <Typography
        variant={isMobile ? 'h6' : 'h4'}
        color="primary"
        sx={{ textTransform: 'uppercase' }}
      >
        <Box id="breadcrumbTitle" />
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        color="text.primary"
        flexWrap={'wrap'}
      >
        {breadcrumbsElements}
      </Box>
    </Box>
  );
};

export default React.memo(DefaultBreadcrumb, () => false);
