import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  UnpublishedOutlined,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES_PATH } from '../../../../layout/constants';
import FilterBox from './FilterBox';
import ProcessingDishInfoBox from '../ProcessingDishInfoBox';
import { NoData } from '../../../../svg';
import { ProcessingDishModel } from '../../../processing-dish/types';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../../common/components/ReasonDialog';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { OperationStatus } from '../../../../common/types';
import useMutationReason from '../../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../../common/components/GenerateCodeBox/types';
interface Props {
  processingDish: ProcessingDishModel;
  setStep: (step: number) => void;
}
const Step1 = (props: Props) => {
  const { setStep, processingDish } = props;
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { mutationReason } = useMutationReason();
  const { key } = useLocation();
  const navigate = useNavigate();
  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const [rowSelection, setRowSelection] = useState<
    ProcessingDishModel | undefined
  >(undefined);

  const onReject = () => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        if (!processingDish) {
          return;
        }
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingDish.detail(processingDish?.id),
              method: 'put',
              data: {
                ...processingDish,
                operationStatus: OperationStatus.deny,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingDish.reason(processingDish?.id),
            params: {
              objectId: processingDish?.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MCB,
              data: [rowSelection?.id],
              statusBefore: processingDish?.operationStatus,
              statusAfter: OperationStatus.deny,
            },
          }),
        ]).then(() => {
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.norms.index,
                ROUTES_PATH.processingDishConfirm.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'denyText',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {processingDish?.name} <br />
              {processingDish?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: processingDish?.operationStatus && (
            <FormattedMessage id={processingDish?.operationStatus} />
          ),
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.deny} />,
        },
      ],
      reasons: listType.MCB?.reasons?.deny,
    });
  };
  return (
    <>
      <FilterBox
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
      <Box className="flex flex-col-reverse tablet:flex-row gap-4 p-2 tablet:p-4 w-full">
        <ProcessingDishInfoBox processingDish={processingDish} />
        {rowSelection ? (
          <ProcessingDishInfoBox processingDish={rowSelection} />
        ) : (
          <Box className="flex flex-col flex-1 items-center justify-center gap-4 shrink-0 w-full">
            <NoData />
            <Typography
              className="whitespace-pre-wrap text-center"
              color="textSecondary"
            >
              <FormattedMessage id="originDish.selectedNote" />
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="flex p-2 gap-2 tablet:gap-4 tablet:p-4 tablet:pt-0">
        <LoadingButton
          variant="outlined"
          startIcon={<UnpublishedOutlined />}
          onClick={onReject}
        >
          <FormattedMessage id="processedFoodConfirm.reject" />
        </LoadingButton>
        <Box flex={1} />
        <Button
          variant="outlined"
          startIcon={<ArrowBackRounded />}
          onClick={() => {
            if (key === 'default') {
              navigate(
                [
                  '',
                  ROUTES_PATH.norms.index,
                  ROUTES_PATH.processingDishConfirm.index,
                ].join('/')
              );
            } else {
              navigate(-1);
            }
          }}
        >
          <FormattedMessage id="back" />
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<ArrowForwardRounded />}
          onClick={() => setStep(1)}
        >
          <FormattedMessage id="continue" />
        </LoadingButton>
      </Box>
      <ReasonDialog {...model} />
    </>
  );
};
export default Step1;
