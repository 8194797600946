import LoadingButton from '@mui/lab/LoadingButton';
import { DialogTitle, DialogTitleProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

export interface ParamsPromptConfirmation {
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
  okId?: React.ReactNode | string;
  cancelId?: React.ReactNode | string;
  warning?: boolean;
  open?: boolean;
  onAccept?: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  slotProps?: {
    dialog?: Omit<DialogProps, 'open'>;
    title?: DialogTitleProps;
    content?: DialogContentProps;
    action?: DialogActionsProps;
  };
}
export interface ConfirmDialogParams {
  promptConfirmation: (setting?: ParamsPromptConfirmation) => Promise<unknown>;
}

const ConfirmDialog = forwardRef((props, ref) => {
  // const classes = useStyles();
  const [settings, setOptions] = useState<ParamsPromptConfirmation>(props);
  const [loading, setLoading] = useState(false);
  const [confirmDialogResolveRef, setConfirmDialogResolveRef] = React.useState<
    | {
        resolve?: (confirm: boolean) => void; //Đố biết sao phải để trong Object
      }
    | undefined
  >();
  const {
    warning,
    title,
    content,
    slotProps,
    okId,
    cancelId,
    onAccept,
    onCancel,
  } = settings;

  const promptConfirmation = useCallback(
    async (setting?: Omit<ParamsPromptConfirmation, 'children'>) => {
      setting && setOptions((old) => ({ ...old, ...setting }));
      const confirmTmp = await new Promise((resolve) => {
        setConfirmDialogResolveRef({ resolve });
      });
      return confirmTmp;
    },
    []
  );

  const close = useCallback(() => {
    setConfirmDialogResolveRef(undefined);
  }, []);

  const onClose = useCallback(async () => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(false);
    onCancel && (await onCancel());
    close();
  }, [close, confirmDialogResolveRef, onCancel]);

  const onOk = useCallback(async () => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(true);
    try {
      setLoading(true);
      onAccept && (await onAccept());
    } finally {
      setLoading(false);
    }
    close();
  }, [close, confirmDialogResolveRef, onAccept]);

  const openDialog = confirmDialogResolveRef?.resolve !== undefined;

  useImperativeHandle(ref, () => ({
    promptConfirmation,
  }));

  return (
    <Dialog
      fullWidth
      maxWidth="tablet"
      {...slotProps?.dialog}
      open={openDialog}
      // classes={{ paper: classes.paper, ...dialogProps?.classes }}
      onClose={() => {
        onClose();
        setOptions(props);
      }}
      keepMounted={false}
    >
      {title && (
        <DialogTitle
          sx={{ padding: 2, pb: 0, display: 'flex', alignItems: 'flex-end' }}
          color={'primary'}
          {...slotProps?.title}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={{ padding: 2, pb: 0, whiteSpace: 'pre-wrap' }}
        {...slotProps?.content}
      >
        {content}
      </DialogContent>
      <DialogActions style={{ padding: 16 }} {...slotProps?.action}>
        <Button
          variant="outlined"
          color="primary"
          title="cancel"
          // classes={{ root: classes.button }}
          onClick={onClose}
          sx={{ minWidth: 100 }}
        >
          {typeof cancelId !== 'string' && typeof cancelId !== 'undefined' ? (
            cancelId
          ) : (
            <FormattedMessage id={cancelId || 'cancel'} />
          )}
        </Button>
        <LoadingButton
          color={warning ? 'error' : 'primary'}
          variant="contained"
          title="submit"
          // classes={{ root: classes.button }}
          onClick={onOk}
          autoFocus
          sx={{ minWidth: 100, ml: 2 }}
          loading={loading}
        >
          {typeof okId !== 'string' && typeof okId !== 'undefined' ? (
            okId
          ) : (
            <FormattedMessage id={okId || 'ok'} />
          )}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
