import dayjs from 'dayjs';

export type some = { [key: string]: any };
export const DEFAULT_PAGESIZE = 50;
export const DEFAULT_PAGESIZE_MOBILE = 20;
export const LIMIT_FILE_SIZE = 2.5;
export const AUTHENTICATION_TOKEN = 'access-token';
export const KEYS_QUERY = {
  genCode: 'genCode',
  qualityStandard: 'qualityStandard',
  qualityStandardDetail: 'qualityStandardDetail',
  standardFood: 'standardFood',
  standardFoodDetail: 'standardFoodDetail',
  groupFood: 'groupFood',
  groupFoodDetail: 'standardFoodDetail',
  commodityClassification: 'commodityClassification',
  commodityClassificationDetail: 'commodityClassificationDetail',
  commodityGroups: 'commodityGroups',
  commodityGroupsDetail: 'commodityGroupsDetail',
  channelDistributions: 'channelDistributions',
  channelDistributionsDetail: 'channelDistributionsDetail',
  orderClassifications: 'orderClassifications',
  orderClassificationsDetail: 'orderClassificationsDetail',
  units: 'units',
  unitsDetail: 'unitsDetail',
  order: 'order',
  orderDetail: 'orderDetail',
  originDish: 'originDish',
  originDishDetail: 'originDishDetail',
  processingFood: 'processingFood',
  processingFoodDetail: 'processingFoodDetail',
  processingDish: 'processingDish',
  processingDishDetail: 'processingDishDetail',
  quotaProcessingDishDetail: 'quotaProcessingDishDetail',
  unProcessingDish: 'unProcessingDish',
  unProcessingDishDetail: 'unProcessingDishDetail',
  quotaDish: 'quotaDish',
  quotaDishDetail: 'quotaDishDetail',
};

export const MODE_THEME = 'mode_theme';

export const DATE_RAGE_OPTIONS = () => [
  {
    id: 1,
    name: 'today',
    value: {
      from: dayjs().startOf('days').toISOString(),
      to: dayjs().endOf('days').toISOString(),
    },
  },
  {
    id: 2,
    name: 'yesterday',
    value: {
      from: dayjs().subtract(1, 'day').startOf('day').toISOString(),
      to: dayjs().subtract(1, 'day').endOf('day').toISOString(),
    },
  },
  {
    id: 3,
    name: 'this_week',
    value: {
      from: dayjs().startOf('weeks').toISOString(),
      to: dayjs().endOf('weeks').toISOString(),
    },
  },
  {
    id: 4,
    name: 'last_week',
    value: {
      from: dayjs().subtract(1, 'week').startOf('week').toISOString(),
      to: dayjs().subtract(1, 'week').endOf('week').toISOString(),
    },
  },
  {
    id: 10,
    name: '10_days_ago',
    value: {
      from: dayjs().subtract(10, 'days').startOf('days').toISOString(),
      to: dayjs().endOf('days').toISOString(),
    },
  },
  {
    id: 5,
    name: 'this_month',
    value: {
      from: dayjs().startOf('month').toISOString(),
      to: dayjs().endOf('month').toISOString(),
    },
  },
  {
    id: 6,
    name: 'last_month',
    value: {
      from: dayjs().subtract(1, 'month').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString(),
    },
  },
  {
    id: 7,
    name: '3_months_ago',
    value: {
      from: dayjs().subtract(3, 'months').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString(),
    },
  },
  {
    id: 8,
    name: '6_months_ago',
    value: {
      from: dayjs().subtract(9, 'months').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString(),
    },
  },
  {
    id: 9,
    name: '12_months_ago',
    value: {
      from: dayjs().subtract(12, 'months').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString(),
    },
  },
  {
    id: 9,
    name: '12_months_ago',
    value: {
      from: dayjs().subtract(12, 'months').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString(),
    },
  },
];

export const localeObject = {
  weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。

  // name: 'en', // name String
  // weekdays: 'Domingo_Lunes ...'.split('_'), // weekdays Array
  // weekdaysShort: 'Sun_M'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
  // weekdaysMin: 'Su_Mo'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
  // yearStart: 4, // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  // months: 'Enero_Febrero ... '.split('_'), // months Array
  // monthsShort: 'Jan_F'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
  // ordinal: n => `${n}º`, // ordinal Function (number) => return number + output
  weekdaysMin: ['CN', 'H', 'B', 'T', 'N', 'S', 'B'],

  formats: {
    // abbreviated format options allowing localization
    LT: 'HH:mm',
    L: 'DD/MM/YYYY',
    LL: 'DD/MM/YYYY HH:mm',
    LLL: 'DD/MM/YYYY HH:mm:ss',
  },
  relativeTime: {
    // relative time format strings, keep %s %d as the same
    future: 'in %s', // e.g. in 2 hours, %s been replaced with 2hours
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours', // e.g. 2 hours, %d been replaced with 2
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
};

export const STATUS_OPTIONS = [
  { id: true, name: 'active', color: 'success' },
  { id: false, name: 'deactive', color: 'error' },
];
