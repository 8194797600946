import { useQuery } from '@tanstack/react-query';
import { ModuleCode } from '../components/GenerateCodeBox/types';
import useGeneralHook from './useGeneralHook';
import { some } from '../constants';

export interface ReasonDataModel {
  objectId: string | number;
  objectType: ModuleCode;
  key: string;
  reason: string;
  data?: some;
  statusBefore: string | boolean;
  statusAfter: string | boolean;
}
const useGetReason = (url?: string) => {
  const { dispatch, fetchThunk } = useGeneralHook();
  const { data } = useQuery<ReasonDataModel>({
    queryKey: [url, 'get-reason'],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ReasonDataModel>({
          url: queryKey[0] as string,
          method: 'get',
        })
      );
      return json.data;
    },
    enabled: !!url,
  });
  return data;
};
export default useGetReason;
