import { Action, ThunkAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logoutFnc } from '../redux/authReducer';
import { setLoading } from '../redux/commonReducer';
import { AppState } from '../redux/store';
import { getBaseUrl } from '../api/API_App';
import Cookies from 'js-cookie';
import { AUTHENTICATION_TOKEN } from './constants';
import { mappedParamsRequest } from './utils';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
axios.defaults.timeout = 20000;
axios.defaults.baseURL = getBaseUrl;

export const requestAxios = (options: AxiosRequestConfig) => {
  return axios.create({
    headers: {
      Authorization: 'Bearer ' + Cookies.get(AUTHENTICATION_TOKEN),
    },
  })({
    ...options,
  });
};

function fetchThunk<T = any, D = any>(
  options: AxiosRequestConfig<D>,
  loading = true
): ThunkAction<Promise<AxiosResponse<T, D>>, AppState, null, Action> {
  return async (dispatch, getState) => {
    if (loading) dispatch(setLoading(true));
    try {
      const json = await requestAxios({
        ...options,
        data: ['post', 'put'].includes(options.method || '')
          ? mappedParamsRequest(options.data)
          : options.data,
      });
      return json || {};
    } catch (e: any) {
      if (e.response.status === 401) {
        dispatch(logoutFnc());
      }
      throw e;
    } finally {
      if (loading) dispatch(setLoading(false));
    }
  };
}

export default fetchThunk;
