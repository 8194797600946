import { get } from 'lodash';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  FieldValues,
  FieldsElementType,
  IUIField,
  FormProps,
  ISchemaForm,
  SchemaFieldName,
} from './utils';

interface Props<T extends FieldValues = FieldValues> {
  methods: UseFormReturn<T>;
  formProps: FormProps;
  schema?: ISchemaForm<T>;
  fieldName?: SchemaFieldName;
  showSubmitButton?: boolean;
  [key: string]: any;
}

const useMappedFieldForm = <T extends FieldValues = FieldValues>(
  props: Props<T>
) => {
  const { formProps, methods, fieldName, schema } = props;

  const groupFields = useMemo((): FieldsElementType[] => {
    if (!schema) {
      return [];
    }
    const valuesField = methods
      ? ((fieldName
          ? methods?.watch(fieldName as any)
          : methods?.watch()) as any)
      : {};
    const fields =
      typeof schema?.fields === 'function'
        ? schema?.fields({ valuesField, methods, fieldName, formProps })
        : schema?.fields;
    const ui =
      typeof schema?.ui === 'function'
        ? schema?.ui({ valuesField, methods, formProps })
        : schema?.ui;

    let groupFields: FieldsElementType[] = ui
      ? ui
          .filter(Boolean)
          .reduce((value: FieldsElementType[], current: IUIField<T>) => {
            const flatValue: string[] = value
              .filter((v) => !v.hidden)
              .reduce((val: string[], cur: FieldsElementType) => {
                return [
                  ...val,
                  ...(cur.fields ?? [])
                    .map((v: any) => v.key_element)
                    .filter(Boolean),
                ];
              }, []);
            const tmp = (current?.fields as string[]) ?? [];
            return [
              ...value,
              {
                ...current,
                fields: (Array.isArray(tmp) ? tmp : [])
                  .filter((val) => val && !flatValue.includes(val as string))
                  .map((val: string) => {
                    const field = get(fields, val) as any;
                    return (
                      field && {
                        ...formProps.globalProps,
                        ...field,
                        key_element: val,
                      }
                    );
                  })
                  .filter(Boolean),
              },
            ];
          }, [])
          .filter(Boolean)
      : [
          {
            id: 'default',
            fields: Object.entries(fields).map(([key, value]) => {
              return {
                key_element: key,
                ...formProps.globalProps,
                ...value,
              };
            }),
          },
        ];

    return groupFields;
  }, [fieldName, formProps, methods, schema]);

  return { groupFields };
};
export default useMappedFieldForm;
