import {
  Action,
  createSlice,
  PayloadAction,
  ThunkAction,
} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { API_PATHS } from '../api/API_App';
import { AUTHENTICATION_TOKEN, some } from '../common/constants';
import { AppState } from './store';
import fetchThunk, { requestAxios } from '../common/fetchThunk';

export interface UserInfoModel {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  two_factor_confirmed_at: string;
  current_team_id: string;
  permissions: string[];
  profile_photo_path: string;
  created_at: string;
  updated_at: string;
  profile_photo_url: string;
}

export interface AuthState {
  isLogin: boolean;
  loading: boolean;
  validate: boolean;
  userInfo?: UserInfoModel;
}

export const initialStateAuth: AuthState = {
  isLogin: false,
  loading: false,
  validate: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setValidateAuth: (state, action) => {
      state.validate = action.payload;
    },
    authIn: (state) => {
      state.isLogin = true;
    },
    authOut: (state) => {
      state.isLogin = false;
      state.userInfo = undefined;
      localStorage.clear();
    },
    setUserInfo: (state, action: PayloadAction<UserInfoModel>) => {
      state.userInfo = action.payload;
    },
  },
});

export const { authIn, authOut, setLoading, setValidateAuth, setUserInfo } =
  authSlice.actions;

export function getUserInfo(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  Action
> {
  return async (dispatch) => {
    try {
      dispatch(setValidateAuth(true));
      await requestAxios({
        url: API_PATHS.auth.XSRFToken,
      });
      const json = await dispatch(
        fetchThunk<UserInfoModel>({
          url: API_PATHS.auth.userInfo,
          method: 'get',
        })
      );
      dispatch(authIn());
      dispatch(setUserInfo(json.data));
    } catch (e) {
      dispatch(authOut());
      dispatch(setValidateAuth(false));
      dispatch(setLoading(false));
    } finally {
      dispatch(setValidateAuth(false));
    }
  };
}

export function loginFnc(
  values: some
): ThunkAction<Promise<void>, AppState, null, Action> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      dispatch(setLoading(true));
      const json = await requestAxios({
        url: API_PATHS.auth.login,
        method: 'post',
        data: values,
      });
      Cookies.set(AUTHENTICATION_TOKEN, json.data.data.token);
      const json2 = await dispatch(
        fetchThunk<UserInfoModel>({
          url: API_PATHS.auth.userInfo,
          method: 'get',
        })
      );
      dispatch(setUserInfo(json2.data));
      dispatch(authIn());
    } catch (e) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function logoutFnc(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  Action
> {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      // await dispatch(fetchThunk({ url: API_PATHS.auth.logout }));
      dispatch(authOut());
      Cookies.remove(AUTHENTICATION_TOKEN);
    } catch (e) {
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export default authSlice.reducer;
