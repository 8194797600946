import { FormattedMessage } from 'react-intl';
import { UnProcessingDishModel } from '../types';
import { Box, Grid, Typography } from '@mui/material';
import OriginalDishInfoBox from '../../processing-dish/detail/OriginalDishInfoBox';
import { OperationStatus } from '../../../common/types';

interface Props {
  data: UnProcessingDishModel;
}
const ConfirmDataBox = (props: Props) => {
  const { data } = props;
  return (
    <>
      {[
        {
          label: 'type',
          value: <FormattedMessage id="unProcessingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name}
              <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id={OperationStatus.create} />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.confirm} />,
        },
      ]?.map((item, index) => {
        return (
          <Typography variant="body2" key={index} className="flex">
            <Box sx={{ minWidth: 160 }}>
              <FormattedMessage id={item.label} />
            </Box>
            :&emsp;{item.value}
          </Typography>
        );
      })}

      <Grid container columnSpacing={2} mt={2}>
        {[
          {
            label: 'unProcessingDish.name',
            value: data.name,
          },
          {
            label: 'unProcessingDish.operationCode',
            value: data.operationCode,
          },
          {
            label: 'unProcessingDish.objectType',
            value: data.objectType,
          },
          {
            label: 'unProcessingDish.displayName',
            value: data.displayName,
          },
          {
            label: 'unProcessingDish.commodityClassification',
            value: data.commodityClassification?.name,
          },
          {
            label: 'unProcessingDish.commodityGroup',
            value: data.commodityGroup?.name,
          },

          {
            label: 'unProcessingDish.mainFoodQuantity',
            value: data.mainFoodQuantity,
          },

          {
            label: 'unProcessingDish.usageRatio',
            value: data.usageRatio,
          },

          {
            label: 'unProcessingDish.nutritionRequired',
            value: (
              <FormattedMessage id={data.nutritionRequired ? 'yes' : 'no'} />
            ),
          },

          {
            label: 'unProcessingDish.isProcessingFood',
            value: (
              <FormattedMessage id={data.isProcessingFood ? 'yes' : 'no'} />
            ),
          },
          {
            label: 'unProcessingDish.referenceInfo',
            node: <OriginalDishInfoBox data={data.originalDish} />,
          },
        ]?.map((item, index) => {
          if (item.node) {
            return (
              <Grid key={index} item xs={12}>
                <Typography variant="body2" component={'div'} mt={2} mb={1}>
                  <FormattedMessage id={item.label} />:
                </Typography>
                {item.node}
              </Grid>
            );
          }
          return (
            <Grid key={index} item xs={6}>
              <Typography variant="body2" className="whitespace-nowrap">
                <FormattedMessage id={item.label} />
                :&nbsp;{item.value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default ConfirmDataBox;
