import { Box, Button, Checkbox, Divider, Typography } from '@mui/material';
import MuiDialog from '../../../common/components/MuiDialog';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import MuiTable from '../../../common/components/MuiTable';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useState } from 'react';
import {
  ProcessingFoodFilterModel,
  ProcessingFoodModel,
} from '../../processing-food/types';
import { OperationStatus, ResponseDataList } from '../../../common/types';
import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import FilterBox from './FilterBox';

interface Props {
  open: boolean;
  onClose: (val: boolean) => void;
  onSubmit: (val: ProcessingFoodModel[]) => void;
  selected: ProcessingFoodModel[];
}
const SelectDishesDialog = (props: Props) => {
  const { selected, open, onClose, onSubmit } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();
  const [rowSelection, setRowSelection] = useState<ProcessingFoodModel[]>([]);
  const hookPagination = usePaginationHook<ProcessingFoodFilterModel>({
    disableLink: true,
  });
  const { params, onPageChange, onRowsPerPageChange } = hookPagination;

  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<ProcessingFoodModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.index,
          params: {
            ...params,
            filters: {
              ...params.filters,
              commodityGroup: params.filters.commodityGroup?.id,
              commodityClassification:
                params.filters.commodityClassification?.id,
              operationStatus: OperationStatus.confirm,
            },
          },
        })
      );
      return json.data;
    },
  });

  return (
    <>
      <MuiDialog
        open={open}
        onClose={onClose}
        title="quota.selectFood"
        maxWidth="laptop"
        fullWidth
        TransitionProps={{
          onEnter: () => setRowSelection([]),
        }}
      >
        <Box px={1.5} pb={2} display={'flex'} flexDirection={'column'}>
          <FilterBox hookPagination={hookPagination} />
          <Divider
            sx={{
              mt: 1,
              mb: 2,
              borderColor: 'primary.main',
              borderStyle: 'dashed',
            }}
          />
          <MuiTable
            boxProps={{ sx: { p: 0, flex: 1 } }}
            enableSelectAll={false}
            data={data?.data || []}
            paginationProps={{
              count: data?.data.length,
              page: params.pageIndex,
              rowsPerPage: params.pageSize,
              onPageChange: onPageChange,
              onRowsPerPageChange: onRowsPerPageChange,
            }}
            muiTableContainerProps={{
              sx: { overflow: 'auto', height: '50vh' },
            }}
            enableStickyHeader
            columns={[
              {
                header: 'select',
                Header: () =>
                  rowSelection.length > 0 ? (
                    <Typography variant="subtitle2" color="primary">
                      {rowSelection.length}
                    </Typography>
                  ) : (
                    <FormattedMessage id="quota.selected" />
                  ),
                size: 80,
                Cell: ({ row }) => {
                  const checked =
                    rowSelection.findIndex((v) => v.id === row.original.id) !==
                    -1;
                  const disabled =
                    selected.findIndex((v) => v.id === row.original.id) !== -1;
                  return (
                    <Checkbox
                      disabled={disabled}
                      checked={disabled || checked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setRowSelection((old) => [row.original, ...old]);
                        } else {
                          setRowSelection((old) =>
                            old.filter((v) => v.id !== row.original.id)
                          );
                        }
                      }}
                    />
                  );
                },
              },
              {
                accessorKey: 'operationCode',
                header: 'operationCode',
                size: 100,
                Header: () => <FormattedMessage id="quota.code" />,
              },
              {
                accessorKey: 'name',
                header: 'name',
                size: 150,
                Header: () => <FormattedMessage id="quota.name" />,
              },
              {
                accessorKey: 'processingUnit.name',
                header: 'processingUnit',
                size: 50,
                Header: () => <FormattedMessage id="quota.processingUnit" />,
              },
              {
                accessorKey: 'conversionRatio',
                header: 'conversionRatio',
                size: 50,
                Header: () => <FormattedMessage id="quota.conversionRatio" />,
              },
              {
                accessorKey: 'standardFood.usageRatio',
                header: 'standardFood',
                size: 50,
                Header: () => <FormattedMessage id="quota.usageRatio" />,
              },
              {
                accessorKey: 'standardFood.protein',
                header: 'standardFood.protein',
                Header: () => <FormattedMessage id="quota.protein" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.protein}
                    />
                  );
                },
              },
              {
                accessorKey: 'standardFood.lipid',
                header: 'standardFood.lipid',
                Header: () => <FormattedMessage id="quota.lipid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber value={row.original.standardFood?.lipid} />
                  );
                },
              },
              {
                accessorKey: 'standardFood.glucid',
                header: 'standardFood.glucid',
                Header: () => <FormattedMessage id="quota.glucid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.glucid}
                    />
                  );
                },
              },

              {
                accessorKey: 'standardFood.calorie',
                header: 'standardFood.calorie',
                Header: () => <FormattedMessage id="quota.calorie" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.calorie}
                    />
                  );
                },
              },
            ]}
          />
          <Box className="flex justify-end gap-4">
            <Button
              variant="outlined"
              sx={{ minWidth: 120 }}
              onClick={() => onClose(false)}
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              sx={{ minWidth: 120 }}
              onClick={() => onSubmit(rowSelection)}
            >
              <FormattedMessage id="confirm" />
            </Button>
          </Box>
        </Box>
      </MuiDialog>
    </>
  );
};
export default SelectDishesDialog;
