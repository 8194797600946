import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import MyLink from '../../common/components/MyLink';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { IRouteObject } from '../../common/types';
import {
  setOpenAsideBar,
  setOpenAsideBarMobile,
} from '../../redux/cacheReducer';
import { AppState } from '../../redux/store';
import { LogoImage, LogoTextSrc } from '../../svg';
import DefaultAsideItems from './DefaultAsideItems';
import ProfileBox from './ProfileBox';
import { ASIDE_WIDTH } from './constants';

const HEADER_HEIGHT = 0;
interface Props {
  listRouter: IRouteObject[];
}

const DefaultAside: React.FunctionComponent<Props> = (props) => {
  const { listRouter } = props;
  const { isMobile, dispatch } = useGeneralHook();
  const location = useLocation();
  const { pathname } = location;
  const openAsideBarMobile = useSelector(
    (state: AppState) => state.cache.openAsideBarMobile
  );
  const openAsideBar = useSelector(
    (state: AppState) => state.cache.openAsideBar
  );
  const ASIDE_MIN_WIDTH = isMobile ? 0 : 64;
  // const openMerge = isMobile ? openAsideBarMobile : openAsideBar || hoverOpen;
  const openMerge = isMobile ? openAsideBarMobile : openAsideBar;
  const openAsideTmp = isMobile ? openAsideBarMobile : openAsideBar;

  const [isResizing, setIsResizing] = React.useState(false);
  const [sidebarWidth, setSidebarWidth] = React.useState(ASIDE_WIDTH);

  const mappedRoutes = (
    list: IRouteObject[],
    parent?: IRouteObject
  ): IRouteObject[] => {
    return list
      .filter((one) => !one.hidden)
      .map((one) => {
        return {
          ...one,
          path: one.index
            ? parent?.path
            : parent?.path !== '/' && parent?.path
            ? `${parent?.path}/${one.path}`
            : one.path,
          children: one.children && mappedRoutes(one.children, one),
        };
      });
  };

  const listRouterMapped = mappedRoutes(listRouter, {
    path: '/',
  });

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
    if (sidebarWidth < ASIDE_WIDTH && sidebarWidth > ASIDE_WIDTH / 2) {
      setSidebarWidth(ASIDE_WIDTH);
    } else if (sidebarWidth < ASIDE_WIDTH / 2) {
      dispatch(setOpenAsideBar(false));
      setSidebarWidth(ASIDE_WIDTH);
    }
  }, [dispatch, sidebarWidth]);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(mouseMoveEvent.clientX);
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    if (isMobile) {
      dispatch(setOpenAsideBarMobile(false));
    }
  }, [dispatch, isMobile, pathname]);

  React.useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <Box
        id="leftPanel"
        sx={{
          minWidth: openAsideTmp ? sidebarWidth : ASIDE_MIN_WIDTH,
          transition: '0.3s',
        }}
      />

      <Box
        id="leftPanel"
        sx={(theme) => ({
          width: openMerge ? sidebarWidth : ASIDE_MIN_WIDTH,
          height: '100vh',
          transition: isResizing ? 'unset' : 'width 0.3s',
          flexShrink: 0,
          zIndex: 200,
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          top: HEADER_HEIGHT,
          left: 0,
          bottom: 0,
          willChange: 'width',
          background: theme.palette.background.paper,
          '&:hover .collapse-btn': { opacity: 1 },
          [theme.breakpoints.down('laptop')]: {
            '& .collapse-btn': { opacity: 1 },
          },
        })}
        onMouseDown={(e) => e.preventDefault()}
      >
        {openAsideTmp && (
          <Box
            sx={{
              position: 'absolute',
              right: -4,
              top: 0,
              bottom: 0,
              width: 8,
              zIndex: 2,
              cursor: 'col-resize',
              resize: 'horizontal',
              ':hover': {
                '&>div': { opacity: 1 },
              },
            }}
            onMouseDown={startResizing}
          >
            <Box
              sx={{
                bgcolor: 'primary.main',
                opacity: 0,
                height: '100%',
                left: 4,
                position: 'absolute',
                transition: 'opacity 200ms ease 0s',
                width: 2,
              }}
            />
          </Box>
        )}
        <Tooltip
          title={<FormattedMessage id={openMerge ? 'collapse' : 'expand'} />}
          arrow
        >
          <ButtonBase
            className="collapse-btn"
            sx={(theme) => ({
              borderRadius: '50%',
              position: 'absolute',
              top: 40,
              right: -14,
              minWidth: 28,
              width: 28,
              height: 28,
              bgcolor: 'background.paper',
              color: 'text.primary',
              boxShadow: 4,
              border: 1,
              borderColor: 'grey.200',
              zIndex: 3,
              transition: 'all 0.3s',
              opacity: !openAsideTmp ? 1 : 0,
              display: isMobile ? 'none' : undefined,
              ':hover': {
                bgcolor: 'primary.main',
                color: 'common.white',
              },
              [theme.breakpoints.down('laptop')]: {
                opacity: 1,
              },
            })}
            onClick={() => {
              dispatch(setOpenAsideBar());
            }}
          >
            <ArrowForwardIosIcon
              color="inherit"
              sx={{
                fontSize: 14,
                transform: openAsideTmp ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </ButtonBase>
        </Tooltip>
        <Box
          overflow="hidden"
          borderRight={1}
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderColor: 'divider',
            borderRight: 1,
          }}
        >
          <MyLink to="/" aria-label="Link to HomePage">
            <Box className="flex items-center w-full justify-center">
              <LogoImage style={{ height: 50, width: 50 }} />
              <Box
                component={'img'}
                src={LogoTextSrc}
                alt="log_text"
                sx={{
                  width: openAsideTmp ? 100 : 0,
                  opacity: openAsideTmp ? 1 : 0,
                  marginLeft: openAsideTmp ? 2 : 0,
                  transition: '0.3s all',
                }}
              />
            </Box>
          </MyLink>
          <PerfectScrollbar style={{ flex: 1 }}>
            <Box
              sx={{
                pb: 2,
                overflowY: 'auto',
                overflowX: 'hidden',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                '& .aside-bar-title': {
                  lineHeight: openAsideTmp ? undefined : 0,
                  opacity: openAsideTmp ? 1 : 0,
                  padding: openAsideTmp ? undefined : 0,
                  marginTop: openAsideTmp ? undefined : -1,
                  transition: '0.3s all',
                },
              }}
            >
              {listRouterMapped?.map((v: IRouteObject, index: number) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      padding: isMobile ? 0.5 : 1,
                    }}
                  >
                    <DefaultAsideItems
                      data={v}
                      pathname={pathname}
                      open={openMerge}
                    />
                  </Box>
                  {index !== listRouterMapped.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </PerfectScrollbar>
          {isMobile && (
            <Box
              sx={{
                pb: 4,
                width: '100%',
                display: 'flex',
              }}
            >
              <ProfileBox
                sx={{
                  width: '100%',
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DefaultAside;
