import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router';
import MuiLoading from '../../common/components/MuiLoading';
import { AppState } from '../../redux/store';
import { PROJECT_LIST_ROUTES } from '../routes';
import DefaultAside from './DefaultAside';
import DefaultBreadcrumb from './DefaultBreadcrumb';
import DefaultHeader from './DefaultHeader';
import { mappedRoutesPermission } from './utils';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { getListType } from '../../redux/commonReducer';

const ProcessLoading = React.memo(
  () => {
    const loading = useSelector(
      (state: AppState) => state.common.loading,
      shallowEqual
    );
    if (loading) {
      return (
        <LinearProgress
          sx={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 100 }}
        />
      );
    }
    return null;
  },
  () => true
);

interface Props {}

const DefaultLayout = (props: Props) => {
  const dispatch: ThunkDispatch<AppState, null, Action> = useDispatch();

  const userInfo = useSelector((state: AppState) => state.auth.userInfo);
  const mappedRoutes = mappedRoutesPermission(
    userInfo?.permissions || [],
    PROJECT_LIST_ROUTES
  ) as any;

  const element = useRoutes(mappedRoutes);

  React.useEffect(() => {
    dispatch(getListType());
  }, [dispatch]);

  return (
    <>
      <Box display="flex" bgcolor={'background.default'}>
        <DefaultAside listRouter={mappedRoutes} />
        <Box
          className="relative flex-1 h-screen overflow-auto flex flex-col"
          sx={(theme) => ({
            [theme.breakpoints.down('mobile')]: {
              minWidth: '100vw',
            },
          })}
        >
          <DefaultHeader />
          <ProcessLoading />
          <Box
            sx={{
              p: 1,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DefaultBreadcrumb />

            <React.Suspense fallback={<MuiLoading />}>{element}</React.Suspense>
          </Box>
          <Box sx={{ bgcolor: 'background.paper', px: 1, py: 0.5 }}>
            <Typography variant="caption">
              <FormattedMessage id="footer" />
            </Typography>
          </Box>
        </Box>
        <Box id="aside-right" />
      </Box>
    </>
  );
};

export default DefaultLayout;
