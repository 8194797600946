import {
  Box,
  Paper,
  Portal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ProcessingFoodModel } from '../../processing-food/types';

interface Props {
  data: ProcessingFoodModel;
  step: number;
  setStep: (step: number) => void;
}

const ProcessingFoodInfoBox = (props: Props) => {
  const { step, setStep, data } = props;
  const container = document.getElementById('confirmProcessedFoodBreadcrumb');
  const containerTitle = document.getElementById('breadcrumbTitle');
  const { isMobile, listType } = useGeneralHook();
  return (
    <>
      {containerTitle && data && (
        <Portal container={containerTitle}>
          <FormattedMessage
            id="processedFoodConfirm.confirm"
            values={{ name: data?.name }}
          />
        </Portal>
      )}
      {container && data && (
        <Portal container={container}>
          <FormattedMessage
            id="processedFoodConfirm.confirm"
            values={{ name: data?.name }}
          />
        </Portal>
      )}
      <Paper className="p-2 tablet:p-4 ">
        <Stepper
          nonLinear
          activeStep={step}
          sx={{ maxWidth: 630, mx: 'auto' }}
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          {['processedFoodConfirm.step1', 'processedFoodConfirm.step2'].map(
            (label, index) => (
              <Step
                key={label}
                completed={(step || 0) > index}
                onClick={() => setStep(index)}
                className="cursor-pointer"
              >
                <StepLabel color="inherit">
                  <FormattedMessage id={label} />
                </StepLabel>
              </Step>
            )
          )}
        </Stepper>
        <Box className="columns-1 tablet:columns-2 md:columns-3 gap-4 mt-4">
          {[
            {
              label: <FormattedMessage id={'processingFood.name'} />,
              value: data?.name,
            },

            {
              label: <FormattedMessage id={'processingFood.operationCode'} />,
              value: data?.operationCode,
            },
            {
              label: (
                <FormattedMessage
                  id={'processingFood.commodityClassification'}
                />
              ),
              value: data?.commodityClassification.name,
            },
            {
              label: <FormattedMessage id={'processingFood.commodityGroup'} />,
              value: [
                data?.commodityGroup?.name,
                listType.NMH?.type.find(
                  (v) => v.id === data?.commodityGroup?.type
                )?.name,
              ]
                .filter(Boolean)
                .join(' - '),
            },
            {
              label: <FormattedMessage id={'processingFood.preliminaryType'} />,
              value: listType.TPCB?.preliminaryType?.find(
                (v) => v.id === data?.preliminaryType
              )?.name,
            },
            {
              label: <FormattedMessage id={'processingFood.processingUnit'} />,
              value: data?.processingUnit?.name,
            },
            {
              label: <FormattedMessage id={'processingFood.conversionRatio'} />,
              value: data?.conversionRatio,
            },
            {
              label: <FormattedMessage id={'status'} />,
              value: listType.TPCB?.operationStatus?.find(
                (v) => v.id === data?.operationStatus
              )?.name,
            },
            {
              label: <FormattedMessage id={'description'} />,
              value: data?.description,
            },
          ].map((item, index) => {
            return (
              <Box className="flex break-inside-avoid mb-1" key={index}>
                <Typography
                  variant="subtitle2"
                  component={'div'}
                  flexShrink={0}
                >
                  {item.label}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ wordBreak: 'break-all', display: 'flex' }}
                >
                  {item.value ? <>:&nbsp;{item.value}</> : item.value}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Paper>
    </>
  );
};
export default ProcessingFoodInfoBox;
