import Menu from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setOpenAsideBarMobile } from '../../redux/cacheReducer';
import ProfileBox from './ProfileBox';
import { HEADER_PORTAL_ID, HEADER_TOOLBAR_PORTAL_ID } from './constants';

const DefaultHeader = () => {
  const { dispatch, isMobile } = useGeneralHook();

  return (
    <>
      <Box
        className="flex items-center tablet:px-4 gap-2 tablet:gap-4 z-10 px-2"
        sx={{
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          minHeight: 54,
        }}
      >
        {isMobile ? (
          <>
            <Button
              variant="outlined"
              sx={{ p: 1, height: 40 }}
              onClick={() => {
                dispatch(setOpenAsideBarMobile());
              }}
            >
              <Menu />
            </Button>
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 tablet:gap-4 h-full"
              id={HEADER_PORTAL_ID}
            />
          </>
        ) : (
          <>
            <Box
              className="flex items-center flex-1 overflow-auto gap-2 tablet:gap-4 h-full"
              id={HEADER_PORTAL_ID}
            />
          </>
        )}
        {!isMobile && <ProfileBox />}
      </Box>
      {isMobile && (
        <Box id={HEADER_TOOLBAR_PORTAL_ID} bgcolor={'background.paper'} />
      )}
    </>
  );
};
export default DefaultHeader;
