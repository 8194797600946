import { Paper, Tab, Tabs } from '@mui/material';
import { Outlet, useLocation } from 'react-router';
import { ROUTES_PATH } from '../../layout/constants';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const PriceSettingPage = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Paper>
        <Tabs
          value={pathname.includes(ROUTES_PATH.unProcessingDish.index) ? 1 : 0}
          aria-label="nav tabs example"
          role="navigation"
        >
          <Tab
            LinkComponent={Link}
            label={<FormattedMessage id="setupPrice.processingFoodTab" />}
            {...{ to: { pathname: ROUTES_PATH.processingFood.index } }}
          />

          <Tab
            LinkComponent={Link}
            label={<FormattedMessage id="setupPrice.unProcessingDishTab" />}
            {...{ to: { pathname: ROUTES_PATH.unProcessingDish.index } }}
          />
        </Tabs>
      </Paper>
      <Outlet />
    </>
  );
};
export default PriceSettingPage;
