import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { OriginalDishModel } from './types';
import { ResponseDataDetail } from '../../../common/types';
import useOriginDishesDetail from './useOriginDishDetail';

const OriginDishDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useOriginDishesDetail(id);

  const onCreateUpdate = useMutation({
    mutationFn: async (values: OriginalDishModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<ResponseDataDetail<OriginalDishModel>>({
            url: API_PATHS.originDish.index,
            method: 'post',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
      } else {
        await dispatch(
          fetchThunk({
            url: API_PATHS.originDish.detail(values.id),
            method: 'put',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
      }
      if (key === 'default') {
        navigate(
          ['', ROUTES_PATH.list.index, ROUTES_PATH.originDish.index].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  if (isLoading) {
    return <MuiLoading />;
  }
  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SchemaForm<OriginalDishModel>
          formData={data}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          schema={{
            fields: ({ formProps: { intl }, valuesField }) => {
              return {
                name: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'originDish.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationCode: {
                  mode: 'raw',
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      module="MG"
                      type={valuesField.type}
                      label={intl.formatMessage({
                        id: 'originDish.operationCode',
                      })}
                    />
                  ),
                  propsWrapper: { tablet: 6 },
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                type: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'originDish.type',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  options: listType?.MG?.type,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                cookingType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'originDish.cookingType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  options: listType?.MG?.cookingType,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                processingUnit: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'originDish.processingUnit',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  options: listType.MG?.processingUnit,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                description: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                },
                status: {
                  mode: 'switch',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  defaultValue: true,
                },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.list.index,
                          ROUTES_PATH.originDish.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
          }}
        />
      </Paper>
    </>
  );
};
export default OriginDishDetailPage;
