import {
  ExpandMoreOutlined,
  RefreshRounded,
  TuneOutlined,
} from '@mui/icons-material';
import { Badge, Box, Button, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import SchemaForm from '../../../../common/SchemaForm';
import usePaginationHook from '../../../../common/hook/usePaginationHook';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import {
  ProcessingDishFilterModel,
  ProcessingDishModel,
} from '../../../processing-dish/types';
import FormControlAutoComplete from '../../../../common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import InfoBox from './InfoBox';
import InfoBoxTitle from './InfoBoxTitle';
import { OperationStatus } from '../../../../common/types';
interface Props {
  rowSelection?: ProcessingDishModel;
  setRowSelection: (value?: ProcessingDishModel) => void;
}
const FilterBox = (props: Props) => {
  const { setRowSelection } = props;
  const { listType, isMobile, intl, dispatch, API_PATHS, fetchThunk } =
    useGeneralHook();
  const hookPagination = usePaginationHook<ProcessingDishFilterModel>({
    defaultFilter: {
      operationStatus: OperationStatus.confirm,
    },
    disableLink: true,
    disablePagination: true,
  });
  const { setParams, clearParams, filters } = hookPagination;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Box className="flex items-center gap-2 tablet:gap-4 p-2 tablet:p-4">
        {!isMobile && (
          <Typography sx={{ flexShrink: 0 }}>
            {intl.formatMessage({
              id: 'search',
            })}
            :
          </Typography>
        )}
        <FormControlAutoComplete<ProcessingDishModel>
          onChange={(_, option) => {
            setRowSelection(option as any);
          }}
          placeholder={intl.formatMessage({
            id: 'processingDish.searchConfirm',
          })}
          loadOptions={{
            queryKey: (text) => [API_PATHS.processingDish.index, text, filters],
            queryFn: async ({ pageParam = 1, queryKey }) => {
              const json = await dispatch(
                fetchThunk({
                  url: queryKey[0],
                  params: {
                    pageIndex: pageParam,
                    filters: { name: queryKey[1], ...queryKey[2] },
                  },
                })
              );
              return json;
            },
          }}
          ListboxProps={{
            sx: { pt: 0 },
          }}
          slotProps={{ paper: { elevation: 8, sx: { mt: 1 } } }}
          renderOption={(props, option, state) => {
            return (
              <>
                {state.index === 0 && (
                  <Box
                    component={'li'}
                    sx={{
                      bgcolor: 'background.paper',
                      position: 'sticky',
                      top: 0,
                      zIndex: 1000,
                      borderBottom: 1,
                      borderColor: 'divider',
                    }}
                    style={{
                      padding: '8px 16px',
                    }}
                  >
                    <InfoBoxTitle />
                  </Box>
                )}
                <Box
                  component={'li'}
                  {...props}
                  style={{
                    padding: '8px 16px',
                  }}
                >
                  <InfoBox data={option} />
                </Box>
              </>
            );
          }}
          clearOnEscape
        />
        <Button
          variant="outlined"
          startIcon={
            <Badge
              badgeContent={Object.keys(filters).length - 1}
              variant="standard"
              color="primary"
            >
              <TuneOutlined />
            </Badge>
          }
          endIcon={!isMobile && <ExpandMoreOutlined />}
          sx={{ flexShrink: 0 }}
          onClick={handleClick}
        >
          {!isMobile &&
            intl.formatMessage({
              id: 'filter',
            })}
        </Button>

        <Button variant="outlined" sx={{ flexShrink: 0 }} onClick={clearParams}>
          <RefreshRounded />
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: { borderColor: 'primary.main', p: 2, mt: 2, width: 280 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SchemaForm<ProcessingDishFilterModel>
          formHookProps={{ defaultValues: filters }}
          globalProps={{
            noHelperText: true,
          }}
          onChange={(val) => setParams(val)}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                objectType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'processingDish.objectType',
                  }),
                  options: listType.MCB?.objectType,
                  rawOptions: true,
                  disableClearBtn: true,
                },
              };
            },
          }}
        />
      </Popover>
    </>
  );
};
export default FilterBox;
