import { FormControl } from '@mui/base';
import { InputLabel, TextField } from '@mui/material';
import { Box, alpha } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IControllerRenderProps } from '../../SchemaForm/utils';
import { KEYS_QUERY, some } from '../../constants';
import useGeneralHook from '../../hook/useGeneralHook';
import { GenerateCodeRequest, GenerateCodeResponse, ModuleCode } from './types';
import { useDebounce } from 'usehooks-ts';

interface Props<T extends some = any> extends IControllerRenderProps<T> {
  initialValue?: string;
  module: ModuleCode;
  name?: string;
  type?: string;
  businessArea?: string;
  sourceType?: string;
  objectType?: string;
  commodityGroup?: string;
  label?: React.ReactNode;
  readOnly?: boolean;
}
const GenerateCodeBox = <T extends some = any>(props: Props<T>) => {
  const {
    label,
    module,
    type,
    name,
    businessArea,
    sourceType,
    objectType,
    commodityGroup,
    initialValue,
    readOnly,
    field: { onChange, ref },
    fieldState: { invalid },
  } = props;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();
  const parts = initialValue?.split('-') ?? ['', '', ''];
  const [touched, setTouched] = useState(!!initialValue);
  const [isCreate, setFlag] = useState(!initialValue);

  const [editedParts, setEditedParts] = useState<string[]>(parts);
  const editedPartsRef = useRef<string[]>(parts);

  useEffect(() => {
    editedPartsRef.current = editedParts;
  }, [editedParts]);

  // Function to handle changes in each part
  const handlePartChange = useCallback((index: number, value: string) => {
    setEditedParts((old) => {
      const newParts = [...old];
      newParts[index] = value;
      return newParts;
    });
  }, []);

  // Function to join the edited parts into a single string
  const joinParts = useMemo(() => {
    return editedParts.join('-');
  }, [editedParts]);

  const codeTmp = useDebounce(editedParts[1], 1000);

  const { refetch } = useQuery<GenerateCodeResponse>({
    queryKey: [{ module }, KEYS_QUERY.genCode],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<GenerateCodeResponse, GenerateCodeRequest>({
          url: API_PATHS.generateCode,
          method: 'post',
          data: {
            name: codeTmp,
            module,
            type,
            businessArea,
            sourceType,
            commodityGroup,
            objectType,
          },
        })
      );
      const genCode = json.data.data.operationCode.split('-');
      if (touched && editedParts[2]) {
        setEditedParts((old) => [genCode[0], old[1] || genCode[1], old[2]]);
      } else {
        setEditedParts(genCode);
        setTouched(false);
      }
      return json.data;
    },
    enabled: !initialValue,
  });

  useEffect(() => {
    onChange(joinParts);
  }, [joinParts, onChange]);

  useEffect(() => {
    if (isCreate ? !touched || !editedPartsRef.current[2] : false) {
      refetch();
    } else {
      setFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, type, businessArea, sourceType, commodityGroup, objectType]);

  const array = Array(module === 'TCCL' ? 2 : 3).fill(0);

  return (
    <>
      <FormControl error={invalid}>
        <InputLabel
          required={true}
          style={{
            height: 'auto',
            display: 'unset',
          }}
          error={invalid}
        >
          {label}
        </InputLabel>
        <Box
          sx={{
            display: 'flex',
            height: 40,
            borderColor: (theme) => {
              return alpha(theme.palette.text.primary, 0.23);
            },
            overflow: 'hidden',
          }}
        >
          {array.map((_, index) => {
            return (
              <Fragment key={index}>
                <TextField
                  inputRef={!editedParts[index] ? ref : undefined}
                  value={editedParts[index]}
                  onChange={(e) => {
                    const val = e.target.value.toLocaleUpperCase();
                    handlePartChange(index, val);
                    if (index === 2) {
                      setTouched(true);
                    }
                  }}
                  error={invalid}
                  InputProps={{
                    readOnly: index === 0 || readOnly,
                    style:
                      index === 0
                        ? {
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                          }
                        : index === array.length - 1
                        ? {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 8,
                            borderTopRightRadius: 8,
                          }
                        : { borderRadius: 0 },
                    sx: {
                      height: '100%',
                      flex: 1,
                      overflow: 'hidden',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center',
                    },
                  }}
                />
                {index !== array.length - 1 && (
                  <Box
                    sx={{
                      height: '100%',
                      borderTop: '1px solid',
                      borderBottom: '1px solid',
                      borderColor: (theme) => {
                        return invalid
                          ? theme.palette.error.main
                          : alpha(theme.palette.text.primary, 0.23);
                      },
                      px: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {'-'}
                  </Box>
                )}
              </Fragment>
            );
          })}
        </Box>
      </FormControl>
    </>
  );
};
export default GenerateCodeBox;
