import { Box, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import { STATUS_OPTIONS } from '../../../common/constants';
import clsx from 'clsx';
import { ProcessingDishModel } from '../../processing-dish/types';
import useGetQuotaProcessingDishDetail from '../../processing-dish/quota/useGetQuotaProcessingDishDetail';
import { useAppSelector } from '../../../redux/store';
import { ProcessingFoodModel } from '../../processing-food/types';
import { mappedProcessingDishQuotaData } from '../../processing-dish/quota/utils';

interface Props {
  processingDish: ProcessingDishModel;
  mode?: 'info' | 'default';
}
const ProcessingDishInfoBox = (props: Props) => {
  const { processingDish, mode = 'default' } = props;
  const { data } = useGetQuotaProcessingDishDetail(processingDish?.id);
  const listType = useAppSelector((state) => state.common.listType);

  const content_2 = (
    <Box
      className={clsx(
        mode === 'info' ? 'flex-1' : 'mt-4',
        'flex flex-col gap-1 italic'
      )}
    >
      {[
        {
          label: <FormattedMessage id={'originDish.name'} />,
          value: processingDish?.originalDish.name,
        },
        {
          label: <FormattedMessage id={'originDish.type'} />,
          value: processingDish?.originalDish?.operationCode,
        },
        {
          label: <FormattedMessage id={'originDish.cookingType'} />,
          value: listType.MG?.cookingType.find(
            (v) => v.id === processingDish?.originalDish?.cookingType
          )?.name,
        },
        {
          label: <FormattedMessage id={'originDish.processingUnit'} />,
          value: listType.MG?.processingUnit.find(
            (v) => v.id === processingDish?.originalDish?.processingUnit
          )?.name,
        },
      ].map((item, index) => {
        return (
          <Typography
            key={index}
            variant={mode === 'info' ? 'inherit' : 'body2'}
            component={'div'}
            flexShrink={0}
            color={'inherit'}
          >
            <Typography variant="inherit" fontWeight={700} component={'span'}>
              {item.label}
            </Typography>
            : &nbsp;{item.value}
          </Typography>
        );
      })}
    </Box>
  );

  const content_1 = (
    <Box
      className={clsx(
        mode === 'info'
          ? 'flex flex-col flex-1 tablet:flex-row tablet:flex-wrap gap-1'
          : 'flex flex-col gap-1'
      )}
    >
      {[
        {
          label: <FormattedMessage id={'processingDish.name'} />,
          value: processingDish?.name,
        },
        {
          label: <FormattedMessage id={'processingDish.operationCode'} />,
          value: processingDish?.operationCode,
        },
        {
          label: <FormattedMessage id={'processingDish.mainFoodQuantity'} />,
          value: processingDish?.mainFoodQuantity,
        },
        {
          label: <FormattedMessage id={'processingDish.displayName'} />,
          value: processingDish?.displayName,
          hidden: mode !== 'info',
        },

        {
          label: <FormattedMessage id={'processingDish.objectType'} />,
          value: processingDish?.objectType,
        },
        {
          label: <FormattedMessage id={'processingDish.nutritionRequired'} />,
          value: (
            <FormattedMessage
              id={processingDish?.nutritionRequired ? 'yes' : 'no'}
            />
          ),
        },

        {
          label: <FormattedMessage id={'status'} />,
          value: processingDish?.operationStatus && (
            <FormattedMessage id={processingDish?.operationStatus} />
          ),
        },
        {
          label: <FormattedMessage id={'statusAdmin'} />,
          value: (
            <FormattedMessage
              id={
                STATUS_OPTIONS.find((v) => v.id === processingDish?.status)
                  ?.name || 'deactive'
              }
            />
          ),
        },
        { node: content_2, hidden: mode !== 'info' },
        {
          label: <FormattedMessage id={'description'} />,
          value: processingDish?.description,
        },
      ]
        .filter((v) => !v.hidden)
        .map((item, index) => {
          if (item.node) {
            return (
              <Typography
                key={index}
                variant="inherit"
                component={'div'}
                flexShrink={0}
                color={'inherit'}
                className={clsx(mode === 'info' ? 'tablet:w-[45%]' : '')}
              >
                {item.node}
              </Typography>
            );
          }
          return (
            <Typography
              key={index}
              variant="inherit"
              component={'div'}
              flexShrink={0}
              color={'inherit'}
              className={clsx(mode === 'info' ? 'tablet:w-[45%]' : '')}
            >
              <Typography variant="inherit" fontWeight={700} component={'span'}>
                {item.label}
              </Typography>
              : &nbsp;{item.value}
            </Typography>
          );
        })}
    </Box>
  );

  const mappedData = mappedProcessingDishQuotaData(data?.items);

  const content_3 = (
    <MuiTable<ProcessingFoodModel>
      boxProps={{ sx: { p: 0, mt: 2, width: '100%' } }}
      enableSelectAll={false}
      data={mappedData}
      enableTableFooter={mappedData.length > 0}
      columns={[
        {
          accessorKey: 'name',
          header: 'name',
          Header: () => <FormattedMessage id="quota.name" />,
          Footer: () => {
            return (
              <Typography
                variant="subtitle2"
                color="secondary"
                sx={{ textAlign: 'center', textTransform: 'uppercase' }}
              >
                <FormattedMessage id="total" />
              </Typography>
            );
          },
          muiTableFooterCellProps: {
            colSpan: 3,
          },
        },
        {
          accessorKey: 'usageRatio',
          header: 'usageRatio',
          Header: () => <FormattedMessage id="quota.usageRatio" />,
          size: 80,
          muiTableFooterCellProps: {
            sx: { display: 'none' },
          },
          Cell: ({ row }) => {
            return (
              <FormattedNumber
                value={
                  row.original.usageRatio ||
                  row.original.standardFood?.usageRatio
                }
              />
            );
          },
        },
        {
          accessorKey: 'quantity',
          header: 'quantity',
          Header: () => <FormattedMessage id="quota.quantity_1" />,
          size: 100,
          muiTableFooterCellProps: {
            sx: { display: 'none' },
          },
          Cell: ({ row }) => {
            return (
              <FormattedNumber
                value={
                  ((row.original.quantity || 0) *
                    (row.original?.conversionRatio || 0)) /
                  100
                }
              />
            );
          },
        },
        {
          accessorKey: 'standardFood.protein',
          header: 'standardFood.protein',
          Header: () => <FormattedMessage id="quota.protein" />,
          size: 50,
          Cell: ({ row }) => {
            return (
              <FormattedNumber value={row.original.standardFood?.protein} />
            );
          },
          Footer: () => {
            return (
              <Typography
                variant="inherit"
                color="secondary"
                sx={{ textAlign: 'center' }}
              >
                <FormattedNumber
                  value={
                    mappedData?.reduce(
                      (v, c) => v + Number(c.standardFood?.protein) || 0,
                      0
                    ) || 0
                  }
                />
              </Typography>
            );
          },
        },
        {
          accessorKey: 'standardFood.lipid',
          header: 'standardFood.lipid',
          Header: () => <FormattedMessage id="quota.lipid" />,
          size: 50,
          Cell: ({ row }) => {
            return <FormattedNumber value={row.original.standardFood?.lipid} />;
          },
          Footer: () => {
            return (
              <Typography
                variant="inherit"
                color="secondary"
                sx={{ textAlign: 'center' }}
              >
                <FormattedNumber
                  value={mappedData?.reduce(
                    (v, c) => v + Number(c.standardFood?.lipid) || 0,
                    0
                  )}
                />
              </Typography>
            );
          },
        },
        {
          accessorKey: 'standardFood.glucid',
          header: 'standardFood.glucid',
          Header: () => <FormattedMessage id="quota.glucid" />,
          size: 50,
          Cell: ({ row }) => {
            return (
              <FormattedNumber value={row.original.standardFood?.glucid} />
            );
          },
          Footer: () => {
            return (
              <Typography
                variant="inherit"
                color="secondary"
                sx={{ textAlign: 'center' }}
              >
                <FormattedNumber
                  value={
                    mappedData?.reduce(
                      (v, c) => v + Number(c.standardFood?.glucid) || 0,
                      0
                    ) || 0
                  }
                />
              </Typography>
            );
          },
        },

        {
          accessorKey: 'standardFood.calorie',
          header: 'standardFood.calorie',
          Header: () => <FormattedMessage id="quota.calorie" />,
          size: 50,
          Cell: ({ row }) => {
            return (
              <FormattedNumber value={row.original.standardFood?.calorie} />
            );
          },
          Footer: () => {
            return (
              <Typography
                variant="inherit"
                color="secondary"
                sx={{ textAlign: 'center' }}
              >
                <FormattedNumber
                  value={
                    mappedData?.reduce(
                      (v, c) => v + Number(c.standardFood?.calorie) || 0,
                      0
                    ) || 0
                  }
                />
              </Typography>
            );
          },
        },
      ]}
    />
  );

  if (mode === 'info') {
    return (
      <Box className={'w-full flex overflow-auto flex-1'}>{content_1}</Box>
    );
  }

  return (
    <Box className={'w-full flex flex-col overflow-auto flex-1'}>
      {content_1}
      {content_2}
      {content_3}
    </Box>
  );
};
export default ProcessingDishInfoBox;
