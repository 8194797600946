import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import { Paper, Step, StepLabel, Stepper } from '@mui/material';
import useGetProcessingDishDetail from '../../processing-dish/detail/useGetProcessingDishDetail';
import { checkReadOnlyProcessingDishConfirm } from '../utils';

const ProcessingDishConfirmPage = () => {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useGeneralHook();
  const [step, setStep] = useState(0);

  const { data, isLoading } = useGetProcessingDishDetail(id);

  if (isLoading) {
    return <MuiLoading />;
  }

  if (!data) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  if (checkReadOnlyProcessingDishConfirm(data)) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <Paper>
      <Stepper
        className="p-2 tablet:p-4"
        nonLinear
        activeStep={step}
        sx={{ maxWidth: 630, mx: 'auto' }}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        {['processingDishConfirm.step1', 'processingDishConfirm.step2'].map(
          (label, index) => (
            <Step
              key={label}
              completed={(step || 0) > index}
              onClick={() => setStep(index)}
              className="cursor-pointer"
            >
              <StepLabel color="inherit">
                <FormattedMessage id={label} />
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
      {step === 0 ? (
        <Step1 setStep={setStep} processingDish={data} />
      ) : (
        <Step2 setStep={setStep} processingDish={data} />
      )}
    </Paper>
  );
};
export default ProcessingDishConfirmPage;
