import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface Props extends BoxProps {
  loadingColor?: 'inherit' | 'primary' | 'secondary' | 'default';
}
interface State {}
class MuiLoading extends React.PureComponent<Props, State> {
  render() {
    const { loadingColor, sx, ...rest } = this.props;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          minHeight: 240,
          flex: 1,
          ...sx,
        }}
        {...rest}
      >
        <CircularProgress size={48} color={'primary'} />
      </Box>
    );
  }
}
export default MuiLoading;
