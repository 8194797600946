import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import MuiDialog from '../../../common/components/MuiDialog';
import { WarningAmberRounded } from '@mui/icons-material';
import { ProcessingFoodModel } from '../types';

interface Props {
  data?: ProcessingFoodModel;
  open: boolean;
  onClose: (val: boolean) => void;
  onSubmit?: (data: ProcessingFoodModel) => Promise<void>;
}
const ConfirmUpdateStatusAdmin = (props: Props) => {
  const { open, onClose, data, onSubmit } = props;
  const [loading, setLoading] = useState(false);

  const renderInfo = () => {
    return (
      <Box className="flex flex-col gap-2">
        {[
          {
            label: 'type',
            value: <FormattedMessage id="processingFood.title" />,
          },
          {
            label: 'subjectChange',
            value: data?.name,
          },
          {
            label: 'currentStatus',
            value: (
              <Typography
                variant="inherit"
                color={!data?.status ? 'success.main' : 'error.main'}
              >
                {!data?.status ? 'ON' : 'OFF'}
              </Typography>
            ),
          },
          {
            label: 'updateStatus',
            value: (
              <Typography
                variant="inherit"
                color={data?.status ? 'success.main' : 'error.main'}
              >
                {data?.status ? 'ON' : 'OFF'}
              </Typography>
            ),
          },
          {
            label: 'processingFood.note_update_status_admin',
          },
        ]?.map((item, index) => {
          if (item.value)
            return (
              <Typography variant="body2" key={index} className="flex gap-1">
                <Box sx={{ minWidth: 160 }}>
                  <FormattedMessage id={item.label} />
                </Box>
                :&emsp;{item.value}
              </Typography>
            );
          return (
            <Typography variant="body2" key={index} mt={2}>
              <FormattedMessage id={item.label} />
            </Typography>
          );
        })}
      </Box>
    );
  };

  const onSubmitFunc = async () => {
    try {
      setLoading(true);
      data && (await onSubmit?.(data));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MuiDialog open={open} onClose={onClose} fullWidth maxWidth="tablet">
        <WarningAmberRounded
          sx={{ mx: 'auto', mt: 2, fontSize: 120, color: 'warning.main' }}
        />
        <Typography variant="h4" color="primary" className="text-center py-4">
          <FormattedMessage
            id={
              data?.status
                ? 'processingFood.deActive_SAD'
                : 'processingFood.active_SAD'
            }
          />
        </Typography>
        <Box px={2}>{renderInfo()}</Box>

        <Box p={2} className="flex justify-center gap-4">
          <Button
            variant="outlined"
            sx={{ minWidth: 100 }}
            onClick={() => onClose(false)}
          >
            <FormattedMessage id="cancel" />
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: 100 }}
            loading={loading}
            onClick={onSubmitFunc}
          >
            <FormattedMessage id="confirm" />
          </LoadingButton>
        </Box>
      </MuiDialog>
    </>
  );
};
export default ConfirmUpdateStatusAdmin;
