import { Navigate, Outlet, useParams } from 'react-router';
import { ROUTES_PATH } from '../../../layout/constants';
import useUnitsDetail from './useUnitsDetail';
import { Portal } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const UnitsDetailOutlet = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useUnitsDetail(id);

  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  const container = document.getElementById('unitsBreadcrumb');
  return (
    <>
      {id && container && (
        <Portal container={container}>
          {data?.name || <FormattedMessage id="units.detail" />}
        </Portal>
      )}
      <Outlet />
    </>
  );
};
export default UnitsDetailOutlet;
