export const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const getBaseUrl = development
  ? '/api/'
  : `${process.env.REACT_APP_HOST_API}`;

export const API_PATHS = {
  listType: '/api/v1/list-types',
  generateCode: '/api/v1/operation-code',
  auth: {
    userInfo: 'api/v1/user',
    login: '/api/v1/login',
    XSRFToken: '/sanctum/csrf-cookie',
  },
  qualityStandard: {
    index: '/api/v1/quality-standards',
    detail: (id) => `/api/v1/quality-standards/${id}`,
  },
  groupFood: {
    index: '/api/v1/standard-food-groups',
    detail: (id) => `/api/v1/standard-food-groups/${id}`,
  },

  standardGroupFood: {
    index: (categoryId) =>
      `/api/v1/standard-food-groups/${categoryId}/standard-foods`,
    detail: (categoryId, id) =>
      `/api/v1/standard-food-groups/${categoryId}/standard-foods/${id}`,
  },
  standardFood: {
    index: `/api/v1/standard-foods`,
  },
  commodityClassifications: {
    index: '/api/v1/commodity-classifications',
    detail: (id) => `/api/v1/commodity-classifications/${id}`,
  },
  commodityGroups: {
    index: (categoryId) =>
      `/api/v1/commodity-classifications/${categoryId}/commodity-groups`,
    detail: (categoryId, id) =>
      `/api/v1/commodity-classifications/${categoryId}/commodity-groups/${id}`,
  },
  channelDistributions: {
    index: '/api/v1/channel-distributions',
    detail: (id) => `/api/v1/channel-distributions/${id}`,
  },
  orderClassifications: {
    index: (categoryId) =>
      `/api/v1/channel-distributions/${categoryId}/order-classifications`,
    detail: (categoryId, id) =>
      `/api/v1/channel-distributions/${categoryId}/order-classifications/${id}`,
  },
  originDish: {
    index: '/api/v1/original-dishes',
    detail: (id) => `/api/v1/original-dishes/${id}`,
  },
  units: {
    index: '/api/v1/processing-units',
    detail: (id) => `/api/v1/processing-units/${id}`,
  },
  order: {
    index: '/api/v1/order',
    detail: (id) => `/api/v1/order/${id}`,
  },
  processingFood: {
    index: '/api/v1/processing-foods',
    detail: (id) => `/api/v1/processing-foods/${id}`,
    reason: (id) => `/api/v1/processing-foods/${id}/reason`,
  },
  processingDish: {
    index: '/api/v1/processing-dishs',
    detail: (id) => `/api/v1/processing-dishs/${id}`,
    quota: (id) => `/api/v1/processing-dishs/${id}/quotas`,
    reason: (id) => `/api/v1/processing-dishs/${id}/reason`,
  },
  unProcessingDish: {
    index: '/api/v1/packed-dishs',
    detail: (id) => `/api/v1/packed-dishs/${id}`,
    reason: (id) => `/api/v1/packed-dishs/${id}/reason`,
  },
};
