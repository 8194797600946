import { Typography, useTheme } from '@mui/material';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { API_PATHS } from '../../api/API_App';
import { setLoading } from '../../redux/commonReducer';
import { AppState, useAppDispatch } from '../../redux/store';
import { useConfirmDialog } from '../components/ConfirmDialogProvider';
import { useMessageDialog } from '../components/MessageDialogProvider';
import { KEYS_QUERY, some } from '../constants';
import fetchThunk from '../fetchThunk';
import useHookQuery from './useHookQuery';

interface OptionsSnackbarObject extends OptionsObject {
  message?: React.ReactNode;
  requestId?: number;
}
interface Props {
  initialState?: some;
}
const useGeneralHook = (props?: Props) => {
  const { initialState = {} } = props || {};
  const dispatch = useAppDispatch();
  const listType = useSelector((state: AppState) => state.common.listType);
  const theme = useTheme();
  const { isMobile, isTablet } = useHookQuery();

  const confirmDialog = useConfirmDialog();
  const messageDialog = useMessageDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const location = useLocation();
  // const navigate = useNavigate();
  const intl = useIntl();
  const [state, setState] = useState<some>(initialState);

  const setLoadingFnc = useCallback(
    (value: boolean) => {
      dispatch(setLoading(value));
    },
    [dispatch]
  );

  const wrapperEnqueueSnackbar = (params: OptionsSnackbarObject) => {
    const { message, ...rest } = params;
    return enqueueSnackbar(
      <Typography variant="subtitle2" component={'div'} color="inherit">
        {message || 'Error!'}
      </Typography>,
      rest
    );
  };

  const copyToClipboard = (text, noNoti?: boolean) => {
    if (text) {
      navigator.clipboard.writeText(text);
      if (!noNoti)
        wrapperEnqueueSnackbar({
          message: intl.formatMessage({ id: 'copied' }),
          variant: 'success',
        });
    } else {
      if (!noNoti)
        wrapperEnqueueSnackbar({
          message: intl.formatMessage({ id: 'copied_fail' }),
          variant: 'error',
        });
    }
  };

  return {
    intl,
    dispatch,
    fetchThunk,
    API_PATHS,
    enqueueSnackbar: wrapperEnqueueSnackbar,
    closeSnackbar,
    listType,
    theme,
    confirmDialog,
    messageDialog,
    // location,
    // navigate,
    setLoading: setLoadingFnc,
    state,
    setState: (value) => setState((old) => (value ? { ...old, ...value } : {})),
    isMobile,
    isTablet,
    copyToClipboard,
    KEYS_QUERY: KEYS_QUERY,
  };
};

export default useGeneralHook;
