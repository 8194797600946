import {
  AddRounded,
  DeleteRounded,
  EditRounded,
  PauseCircle,
  PlayCircle,
  Visibility,
} from '@mui/icons-material';
import { Button, Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import {
  OperationStatus,
  ResponseDataDetail,
  ResponseDataList,
} from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import ProcessingFoodFilterBox from './ProcessingFoodFilterBox';
import { ProcessingFoodFilterModel, ProcessingFoodModel } from '../types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../common/components/ReasonDialog';
import { useState } from 'react';
import RestartProgressDialog from '../../../common/components/RestartProgressDialog';
import { useNavigate } from 'react-router';
import useMutationReason from '../../../common/hook/useMutationReason';
import useGetReason from '../../../common/hook/useGetReason';
import { ModuleEnum } from '../../../common/components/GenerateCodeBox/types';
import { checkReadOnlyProcessingFoodConfirm } from '../../processing-food-confirm/utils';

const ProcessingFoodListPage = () => {
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar, listType } =
    useGeneralHook();
  const navigate = useNavigate();
  const { mutationReason } = useMutationReason();
  const hookPagination = usePaginationHook<ProcessingFoodFilterModel>();

  const [openRestartProgress, setOpenRestartProgress] = useState<
    ProcessingFoodModel | undefined
  >(undefined);
  const reasons = useGetReason(
    openRestartProgress
      ? API_PATHS.processingFood.reason(openRestartProgress?.id)
      : undefined
  );
  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<ProcessingFoodModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.index,
          params: {
            ...params,
            filters: {
              ...params.filters,
              commodityGroup: params.filters.commodityGroup?.id,
              commodityClassification:
                params.filters.commodityClassification?.id,
            },
          },
        })
      );
      return json.data;
    },
  });

  const onRestartProcess = useMutation({
    mutationKey: ['onRestartProcess'],
    mutationFn: async () => {
      if (!openRestartProgress) {
        return;
      }
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.detail(openRestartProgress?.id),
          method: 'put',
          data: {
            ...openRestartProgress,
            operationStatus: OperationStatus.create,
          },
        })
      );
      setOpenRestartProgress(undefined);
      navigate({ pathname: json.data.data?.id + '' });
    },
  });

  const onStopProcess = (data: ProcessingFoodModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingFood.detail(data?.id),
              method: 'put',
              data: {
                ...data,
                operationStatus: OperationStatus.stop,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingFood.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.TPCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'paused',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingFood.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id="confirmed" />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id="stop" />,
        },
      ],
      reasons: listType.TPCB?.reasons?.stop,
    });
  };

  const onDelete = (data: ProcessingFoodModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingFood.detail(data.id),
              method: 'delete',
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingFood.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.TPCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'cancel',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingFood.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: (
            <>
              <FormattedMessage id={data.operationStatus} />
              &nbsp; (<FormattedMessage id={'active'} />)
            </>
          ),
        },
        {
          label: 'updateStatus',
          value: (
            <>
              <FormattedMessage id={OperationStatus.cancel} />
              &nbsp; (<FormattedMessage id={'deactive'} />)
            </>
          ),
        },
      ],
      reasons: listType.TPCB?.reasons?.cancel,
    });
  };

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <ProcessingFoodFilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.qualityStandard.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<ProcessingFoodModel>
        data={data?.data ?? []}
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.meta.total,
          page: data?.meta.currentPage,
          rowsPerPage: data?.meta.perPage,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => [
          [OperationStatus.deny].includes(row.original.operationStatus) ? (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              onClick={() => setOpenRestartProgress(row.original)}
            >
              <EditRounded />
            </IconButtonTooltip>
          ) : checkReadOnlyProcessingFoodConfirm(row.original) ? (
            <MyLink to={row.original.id.toString()}>
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="view" /> }}
              >
                <Visibility />
              </IconButtonTooltip>
            </MyLink>
          ) : (
            <MyLink to={row.original.id.toString()}>
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              >
                <EditRounded />
              </IconButtonTooltip>
            </MyLink>
          ),
          <IconButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="cancel" /> }}
            onClick={() => onDelete(row.original)}
            disabled={[
              OperationStatus.cancel,
              OperationStatus.stop,
              OperationStatus.confirm,
            ].includes(row.original.operationStatus)}
          >
            <DeleteRounded />
          </IconButtonTooltip>,
          row.original.operationStatus === OperationStatus.confirm ? (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="paused" /> }}
              onClick={() => onStopProcess(row.original)}
            >
              <PlayCircle />
            </IconButtonTooltip>
          ) : (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="play" /> }}
              disabled
            >
              <PauseCircle />
            </IconButtonTooltip>
          ),
        ]}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.code" />,
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.name" />,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <MyLink
                  to={row.original.id.toString()}
                  underline="always"
                  color="inherit"
                  className="break-all"
                >
                  {renderedCellValue}
                </MyLink>
              );
            },
          },
          {
            accessorKey: 'processingUnit.name',
            header: 'unit',
            Header: () => (
              <FormattedMessage id="processingFood.processingUnit" />
            ),
          },
          {
            accessorKey: 'commodityClassification.name',
            header: 'commodityClassification.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityClassification" />
            ),
          },
          {
            accessorKey: 'commodityGroup.name',
            header: 'commodityGroup.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityGroup" />
            ),
          },
          {
            accessorKey: 'preliminaryType',
            header: 'preliminaryType',
            Header: () => (
              <FormattedMessage id="processingFood.preliminaryType" />
            ),
            Cell: ({ renderedCellValue }) => {
              const status = listType.TPCB?.preliminaryType?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'standardFood.name',
            header: 'standardFood.name ',
            Header: () => <FormattedMessage id="processingFood.standardFood" />,
          },
          {
            accessorKey: 'operationStatus',
            header: 'operationStatus',
            Header: () => <FormattedMessage id="status" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.TPCB?.operationStatus?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'status',
            header: 'status',
            Header: () => <FormattedMessage id="statusAdmin" />,
            Cell: ({ renderedCellValue }) => {
              const status = STATUS_OPTIONS.find(
                (v) => v.id === renderedCellValue
              );

              return (
                status && (
                  <Chip
                    label={<FormattedMessage id={status.name} />}
                    variant="outlined"
                    color={status.color as any}
                    size="small"
                  />
                )
              );
            },
          },
        ]}
      />
      <ReasonDialog {...model} />
      <RestartProgressDialog
        open={!!openRestartProgress}
        onClose={() => setOpenRestartProgress(undefined)}
        onSubmit={onRestartProcess.mutateAsync}
        title="re_initialize"
        infos={[
          {
            label: 'type',
            value: <FormattedMessage id="processingFood.title" />,
          },
          {
            label: 'subjectChange',
            value: (
              <Box>
                {openRestartProgress?.name}
                <br />
                {openRestartProgress?.operationCode}
              </Box>
            ),
          },
          {
            label: 'currentStatus',
            value: openRestartProgress?.operationStatus && (
              <FormattedMessage id={openRestartProgress?.operationStatus} />
            ),
          },
          {
            label: 'updateStatus',
            value: <FormattedMessage id={OperationStatus.create} />,
          },
          {
            label: 'denyReason',
            value: reasons?.reason
              ? reasons?.reason
              : listType.TPCB?.reasons.deny?.find((v) => v.id === reasons?.key)
                  ?.name,
          },
        ]}
      />
    </Paper>
  );
};
export default ProcessingFoodListPage;
