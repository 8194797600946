import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { OrderDetailModel } from './types';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import dayjs from 'dayjs';
import { useMessageDialog } from '../../../common/components/MessageDialogProvider';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import { some } from '../../../common/constants';
import { STATUS_ORDER } from '../list/types';

interface Props {
  formData?: some;
}
const OrderInfoForm = (props: Props) => {
  const { formData } = props;
  const { openDialog } = useMessageDialog();
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar } = useGeneralHook();
  const { filters } = usePaginationHook<{
    date?: string;
    categoryId?: number;
  }>({
    defaultFilter: {
      date: dayjs().toISOString(),
    },
  });
  const { id } = useParams<{ id: string }>();
  const { key } = useLocation();
  const navigate = useNavigate();

  const onCreateUpdate = useMutation({
    mutationFn: async (values: OrderDetailModel) => {
      openDialog({
        title: 'Thực đơn được lưu thành công',
        onClose: () => {
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.list.index,
                ROUTES_PATH.channelDistributions.index,
                id,
                ROUTES_PATH.orderClassifications.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        },
      });
      // if (!values.id) {
      //   await dispatch(
      //     fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
      //       url: API_PATHS.orderClassifications.index(id),
      //       method: 'post',
      //       data: values,
      //     })
      //   );
      //   enqueueSnackbar({
      //     message: <FormattedMessage id={'createSuccess'} />,
      //     variant: 'success',
      //   });
      // } else {
      //   await dispatch(
      //     fetchThunk<ResponseDataDetail<OrderDetailModel>, OrderDetailModel>({
      //       url: API_PATHS.orderClassifications.detail(id, values.id),
      //       method: 'put',
      //       data: values,
      //     })
      //   );
      //   enqueueSnackbar({
      //     message: <FormattedMessage id={'updateSuccess'} />,
      //     variant: 'success',
      //   });
      // }
      // if (key === 'default') {
      //   navigate(
      //     [
      //       '',
      //       ROUTES_PATH.list.index,
      //       ROUTES_PATH.channelDistributions.index,
      //       id,
      //       ROUTES_PATH.orderClassifications.index,
      //     ].join('/')
      //   );
      // } else {
      //   navigate(-1);
      // }
    },
  });

  return (
    <SchemaForm
      formHookProps={{
        values: formData,
      }}
      onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
      schema={{
        fields: ({ formProps: { intl, listType }, valuesField }) => {
          return {
            customerName: {
              mode: 'text-field',
              label: 'Tên khách hàng',
              propsWrapper: {
                tablet: 6,
              },
              rules: { required: true },
            },
            orderName: {
              mode: 'text-field',
              label: 'Tên thực đơn',
              propsWrapper: {
                tablet: 6,
              },
              rules: { required: true },
            },
            customerCodeName: {
              mode: 'raw',
              render: (params) => (
                <GenerateCodeBox
                  {...params}
                  initialValue={formData?.customerCodeName}
                  module="DVT"
                  label={'Mã khách hàng'}
                />
              ),
              propsWrapper: { tablet: 6 },
              rules: {
                validate: (val: string) => {
                  return val?.split('-').every((v) => !!v.trim())
                    ? true
                    : 'required';
                },
              },
            },
            codeSetOrder: {
              mode: 'raw',
              render: (params) => (
                <GenerateCodeBox
                  {...params}
                  initialValue={formData?.codeSetOrder}
                  module="MG"
                  label={'Mã bộ thực đơn'}
                />
              ),
              propsWrapper: { tablet: 6 },
              rules: {
                validate: (val: string) => {
                  return val?.split('-').every((v) => !!v.trim())
                    ? true
                    : 'required';
                },
              },
            },
            createdAt: {
              mode: 'datePicker',
              label: 'Ngày tạo bộ thực đơn',
              propsWrapper: { tablet: 4 },
              rules: { required: true },
            },
            serveDate: {
              mode: 'datePicker',
              label: 'Ngày phục vụ thực đơn',
              propsWrapper: { tablet: 4 },
              rules: { required: true },
            },
            approveAt: {
              mode: 'datePicker',
              label: 'Ngày duyệt bộ thực đơn',
              propsWrapper: { tablet: 4 },
              rules: { required: true },
            },
            createdBy: {
              mode: 'text-field',
              label: 'Người tạo bộ thực đơn',
              propsWrapper: { tablet: 4 },
              rules: { required: true },
            },
            status: {
              mode: 'select',
              label: 'Trạng thái bộ thực đơn',
              options: STATUS_ORDER,
              rawOptions: true,
              propsWrapper: { tablet: 4 },
              rules: { required: true },
            },
            blank: {
              mode: 'node',
              unregister: true,
              propsWrapper: { tablet: 6 },
            },
            back: {
              mode: 'button',
              variant: 'outlined',
              startIcon: <UndoRounded />,
              children: intl.formatMessage({
                id: 'back',
              }),
              onClick: () => {
                if (key === 'default') {
                  navigate(
                    [
                      '',
                      ROUTES_PATH.list.index,
                      ROUTES_PATH.channelDistributions.index,
                      id,
                      ROUTES_PATH.orderClassifications.index,
                    ].join('/')
                  );
                } else {
                  navigate(-1);
                }
              },
              propsWrapper: {
                mobile: true,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
            submit: {
              mode: 'button',
              type: 'submit',
              variant: 'contained',
              startIcon: <SaveRounded />,
              children: intl.formatMessage({
                id: 'save',
              }),
              propsWrapper: {
                mobile: undefined,
              },
            },
          };
        },
      }}
    />
  );
};
export default OrderInfoForm;
