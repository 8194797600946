import {
  AddRounded,
  DeleteRounded,
  EditRounded,
  PauseCircle,
  PlayCircle,
  Visibility,
} from '@mui/icons-material';
import { Button, Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { OperationStatus, ResponseDataList } from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import { UnProcessedDishFilterModel, UnProcessingDishModel } from '../types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../common/components/ReasonDialog';
import { useState } from 'react';
import FilterBox from './FilterBox';
import useMutationReason from '../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../common/components/GenerateCodeBox/types';
import { checkReadOnlyUnProcessingFood } from '../utils';

const UnProcessingDishPage = () => {
  const {
    dispatch,
    fetchThunk,
    API_PATHS,

    listType,
    enqueueSnackbar,
  } = useGeneralHook();
  const hookPagination = usePaginationHook<UnProcessedDishFilterModel>();
  const { mutationReason } = useMutationReason();

  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<UnProcessingDishModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<UnProcessingDishModel>>({
          url: API_PATHS.unProcessingDish.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  const onDelete = (data: UnProcessingDishModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.unProcessingDish.detail(data.id),
              method: 'delete',
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.unProcessingDish.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MKCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'cancel',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="unProcessingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: data?.name,
        },
        {
          label: 'currentStatus',
          value: (
            <>
              <FormattedMessage id={data.operationStatus} />
              &nbsp; (<FormattedMessage id={'active'} />)
            </>
          ),
        },
        {
          label: 'updateStatus',
          value: (
            <>
              <FormattedMessage id={OperationStatus.cancel} />
              &nbsp; (<FormattedMessage id={'deactive'} />)
            </>
          ),
        },
      ],
      reasons: listType.MKCB?.reasons?.cancel,
    });
  };

  const onStopProcess = (data: UnProcessingDishModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.unProcessingDish.detail(data?.id),
              method: 'put',
              data: {
                ...data,
                operationStatus: OperationStatus.stop,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.unProcessingDish.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MKCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'paused',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="unProcessingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id={data.operationStatus} />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id="stop" />,
        },
      ],
      reasons: listType.MKCB?.reasons?.stop,
    });
  };
  const onStartProcess = (data: UnProcessingDishModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.unProcessingDish.detail(data?.id),
              method: 'put',
              data: {
                ...data,
                operationStatus: OperationStatus.confirm,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.unProcessingDish.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MKCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.confirm,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'activate',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="unProcessingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id={data.operationStatus} />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id="confirm" />,
        },
      ],
      reasons: listType.MKCB?.reasons?.activate,
    });
  };
  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <FilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.unProcessingDish.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<UnProcessingDishModel>
        data={
          data?.data?.slice(
            (params.pageIndex - 1) * params.pageSize,
            params.pageIndex * params.pageSize
          ) ?? []
        }
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.data.length,
          page: params.pageIndex,
          rowsPerPage: params.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => {
          return [
            checkReadOnlyUnProcessingFood(row.original) ? (
              <MyLink to={row.original.id.toString()}>
                <IconButtonTooltip
                  tooltipProps={{ title: <FormattedMessage id="view" /> }}
                >
                  <Visibility />
                </IconButtonTooltip>
              </MyLink>
            ) : (
              <MyLink to={row.original.id.toString()}>
                <IconButtonTooltip
                  tooltipProps={{ title: <FormattedMessage id="edit" /> }}
                >
                  <EditRounded />
                </IconButtonTooltip>
              </MyLink>
            ),
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="delete" /> }}
              onClick={() => onDelete(row.original)}
              disabled={[
                OperationStatus.cancel,
                OperationStatus.stop,
                OperationStatus.confirm,
              ].includes(row.original.operationStatus)}
            >
              <DeleteRounded />
            </IconButtonTooltip>,
            row.original.operationStatus === OperationStatus.confirm ? (
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="paused" /> }}
                onClick={() => {
                  onStopProcess(row.original);
                }}
              >
                <PlayCircle />
              </IconButtonTooltip>
            ) : (
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="play" /> }}
                disabled={[OperationStatus.create].includes(
                  row.original.operationStatus
                )}
                onClick={() => {
                  onStartProcess(row.original);
                }}
              >
                <PauseCircle />
              </IconButtonTooltip>
            ),
          ];
        }}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => (
              <FormattedMessage id="unProcessingDish.operationCode" />
            ),
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="unProcessingDish.name" />,
          },
          {
            accessorKey: 'originalDish.name',
            header: 'originalDish.name',
            Header: () => (
              <FormattedMessage id="unProcessingDish.originalDish" />
            ),
          },
          {
            accessorKey: 'objectType',
            header: 'objectType ',
            Header: () => <FormattedMessage id="unProcessingDish.objectType" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.MKCB?.objectType?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'mainFoodQuantity',
            header: 'mainFoodQuantity',
            Header: () => (
              <FormattedMessage id="unProcessingDish.mainFoodQuantity" />
            ),
          },
          {
            accessorKey: 'standardFood.calorie',
            header: 'standardFood.calorie ',
            enableSorting: true,
            Header: () => (
              <FormattedMessage id="unProcessingDish.totalCalorie" />
            ),
          },
          {
            accessorKey: 'operationStatus',
            header: 'operationStatus',
            enableSorting: true,
            Header: () => <FormattedMessage id="status" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.MKCB?.operationStatus?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'status',
            header: 'status',
            enableSorting: true,
            Header: () => <FormattedMessage id="statusAdmin" />,
            Cell: ({ renderedCellValue }) => {
              const status = STATUS_OPTIONS.find(
                (v) => v.id === renderedCellValue
              );

              return (
                status && (
                  <Chip
                    label={<FormattedMessage id={status.name} />}
                    variant="outlined"
                    color={status.color as any}
                    size="small"
                  />
                )
              );
            },
          },
        ]}
      />
      <ReasonDialog {...model} />
    </Paper>
  );
};
export default UnProcessingDishPage;
