import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { Box, InputLabel, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { ResponseDataDetail } from '../../../common/types';
import { calculateCalorie } from './utils';
import { StandardFoodModel } from '../types';

const StandardFoodDetailPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar } = useGeneralHook();
  const { id } = useParams<{ id: string }>();
  const { key } = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<StandardFoodModel>({
    queryKey: [id, categoryId, KEYS_QUERY.standardFoodDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<StandardFoodModel>>({
          url: API_PATHS.standardGroupFood.detail(queryKey[0], queryKey[1]),
        })
      );
      return json.data.data;
    },
    enabled: !!categoryId,
  });

  const onCreateUpdate = useMutation({
    mutationFn: async (values: StandardFoodModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<ResponseDataDetail<StandardFoodModel>, StandardFoodModel>({
            url: API_PATHS.standardGroupFood.index(id),
            method: 'post',
            data: { ...values, calorie: calculateCalorie(values) },
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
      } else {
        await dispatch(
          fetchThunk<ResponseDataDetail<StandardFoodModel>, StandardFoodModel>({
            url: API_PATHS.standardGroupFood.detail(id, values.id),
            method: 'put',
            data: { ...values, calorie: calculateCalorie(values) },
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
      }
      if (key === 'default') {
        navigate(
          [
            '',
            ROUTES_PATH.list.index,
            ROUTES_PATH.groupFood.index,
            id,
            ROUTES_PATH.standardFood.index,
          ].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  if (isLoading) {
    return <MuiLoading />;
  }

  if (!isLoading && !data && categoryId) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SchemaForm<StandardFoodModel, 'info' | 'content' | 'footer'>
          formData={data}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          schema={{
            fields: ({
              formProps: { intl, listType },
              methods: { setValue },
              valuesField,
            }) => {
              return {
                name: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'standardFood.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationCode: {
                  mode: 'raw',
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      module="TPTC"
                      sourceType={valuesField.sourceType}
                      label={intl.formatMessage({
                        id: 'standardFood.operationCode',
                      })}
                    />
                  ),
                  propsWrapper: { tablet: 6 },
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                sourceType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'standardFood.sourceType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  options: listType?.TPTC?.sourceType,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                code: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'standardFood.code',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                type: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'standardFood.type',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  options: listType?.TPTC?.type,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                usageRatio: {
                  mode: 'text-field',
                  type: 'number',
                  label: intl.formatMessage({
                    id: 'standardFood.usageRatio',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: {
                    required: true,

                    min: {
                      value: 0,
                      message: intl.formatMessage({
                        id: 'standardFood.invalidUsageRatio',
                      }),
                    },
                    max: {
                      value: 100,
                      message: intl.formatMessage({
                        id: 'standardFood.invalidUsageRatio',
                      }),
                    },
                  },
                  propsWrapper: { tablet: 6 },
                },
                protein: {
                  mode: 'text-field',
                  type: 'number',
                  rules: { required: true },
                  onKeyUp: () => {
                    if (!valuesField.isManual) {
                      const calorie = calculateCalorie(valuesField);
                      setValue('calorie', calorie);
                    }
                  },
                  noHelperText: true,
                },
                lipid: {
                  mode: 'text-field',
                  type: 'number',
                  rules: { required: true },
                  onKeyUp: () => {
                    if (!valuesField.isManual) {
                      const calorie = calculateCalorie(valuesField);
                      setValue('calorie', calorie);
                    }
                  },
                  noHelperText: true,
                },
                glucid: {
                  mode: 'text-field',
                  type: 'number',
                  rules: { required: true },
                  onKeyUp: () => {
                    if (!valuesField.isManual) {
                      const calorie = calculateCalorie(valuesField);
                      setValue('calorie', calorie);
                    }
                  },
                  noHelperText: true,
                },
                calorie: {
                  mode: 'text-field',
                  type: 'number',
                  rules: { required: true },
                  readOnly: !valuesField.isManual,
                  noHelperText: true,
                },
                isManual: {
                  mode: 'switch',
                  noHelperText: true,
                  onChange: (checked) => {
                    if (!checked) {
                      const calorie = calculateCalorie(valuesField);
                      setValue('calorie', calorie);
                    }
                  },
                },
                description: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                },

                status: {
                  mode: 'switch',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  defaultValue: true,
                },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.list.index,
                          ROUTES_PATH.groupFood.index,
                          id,
                          ROUTES_PATH.standardFood.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
            ui: [
              {
                id: 'info',
                fields: [
                  'name',
                  'operationCode',
                  'sourceType',
                  'code',
                  'type',
                  'usageRatio',
                ],
              },
              {
                id: 'content',
                fields: ['protein', 'lipid', 'glucid', 'calorie', 'isManual'],
              },
              {
                id: 'footer',
                fields: ['description', 'status', 'back', 'submit'],
              },
            ],
            layout: ({ listElement, fields, valuesField }) => {
              const calorie = calculateCalorie(valuesField);
              return (
                <>
                  {listElement[0]}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      px: 6,
                      py: 4,
                      flexWrap: 'wrap',
                    }}
                  >
                    {['protein', 'lipid', 'glucid', 'calorie', 'isManual']
                      .filter(Boolean)
                      .map((val, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              width: 200,
                            }}
                          >
                            <Box
                              sx={{
                                borderBottom: '1px dashed',
                                borderColor: 'primary.main',
                                py: 1,
                                height: 60,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <InputLabel
                                required={val !== 'isManual'}
                                sx={{
                                  height: 'auto',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography variant="subtitle1">
                                  <FormattedMessage
                                    id={`standardFood.${val}`}
                                  />
                                </Typography>
                              </InputLabel>
                            </Box>
                            <Box
                              sx={{
                                borderBottom: '1px dashed',
                                borderColor: 'primary.main',
                                px: 2,
                                py: 1,
                                height: 57,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {fields.content?.[val]}
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                  {listElement[2]}
                </>
              );
            },
          }}
        />
      </Paper>
    </>
  );
};
export default StandardFoodDetailPage;
