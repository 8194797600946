import {
  AddRounded,
  ArrowBackRounded,
  DeleteRounded,
  SaveRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import MuiTable from '../../../common/components/MuiTable';
import SchemaElement from '../../../common/SchemaForm/SchemaElement';
import SelectDishesDialog from './SelectDishesDialog';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import useGetQuotaProcessingDishDetail from './useGetQuotaProcessingDishDetail';
import { QuotaModel } from '../types';
import { ProcessingFoodModel } from '../../processing-food/types';
import { LoadingButton } from '@mui/lab';
import { ROUTES_PATH } from '../../../layout/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { mappedProcessingDishQuotaData } from './utils';

interface Props {
  readOnly?: boolean;
}
const FormBox = (props: Props) => {
  const { readOnly } = props;
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar } = useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data } = useGetQuotaProcessingDishDetail(id);
  const methods = useForm<QuotaModel>({
    values: data,
    defaultValues: { items: [] },
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const { control, watch, getFieldState, formState } = methods;
  const { error } = getFieldState('items', formState);
  const { remove, append } = useFieldArray<QuotaModel>({
    name: 'items',
    control,
    rules: { validate: (val) => val?.length > 0 },
  });

  const fields = watch('items');

  const mappedData = mappedProcessingDishQuotaData(fields);
  const onSubmit = useMutation({
    mutationKey: ['update_dishes_quota'],
    mutationFn: async (values: QuotaModel) => {
      await dispatch(
        fetchThunk({
          url: API_PATHS.processingDish.quota(id),
          method: 'put',
          data: {
            items: values.items?.map((v) => ({
              id: v.id,
              quantity: v.quantity,
              usageRatio: v.usageRatio || v.standardFood.usageRatio,
            })),
          },
        })
      );
      enqueueSnackbar({
        message: <FormattedMessage id={'updateSuccess'} />,
        variant: 'success',
      });
      if (key === 'default') {
        navigate(
          [
            '',
            ROUTES_PATH.techniques.index,
            ROUTES_PATH.processingDish.index,
          ].join('/')
        );
      } else {
        navigate(-1);
      }
    },
  });

  return (
    <Paper variant="outlined">
      <Box className="flex items-center justify-between p-2 tablet:p-4">
        <Typography variant="h6">
          <FormattedMessage id="processingDish.quota" />
        </Typography>
        {!readOnly && (
          <Button startIcon={<AddRounded />} onClick={() => setOpen(true)}>
            <FormattedMessage id="add" />
          </Button>
        )}
      </Box>
      <Divider />
      <FormProvider {...methods}>
        <Box
          component={'form'}
          onSubmit={methods.handleSubmit((val) => onSubmit.mutateAsync(val))}
        >
          <MuiTable<ProcessingFoodModel>
            boxProps={{ sx: { py: 2 } }}
            data={mappedData}
            enableRowActions={!readOnly}
            enableTableFooter={fields?.length > 0}
            getRowId={(originalRow: any) => originalRow.key}
            renderRowActions={({ row }) => [
              <Tooltip title={<FormattedMessage id="delete" />} key={'delete'}>
                <IconButton onClick={() => remove(row.index)}>
                  <DeleteRounded />
                </IconButton>
              </Tooltip>,
            ]}
            columns={[
              {
                accessorKey: 'operationCode',
                header: 'operationCode',
                size: 100,
                Header: () => <FormattedMessage id="quota.code" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'name',
                header: 'name',
                size: 150,
                Header: () => <FormattedMessage id="quota.name" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'quantity',
                header: 'quantity',
                Header: () => <FormattedMessage id="quota.quantity_100" />,
                size: 120,
                Cell: ({ row }) => {
                  return (
                    <>
                      <SchemaElement
                        fieldName={`items.${row.index}.quantity`}
                        propsElement={{
                          mode: 'text-field',
                          type: 'number',
                          noHelperText: true,
                          sx: { width: 80 },
                          rules: { required: true, min: 0 },
                          inputProps: {
                            style: { textAlign: 'center', padding: 4 },
                          },
                          readOnly,
                        }}
                      />
                    </>
                  );
                },
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'quantity_1',
                header: 'quantity_1',
                Header: () => <FormattedMessage id="quota.quantity_1" />,
                size: 100,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={
                        ((row.original.quantity || 0) *
                          (row.original?.conversionRatio || 0)) /
                        100
                      }
                    />
                  );
                },
              },
              {
                accessorKey: 'processingUnit.name',
                header: 'processingUnit',
                size: 50,
                Header: () => <FormattedMessage id="quota.processingUnit" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
              },
              {
                accessorKey: 'conversionRatio',
                header: 'conversionRatio',
                size: 50,
                Header: () => <FormattedMessage id="quota.conversionRatio" />,
                muiTableFooterCellProps: {
                  sx: { display: 'none' },
                },
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber value={row.original.conversionRatio} />
                  );
                },
              },
              {
                accessorKey: 'standardFood.usageRatio',
                header: 'standardFood',
                size: 50,
                Header: () => <FormattedMessage id="quota.usageRatio" />,
                muiTableFooterCellProps: {
                  colSpan: 7,
                },
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={
                        row.original?.usageRatio ||
                        row.original.standardFood?.usageRatio
                      }
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="subtitle2"
                      color="secondary"
                      sx={{ textAlign: 'center', textTransform: 'uppercase' }}
                    >
                      <FormattedMessage id="total" />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.protein',
                header: 'standardFood.protein',
                Header: () => <FormattedMessage id="quota.protein" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.protein}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.protein) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.lipid',
                header: 'standardFood.lipid',
                Header: () => <FormattedMessage id="quota.lipid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber value={row.original.standardFood?.lipid} />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={mappedData?.reduce(
                          (v, c) => v + Number(c.standardFood?.lipid) || 0,
                          0
                        )}
                      />
                    </Typography>
                  );
                },
              },
              {
                accessorKey: 'standardFood.glucid',
                header: 'standardFood.glucid',
                Header: () => <FormattedMessage id="quota.glucid" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.glucid}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.glucid) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },

              {
                accessorKey: 'standardFood.calorie',
                header: 'standardFood.calorie',
                Header: () => <FormattedMessage id="quota.calorie" />,
                size: 50,
                Cell: ({ row }) => {
                  return (
                    <FormattedNumber
                      value={row.original.standardFood?.calorie}
                    />
                  );
                },
                Footer: () => {
                  return (
                    <Typography
                      variant="inherit"
                      color="secondary"
                      sx={{ textAlign: 'center' }}
                    >
                      <FormattedNumber
                        value={
                          mappedData?.reduce(
                            (v, c) => v + Number(c.standardFood?.calorie) || 0,
                            0
                          ) || 0
                        }
                      />
                    </Typography>
                  );
                },
              },
            ]}
          />
          <Box className="flex justify-between px-2 tablet:px-4">
            <Typography variant="caption" className="italic">
              <FormattedMessage id="processingDish.node_1" />
              <br />
              <FormattedMessage id="processingDish.node_2" />
            </Typography>
            {error?.root && (
              <Typography variant="caption" color="error" noWrap>
                <FormattedMessage id="processingDish.dishRequired" />
              </Typography>
            )}
          </Box>
          <Box className="flex justify-end p-2 gap-2 tablet:gap-4 tablet:p-4 pt-0">
            <Button
              variant="outlined"
              startIcon={<ArrowBackRounded />}
              onClick={() => {
                if (key === 'default') {
                  navigate(
                    [
                      '',
                      ROUTES_PATH.techniques.index,
                      ROUTES_PATH.processingDish.index,
                    ].join('/')
                  );
                } else {
                  navigate(-1);
                }
              }}
            >
              <FormattedMessage id="back" />
            </Button>
            {!readOnly && (
              <LoadingButton
                variant="contained"
                startIcon={<SaveRounded />}
                type="submit"
              >
                <FormattedMessage id="save" />
              </LoadingButton>
            )}
          </Box>
        </Box>
      </FormProvider>
      <SelectDishesDialog
        selected={fields}
        open={open}
        onClose={setOpen}
        onSubmit={(val: ProcessingFoodModel[]) => {
          val.forEach((v) => {
            append(v);
          });
          setOpen(false);
        }}
      />
    </Paper>
  );
};
export default FormBox;
