import { Box, Button, Checkbox, Chip, Paper, Tooltip } from '@mui/material';
import usePaginationHook from '../../../../common/hook/usePaginationHook';

import MuiTable from '../../../../common/components/MuiTable';

import { KEYS_QUERY } from '../../../../common/constants';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import ProcessingFoodInfoBox from './ProcessingFoodInfoBox';
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackRounded,
  ArrowForwardRounded,
  UnpublishedOutlined,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES_PATH } from '../../../../layout/constants';
import {
  ProcessingFoodFilterModel,
  ProcessingFoodModel,
} from '../../../processing-food/types';
import { OperationStatus, ResponseDataList } from '../../../../common/types';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import FilterBox from '../../list/FilterBox';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../../common/components/ReasonDialog';
import useMutationReason from '../../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../../common/components/GenerateCodeBox/types';

interface Props {
  processingFood: ProcessingFoodModel;
  setStep: (step: number) => void;
}
const Step1 = (props: Props) => {
  const { setStep, processingFood } = props;
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();
  const { mutationReason } = useMutationReason();
  const hookPagination = usePaginationHook<ProcessingFoodFilterModel>({
    defaultFilter: {
      pageSize: 10,
    },
    disableLink: true,
  });
  const [rowSelection, setRowSelection] = useState<ProcessingFoodModel[]>([]);
  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;

  const { data, isLoading } = useQuery<ResponseDataList<ProcessingFoodModel>>({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.index,
          params: {
            ...params,
            filters: {
              ...params.filters,
              commodityGroup: params.filters.commodityGroup?.id,
              commodityClassification:
                params.filters.commodityClassification?.id,
              operationStatus: [OperationStatus.confirm].join(','),
            },
          },
        })
      );
      return json.data;
    },
  });

  const onReject = () => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        if (!processingFood) {
          return;
        }
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingFood.detail(processingFood?.id),
              method: 'put',
              data: {
                ...processingFood,
                operationStatus: OperationStatus.deny,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingFood.reason(processingFood?.id),
            params: {
              objectId: processingFood?.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.TPCB,
              data: rowSelection.map((v) => v.id),
              statusBefore: processingFood?.operationStatus,
              statusAfter: OperationStatus.deny,
            },
          }),
        ]).then(() => {
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
          if (key === 'default') {
            navigate(
              [
                '',
                ROUTES_PATH.norms.index,
                ROUTES_PATH.processingFoodConfirm.index,
              ].join('/')
            );
          } else {
            navigate(-1);
          }
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'denyText',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingFood.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {processingFood?.name} <br />
              {processingFood?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: processingFood?.operationStatus && (
            <FormattedMessage id={processingFood?.operationStatus} />
          ),
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.deny} />,
        },
      ],
      reasons: listType.TPCB?.reasons?.deny,
    });
  };

  return (
    <>
      <Paper>
        <Box className="p-2">
          <FilterBox hookPagination={hookPagination} hiddenStatus />
          <Box className="flex flex-wrap gap-2 tablet:gap-4 mt-2">
            {rowSelection.map((item) => {
              return (
                <Tooltip
                  key={item.id}
                  title={
                    <Box className="flex gap-4">
                      <ProcessingFoodInfoBox data={processingFood as any} />
                      <ProcessingFoodInfoBox data={item} />
                    </Box>
                  }
                  slotProps={{ tooltip: { sx: { maxWidth: 800, p: 2 } } }}
                >
                  <Chip
                    variant="outlined"
                    label={item.name}
                    onDelete={() => {
                      setRowSelection((old) =>
                        old.filter((val) => val.id !== item.id)
                      );
                    }}
                  />
                </Tooltip>
              );
            })}
          </Box>
        </Box>
        <MuiTable<ProcessingFoodModel>
          data={data?.data ?? []}
          state={{
            isLoading: isLoading,
            sorting,
          }}
          onSortingChange={onSortingChange}
          paginationProps={{
            count: data?.data.length,
            page: params.pageIndex,
            rowsPerPage: params.pageSize,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
            rowsPerPageOptions: [10, 20, 50, 100],
          }}
          columns={[
            {
              header: 'select',
              Header: () => (
                <FormattedMessage id="processedFoodConfirm.selectDuplicate" />
              ),
              size: 80,
              Cell: ({ row }) => {
                const checked =
                  rowSelection.findIndex((v) => v.id === row.original.id) !==
                  -1;
                return (
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setRowSelection((old) => [row.original, ...old]);
                      } else {
                        setRowSelection((old) =>
                          old.filter((v) => v.id !== row.original.id)
                        );
                      }
                    }}
                  />
                );
              },
            },
            {
              accessorKey: 'id',
              header: 'id',
              enableSorting: true,
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'operationCode',
              header: 'operationCode',
              enableSorting: true,
              Header: () => <FormattedMessage id="processingFood.code" />,
            },
            {
              accessorKey: 'name',
              header: 'name',
              enableSorting: true,
              Header: () => <FormattedMessage id="processingFood.name" />,
            },
            {
              accessorKey: 'processingUnit.name',
              header: 'unit',
              Header: () => (
                <FormattedMessage id="processingFood.processingUnit" />
              ),
            },
            {
              accessorKey: 'commodityClassification.name',
              header: 'commodityClassification.name ',
              Header: () => (
                <FormattedMessage id="processingFood.commodityClassification" />
              ),
            },
            {
              accessorKey: 'preliminaryType',
              header: 'preliminaryType',
              Header: () => (
                <FormattedMessage id="processingFood.preliminaryType" />
              ),
              Cell: ({ renderedCellValue }) => {
                const preliminaryType = listType.TPCB?.preliminaryType?.find(
                  (v) => v.id === renderedCellValue
                );
                return preliminaryType?.name;
              },
            },
            {
              accessorKey: 'standardFood.name',
              header: 'standardFood.name ',
              Header: () => (
                <FormattedMessage id="processingFood.standardFood" />
              ),
            },
            {
              accessorKey: 'operationStatus',
              header: 'status',
              Header: () => <FormattedMessage id="status" />,
              Cell: ({ renderedCellValue }) => {
                const status = listType.TPCB?.operationStatus?.find(
                  (v) => v.id === renderedCellValue
                );
                return status?.name;
              },
            },
          ]}
        />
        <Box className="flex p-2 gap-2 tablet:gap-4 tablet:p-4 tablet:pt-0">
          <LoadingButton
            variant="outlined"
            startIcon={<UnpublishedOutlined />}
            onClick={onReject}
          >
            <FormattedMessage id="processedFoodConfirm.reject" />
          </LoadingButton>
          <Box flex={1} />
          <Button
            variant="outlined"
            startIcon={<ArrowBackRounded />}
            onClick={() => {
              if (key === 'default') {
                navigate(
                  [
                    '',
                    ROUTES_PATH.norms.index,
                    ROUTES_PATH.processingFoodConfirm.index,
                  ].join('/')
                );
              } else {
                navigate(-1);
              }
            }}
          >
            <FormattedMessage id="back" />
          </Button>
          <LoadingButton
            variant="contained"
            endIcon={<ArrowForwardRounded />}
            onClick={() => setStep(1)}
          >
            <FormattedMessage id="continue" />
          </LoadingButton>
        </Box>
      </Paper>
      <ReasonDialog {...model} />
    </>
  );
};
export default Step1;
