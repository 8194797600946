import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import useProcessingFoodDetail from './useProcessingFoodDetail';
import { ProcessingFoodModel } from '../types';
import { useState } from 'react';
import { KEYS_QUERY, some } from '../../../common/constants';
import ConfirmUpdateStatusAdmin from './ConfirmUpdateStatusAdmin';
import { OperationStatus, ResponseDataDetail } from '../../../common/types';
import { checkReadOnlyProcessingFoodConfirm } from '../../processing-food-confirm/utils';

const ProcessingFoodDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState<
    ProcessingFoodModel | undefined
  >(undefined);

  const { data, isLoading } = useProcessingFoodDetail(id);

  const onUpdate = useMutation({
    mutationFn: async (values: ProcessingFoodModel) => {
      await dispatch(
        fetchThunk({
          url: API_PATHS.processingFood.detail(values.id),
          method: 'put',
          data: values,
        })
      );
      enqueueSnackbar({
        message: <FormattedMessage id={'updateSuccess'} />,
        variant: 'success',
      });
      if (key === 'default') {
        navigate(
          ['', ROUTES_PATH.list.index, ROUTES_PATH.processingFood.index].join(
            '/'
          )
        );
      } else {
        navigate(-1);
      }
    },
  });

  const onCreateUpdate = useMutation({
    mutationFn: async (values: ProcessingFoodModel) => {
      if (!values.id) {
        const json = await dispatch(
          fetchThunk<ResponseDataDetail<ProcessingFoodModel>>({
            url: API_PATHS.processingFood.index,
            method: 'post',
            data: values,
          })
        );
        navigate(
          {
            pathname: [
              '',
              ROUTES_PATH.techniques.index,
              ROUTES_PATH.processingFood.index,
            ].join('/'),
          },
          { replace: true }
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
      } else {
        if (values.status !== data?.status) {
          setOpenUpdateStatusDialog(values);
        } else {
          onUpdate.mutateAsync(values);
        }
      }
    },
  });

  if (isLoading) {
    return <MuiLoading />;
  }

  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SchemaForm<ProcessingFoodModel>
          formData={data}
          formHookProps={{
            defaultValues: {
              operationStatus: OperationStatus.create,
              status: true,
            },
          }}
          globalProps={{
            readOnly: data?.id
              ? checkReadOnlyProcessingFoodConfirm(data)
              : false,
          }}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          schema={{
            fields: ({
              formProps: { intl, listType, readOnly },
              valuesField,
              methods: { setValue },
            }) => {
              return {
                name: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'processingFood.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationCode: {
                  mode: 'raw',
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      module="TPCB"
                      commodityGroup={valuesField?.commodityGroup?.name}
                      label={intl.formatMessage({
                        id: 'processingFood.operationCode',
                      })}
                    />
                  ),
                  propsWrapper: { tablet: 6 },
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                commodityClassification: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityClassification',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityClassifications.index,
                      text,
                      KEYS_QUERY.commodityClassification,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                  },
                  onChange: () => {
                    setValue('commodityGroup', null);
                  },
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                commodityGroup: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityGroup',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityGroups.index(
                        valuesField.commodityClassification?.id
                      ),
                      text,
                      KEYS_QUERY.commodityGroups,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                    config: {
                      enabled: !!valuesField.commodityClassification?.id,
                    },
                  },
                  getOptionLabel: (option: some) =>
                    [
                      option.name,
                      listType.NMH?.type.find((v) => v.id === option.type)
                        ?.name,
                    ]
                      .filter(Boolean)
                      .join(' - '),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },

                processingUnit: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.processingUnit',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  rules: { required: true },
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.units.index,
                      text,
                      KEYS_QUERY.units,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                  },
                  onChange: (option) => {
                    setValue('conversionRatio', option?.conversionRatio);
                  },
                  propsWrapper: { tablet: 6 },
                },
                conversionRatio: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'processingFood.conversionRatio',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                  readOnly: valuesField.processingUnit?.type === 'CD',
                },
                preliminaryType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'processingFood.preliminaryType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  options: listType?.TPCB?.preliminaryType || [],
                  rawOptions: true,
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationStatus: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  options: listType.TPCB?.operationStatus,
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                status: {
                  mode: 'switch',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  labelPlacement: 'top',
                  propsWrapper: { tablet: 6 },
                },
                blank: { mode: 'node', propsWrapper: { tablet: 6 } },

                description: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                  propsWrapper: { tablet: 6 },
                },
                blank2: { mode: 'node', propsWrapper: { tablet: 6 } },

                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.techniques.index,
                          ROUTES_PATH.processingFood.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  hidden: readOnly,
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
          }}
        />

        <ConfirmUpdateStatusAdmin
          data={openUpdateStatusDialog}
          open={!!openUpdateStatusDialog}
          onClose={() => setOpenUpdateStatusDialog(undefined)}
          onSubmit={onUpdate.mutateAsync}
        />
      </Paper>
    </>
  );
};
export default ProcessingFoodDetail;
