import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ResponseDataDetail } from '../../../common/types';
import { QuotaModel } from '../types';

const useGetQuotaProcessingDishDetail = (id?: string | number) => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isLoading } = useQuery<QuotaModel>({
    queryKey: [id, KEYS_QUERY.quotaProcessingDishDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<QuotaModel>>({
          url: API_PATHS.processingDish.quota(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  return { data, isLoading };
};
export default useGetQuotaProcessingDishDetail;
