import { OperationStatus } from '../../common/types';
import { UnProcessingDishModel } from './types';

export const UnProcessingDishReadOnlyStatus = [
  OperationStatus.stop,
  OperationStatus.confirm,
];
export const checkReadOnlyUnProcessingFood = (data: UnProcessingDishModel) => {
  return UnProcessingDishReadOnlyStatus.includes(data.operationStatus);
};
