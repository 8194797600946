import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormHelperText,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { get, pickBy } from 'lodash';
import _ from 'lodash/fp';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ElementFormProps, ElementMode } from './utils';

const ArrayElement = React.lazy(
  () => import('./element/array-element/ArrayElement')
);
const AutoCompleteElement = React.lazy(
  () => import('./element/autocomplete/AutoCompleteElement')
);
const FormControlAutoComplete = React.lazy(
  () => import('./element/autocomplete/FormControlAutoComplete')
);
const CheckBoxElement = React.lazy(
  () => import('./element/checkbox/CheckBoxElement')
);
const DateRangePickerElement = React.lazy(
  () => import('./element/date-range/DateRangePickerElement')
);
const DatePickerElement = React.lazy(
  () => import('./element/datepicker-element/DatePickerElement')
);
const DateTimePickerElement = React.lazy(
  () => import('./element/datepickerTime-element/DateTimePickerElement')
);
const DropZoneElement = React.lazy(
  () => import('./element/drop-zone/DropZoneElement')
);
const MultipleCheckBoxElement = React.lazy(
  () => import('./element/multiple-checkbox/MultipleCheckBoxElement')
);
const MultipleRadioElement = React.lazy(
  () => import('./element/multiple-radio/MultipleRadioElement')
);
const PhoneFieldElement = React.lazy(
  () => import('./element/phone-input/PhoneFieldElement')
);
const RadioElement = React.lazy(() => import('./element/radio/RadioElement'));
const SectionElement = React.lazy(
  () => import('./element/section-element/SectionElement')
);
const SelectElement = React.lazy(
  () => import('./element/select/SelectElement')
);
const SwitchElement = React.lazy(
  () => import('./element/switch/SwitchElement')
);
const TextEditorElement = React.lazy(
  () => import('./element/text-editor/TextEditorElement')
);
const TextFieldElement = React.lazy(
  () => import('./element/text-field/TextFieldElement')
);
const TimePickerElement = React.lazy(
  () => import('./element/timepicker-element/TimePickerElement')
);
const UploadFileElement = React.lazy(
  () => import('./element/uploadFile/UploadFileElement')
);

interface Props {
  fieldName: string;
  rawElement?: boolean;
  propsElement: ElementFormProps;
}

const SchemaElement = (props: Props) => {
  const { propsElement } = props;
  const { unregister, mode } = propsElement as any;

  if (!mode) {
    return null;
  }
  if (unregister || ['node', 'button'].includes(mode)) {
    return <SchemaElementRaw {...props} />;
  }
  return <SchemaElementContext {...props} />;
};

const SchemaElementRaw = (props: Props) => {
  const { rawElement, propsElement } = props;
  const {
    mode,
    render,
    unregister,
    propsWrapper,
    defaultValue,
    hidden,
    mapperValue,
    noHelperText,
    ...rest
  } = propsElement as any;

  const getElement = React.useMemo(() => {
    let element;

    if (!mode) {
      return [];
    }
    switch (mode as ElementMode) {
      case 'hidden':
        break;
      case 'text':
        element = (
          <Box>
            <Typography variant="subtitle1">{rest.label}</Typography>
            <Typography variant="body2">{rest.value}</Typography>
          </Box>
        );
        break;
      case 'text-field':
        element = <TextFieldElement fullWidth {...rest} />;
        break;
      case 'phone-field':
        element = <PhoneFieldElement fullWidth {...rest} />;
        break;
      case 'uploadFile':
        element = <UploadFileElement fullWidth {...rest} />;
        break;
      case 'checkbox':
        element = <CheckBoxElement {...rest} />;
        break;
      case 'multiple-checkbox':
        element = <MultipleCheckBoxElement {...rest} />;
        break;
      case 'radio':
        element = <RadioElement {...rest} />;
        break;
      case 'multiple-radio':
        element = <MultipleRadioElement {...rest} />;
        break;
      case 'select':
        element = <SelectElement {...rest} />;
        break;
      case 'switch':
        element = <SwitchElement {...rest} />;
        break;
      case 'auto-complete':
        element = <FormControlAutoComplete fullWidth {...rest} />;
        break;
      case 'datePicker':
        element = <DatePickerElement {...(rest as any)} />;
        break;
      case 'dateTimePicker':
        element = <DatePickerElement {...(rest as any)} />;
        break;
      case 'timePicker':
        element = <TimePickerElement {...(rest as any)} />;
        break;
      case 'dateRange':
        element = <DateRangePickerElement {...(rest as any)} />;
        break;
      case 'text-editor':
        element = <TextEditorElement {...rest} />;
        break;
      case 'drop-zone':
        element = <DropZoneElement {...rest} />;
        break;
      case 'node':
        element = typeof render === 'function' ? render(rest) : render;
        break;
      case 'button':
        element = (
          <LoadingButton variant="contained" color="primary" {...rest}>
            {rest.children}
          </LoadingButton>
        );
        break;
      case 'raw':
        element =
          typeof render === 'function'
            ? render(rest)
            : render
            ? React.createElement(render as any, rest)
            : null;
        break;
      default:
        element =
          typeof render === 'function'
            ? render(rest)
            : render
            ? React.createElement(render as any, rest)
            : null;
        break;
    }
    return element;
  }, [mode, render, rest]);

  const content = React.useMemo(() => {
    return (
      <>
        <React.Suspense fallback={<Skeleton />}>{getElement}</React.Suspense>
      </>
    );
  }, [getElement]);

  if (hidden) {
    return null;
  }
  if (mode === 'hidden') {
    return content;
  }
  if (rawElement) {
    return <div {...propsWrapper}>{content}</div>;
  }
  return (
    <Grid item mobile={12} {...propsWrapper}>
      {content}
    </Grid>
  );
};

const SchemaElementContext = (props: Props) => {
  const { fieldName, rawElement, propsElement } = props;
  const {
    mode,
    render,
    unregister,
    rules = {},
    propsWrapper,
    defaultValue,
    tooltipError,
    noHelperText,
    shouldUnregister,
    hidden,
    mapperValue,
    ...rest
  } = propsElement as any;
  const methods = useFormContext();
  const {
    control,
    register,
    formState: { errors },
  } = methods;

  const controllerMethods = useController({
    name: fieldName,
    control,
    shouldUnregister: shouldUnregister || unregister,
    rules,
    defaultValue,
  });
  const {
    field: { value, name, onBlur, onChange, ref },
    fieldState: { invalid },
  } = controllerMethods;
  const onChangeValue = useCallback(
    (onChangeTmp, params) => {
      const { onChange } = rest;
      if (mapperValue) {
        if (typeof params === 'object') {
          onChangeTmp(mapperValue(...params));
          onChange && onChange(mapperValue(...params));
        } else {
          onChangeTmp(mapperValue(params));
          onChange && onChange(mapperValue(params));
        }
      } else {
        if (typeof params === 'object') {
          onChangeTmp(...params);
          onChange && onChange(...params);
        } else {
          onChangeTmp(params);
          onChange && onChange(params);
        }
      }
    },
    [mapperValue, rest]
  );

  const required = Object.keys(rules)?.length > 0;

  const getElement = React.useMemo(() => {
    let element;

    if (!mode) {
      return [];
    }
    switch (mode as ElementMode) {
      case 'hidden':
        element = <input type="hidden" {...register(fieldName, rules)} />;
        break;
      case 'text':
        element = (
          <Box>
            <Typography variant="subtitle1">{rest.label}</Typography>
            <Typography variant="body2">{rest.value}</Typography>
          </Box>
        );
        break;
      case 'text-field':
        element = (
          <TextFieldElement
            fullWidth
            required={required}
            name={name}
            {...rest}
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            value={
              typeof value === 'string' || typeof value === 'number'
                ? value
                : ''
            }
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'phone-field':
        element = (
          <PhoneFieldElement
            fullWidth
            required={required}
            name={name}
            {...rest}
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            value={value}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'uploadFile':
        element = (
          <UploadFileElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            fullWidth
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'checkbox':
        element = (
          <CheckBoxElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'multiple-checkbox':
        element = (
          <MultipleCheckBoxElement
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'radio':
        element = (
          <RadioElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'multiple-radio':
        element = (
          <MultipleRadioElement
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'select':
        element = (
          <SelectElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            onBlur={onBlur}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'switch':
        element = (
          <SwitchElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            disabled={rest.disabled || rest.readOnly}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'auto-complete':
        element = (
          <AutoCompleteElement
            innerRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            error={invalid}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
          />
        );
        break;
      case 'datePicker':
        element = (
          <DatePickerElement
            required={required}
            {...rest}
            {...controllerMethods.field}
            value={value ? dayjs(value) : null}
            onChange={(val?: Dayjs | null) => {
              onChangeValue(onChange, val ? val?.toISOString() : null);
            }}
            error={invalid}
          />
        );
        break;
      case 'dateTimePicker':
        element = (
          <DateTimePickerElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            value={value ? dayjs(value) : null}
            onChange={(val?: Dayjs | null) => {
              onChangeValue(onChange, val ? val?.toISOString() : null);
            }}
            error={invalid}
          />
        );
        break;
      case 'timePicker':
        element = (
          <TimePickerElement
            inputRef={(e) => {
              ref(e);
              rest.inputRef && rest.inputRef(e);
            }}
            required={required}
            {...rest}
            {...controllerMethods.field}
            value={value ? dayjs(value) : null}
            onChange={(val?: Dayjs | null) => {
              onChangeValue(onChange, val ? val?.toISOString() : null);
            }}
            error={invalid}
          />
        );
        break;
      case 'dateRange':
        element = (
          <DateRangePickerElement
            required={required}
            {...controllerMethods.field}
            {...rest}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'text-editor':
        element = (
          <TextEditorElement
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'drop-zone':
        element = (
          <DropZoneElement
            required={required}
            {...rest}
            {...controllerMethods.field}
            onChange={(...params) => {
              onChangeValue(onChange, params);
            }}
            error={invalid}
          />
        );
        break;
      case 'array':
        element = <ArrayElement {...rest} name={fieldName} />;
        break;
      case 'section':
        element = <SectionElement {...rest} name={fieldName} />;
        break;
      case 'raw':
        element = render ? render({ ...rest, ...controllerMethods }) : null;
        break;
    }
    return element;
  }, [
    mode,
    register,
    fieldName,
    rules,
    rest,
    required,
    name,
    value,
    invalid,
    controllerMethods,
    onBlur,
    render,
    ref,
    onChangeValue,
    onChange,
  ]);

  const errorMessage = React.useCallback(() => {
    const tmp = pickBy(get(errors, fieldName), (value, key) => {
      return key !== 'ref';
    }) as any;
    const message = tmp?.message || tmp?.type;
    return { ...tmp, message: message };
  }, [errors, fieldName]);

  const content = React.useMemo(() => {
    const messageError =
      errorMessage() &&
      _.entries(errorMessage()).map(([type, message]: [string, unknown]) => {
        return (
          typeof message === 'string' &&
          (message ? type !== 'type' : true) && (
            <span key={type}>
              {message === 'required' ? (
                <FormattedMessage id="required" />
              ) : (
                message
              )}
              <br />
            </span>
          )
        );
      });

    return (
      <>
        <React.Suspense fallback={<Skeleton />}>{getElement}</React.Suspense>
        {!noHelperText && (
          <Tooltip title={tooltipError ? messageError : ''} arrow>
            <FormHelperText
              style={{ height: 8 }}
              error={!rest?.readOnly}
              component="div"
            >
              <Typography
                variant="caption"
                color="inherit"
                noWrap
                component="div"
              >
                {messageError}
              </Typography>
            </FormHelperText>
          </Tooltip>
        )}
      </>
    );
  }, [errorMessage, getElement, noHelperText, rest?.readOnly, tooltipError]);

  if (hidden) {
    return null;
  }
  if (mode === 'hidden') {
    return content;
  }
  if (rawElement) {
    return <div {...propsWrapper}>{content}</div>;
  }
  return (
    <Grid item mobile={12} {...propsWrapper}>
      {content}
    </Grid>
  );
};

export default SchemaElement;
