import {
  ExpandMoreOutlined,
  RefreshRounded,
  SearchRounded,
  TuneOutlined,
} from '@mui/icons-material';
import { Badge, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import SchemaForm from '../../../common/SchemaForm';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { ProcessingFoodFilterModel } from '../../processing-food/types';
import { CommodityGroupsModel } from '../../commodity-groups/detail/types';

interface Props {
  hookPagination: HookPaginationProps<ProcessingFoodFilterModel>;
}
const Filter = (props: Props) => {
  const { hookPagination } = props;
  const { setParams, clearParams, filters } = hookPagination;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <SchemaForm<ProcessingFoodFilterModel>
        formHookProps={{ defaultValues: filters }}
        globalProps={{
          noHelperText: true,
        }}
        onSubmit={(val) => setParams(val)}
        schema={{
          fields: ({ formProps: { intl, isMobile } }) => {
            return {
              searchText: {
                mode: 'node',
                hidden: isMobile,
                render: (
                  <Typography sx={{ mt: 1 }}>
                    {intl.formatMessage({
                      id: 'search',
                    })}
                    :
                  </Typography>
                ),
                propsWrapper: { mobile: undefined },
              },
              name: {
                mode: 'text-field',
                placeholder: intl.formatMessage({
                  id: 'processingFood.search',
                }),
                propsWrapper: { mobile: true },
              },
              submit: {
                mode: 'button',
                type: 'submit',
                variant: 'contained',
                startIcon: <SearchRounded />,
                children:
                  !isMobile &&
                  intl.formatMessage({
                    id: 'search',
                  }),
                propsWrapper: { mobile: undefined },
              },
              filter: {
                mode: 'button',
                variant: 'outlined',
                children:
                  !isMobile &&
                  intl.formatMessage({
                    id: 'filter',
                  }),
                startIcon: (
                  <Badge
                    badgeContent={Object.keys(filters).length}
                    variant="standard"
                    color="primary"
                  >
                    <TuneOutlined />
                  </Badge>
                ),
                endIcon: !isMobile && <ExpandMoreOutlined />,
                onClick: handleClick,
                propsWrapper: { mobile: undefined },
              },
              reset: {
                mode: 'button',
                hidden: isMobile,
                variant: 'outlined',
                children: <RefreshRounded />,
                onClick: () => clearParams(),
                propsWrapper: { mobile: undefined },
              },
            };
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: { borderColor: 'primary.main', p: 2, mt: 2, width: 280 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SchemaForm<ProcessingFoodFilterModel>
          formHookProps={{ defaultValues: filters }}
          globalProps={{
            noHelperText: true,
          }}
          onChange={(val) => {
            setParams({
              ...val,
            });
          }}
          schema={{
            fields: ({
              formProps: {
                intl,
                listType,
                dispatch,
                fetchThunk,
                API_PATHS,
                KEYS_QUERY,
              },
              methods: { setValue },
              valuesField,
            }) => {
              return {
                preliminaryType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'processingFood.preliminaryType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  hasAllOptions: true,
                  options: listType?.TPCB?.preliminaryType,
                  rawOptions: true,
                },
                commodityClassification: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityClassification',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityClassifications.index,
                      text,
                      KEYS_QUERY.commodityClassification,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                    mapped: (values) =>
                      values.map((v) => ({
                        id: v.id,
                        name: v.name,
                      })),
                  },
                  onChange: () => {
                    setValue('commodityGroup', null);
                  },
                  rules: { required: true },
                },
                commodityGroup: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingFood.commodityGroup',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [
                      API_PATHS.commodityGroups.index(
                        valuesField.commodityClassification?.id
                      ),
                      text,
                      KEYS_QUERY.commodityGroups,
                    ],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                    mapped: (values) =>
                      values.map((v) => ({
                        id: v.id,
                        name: v.name,
                        type: v.type,
                      })),
                    config: {
                      enabled: !!valuesField.commodityClassification?.id,
                    },
                  },
                  getOptionLabel: (option: CommodityGroupsModel) =>
                    [
                      option.name,
                      listType.NMH?.type.find((v) => v.id === option.type)
                        ?.name,
                    ]
                      .filter(Boolean)
                      .join(' - '),
                  rules: { required: true },
                },
              };
            },
          }}
        />
      </Popover>
    </>
  );
};
export default Filter;
