import { Box, Typography } from '@mui/material';
import MuiDialog from '../../../common/components/MuiDialog';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from '../../../redux/store';
import SchemaForm from '../../../common/SchemaForm';
import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { UnProcessingDishModel } from '../../un-processing-dish/types';

export interface UpdatePriceDialogProps {
  open: boolean;
  onClose: () => void;
  data?: UnProcessingDishModel;
}
const UpdatePriceDialog = (props: UpdatePriceDialogProps) => {
  const { onClose, open, data } = props;
  const listType = useAppSelector((state) => state.common.listType);
  const processingFoodInfo = (
    <Box className="columns-1 tablet:columns-2 gap-4">
      {[
        {
          label: 'unProcessingDish.name',
          value: data?.name,
        },
        {
          label: 'unProcessingDish.operationCode',
          value: data?.operationCode,
        },
        {
          label: 'unProcessingDish.objectType',
          value: data?.objectType,
        },
        {
          label: 'unProcessingDish.displayName',
          value: data?.displayName,
        },
        {
          label: 'unProcessingDish.commodityClassification',
          value: data?.commodityClassification?.name,
        },
        {
          label: 'unProcessingDish.commodityGroup',
          value: data?.commodityGroup?.name,
        },

        {
          label: 'unProcessingDish.mainFoodQuantity',
          value: data?.mainFoodQuantity,
        },

        {
          label: 'unProcessingDish.usageRatio',
          value: data?.usageRatio,
        },

        {
          label: 'unProcessingDish.nutritionRequired',
          value: (
            <FormattedMessage id={data?.nutritionRequired ? 'yes' : 'no'} />
          ),
        },

        {
          label: 'unProcessingDish.isProcessingFood',
          value: (
            <FormattedMessage id={data?.isProcessingFood ? 'yes' : 'no'} />
          ),
        },
      ].map((item, index) => {
        return (
          <Box className="flex break-inside-avoid mb-1" key={index}>
            <Typography variant="subtitle2" component={'div'} flexShrink={0}>
              <FormattedMessage id={item.label} />
            </Typography>
            <Typography
              variant="body2"
              sx={{ wordBreak: 'break-all', display: 'flex' }}
            >
              {item.value ? <>:&nbsp;{item.value}</> : item.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
  const originDishInfo = (
    <Box className="columns-1 tablet:columns-2 gap-4">
      {[
        {
          label: 'standardFood.name',
          value: data?.standardFood?.name,
        },
        {
          label: 'standardFood.operationCode',
          value: data?.standardFood?.operationCode,
        },
        {
          label: 'standardFood.sourceType',
          value: listType.TPTC?.sourceType?.find(
            (v) => v.id === data?.standardFood.sourceType
          )?.name,
        },
        {
          label: 'standardFood.type',
          value: listType.TPTC?.type?.find(
            (v) => v.id === data?.standardFood.type
          )?.name,
        },
        {
          label: 'standardFood.usageRatio',
          value: data?.standardFood?.usageRatio,
        },
        {
          label: 'standardFood.protein',
          value: data?.standardFood?.protein,
        },
        {
          label: 'standardFood.lipid',
          value: data?.standardFood?.lipid,
        },
        {
          label: 'standardFood.calorie',
          value: data?.standardFood?.calorie,
        },
      ].map((item, index) => {
        return (
          <Box className="flex break-inside-avoid mb-1 italic" key={index}>
            <Typography variant="subtitle2" component={'div'} flexShrink={0}>
              <FormattedMessage id={item.label} />
            </Typography>
            <Typography
              variant="body2"
              sx={{ wordBreak: 'break-all', display: 'flex' }}
            >
              {item.value ? <>:&nbsp;{item.value}</> : item.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
  return (
    <MuiDialog open={open} onClose={onClose} title="setupPrice.referenceInfo">
      <Box className="flex flex-col gap-4 px-4 pb-4">
        {processingFoodInfo}
        {originDishInfo}
        <SchemaForm
          formData={{ price: 10000 }}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                price: {
                  mode: 'text-field',
                  label: intl.formatMessage({ id: 'setupPrice.priceInput' }),
                  placeholder: intl.formatMessage({
                    id: 'setupPrice.priceInputPl',
                  }),
                  InputLabelProps: { sx: { fontSize: 16, fontWeight: 700 } },
                  rules: {
                    required: true,
                    validate: (value) =>
                      value > 0
                        ? true
                        : intl.formatMessage({
                            id: 'setupPrice.priceInputInvalid',
                          }),
                  },
                },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    onClose();
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'confirm',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
          }}
        />
      </Box>
    </MuiDialog>
  );
};
export default UpdatePriceDialog;
