import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ResponseDataDetail } from '../../../common/types';
import { UnProcessingDishModel } from '../types';

const useUnProcessingDishDetail = (id?: string) => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isLoading } = useQuery<UnProcessingDishModel>({
    queryKey: [id, KEYS_QUERY.unProcessingDishDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<UnProcessingDishModel>>({
          url: API_PATHS.unProcessingDish.detail(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  return { data, isLoading };
};
export default useUnProcessingDishDetail;
