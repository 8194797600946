import { AddRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Button, Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import FilterBox from './FilterBox';
import {
  ORDER_DATA,
  OrderFilterModel,
  OrderItemDetail,
  OrderModelList,
  STATUS_ORDER,
} from './types';
import dayjs from 'dayjs';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import QueryString from 'qs';
const OrderListPage = () => {
  const { dispatch, fetchThunk, API_PATHS, confirmDialog, intl } =
    useGeneralHook();

  const hookPagination = usePaginationHook<OrderFilterModel>();

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;

  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<OrderModelList>
  >({
    queryKey: [KEYS_QUERY.order, params],
    queryFn: async () => {
      // const json = await dispatch(
      //   fetchThunk<
      //     ResponseDataList<orderModelList>,
      //     orderFilterModel
      //   >({
      //     url: API_PATHS.order.index(id),
      //     params: params,
      //   })
      // );
      // return json.data;
      return ORDER_DATA as any;
    },
    enabled: !!params.filters.customer,
  });

  const deleteMutation = useMutation({
    mutationFn: async (item: OrderModelList) => {
      await confirmDialog.promptConfirmation({
        title: intl.formatMessage({ id: 'confirm' }),
        content: intl.formatMessage(
          { id: 'deleteConfirm' },
          { name: item.codeSetOrder }
        ),
        onAccept: async () => {
          await dispatch(
            fetchThunk({
              url: API_PATHS.order.detail(item.id),
              method: 'delete',
            })
          );
          refetch();
        },
      });
    },
  });

  return (
    <>
      <Paper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 1,
            pb: 0,
          }}
        >
          <FilterBox hookPagination={hookPagination} />
          <MyLink to={'1'} sx={{ alignSelf: 'end' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddRounded />}
            >
              <FormattedMessage id="add" />
            </Button>
          </MyLink>
        </Box>
        <MuiTable<OrderModelList>
          data={data?.data ?? []}
          state={{
            isLoading: isLoading,
            sorting,
          }}
          onSortingChange={onSortingChange}
          paginationProps={{
            count: data?.meta.total,
            page: data?.meta.currentPage,
            rowsPerPage: data?.meta.perPage,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
          }}
          enableExpanding
          enableRowActions
          renderRowActions={({ row }) => [
            <MyLink
              to={{
                pathname: params.filters.customer?.id + '',
                search: QueryString.stringify({
                  serveDate: row.original.serveDate.toString(),
                }),
              }}
            >
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              >
                <EditRounded />
              </IconButtonTooltip>
            </MyLink>,
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="delete" /> }}
              onClick={() => deleteMutation.mutateAsync(row.original)}
            >
              <DeleteRounded />
            </IconButtonTooltip>,
          ]}
          muiDetailPanelProps={() => ({
            sx: (theme) => ({
              p: 0,
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,210,244,0.1)'
                  : 'rgba(0,0,0,0.1)',
            }),
          })}
          renderDetailPanel={({ row }) => {
            return (
              <MuiTable<OrderItemDetail>
                data={row.original.listOrder ?? []}
                columns={[
                  {
                    accessorKey: 'id',
                    header: 'id',
                    Header: () => <FormattedMessage id="id" />,
                    size: 40,
                  },
                  {
                    accessorKey: 'codeOrder',
                    header: 'codeOrder',
                    Header: () => <FormattedMessage id="order.codeOrder" />,
                  },
                  {
                    accessorKey: 'channelDistribution',
                    header: 'channelDistribution',
                    Header: () => (
                      <FormattedMessage id="order.channelDistribution" />
                    ),
                  },
                  {
                    accessorKey: 'orderClassifications',
                    header: 'orderClassifications',
                    Header: () => (
                      <FormattedMessage id="order.orderClassifications" />
                    ),
                  },
                  {
                    accessorKey: 'status',
                    header: 'status',
                    Header: () => <FormattedMessage id="status" />,
                  },
                ]}
              />
            );
          }}
          columns={[
            {
              accessorKey: 'id',
              header: 'id',
              enableSorting: true,
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'serveDate',
              header: 'serveDate',
              Header: () => <FormattedMessage id="order.serveDate" />,
              Cell: ({ row }) => {
                return (
                  row.original.serveDate &&
                  dayjs(row.original.serveDate).format('LL')
                );
              },
            },
            {
              accessorKey: 'codeSetOrder',
              header: 'codeSetOrder',
              enableSorting: true,
              Header: () => <FormattedMessage id="order.codeSetOrder" />,
            },
            {
              accessorKey: 'status',
              header: 'status',
              Header: () => <FormattedMessage id="status" />,
              Cell: ({ renderedCellValue }) => {
                const status = STATUS_ORDER.find(
                  (v) => v.id === renderedCellValue
                );

                return (
                  status && (
                    <Chip
                      label={<FormattedMessage id={status.name} />}
                      variant="outlined"
                      color={status.color as any}
                      size="small"
                    />
                  )
                );
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};
export default OrderListPage;
