import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { ProcessingFoodModel } from '../../../processing-food/types';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import ProcessingFoodInfoBox from '../Step1/ProcessingFoodInfoBox';
import { StandardFoodModel } from '../../../standard-food/types';

interface Props {
  processingFood: ProcessingFoodModel;
  standardFood: StandardFoodModel;
}
const ConfirmDataBox = (props: Props) => {
  const { processingFood, standardFood } = props;
  const { listType } = useGeneralHook();
  return (
    <>
      <ProcessingFoodInfoBox
        data={processingFood}
        boxProps={{ className: 'tablet:columns-2' }}
      />

      <Box className="tablet:columns-2 mt-4">
        {[
          {
            label: 'standardFood.name',
            value: standardFood.name,
          },
          {
            label: 'standardFood.operationCode',
            value: standardFood.operationCode,
          },
          {
            label: 'standardFood.sourceType',
            value: listType.TPTC?.sourceType?.find(
              (v) => v.id === standardFood.sourceType
            )?.name,
          },
          {
            label: 'standardFood.type',
            value: listType.TPTC?.type?.find((v) => v.id === standardFood.type)
              ?.name,
          },
          {
            label: 'standardFood.usageRatio',
            value: standardFood.usageRatio,
          },
          {
            label: 'standardFood.protein',
            value: standardFood.protein,
          },
          {
            label: 'standardFood.lipid',
            value: standardFood.lipid,
          },
          {
            label: 'standardFood.calorie',
            value: standardFood.calorie,
          },
        ]?.map((item, index) => {
          return (
            <Box className="flex break-inside-avoid mb-1 italic" key={index}>
              <Typography
                variant="subtitle2"
                component={'div'}
                flexShrink={0}
                color={'inherit'}
                className="whitespace-nowrap"
              >
                <FormattedMessage id={item.label} />
              </Typography>
              <Typography
                variant="body2"
                color={'inherit'}
                sx={{ wordBreak: 'break-all', display: 'flex' }}
              >
                {item.value ? <>:&nbsp;{item.value}</> : item.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default ConfirmDataBox;
