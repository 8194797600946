import {
  CalculateRounded,
  CheckCircleOutlineRounded,
  Dashboard,
  FilterFramesOutlined,
  FilterFramesRounded,
  FoodBankRounded,
  PriceChange,
  PriceChangeRounded,
  RouteRounded,
} from '@mui/icons-material';
import { Navigate } from 'react-router';
import { IRouteObject } from '../common/types';
import NotFoundPage from '../modules/NotFoundPage';
import GroupFoodDetailPage from '../modules/group-food/detail/GroupFoodDetailPage';
import GroupFoodPage from '../modules/group-food/list/GroupFoodPage';
import StandardFoodPage from '../modules/standard-food/list/StandardFoodPage';
import ProfilePage from '../modules/profile/ProfilePage';
import QualityStandardDetailPage from '../modules/quality-standard/detail/QualityStandardDetailPage';
import QualityStandardPage from '../modules/quality-standard/list/QualityStandardPage';
import {
  CalculateUnit,
  CommodityClassifications,
  Documentation,
  FoodMenuIcon,
  OriginDishes,
  ProgressedFoodIcon,
  QualityFood,
  QualityStandard,
  UnProgressedFoodIcon,
} from '../svg';
import { ROUTES_PATH } from './constants';
import StandardFoodDetailPage from '../modules/standard-food/detail/StandardFoodDetailPage';
import CommodityClassificationsPage from '../modules/commodity-classifications/list/CommodityClassificationsPage';
import CommodityClassificationsDetailPage from '../modules/commodity-classifications/detail/CommodityClassificationsDetailPage';
import CommodityGroupsDetailPage from '../modules/commodity-groups/detail/CommodityGroupsDetailPage';
import CommodityGroupsPage from '../modules/commodity-groups/list/CommodityGroupsPage';
import ChannelDistributionsPage from '../modules/channel-distributions/list/ChannelDistributionsPage';
import ChannelDistributionsDetailPage from '../modules/channel-distributions/detail/ChannelDistributionsDetailPage';
import ChannelDistributionsDetailOutlet from '../modules/channel-distributions/detail/ChannelDistributionsDetailOutlet';
import CommodityClassificationsDetailOutlet from '../modules/commodity-classifications/detail/CommodityClassificationsDetailOutlet';
import UnitsPage from '../modules/units/list/UnitsPage';
import UnitsDetailPage from '../modules/units/detail/UnitsDetailPage';
import UnitsDetailOutlet from '../modules/units/detail/UnitsDetailOutlet';
import OrderClassificationsPage from '../modules/order-classifications/list/OrderClassificationsPage';
import OrderClassificationsDetailPage from '../modules/order-classifications/detail/OrderClassificationsDetailPage';
import GroupFoodDetailOutlet from '../modules/group-food/detail/GroupFoodDetailOutlet';
import { Alert, Typography } from '@mui/material';
import OrderListPage from '../modules/orders/list/OrderListPage';
import OrderDetailPage from '../modules/orders/detail/OrderDetailPage';
import PriceQuantitativePage from '../modules/origin-dish/list/PriceQuantitativePage';
import OriginDishDetailPage from '../modules/origin-dish/detail/OriginDishDetailPage';
import ProcessingFoodListPage from '../modules/processing-food/list/ProcessingFoodListPage';
import ProcessingFoodDetail from '../modules/processing-food/detail/ProcessingFoodDetail';
import ProcessingDishListPage from '../modules/processing-dish/list/ProcessingDishListPage';
import ProcessingDishDetailPage from '../modules/processing-dish/detail/ProcessingDishDetailPage';
import ProcessingDishQuotaDetailPage from '../modules/processing-dish/quota/ProcessingDishQuotaDetailPage';
import ProcessingFoodConfirmListPage from '../modules/processing-food-confirm/list/ProcessingFoodConfirmListPage';
import ProcessingFoodConfirmPage from '../modules/processing-food-confirm/detail/ProcessingFoodConfirmPage';
import ProcessingDishConfirmListPage from '../modules/processing-dish-confirm/list/ProcessingDishConfirmListPage';
import ProcessingDishConfirmPage from '../modules/processing-dish-confirm/detail/ProcessingDishConfirmPage';
import UnProcessingDishPage from '../modules/un-processing-dish/list/UnProcessingDishPage';
import UnProcessingDishDetailPage from '../modules/un-processing-dish/detail/UnProcessingDishDetailPage';
import PriceSettingPage from '../modules/price-setting/PriceSettingPage';
import SetupPriceProcessingFoodTab from '../modules/price-setting/processing-food/SetupPriceProcessingFoodTab';
import SetupPriceUnProcessingDishTab from '../modules/price-setting/unprocessing-dish/SetupPriceUnProcessingDishTab';

const BuildingModule = () => {
  return (
    <Alert variant="filled" severity="info" sx={{ width: 320 }}>
      <Typography>{'Tính năng đang được xây dựng'}</Typography>
    </Alert>
  );
};
export const PROJECT_LIST_ROUTES: IRouteObject[] = [
  {
    title: 'profile',
    path: ROUTES_PATH.profile,
    element: <ProfilePage />,
    hidden: true,
  },
  {
    path: '',
    element: <Navigate to={ROUTES_PATH.dashboard} replace />,
    hidden: true,
  },
  {
    title: 'dashboard.title',
    path: ROUTES_PATH.dashboard,
    icon: <Dashboard />,
    element: <div>Dashboard</div>,
  },
  {
    title: 'list',
    path: ROUTES_PATH.list.index,
    children: [
      {
        title: 'qualityStandard.title',
        label: 'qualityStandard.label',
        path: ROUTES_PATH.qualityStandard.index,
        icon: <QualityStandard />,
        children: [
          {
            index: true,
            element: <QualityStandardPage />,
          },
          {
            hidden: true,
            label: 'qualityStandard.create',
            path: ROUTES_PATH.qualityStandard.create,
            element: <QualityStandardDetailPage />,
          },
          {
            hidden: true,
            label: 'qualityStandard.detail',
            path: ROUTES_PATH.qualityStandard.detail,
            element: <QualityStandardDetailPage />,
          },
        ],
      },
      {
        title: 'groupFood.title',
        label: 'groupFood.label',
        path: ROUTES_PATH.groupFood.index,
        icon: <QualityFood />,
        children: [
          {
            index: true,
            element: <GroupFoodPage />,
          },
          {
            hidden: true,
            label: 'groupFood.create',
            path: ROUTES_PATH.groupFood.create,
            element: <GroupFoodDetailPage />,
          },
          {
            hidden: true,
            label: 'groupFood.detail',
            path: ROUTES_PATH.groupFood.detail,
            portalId: 'groupFoodBreadcrumb',
            element: <GroupFoodDetailOutlet />,
            children: [
              {
                index: true,
                element: <GroupFoodDetailPage />,
              },
              {
                title: 'standardFood.title',
                label: 'standardFood.label',
                icon: <QualityFood />,
                path: ROUTES_PATH.standardFood.index,
                children: [
                  {
                    index: true,
                    element: <StandardFoodPage />,
                  },
                  {
                    label: 'standardFood.create',
                    path: ROUTES_PATH.standardFood.create,
                    element: <StandardFoodDetailPage />,
                  },
                  {
                    label: 'standardFood.detail',
                    path: ROUTES_PATH.standardFood.detail,
                    element: <StandardFoodDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'originDish.title',
        label: 'originDish.label',
        path: ROUTES_PATH.originDish.index,
        icon: <OriginDishes />,
        children: [
          {
            index: true,
            element: <PriceQuantitativePage />,
          },
          {
            label: 'originDish.create',
            path: ROUTES_PATH.originDish.create,
            element: <OriginDishDetailPage />,
          },
          {
            label: 'originDish.detail',
            path: ROUTES_PATH.originDish.detail,
            element: <OriginDishDetailPage />,
          },
        ],
      },

      {
        title: 'commodityClassifications.title',
        label: 'commodityClassifications.label',
        path: ROUTES_PATH.commodityClassifications.index,
        icon: <CommodityClassifications />,
        children: [
          {
            index: true,
            element: <CommodityClassificationsPage />,
          },
          {
            hidden: true,
            title: 'commodityClassifications.create',
            path: ROUTES_PATH.commodityClassifications.create,
            element: <CommodityClassificationsDetailPage />,
          },
          {
            hidden: true,
            title: 'commodityClassifications.detail',
            path: ROUTES_PATH.commodityClassifications.detail,
            portalId: 'commodityGroupBreadcrumb',
            element: <CommodityClassificationsDetailOutlet />,
            children: [
              { index: true, element: <CommodityClassificationsDetailPage /> },
              {
                title: 'commodityGroups.title',
                icon: <QualityFood />,
                path: ROUTES_PATH.commodityGroups.index,
                children: [
                  {
                    index: true,
                    element: <CommodityGroupsPage />,
                  },
                  {
                    label: 'commodityGroups.create',
                    path: ROUTES_PATH.commodityGroups.create,
                    element: <CommodityGroupsDetailPage />,
                  },
                  {
                    label: 'commodityGroups.detail',
                    path: ROUTES_PATH.commodityGroups.detail,
                    element: <CommodityGroupsDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'units.title',
        label: 'units.label',
        path: ROUTES_PATH.units.index,
        icon: <CalculateUnit />,
        children: [
          {
            index: true,
            element: <UnitsPage />,
          },
          {
            hidden: true,
            title: 'units.create',
            path: ROUTES_PATH.units.create,
            element: <UnitsDetailPage />,
          },
          {
            hidden: true,
            title: 'units.detail',
            path: ROUTES_PATH.units.detail,
            element: <UnitsDetailOutlet />,
            portalId: 'unitsBreadcrumb',
            children: [{ index: true, element: <UnitsDetailPage /> }],
          },
        ],
      },
      {
        title: 'channelDistributions.title',
        label: 'channelDistributions.label',
        path: ROUTES_PATH.channelDistributions.index,
        icon: <RouteRounded />,
        children: [
          {
            index: true,
            element: <ChannelDistributionsPage />,
          },
          {
            hidden: true,
            title: 'channelDistributions.create',
            path: ROUTES_PATH.channelDistributions.create,
            element: <ChannelDistributionsDetailPage />,
          },
          {
            hidden: true,
            title: 'channelDistributions.detail',
            path: ROUTES_PATH.channelDistributions.detail,
            portalId: 'channelDistributionBreadcrumb',
            element: <ChannelDistributionsDetailOutlet />,
            children: [
              { index: true, element: <ChannelDistributionsDetailPage /> },
              {
                title: 'orderClassifications.title',
                path: ROUTES_PATH.orderClassifications.index,
                children: [
                  {
                    index: true,
                    element: <OrderClassificationsPage />,
                  },
                  {
                    label: 'orderClassifications.create',
                    path: ROUTES_PATH.orderClassifications.create,
                    element: <OrderClassificationsDetailPage />,
                  },
                  {
                    label: 'orderClassifications.detail',
                    path: ROUTES_PATH.orderClassifications.detail,
                    element: <OrderClassificationsDetailPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.techniques.index,
    title: 'techniques.title',
    children: [
      {
        path: ROUTES_PATH.processingFood.index,
        title: 'processingFood.title',
        label: 'processingFood.label',
        icon: <ProgressedFoodIcon />,
        children: [
          {
            index: true,
            element: <ProcessingFoodListPage />,
          },
          {
            label: 'processingFood.create',
            path: ROUTES_PATH.processingFood.create,
            element: <ProcessingFoodDetail />,
          },
          {
            label: 'processingFood.detail',
            path: ROUTES_PATH.processingFood.detail,
            element: <ProcessingFoodDetail />,
          },
        ],
      },

      {
        path: ROUTES_PATH.processingDish.index,
        title: 'processingDish.title',
        label: 'processingDish.label',
        icon: <FoodBankRounded />,
        children: [
          {
            index: true,
            element: <ProcessingDishListPage />,
          },
          {
            label: 'processingDish.create',
            path: ROUTES_PATH.processingDish.create,
            element: <ProcessingDishDetailPage />,
          },
          {
            label: 'processingDish.detail',
            path: ROUTES_PATH.processingDish.detail,
            element: <ProcessingDishDetailPage />,
          },
          {
            portalId: 'updateDishQuotaBreadcrumb',
            path: ROUTES_PATH.processingDish.quota,
            element: <ProcessingDishQuotaDetailPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.norms.index,
    title: 'norms.title',
    children: [
      {
        path: ROUTES_PATH.processingFoodConfirm.index,
        title: 'processedFoodConfirm.title',
        label: 'processedFoodConfirm.label',
        icon: <CheckCircleOutlineRounded />,
        children: [
          {
            index: true,
            element: <ProcessingFoodConfirmListPage />,
          },
          {
            portalId: 'confirmProcessedFoodBreadcrumb',
            path: ROUTES_PATH.processingFoodConfirm.detail,
            element: <ProcessingFoodConfirmPage />,
          },
        ],
      },
      {
        path: ROUTES_PATH.processingDishConfirm.index,
        title: 'processingDishConfirm.title',
        label: 'processingDishConfirm.label',
        icon: <CalculateRounded />,
        children: [
          {
            index: true,
            element: <ProcessingDishConfirmListPage />,
          },
          {
            label: 'processingDishConfirm.confirm',
            path: ROUTES_PATH.processingDishConfirm.detail,
            element: <ProcessingDishConfirmPage />,
          },
        ],
      },
      {
        path: ROUTES_PATH.unProcessingDish.index,
        title: 'unProcessingDish.title',
        label: 'unProcessingDish.label',
        icon: <UnProgressedFoodIcon />,
        children: [
          {
            index: true,
            element: <UnProcessingDishPage />,
          },
          {
            label: 'unProcessingDish.create',
            path: ROUTES_PATH.unProcessingDish.create,
            element: <UnProcessingDishDetailPage />,
          },
          {
            label: 'unProcessingDish.detail',
            path: ROUTES_PATH.unProcessingDish.detail,
            element: <UnProcessingDishDetailPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.price.index,
    title: 'price.title',
    children: [
      {
        path: ROUTES_PATH.setupPrice.index,
        title: 'setupPrice.title',
        label: 'setupPrice.processingFood',
        icon: <PriceChangeRounded />,
        element: <PriceSettingPage />,
        children: [
          {
            index: true,
            element: <SetupPriceProcessingFoodTab />,
          },
          {
            label: 'setupPrice.processingFood',
            path: ROUTES_PATH.processingFood.index,
            element: <SetupPriceProcessingFoodTab />,
          },
          {
            label: 'setupPrice.unProcessingDish',
            path: ROUTES_PATH.unProcessingDish.index,
            element: <SetupPriceUnProcessingDishTab />,
          },
        ],
      },
      {
        path: ROUTES_PATH.quantitative.index,
        title: 'quantitative.title',
        label: 'quantitative.label',
        icon: <FilterFramesRounded />,
        children: [
          {
            index: true,
            element: <PriceQuantitativePage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.menu.index,
    title: 'menu.title',
    children: [
      {
        path: ROUTES_PATH.order.index,
        title: 'order.title',
        label: 'order.label',
        icon: <FoodMenuIcon />,
        children: [
          {
            index: true,

            element: <OrderListPage />,
          },
          {
            path: ROUTES_PATH.order.detail,
            title: 'order.detail',
            element: <OrderDetailPage />,
            collapseWhenIn: ({ isTablet }) => isTablet,
          },
        ],
      },
    ],
  },
  {
    path: ROUTES_PATH.documentation.index,
    title: 'documentation.title',
    label: 'documentation.label',
    icon: <Documentation />,
    element: <BuildingModule />,
  },
  { path: ROUTES_PATH[404], element: <NotFoundPage />, hidden: true },
  {
    path: '*',
    element: <Navigate replace to={ROUTES_PATH[404]} />,
    hidden: true,
  },
];
