import {
  Button,
  ButtonProps,
  IconButton,
  Tooltip,
  TooltipProps,
} from '@mui/material';

interface Props extends ButtonProps {
  tooltipProps?: Omit<TooltipProps, 'children'>;
}
const IconButtonTooltip = (props: Props) => {
  const { tooltipProps, disabled, ...rest } = props;
  if (tooltipProps && !disabled)
    return (
      <Tooltip {...tooltipProps}>
        <IconButton color="primary" {...rest} />
      </Tooltip>
    );
  return <IconButton disabled={disabled} {...rest} />;
};
export default IconButtonTooltip;
