import { Navigate, Outlet, useParams } from 'react-router';
import { ROUTES_PATH } from '../../../layout/constants';
import useCommodityClassificationsDetail from './useCommodityClassificationsDetail';
import { Portal } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const CommodityClassificationsDetailOutlet = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useCommodityClassificationsDetail(id);

  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }
  const container = document.getElementById('commodityGroupBreadcrumb');
  return (
    <>
      {id && container && (
        <Portal container={container}>
          {data?.name || (
            <FormattedMessage id="commodityClassifications.detail" />
          )}
        </Portal>
      )}
      <Outlet />
    </>
  );
};
export default CommodityClassificationsDetailOutlet;
