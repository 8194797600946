import { OrderDetailModel } from '../detail/types';

export interface OrderFilterModel {
  customer?: { id: number; name: string };
  status?: string;
}

export interface OrderItemDetail {
  id: number;
  codeOrdg;
  channelDistribution: string;
  orderClassifications: string;
  status: string;
}
export interface OrderModelList {
  id: number;
  serveDate: string;
  codeSetOrder: string;
  status: string;
  listOrder: OrderItemDetail[];
}
export const STATUS_ORDER = [
  { id: 'initial', name: 'Khởi tạo', color: 'info' },
  { id: 'completed', name: 'Đã hoàn thành', color: 'success' },
  { id: 'process', name: 'Đang thực hiện', color: 'warning' },
];
export const ORDER_DATA: { data: OrderDetailModel[]; meta: any } = {
  data: [
    {
      id: 1,
      serveDate: '2024-06-24',
      codeSetOrder: 'BTD-KH-VHC2-20240624',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-21',
      createdAt: '2024-06-01',
      createdBy: 'Trần Hà An',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '1.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240624',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 1,
              name: 'Cá ba sa tẩm bột chiên xù 60g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 22,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 23,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 24,
                  name: 'Bột chiên xù',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.09,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 25,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 26,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 27,
                  name: 'Dầu ăn',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 28,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 29,
                  name: 'Fille basa t1',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.67,
                },
                {
                  id: 30,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 31,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 32,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 33,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 34,
                  name: 'Mỳ chính',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 35,
                  name: 'Ngũ vị hương',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 36,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 2,
              name: 'Thịt lợn om ngô 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 317,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 318,
                  name: 'Dầu hào 700ml',
                  amount: 0.35,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 319,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 320,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 321,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 322,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 323,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 324,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 325,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 326,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 327,
                  name: 'Ngô bắp cả vỏ',
                  amount: 50.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 328,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 329,
                  name: 'Thịt lợn nạc',
                  amount: 30.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 3,
              name: 'Bắp cảI xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 1,
                  name: 'Bắp cảI',
                  amount: 70.0,
                  usageRatio: 90,
                  protein: 0.0162,
                  lipid: 0.00081,
                  glucid: 0.06219,
                  calorie: 0.324,
                },
                {
                  id: 2,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 3,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 4,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 5,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 6,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 7,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 8,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 9,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 10,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 11,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 4,
              name: 'Canh bí nấu xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 62,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 63,
                  name: 'Bí xanh cả vỏ',
                  amount: 40.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 64,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 65,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 66,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 67,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 68,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 69,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 70,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 71,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 72,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 5,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '1.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240624',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 6,
              name: 'Cá ba sa tẩm bột chiên xù 60g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 22,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 23,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 24,
                  name: 'Bột chiên xù',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.09,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 25,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 26,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 27,
                  name: 'Dầu ăn',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 28,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 29,
                  name: 'Fille basa t1',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.67,
                },
                {
                  id: 30,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 31,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 32,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 33,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 34,
                  name: 'Mỳ chính',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 35,
                  name: 'Ngũ vị hương',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 36,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 7,
              name: 'Thịt lợn om ngô 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 317,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 318,
                  name: 'Dầu hào 700ml',
                  amount: 0.35,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 319,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 320,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 321,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 322,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 323,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 324,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 325,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 326,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 327,
                  name: 'Ngô bắp cả vỏ',
                  amount: 50.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 328,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 329,
                  name: 'Thịt lợn nạc',
                  amount: 30.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 8,
              name: 'Bắp cảI xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 1,
                  name: 'Bắp cảI',
                  amount: 70.0,
                  usageRatio: 90,
                  protein: 0.0162,
                  lipid: 0.00081,
                  glucid: 0.06219,
                  calorie: 0.324,
                },
                {
                  id: 2,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 3,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 4,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 5,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 6,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 7,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 8,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 9,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 10,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 11,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 9,
              name: 'Canh bí nấu xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 62,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 63,
                  name: 'Bí xanh cả vỏ',
                  amount: 40.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 64,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 65,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 66,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 67,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 68,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 69,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 70,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 71,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 72,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 10,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '1.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240624',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 11,
              name: 'Sữa chua ăn mộc châu milk có đường',
              usageRatio: 100,
              ingredient: [],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      serveDate: '2024-06-25',
      codeSetOrder: 'BTD-KH-VHC2-20240625',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-21',
      createdAt: '2024-06-01',
      createdBy: 'Trần Hà An',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '2.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240625',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 12,
              name: 'Thịt lợn sốt cà chua 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 345,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 346,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 347,
                  name: 'Cà chua',
                  amount: 10.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.0021850000000000003,
                  glucid: 0.045315,
                  calorie: 0.22799999999999998,
                },
                {
                  id: 348,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 349,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 350,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 351,
                  name: 'Hành lá',
                  amount: 0.4,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 352,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 353,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 354,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 355,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 356,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 357,
                  name: 'Nước mắm',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 358,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 13,
              name: 'Chả lụa rim tiêu 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 138,
                  name: 'Bột canh ( hc)',
                  amount: 0.32,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 139,
                  name: 'Chả lụa hvs',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.154,
                  lipid: 0.423,
                  glucid: 0.009,
                  calorie: 4.460999999999999,
                },
                {
                  id: 140,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 141,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 142,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 143,
                  name: 'Hạt nêm',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.12999999999999998,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.585,
                },
                {
                  id: 144,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 145,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 146,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 147,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 14,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 15,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 16,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '2.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240625',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 17,
              name: 'Thịt lợn sốt cà chua 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 345,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 346,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 347,
                  name: 'Cà chua',
                  amount: 10.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.0021850000000000003,
                  glucid: 0.045315,
                  calorie: 0.22799999999999998,
                },
                {
                  id: 348,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 349,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 350,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 351,
                  name: 'Hành lá',
                  amount: 0.4,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 352,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 353,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 354,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 355,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 356,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 357,
                  name: 'Nước mắm',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 358,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 18,
              name: 'Chả lụa rim tiêu 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 138,
                  name: 'Bột canh ( hc)',
                  amount: 0.32,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 139,
                  name: 'Chả lụa hvs',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.154,
                  lipid: 0.423,
                  glucid: 0.009,
                  calorie: 4.460999999999999,
                },
                {
                  id: 140,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 141,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 142,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 143,
                  name: 'Hạt nêm',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.12999999999999998,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.585,
                },
                {
                  id: 144,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 145,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 146,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 147,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 19,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 20,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 21,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '2.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240625',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 22,
              name: 'Bánh mỳ kem th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 399,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.077,
                  lipid: 0.153,
                  glucid: 0.542,
                  calorie: 3.853,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      serveDate: '2024-06-26',
      codeSetOrder: 'BTD-KH-VHC2-20240626',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-21',
      createdAt: '2024-06-01',
      createdBy: 'Trần Hà An',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '3.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240626',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 23,
              name: 'Thịt gà lắc pho mai 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 250,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 251,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 252,
                  name: 'Bột đao',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 253,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 254,
                  name: 'Dầu ăn',
                  amount: 12.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 255,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 256,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 257,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 258,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 259,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 260,
                  name: 'Ngũ vị hương',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 261,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 262,
                  name: 'Pho mai lắc',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.477,
                  lipid: 0.305,
                  glucid: 0.0,
                  calorie: 4.64,
                },
                {
                  id: 263,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 264,
                  name: 'Ức phi lê không da',
                  amount: 55.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -9.999999999999999e-5,
                  calorie: 1.34,
                },
                {
                  id: 265,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
              ],
            },
            {
              id: 24,
              name: 'Đậu phụ tứ xuyên',
              usageRatio: 100,
              ingredient: [
                {
                  id: 182,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 183,
                  name: 'Bột đao',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 184,
                  name: 'Cà chua',
                  amount: 7.0,
                  usageRatio: 95,
                  protein: 0.005699999999999999,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 185,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 186,
                  name: 'Đậu phụ',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 187,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 188,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 189,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 190,
                  name: 'Hạt đIều màu',
                  amount: 0.1,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 191,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 192,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 193,
                  name: 'Mộc nhĩ',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0954,
                  lipid: 0.0018,
                  glucid: 0.6479999999999999,
                  calorie: 2.988,
                },
                {
                  id: 194,
                  name: 'MuốI hạt',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 195,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 196,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 197,
                  name: 'Thịt vai, mông',
                  amount: 4.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 198,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 199,
                  name: 'Magi 700ml',
                  amount: 1.4,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 25,
              name: 'Bí xanh xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 12,
                  name: 'Bí xanh cả vỏ',
                  amount: 80.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 13,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 14,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 15,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 16,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 17,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 18,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 19,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 20,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 21,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 26,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 27,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '3.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240626',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 28,
              name: 'Thịt gà lắc pho mai 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 250,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 251,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 252,
                  name: 'Bột đao',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 253,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 254,
                  name: 'Dầu ăn',
                  amount: 12.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 255,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 256,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 257,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 258,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 259,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 260,
                  name: 'Ngũ vị hương',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 261,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 262,
                  name: 'Pho mai lắc',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.477,
                  lipid: 0.305,
                  glucid: 0.0,
                  calorie: 4.64,
                },
                {
                  id: 263,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 264,
                  name: 'Ức phi lê không da',
                  amount: 55.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -9.999999999999999e-5,
                  calorie: 1.34,
                },
                {
                  id: 265,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
              ],
            },
            {
              id: 29,
              name: 'Đậu phụ tứ xuyên',
              usageRatio: 100,
              ingredient: [
                {
                  id: 182,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 183,
                  name: 'Bột đao',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 184,
                  name: 'Cà chua',
                  amount: 7.0,
                  usageRatio: 95,
                  protein: 0.005699999999999999,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 185,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 186,
                  name: 'Đậu phụ',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 187,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 188,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 189,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 190,
                  name: 'Hạt đIều màu',
                  amount: 0.1,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 191,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 192,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 193,
                  name: 'Mộc nhĩ',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0954,
                  lipid: 0.0018,
                  glucid: 0.6479999999999999,
                  calorie: 2.988,
                },
                {
                  id: 194,
                  name: 'MuốI hạt',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 195,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 196,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 197,
                  name: 'Thịt vai, mông',
                  amount: 4.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 198,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 199,
                  name: 'Magi 700ml',
                  amount: 1.4,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 30,
              name: 'Bí xanh xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 12,
                  name: 'Bí xanh cả vỏ',
                  amount: 80.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 13,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 14,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 15,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 16,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 17,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 18,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 19,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 20,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 21,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 31,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 32,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '3.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240626',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 33,
              name: 'ChuốI tây',
              usageRatio: 100,
              ingredient: [
                {
                  id: 400,
                  name: '',
                  amount: 120.0,
                  usageRatio: 76,
                  protein: 0.00684,
                  lipid: 0.00228,
                  glucid: 0.114,
                  calorie: 0.5016,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      serveDate: '2024-06-27',
      codeSetOrder: 'BTD-KH-VHC2-20240627',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-21',
      createdAt: '2024-06-01',
      createdBy: 'Trần Hà An',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '4.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240627',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 34,
              name: 'Cá ba sa tẩm bột chiên xù 90g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 37,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 38,
                  name: 'Bột chiên giòn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 39,
                  name: 'Bột chiên xù',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.09000000000000001,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 40,
                  name: 'Bột mỳ',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 41,
                  name: 'Bột nghệ',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051,
                  glucid: 0.721,
                  calorie: 3.6000000000000005,
                },
                {
                  id: 42,
                  name: 'Dầu ăn',
                  amount: 18.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 43,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 44,
                  name: 'Fille basa t1',
                  amount: 90.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.6699999999999999,
                },
                {
                  id: 45,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 46,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 47,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 48,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 49,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 50,
                  name: 'Ngũ vị hương',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 51,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 35,
              name: 'Thịt lợn hầm khoai tây cà rốt 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 280,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 281,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 282,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 283,
                  name: 'Cần tây',
                  amount: 0.2,
                  usageRatio: 84,
                  protein: 0.03108,
                  lipid: 0.0013439999999999997,
                  glucid: 0.07929599999999999,
                  calorie: 0.45359999999999995,
                },
                {
                  id: 284,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 285,
                  name: 'Gừng',
                  amount: 0.3,
                  usageRatio: 90,
                  protein: 0.0036000000000000003,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.36900000000000005,
                },
                {
                  id: 286,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 287,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 288,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 289,
                  name: 'Khoai tây cả vỏ',
                  amount: 40.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 290,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 291,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 292,
                  name: 'Thịt nạc mông nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.2468,
                  lipid: 0.0472,
                  glucid: 0.0,
                  calorie: 1.41,
                },
                {
                  id: 293,
                  name: 'Thịt nạc vai nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.20829999999999999,
                  lipid: 0.07289999999999999,
                  glucid: -0.0002,
                  calorie: 1.49,
                },
              ],
            },
            {
              id: 36,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 37,
              name: 'Canh củ quả hầm xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 97,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 98,
                  name: 'Cà chua',
                  amount: 8.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 99,
                  name: 'Cà rốt cả vỏ',
                  amount: 7.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 100,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 101,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 102,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 103,
                  name: 'Khoai tây cả vỏ',
                  amount: 20.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 104,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 105,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 106,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 107,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 108,
                  name: 'Su su cả vỏ',
                  amount: 20.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 109,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 38,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '4.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240627',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 39,
              name: 'Cá ba sa tẩm bột chiên xù 90g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 37,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 38,
                  name: 'Bột chiên giòn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 39,
                  name: 'Bột chiên xù',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.09000000000000001,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 40,
                  name: 'Bột mỳ',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 41,
                  name: 'Bột nghệ',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051,
                  glucid: 0.721,
                  calorie: 3.6000000000000005,
                },
                {
                  id: 42,
                  name: 'Dầu ăn',
                  amount: 18.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 43,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 44,
                  name: 'Fille basa t1',
                  amount: 90.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.6699999999999999,
                },
                {
                  id: 45,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 46,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 47,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 48,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 49,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 50,
                  name: 'Ngũ vị hương',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 51,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 40,
              name: 'Thịt lợn hầm khoai tây cà rốt 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 280,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 281,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 282,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 283,
                  name: 'Cần tây',
                  amount: 0.2,
                  usageRatio: 84,
                  protein: 0.03108,
                  lipid: 0.0013439999999999997,
                  glucid: 0.07929599999999999,
                  calorie: 0.45359999999999995,
                },
                {
                  id: 284,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 285,
                  name: 'Gừng',
                  amount: 0.3,
                  usageRatio: 90,
                  protein: 0.0036000000000000003,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.36900000000000005,
                },
                {
                  id: 286,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 287,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 288,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 289,
                  name: 'Khoai tây cả vỏ',
                  amount: 40.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 290,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 291,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 292,
                  name: 'Thịt nạc mông nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.2468,
                  lipid: 0.0472,
                  glucid: 0.0,
                  calorie: 1.41,
                },
                {
                  id: 293,
                  name: 'Thịt nạc vai nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.20829999999999999,
                  lipid: 0.07289999999999999,
                  glucid: -0.0002,
                  calorie: 1.49,
                },
              ],
            },
            {
              id: 41,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 42,
              name: 'Canh củ quả hầm xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 97,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 98,
                  name: 'Cà chua',
                  amount: 8.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 99,
                  name: 'Cà rốt cả vỏ',
                  amount: 7.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 100,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 101,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 102,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 103,
                  name: 'Khoai tây cả vỏ',
                  amount: 20.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 104,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 105,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 106,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 107,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 108,
                  name: 'Su su cả vỏ',
                  amount: 20.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 109,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 43,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '4.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240627',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 44,
              name: 'Dưa hấu',
              usageRatio: 100,
              ingredient: [
                {
                  id: 401,
                  name: '',
                  amount: 120.0,
                  usageRatio: 52,
                  protein: 0.00624,
                  lipid: 0.00104,
                  glucid: 0.01456,
                  calorie: 0.09359999999999999,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 5,
      serveDate: '2024-06-28',
      codeSetOrder: 'BTD-KH-VHC2-20240628',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-21',
      createdAt: '2024-06-01',
      createdBy: 'Trần Hà An',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '5.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240628',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 45,
              name: 'Cơm rang thập cẩm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 150,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 151,
                  name: 'Cà rốt cả vỏ',
                  amount: 8.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 152,
                  name: 'Dầu ăn',
                  amount: 7.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 153,
                  name: 'Dưa chuột',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0076,
                  lipid: 0.000855,
                  glucid: 0.034295,
                  calorie: 0.17099999999999999,
                },
                {
                  id: 154,
                  name: 'Đỗ quả',
                  amount: 15.0,
                  usageRatio: 90,
                  protein: 0.045000000000000005,
                  lipid: 0.0,
                  glucid: 0.1287,
                  calorie: 0.693,
                },
                {
                  id: 155,
                  name: 'Gạo pc',
                  amount: 100.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 156,
                  name: 'Giò lụa hvs',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.17300000000000001,
                  lipid: 0.224,
                  glucid: 0.011000000000000001,
                  calorie: 2.753,
                },
                {
                  id: 157,
                  name: 'Hành khô cả vỏ',
                  amount: 0.8,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 158,
                  name: 'Hành lá',
                  amount: 1.0,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 159,
                  name: 'Hạt đIều màu',
                  amount: 1.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 160,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 161,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 162,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 163,
                  name: 'Ngô bắp cả vỏ',
                  amount: 30.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 164,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 165,
                  name: 'Xúc xích hvs',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.10600000000000001,
                  lipid: 0.376,
                  glucid: 0.022,
                  calorie: 3.8289999999999997,
                },
                {
                  id: 166,
                  name: 'Magi 700ml',
                  amount: 1.05,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 167,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 12.6,
                  usageRatio: 86,
                  protein: 0.109994,
                  lipid: 0.070262,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 46,
              name: 'Canh chua nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 82,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 83,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 84,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 85,
                  name: 'Đường trắng',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 86,
                  name: 'Giá đỗ',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 87,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 88,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 89,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 90,
                  name: 'Me quả',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 91,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 92,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 93,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 94,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 95,
                  name: 'Thịt vai, mông',
                  amount: 2.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 96,
                  name: 'Dứa xanh quả cả vỏ',
                  amount: 8.0,
                  usageRatio: 60,
                  protein: 0.0048,
                  lipid: 0.0,
                  glucid: 0.0438,
                  calorie: 0.192,
                },
              ],
            },
          ],
        },
        {
          id: '5.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240628',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 47,
              name: 'Cơm rang thập cẩm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 150,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 151,
                  name: 'Cà rốt cả vỏ',
                  amount: 8.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 152,
                  name: 'Dầu ăn',
                  amount: 7.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 153,
                  name: 'Dưa chuột',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0076,
                  lipid: 0.000855,
                  glucid: 0.034295,
                  calorie: 0.17099999999999999,
                },
                {
                  id: 154,
                  name: 'Đỗ quả',
                  amount: 15.0,
                  usageRatio: 90,
                  protein: 0.045000000000000005,
                  lipid: 0.0,
                  glucid: 0.1287,
                  calorie: 0.693,
                },
                {
                  id: 155,
                  name: 'Gạo pc',
                  amount: 100.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 156,
                  name: 'Giò lụa hvs',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.17300000000000001,
                  lipid: 0.224,
                  glucid: 0.011000000000000001,
                  calorie: 2.753,
                },
                {
                  id: 157,
                  name: 'Hành khô cả vỏ',
                  amount: 0.8,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 158,
                  name: 'Hành lá',
                  amount: 1.0,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 159,
                  name: 'Hạt đIều màu',
                  amount: 1.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 160,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 161,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 162,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 163,
                  name: 'Ngô bắp cả vỏ',
                  amount: 30.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 164,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 165,
                  name: 'Xúc xích hvs',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.10600000000000001,
                  lipid: 0.376,
                  glucid: 0.022,
                  calorie: 3.8289999999999997,
                },
                {
                  id: 166,
                  name: 'Magi 700ml',
                  amount: 1.05,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 167,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 12.6,
                  usageRatio: 86,
                  protein: 0.109994,
                  lipid: 0.070262,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 48,
              name: 'Canh chua nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 82,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 83,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 84,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 85,
                  name: 'Đường trắng',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 86,
                  name: 'Giá đỗ',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 87,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 88,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 89,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 90,
                  name: 'Me quả',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 91,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 92,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 93,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 94,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 95,
                  name: 'Thịt vai, mông',
                  amount: 2.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 96,
                  name: 'Dứa xanh quả cả vỏ',
                  amount: 8.0,
                  usageRatio: 60,
                  protein: 0.0048,
                  lipid: 0.0,
                  glucid: 0.0438,
                  calorie: 0.192,
                },
              ],
            },
          ],
        },
        {
          id: '5.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240628',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 49,
              name: 'Bánh chuốI mini th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 402,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.061000000000000006,
                  lipid: 0.152,
                  glucid: 0.197,
                  calorie: 2.395,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      serveDate: '2024-07-01',
      codeSetOrder: 'BTD-KH-VHC2-20240701',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-28',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '6.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240701',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 50,
              name: 'Thịt bò xào lúc lắc 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 236,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 237,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 238,
                  name: 'Dầu ăn',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 239,
                  name: 'Đỗ quả',
                  amount: 10.0,
                  usageRatio: 90,
                  protein: 0.045,
                  lipid: 0.0,
                  glucid: 0.12869999999999998,
                  calorie: 0.693,
                },
                {
                  id: 240,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 241,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 242,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 243,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 244,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 245,
                  name: 'Ngô bắp cả vỏ',
                  amount: 20.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 246,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 247,
                  name: 'Su su cả vỏ',
                  amount: 10.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 248,
                  name: 'Thịt bò',
                  amount: 50.0,
                  usageRatio: 98,
                  protein: 0.20579999999999998,
                  lipid: 0.03724,
                  glucid: 0.0,
                  calorie: 1.1564,
                },
                {
                  id: 249,
                  name: 'Magi 700ml',
                  amount: 0.49,
                  usageRatio: 100,
                  protein: 0.027500000000000004,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 51,
              name: 'Trứng đúc thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 370,
                  name: 'Dầu ăn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 371,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 372,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 373,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 374,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 375,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 376,
                  name: 'Thịt vai, mông',
                  amount: 10.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 377,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 52,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 53,
              name: 'Canh cảI xanh nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 73,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 74,
                  name: 'CảI xanh',
                  amount: 30.0,
                  usageRatio: 76,
                  protein: 0.01292,
                  lipid: 0.0010639999999999998,
                  glucid: 0.028576,
                  calorie: 0.17479999999999998,
                },
                {
                  id: 75,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 76,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 77,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 78,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 79,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 80,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 81,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 54,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 55,
              name: 'Tôm nõn tẩm bột chiên 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 359,
                  name: 'Bột canh ( hc)',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 360,
                  name: 'Bột chiên giòn',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 361,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 362,
                  name: 'Dầu ăn',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 363,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 364,
                  name: 'Hạt nêm',
                  amount: 0.07,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.5849999999999997,
                },
                {
                  id: 365,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 366,
                  name: 'Mỳ chính',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 367,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 368,
                  name: 'Thì là',
                  amount: 0.4,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
                {
                  id: 369,
                  name: 'Tôm nõn gẫy hấp cbm 60-80',
                  amount: 35.0,
                  usageRatio: 46,
                  protein: 0.08096,
                  lipid: 0.0041400000000000005,
                  glucid: 0.0041400000000000005,
                  calorie: 0.3772,
                },
              ],
            },
            {
              id: 56,
              name: 'Thịt lợn rán xá xíu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 330,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 331,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 332,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 333,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 334,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 335,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 336,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 337,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 338,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 339,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.07,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 340,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 341,
                  name: 'Ngũ vị hương',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 342,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 343,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 344,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 57,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 58,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 59,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '6.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240701',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 60,
              name: 'Thịt bò xào lúc lắc 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 236,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 237,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 238,
                  name: 'Dầu ăn',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 239,
                  name: 'Đỗ quả',
                  amount: 10.0,
                  usageRatio: 90,
                  protein: 0.045,
                  lipid: 0.0,
                  glucid: 0.12869999999999998,
                  calorie: 0.693,
                },
                {
                  id: 240,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 241,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 242,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 243,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 244,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 245,
                  name: 'Ngô bắp cả vỏ',
                  amount: 20.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 246,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 247,
                  name: 'Su su cả vỏ',
                  amount: 10.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 248,
                  name: 'Thịt bò',
                  amount: 50.0,
                  usageRatio: 98,
                  protein: 0.20579999999999998,
                  lipid: 0.03724,
                  glucid: 0.0,
                  calorie: 1.1564,
                },
                {
                  id: 249,
                  name: 'Magi 700ml',
                  amount: 0.49,
                  usageRatio: 100,
                  protein: 0.027500000000000004,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 61,
              name: 'Trứng đúc thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 370,
                  name: 'Dầu ăn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 371,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 372,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 373,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 374,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 375,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 376,
                  name: 'Thịt vai, mông',
                  amount: 10.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 377,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 62,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 63,
              name: 'Canh cảI xanh nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 73,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 74,
                  name: 'CảI xanh',
                  amount: 30.0,
                  usageRatio: 76,
                  protein: 0.01292,
                  lipid: 0.0010639999999999998,
                  glucid: 0.028576,
                  calorie: 0.17479999999999998,
                },
                {
                  id: 75,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 76,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 77,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 78,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 79,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 80,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 81,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 64,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 65,
              name: 'Tôm nõn tẩm bột chiên 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 359,
                  name: 'Bột canh ( hc)',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 360,
                  name: 'Bột chiên giòn',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 361,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 362,
                  name: 'Dầu ăn',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 363,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 364,
                  name: 'Hạt nêm',
                  amount: 0.07,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.5849999999999997,
                },
                {
                  id: 365,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 366,
                  name: 'Mỳ chính',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 367,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 368,
                  name: 'Thì là',
                  amount: 0.4,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
                {
                  id: 369,
                  name: 'Tôm nõn gẫy hấp cbm 60-80',
                  amount: 35.0,
                  usageRatio: 46,
                  protein: 0.08096,
                  lipid: 0.0041400000000000005,
                  glucid: 0.0041400000000000005,
                  calorie: 0.3772,
                },
              ],
            },
            {
              id: 66,
              name: 'Thịt lợn rán xá xíu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 330,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 331,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 332,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 333,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 334,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 335,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 336,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 337,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 338,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 339,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.07,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 340,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 341,
                  name: 'Ngũ vị hương',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 342,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 343,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 344,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 67,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 68,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 69,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '6.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240701',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 70,
              name: 'ChuốI tây',
              usageRatio: 100,
              ingredient: [
                {
                  id: 400,
                  name: '',
                  amount: 120.0,
                  usageRatio: 76,
                  protein: 0.00684,
                  lipid: 0.00228,
                  glucid: 0.114,
                  calorie: 0.5016,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 7,
      serveDate: '2024-07-02',
      codeSetOrder: 'BTD-KH-VHC2-20240702',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-28',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '7.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240702',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 71,
              name: 'Thịt lợn sốt cà chua 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 345,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 346,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 347,
                  name: 'Cà chua',
                  amount: 10.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.0021850000000000003,
                  glucid: 0.045315,
                  calorie: 0.22799999999999998,
                },
                {
                  id: 348,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 349,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 350,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 351,
                  name: 'Hành lá',
                  amount: 0.4,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 352,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 353,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 354,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 355,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 356,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 357,
                  name: 'Nước mắm',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 358,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 72,
              name: 'Chả lụa rim tiêu 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 138,
                  name: 'Bột canh ( hc)',
                  amount: 0.32,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 139,
                  name: 'Chả lụa hvs',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.154,
                  lipid: 0.423,
                  glucid: 0.009,
                  calorie: 4.460999999999999,
                },
                {
                  id: 140,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 141,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 142,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 143,
                  name: 'Hạt nêm',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.12999999999999998,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.585,
                },
                {
                  id: 144,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 145,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 146,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 147,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 73,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 74,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 75,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 76,
              name: 'Thịt bò xào lúc lắc 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 236,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 237,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 238,
                  name: 'Dầu ăn',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 239,
                  name: 'Đỗ quả',
                  amount: 10.0,
                  usageRatio: 90,
                  protein: 0.045,
                  lipid: 0.0,
                  glucid: 0.12869999999999998,
                  calorie: 0.693,
                },
                {
                  id: 240,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 241,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 242,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 243,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 244,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 245,
                  name: 'Ngô bắp cả vỏ',
                  amount: 20.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 246,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 247,
                  name: 'Su su cả vỏ',
                  amount: 10.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 248,
                  name: 'Thịt bò',
                  amount: 50.0,
                  usageRatio: 98,
                  protein: 0.20579999999999998,
                  lipid: 0.03724,
                  glucid: 0.0,
                  calorie: 1.1564,
                },
                {
                  id: 249,
                  name: 'Magi 700ml',
                  amount: 0.49,
                  usageRatio: 100,
                  protein: 0.027500000000000004,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 77,
              name: 'Trứng đúc thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 370,
                  name: 'Dầu ăn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 371,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 372,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 373,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 374,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 375,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 376,
                  name: 'Thịt vai, mông',
                  amount: 10.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 377,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 78,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 79,
              name: 'Canh cảI xanh nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 73,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 74,
                  name: 'CảI xanh',
                  amount: 30.0,
                  usageRatio: 76,
                  protein: 0.01292,
                  lipid: 0.0010639999999999998,
                  glucid: 0.028576,
                  calorie: 0.17479999999999998,
                },
                {
                  id: 75,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 76,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 77,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 78,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 79,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 80,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 81,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 80,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '7.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240702',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 81,
              name: 'Thịt lợn sốt cà chua 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 345,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 346,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 347,
                  name: 'Cà chua',
                  amount: 10.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.0021850000000000003,
                  glucid: 0.045315,
                  calorie: 0.22799999999999998,
                },
                {
                  id: 348,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 349,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 350,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 351,
                  name: 'Hành lá',
                  amount: 0.4,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 352,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 353,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 354,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 355,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 356,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 357,
                  name: 'Nước mắm',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 358,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 82,
              name: 'Chả lụa rim tiêu 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 138,
                  name: 'Bột canh ( hc)',
                  amount: 0.32,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 139,
                  name: 'Chả lụa hvs',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.154,
                  lipid: 0.423,
                  glucid: 0.009,
                  calorie: 4.460999999999999,
                },
                {
                  id: 140,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 141,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 142,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 143,
                  name: 'Hạt nêm',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.12999999999999998,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.585,
                },
                {
                  id: 144,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 145,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 146,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 147,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 83,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 84,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 85,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 86,
              name: 'Thịt lợn kho tàu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 294,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 295,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 296,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 297,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 298,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 299,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 300,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 301,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 302,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 303,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 304,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 87,
              name: 'Trứng kho',
              usageRatio: 100,
              ingredient: [
                {
                  id: 378,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 379,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 380,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 381,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 382,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 383,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 88,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 89,
              name: 'Canh đậu thịt cà chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 110,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 111,
                  name: 'Cà chua',
                  amount: 13.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 112,
                  name: 'Đậu phụ',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 113,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 114,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 115,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 116,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 117,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 118,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 119,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 120,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 90,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '7.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240702',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 91,
              name: 'Dưa hấu',
              usageRatio: 100,
              ingredient: [
                {
                  id: 401,
                  name: '',
                  amount: 120.0,
                  usageRatio: 52,
                  protein: 0.00624,
                  lipid: 0.00104,
                  glucid: 0.01456,
                  calorie: 0.09359999999999999,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 8,
      serveDate: '2024-07-03',
      codeSetOrder: 'BTD-KH-VHC2-20240703',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-28',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '8.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240703',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 92,
              name: 'Tôm nõn tẩm bột chiên 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 359,
                  name: 'Bột canh ( hc)',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 360,
                  name: 'Bột chiên giòn',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 361,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 362,
                  name: 'Dầu ăn',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 363,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 364,
                  name: 'Hạt nêm',
                  amount: 0.07,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.5849999999999997,
                },
                {
                  id: 365,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 366,
                  name: 'Mỳ chính',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 367,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 368,
                  name: 'Thì là',
                  amount: 0.4,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
                {
                  id: 369,
                  name: 'Tôm nõn gẫy hấp cbm 60-80',
                  amount: 35.0,
                  usageRatio: 46,
                  protein: 0.08096,
                  lipid: 0.0041400000000000005,
                  glucid: 0.0041400000000000005,
                  calorie: 0.3772,
                },
              ],
            },
            {
              id: 93,
              name: 'Thịt lợn rán xá xíu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 330,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 331,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 332,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 333,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 334,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 335,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 336,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 337,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 338,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 339,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.07,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 340,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 341,
                  name: 'Ngũ vị hương',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 342,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 343,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 344,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 94,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 95,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 96,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 97,
              name: 'Thịt gà rang gừng 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 266,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 267,
                  name: 'Bột đao',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 268,
                  name: 'Bột nghệ',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 269,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 270,
                  name: 'ĐùI tháo khớp phi lê (có da, ko xương)',
                  amount: 20.0,
                  usageRatio: 100,
                  protein: 0.18,
                  lipid: 0.068,
                  glucid: 0.0089,
                  calorie: 1.3699999999999999,
                },
                {
                  id: 271,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 272,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 273,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 274,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 275,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 276,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 277,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 278,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 279,
                  name: 'Ức phi lê không da',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -0.0001,
                  calorie: 1.3399999999999999,
                },
              ],
            },
            {
              id: 98,
              name: 'Vò viên ba sa sốt cà chua 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 384,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 385,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 386,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 387,
                  name: 'Cà chua',
                  amount: 6.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 388,
                  name: 'Chả cá sông đốc viên',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.11099999999999999,
                  lipid: 0.13,
                  glucid: 0.0612,
                  calorie: 1.8599999999999999,
                },
                {
                  id: 389,
                  name: 'Dầu ăn',
                  amount: 6.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 390,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 391,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 392,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 393,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 394,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 395,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 396,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 397,
                  name: 'Thì là',
                  amount: 0.2,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
              ],
            },
            {
              id: 99,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 100,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 101,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '8.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240703',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 102,
              name: 'Thịt gà lắc pho mai 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 250,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 251,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 252,
                  name: 'Bột đao',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 253,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 254,
                  name: 'Dầu ăn',
                  amount: 12.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 255,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 256,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 257,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 258,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 259,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 260,
                  name: 'Ngũ vị hương',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 261,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 262,
                  name: 'Pho mai lắc',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.477,
                  lipid: 0.305,
                  glucid: 0.0,
                  calorie: 4.64,
                },
                {
                  id: 263,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 264,
                  name: 'Ức phi lê không da',
                  amount: 55.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -9.999999999999999e-5,
                  calorie: 1.34,
                },
                {
                  id: 265,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
              ],
            },
            {
              id: 103,
              name: 'Đậu phụ tứ xuyên',
              usageRatio: 100,
              ingredient: [
                {
                  id: 182,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 183,
                  name: 'Bột đao',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 184,
                  name: 'Cà chua',
                  amount: 7.0,
                  usageRatio: 95,
                  protein: 0.005699999999999999,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 185,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 186,
                  name: 'Đậu phụ',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 187,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 188,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 189,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 190,
                  name: 'Hạt đIều màu',
                  amount: 0.1,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 191,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 192,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 193,
                  name: 'Mộc nhĩ',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0954,
                  lipid: 0.0018,
                  glucid: 0.6479999999999999,
                  calorie: 2.988,
                },
                {
                  id: 194,
                  name: 'MuốI hạt',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 195,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 196,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 197,
                  name: 'Thịt vai, mông',
                  amount: 4.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 198,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 199,
                  name: 'Magi 700ml',
                  amount: 1.4,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 104,
              name: 'Bí xanh xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 12,
                  name: 'Bí xanh cả vỏ',
                  amount: 80.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 13,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 14,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 15,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 16,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 17,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 18,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 19,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 20,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 21,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 105,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 106,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 107,
              name: 'Thịt gà rang gừng 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 266,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 267,
                  name: 'Bột đao',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 268,
                  name: 'Bột nghệ',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 269,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 270,
                  name: 'ĐùI tháo khớp phi lê (có da, ko xương)',
                  amount: 20.0,
                  usageRatio: 100,
                  protein: 0.18,
                  lipid: 0.068,
                  glucid: 0.0089,
                  calorie: 1.3699999999999999,
                },
                {
                  id: 271,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 272,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 273,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 274,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 275,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 276,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 277,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 278,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 279,
                  name: 'Ức phi lê không da',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -0.0001,
                  calorie: 1.3399999999999999,
                },
              ],
            },
            {
              id: 108,
              name: 'Vò viên ba sa sốt cà chua 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 384,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 385,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 386,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 387,
                  name: 'Cà chua',
                  amount: 6.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 388,
                  name: 'Chả cá sông đốc viên',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.11099999999999999,
                  lipid: 0.13,
                  glucid: 0.0612,
                  calorie: 1.8599999999999999,
                },
                {
                  id: 389,
                  name: 'Dầu ăn',
                  amount: 6.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 390,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 391,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 392,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 393,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 394,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 395,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 396,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 397,
                  name: 'Thì là',
                  amount: 0.2,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
              ],
            },
            {
              id: 109,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 110,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 111,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '8.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240703',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 112,
              name: 'Bánh chuốI mini th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 402,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.061000000000000006,
                  lipid: 0.152,
                  glucid: 0.197,
                  calorie: 2.395,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 9,
      serveDate: '2024-07-04',
      codeSetOrder: 'BTD-KH-VHC2-20240704',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-28',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '9.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240704',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 113,
              name: 'Cá ba sa tẩm bột chiên xù 90g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 37,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 38,
                  name: 'Bột chiên giòn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 39,
                  name: 'Bột chiên xù',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.09000000000000001,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 40,
                  name: 'Bột mỳ',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 41,
                  name: 'Bột nghệ',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051,
                  glucid: 0.721,
                  calorie: 3.6000000000000005,
                },
                {
                  id: 42,
                  name: 'Dầu ăn',
                  amount: 18.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 43,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 44,
                  name: 'Fille basa t1',
                  amount: 90.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.6699999999999999,
                },
                {
                  id: 45,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 46,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 47,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 48,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 49,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 50,
                  name: 'Ngũ vị hương',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 51,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 114,
              name: 'Thịt lợn hầm khoai tây cà rốt 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 280,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 281,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 282,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 283,
                  name: 'Cần tây',
                  amount: 0.2,
                  usageRatio: 84,
                  protein: 0.03108,
                  lipid: 0.0013439999999999997,
                  glucid: 0.07929599999999999,
                  calorie: 0.45359999999999995,
                },
                {
                  id: 284,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 285,
                  name: 'Gừng',
                  amount: 0.3,
                  usageRatio: 90,
                  protein: 0.0036000000000000003,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.36900000000000005,
                },
                {
                  id: 286,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 287,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 288,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 289,
                  name: 'Khoai tây cả vỏ',
                  amount: 40.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 290,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 291,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 292,
                  name: 'Thịt nạc mông nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.2468,
                  lipid: 0.0472,
                  glucid: 0.0,
                  calorie: 1.41,
                },
                {
                  id: 293,
                  name: 'Thịt nạc vai nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.20829999999999999,
                  lipid: 0.07289999999999999,
                  glucid: -0.0002,
                  calorie: 1.49,
                },
              ],
            },
            {
              id: 115,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 116,
              name: 'Canh củ quả hầm xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 97,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 98,
                  name: 'Cà chua',
                  amount: 8.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 99,
                  name: 'Cà rốt cả vỏ',
                  amount: 7.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 100,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 101,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 102,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 103,
                  name: 'Khoai tây cả vỏ',
                  amount: 20.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 104,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 105,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 106,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 107,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 108,
                  name: 'Su su cả vỏ',
                  amount: 20.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 109,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 117,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 118,
              name: 'Thịt lợn om nấm 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 305,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 306,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 307,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 308,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 309,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 310,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 311,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 312,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 313,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 314,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 315,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 316,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 119,
              name: 'Đậu phụ sốt cà chua thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 168,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 169,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 170,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 171,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 172,
                  name: 'Đậu phụ',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 173,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 174,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 175,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 176,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 177,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 178,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 179,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 180,
                  name: 'Nước mắm',
                  amount: 1.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 181,
                  name: 'Thịt vai, mông',
                  amount: 5.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 120,
              name: 'Khoai tây xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 212,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 213,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 214,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 215,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 216,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 217,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 218,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 219,
                  name: 'Khoai tây cả vỏ',
                  amount: 80.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 220,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 221,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 222,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 121,
              name: 'Canh rau ngót nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 129,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 130,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 131,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 132,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 133,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 134,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 135,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 136,
                  name: 'Rau ngót cành',
                  amount: 20.0,
                  usageRatio: 77,
                  protein: 0.04081,
                  lipid: 0.0,
                  glucid: 0.04543,
                  calorie: 0.3465,
                },
                {
                  id: 137,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 122,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '9.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240704',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 123,
              name: 'Cá ba sa tẩm bột chiên xù 90g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 37,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 38,
                  name: 'Bột chiên giòn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 39,
                  name: 'Bột chiên xù',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.09000000000000001,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 40,
                  name: 'Bột mỳ',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 41,
                  name: 'Bột nghệ',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051,
                  glucid: 0.721,
                  calorie: 3.6000000000000005,
                },
                {
                  id: 42,
                  name: 'Dầu ăn',
                  amount: 18.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 43,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 44,
                  name: 'Fille basa t1',
                  amount: 90.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.6699999999999999,
                },
                {
                  id: 45,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 46,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 47,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 48,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 49,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 50,
                  name: 'Ngũ vị hương',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 51,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 124,
              name: 'Thịt lợn hầm khoai tây cà rốt 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 280,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 281,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 282,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 283,
                  name: 'Cần tây',
                  amount: 0.2,
                  usageRatio: 84,
                  protein: 0.03108,
                  lipid: 0.0013439999999999997,
                  glucid: 0.07929599999999999,
                  calorie: 0.45359999999999995,
                },
                {
                  id: 284,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 285,
                  name: 'Gừng',
                  amount: 0.3,
                  usageRatio: 90,
                  protein: 0.0036000000000000003,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.36900000000000005,
                },
                {
                  id: 286,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 287,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 288,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 289,
                  name: 'Khoai tây cả vỏ',
                  amount: 40.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 290,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 291,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 292,
                  name: 'Thịt nạc mông nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.2468,
                  lipid: 0.0472,
                  glucid: 0.0,
                  calorie: 1.41,
                },
                {
                  id: 293,
                  name: 'Thịt nạc vai nguyên quả',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.20829999999999999,
                  lipid: 0.07289999999999999,
                  glucid: -0.0002,
                  calorie: 1.49,
                },
              ],
            },
            {
              id: 125,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 126,
              name: 'Canh củ quả hầm xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 97,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 98,
                  name: 'Cà chua',
                  amount: 8.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 99,
                  name: 'Cà rốt cả vỏ',
                  amount: 7.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 100,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 101,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 102,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 103,
                  name: 'Khoai tây cả vỏ',
                  amount: 20.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 104,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 105,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 106,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 107,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 108,
                  name: 'Su su cả vỏ',
                  amount: 20.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 109,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 127,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 128,
              name: 'Thịt lợn om nấm 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 305,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 306,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 307,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 308,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 309,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 310,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 311,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 312,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 313,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 314,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 315,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 316,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 129,
              name: 'Đậu phụ sốt cà chua thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 168,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 169,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 170,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 171,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 172,
                  name: 'Đậu phụ',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 173,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 174,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 175,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 176,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 177,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 178,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 179,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 180,
                  name: 'Nước mắm',
                  amount: 1.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 181,
                  name: 'Thịt vai, mông',
                  amount: 5.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 130,
              name: 'Khoai tây xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 212,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 213,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 214,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 215,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 216,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 217,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 218,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 219,
                  name: 'Khoai tây cả vỏ',
                  amount: 80.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 220,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 221,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 222,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 131,
              name: 'Canh rau ngót nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 129,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 130,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 131,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 132,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 133,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 134,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 135,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 136,
                  name: 'Rau ngót cành',
                  amount: 20.0,
                  usageRatio: 77,
                  protein: 0.04081,
                  lipid: 0.0,
                  glucid: 0.04543,
                  calorie: 0.3465,
                },
                {
                  id: 137,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 132,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '9.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240704',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 133,
              name: 'Sữa chua ăn mộc châu milk có đường',
              usageRatio: 100,
              ingredient: [],
            },
          ],
        },
      ],
    },
    {
      id: 10,
      serveDate: '2024-07-05',
      codeSetOrder: 'BTD-KH-VHC2-20240705',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-06-28',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đã hoàn thành',
      listOrder: [
        {
          id: '10.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240705',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 134,
              name: 'Cơm rang thập cẩm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 150,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 151,
                  name: 'Cà rốt cả vỏ',
                  amount: 8.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 152,
                  name: 'Dầu ăn',
                  amount: 7.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 153,
                  name: 'Dưa chuột',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0076,
                  lipid: 0.000855,
                  glucid: 0.034295,
                  calorie: 0.17099999999999999,
                },
                {
                  id: 154,
                  name: 'Đỗ quả',
                  amount: 15.0,
                  usageRatio: 90,
                  protein: 0.045000000000000005,
                  lipid: 0.0,
                  glucid: 0.1287,
                  calorie: 0.693,
                },
                {
                  id: 155,
                  name: 'Gạo pc',
                  amount: 100.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 156,
                  name: 'Giò lụa hvs',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.17300000000000001,
                  lipid: 0.224,
                  glucid: 0.011000000000000001,
                  calorie: 2.753,
                },
                {
                  id: 157,
                  name: 'Hành khô cả vỏ',
                  amount: 0.8,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 158,
                  name: 'Hành lá',
                  amount: 1.0,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 159,
                  name: 'Hạt đIều màu',
                  amount: 1.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 160,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 161,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 162,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 163,
                  name: 'Ngô bắp cả vỏ',
                  amount: 30.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 164,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 165,
                  name: 'Xúc xích hvs',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.10600000000000001,
                  lipid: 0.376,
                  glucid: 0.022,
                  calorie: 3.8289999999999997,
                },
                {
                  id: 166,
                  name: 'Magi 700ml',
                  amount: 1.05,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 167,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 12.6,
                  usageRatio: 86,
                  protein: 0.109994,
                  lipid: 0.070262,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 135,
              name: 'Canh chua nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 82,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 83,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 84,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 85,
                  name: 'Đường trắng',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 86,
                  name: 'Giá đỗ',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 87,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 88,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 89,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 90,
                  name: 'Me quả',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 91,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 92,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 93,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 94,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 95,
                  name: 'Thịt vai, mông',
                  amount: 2.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 96,
                  name: 'Dứa xanh quả cả vỏ',
                  amount: 8.0,
                  usageRatio: 60,
                  protein: 0.0048,
                  lipid: 0.0,
                  glucid: 0.0438,
                  calorie: 0.192,
                },
              ],
            },
            {
              id: 136,
              name: 'Thịt bò xào lúc lắc 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 236,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 237,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 238,
                  name: 'Dầu ăn',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 239,
                  name: 'Đỗ quả',
                  amount: 10.0,
                  usageRatio: 90,
                  protein: 0.045,
                  lipid: 0.0,
                  glucid: 0.12869999999999998,
                  calorie: 0.693,
                },
                {
                  id: 240,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 241,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 242,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 243,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 244,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 245,
                  name: 'Ngô bắp cả vỏ',
                  amount: 20.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 246,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 247,
                  name: 'Su su cả vỏ',
                  amount: 10.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 248,
                  name: 'Thịt bò',
                  amount: 50.0,
                  usageRatio: 98,
                  protein: 0.20579999999999998,
                  lipid: 0.03724,
                  glucid: 0.0,
                  calorie: 1.1564,
                },
                {
                  id: 249,
                  name: 'Magi 700ml',
                  amount: 0.49,
                  usageRatio: 100,
                  protein: 0.027500000000000004,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 137,
              name: 'Trứng đúc thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 370,
                  name: 'Dầu ăn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 371,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 372,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 373,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 374,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 375,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 376,
                  name: 'Thịt vai, mông',
                  amount: 10.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 377,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 138,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 139,
              name: 'Canh cảI xanh nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 73,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 74,
                  name: 'CảI xanh',
                  amount: 30.0,
                  usageRatio: 76,
                  protein: 0.01292,
                  lipid: 0.0010639999999999998,
                  glucid: 0.028576,
                  calorie: 0.17479999999999998,
                },
                {
                  id: 75,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 76,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 77,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 78,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 79,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 80,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 81,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 140,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '10.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240705',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 141,
              name: 'Cơm rang thập cẩm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 150,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 151,
                  name: 'Cà rốt cả vỏ',
                  amount: 8.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 152,
                  name: 'Dầu ăn',
                  amount: 7.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 153,
                  name: 'Dưa chuột',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0076,
                  lipid: 0.000855,
                  glucid: 0.034295,
                  calorie: 0.17099999999999999,
                },
                {
                  id: 154,
                  name: 'Đỗ quả',
                  amount: 15.0,
                  usageRatio: 90,
                  protein: 0.045000000000000005,
                  lipid: 0.0,
                  glucid: 0.1287,
                  calorie: 0.693,
                },
                {
                  id: 155,
                  name: 'Gạo pc',
                  amount: 100.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 156,
                  name: 'Giò lụa hvs',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.17300000000000001,
                  lipid: 0.224,
                  glucid: 0.011000000000000001,
                  calorie: 2.753,
                },
                {
                  id: 157,
                  name: 'Hành khô cả vỏ',
                  amount: 0.8,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 158,
                  name: 'Hành lá',
                  amount: 1.0,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 159,
                  name: 'Hạt đIều màu',
                  amount: 1.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 160,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 161,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 162,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 163,
                  name: 'Ngô bắp cả vỏ',
                  amount: 30.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 164,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 165,
                  name: 'Xúc xích hvs',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.10600000000000001,
                  lipid: 0.376,
                  glucid: 0.022,
                  calorie: 3.8289999999999997,
                },
                {
                  id: 166,
                  name: 'Magi 700ml',
                  amount: 1.05,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 167,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 12.6,
                  usageRatio: 86,
                  protein: 0.109994,
                  lipid: 0.070262,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 142,
              name: 'Canh chua nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 82,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 83,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 84,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 85,
                  name: 'Đường trắng',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 86,
                  name: 'Giá đỗ',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 87,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 88,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 89,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 90,
                  name: 'Me quả',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 91,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 92,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 93,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 94,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 95,
                  name: 'Thịt vai, mông',
                  amount: 2.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 96,
                  name: 'Dứa xanh quả cả vỏ',
                  amount: 8.0,
                  usageRatio: 60,
                  protein: 0.0048,
                  lipid: 0.0,
                  glucid: 0.0438,
                  calorie: 0.192,
                },
              ],
            },
            {
              id: 143,
              name: 'Thịt bò xào lúc lắc 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 236,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 237,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 238,
                  name: 'Dầu ăn',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 239,
                  name: 'Đỗ quả',
                  amount: 10.0,
                  usageRatio: 90,
                  protein: 0.045,
                  lipid: 0.0,
                  glucid: 0.12869999999999998,
                  calorie: 0.693,
                },
                {
                  id: 240,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 241,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 242,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 243,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 244,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 245,
                  name: 'Ngô bắp cả vỏ',
                  amount: 20.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 246,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 247,
                  name: 'Su su cả vỏ',
                  amount: 10.0,
                  usageRatio: 80,
                  protein: 0.0064,
                  lipid: 0.0008,
                  glucid: 0.0368,
                  calorie: 0.184,
                },
                {
                  id: 248,
                  name: 'Thịt bò',
                  amount: 50.0,
                  usageRatio: 98,
                  protein: 0.20579999999999998,
                  lipid: 0.03724,
                  glucid: 0.0,
                  calorie: 1.1564,
                },
                {
                  id: 249,
                  name: 'Magi 700ml',
                  amount: 0.49,
                  usageRatio: 100,
                  protein: 0.027500000000000004,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 144,
              name: 'Trứng đúc thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 370,
                  name: 'Dầu ăn',
                  amount: 4.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 371,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 372,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 373,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 374,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 375,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 376,
                  name: 'Thịt vai, mông',
                  amount: 10.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 377,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 145,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 146,
              name: 'Canh cảI xanh nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 73,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 74,
                  name: 'CảI xanh',
                  amount: 30.0,
                  usageRatio: 76,
                  protein: 0.01292,
                  lipid: 0.0010639999999999998,
                  glucid: 0.028576,
                  calorie: 0.17479999999999998,
                },
                {
                  id: 75,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 76,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 77,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 78,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 79,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 80,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 81,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 147,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '10.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240705',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đã hoàn thành',
          disks: [
            {
              id: 148,
              name: 'Bánh mỳ kem th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 399,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.077,
                  lipid: 0.153,
                  glucid: 0.542,
                  calorie: 3.853,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 11,
      serveDate: '2024-07-08',
      codeSetOrder: 'BTD-KH-VHC2-20240708',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-07-05',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Đang thực hiện',
      listOrder: [
        {
          id: '11.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240708',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Đang thực hiện',
          disks: [
            {
              id: 149,
              name: 'Thịt gà lắc pho mai 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 250,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 251,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 252,
                  name: 'Bột đao',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 253,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 254,
                  name: 'Dầu ăn',
                  amount: 12.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 255,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 256,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 257,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 258,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 259,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 260,
                  name: 'Ngũ vị hương',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 261,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 262,
                  name: 'Pho mai lắc',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.477,
                  lipid: 0.305,
                  glucid: 0.0,
                  calorie: 4.64,
                },
                {
                  id: 263,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 264,
                  name: 'Ức phi lê không da',
                  amount: 55.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -9.999999999999999e-5,
                  calorie: 1.34,
                },
                {
                  id: 265,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
              ],
            },
            {
              id: 150,
              name: 'Đậu phụ tứ xuyên',
              usageRatio: 100,
              ingredient: [
                {
                  id: 182,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 183,
                  name: 'Bột đao',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 184,
                  name: 'Cà chua',
                  amount: 7.0,
                  usageRatio: 95,
                  protein: 0.005699999999999999,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 185,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 186,
                  name: 'Đậu phụ',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 187,
                  name: 'Đường trắng',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.9930000000000001,
                  calorie: 3.97,
                },
                {
                  id: 188,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 189,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 190,
                  name: 'Hạt đIều màu',
                  amount: 0.1,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 191,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 192,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 193,
                  name: 'Mộc nhĩ',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0954,
                  lipid: 0.0018,
                  glucid: 0.6479999999999999,
                  calorie: 2.988,
                },
                {
                  id: 194,
                  name: 'MuốI hạt',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 195,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 196,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 197,
                  name: 'Thịt vai, mông',
                  amount: 4.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 198,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 199,
                  name: 'Magi 700ml',
                  amount: 1.4,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 151,
              name: 'Bí xanh xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 12,
                  name: 'Bí xanh cả vỏ',
                  amount: 80.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 13,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 14,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 15,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 16,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 17,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 18,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 19,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 20,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 21,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 152,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 153,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '11.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240708',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Khởi tạo',
          disks: [
            {
              id: 154,
              name: 'Tôm nõn tẩm bột chiên 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 359,
                  name: 'Bột canh ( hc)',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 360,
                  name: 'Bột chiên giòn',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 361,
                  name: 'Bột mỳ',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011,
                  glucid: 0.732,
                  calorie: 3.47,
                },
                {
                  id: 362,
                  name: 'Dầu ăn',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 363,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 364,
                  name: 'Hạt nêm',
                  amount: 0.07,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.26499999999999996,
                  calorie: 1.5849999999999997,
                },
                {
                  id: 365,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 366,
                  name: 'Mỳ chính',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 367,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 368,
                  name: 'Thì là',
                  amount: 0.4,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
                {
                  id: 369,
                  name: 'Tôm nõn gẫy hấp cbm 60-80',
                  amount: 35.0,
                  usageRatio: 46,
                  protein: 0.08096,
                  lipid: 0.0041400000000000005,
                  glucid: 0.0041400000000000005,
                  calorie: 0.3772,
                },
              ],
            },
            {
              id: 155,
              name: 'Thịt lợn rán xá xíu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 330,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 331,
                  name: 'Dầu hào 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 332,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 333,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 334,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 335,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 336,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 337,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 338,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 339,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.15,
                  usageRatio: 100,
                  protein: 0.07,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 340,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 341,
                  name: 'Ngũ vị hương',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 342,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 343,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 344,
                  name: 'TỏI khô',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028000000000000004,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 156,
              name: 'Rau muống xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 227,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 228,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 229,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 230,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 231,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 232,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 233,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 234,
                  name: 'Rau muống',
                  amount: 70.0,
                  usageRatio: 85,
                  protein: 0.0272,
                  lipid: 0.00204,
                  glucid: 0.02771,
                  calorie: 0.238,
                },
                {
                  id: 235,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
              ],
            },
            {
              id: 157,
              name: 'Nước canh rau muống nấu chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 223,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 224,
                  name: 'Me quả',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.01615,
                  lipid: 0.0,
                  glucid: 0.0578,
                  calorie: 0.2975,
                },
                {
                  id: 225,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 226,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 158,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '11.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240708',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Đang thực hiện',
          disks: [
            {
              id: 159,
              name: 'Bánh chuốI mini th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 402,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.061000000000000006,
                  lipid: 0.152,
                  glucid: 0.197,
                  calorie: 2.395,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 12,
      serveDate: '2024-07-09',
      codeSetOrder: 'BTD-KH-VHC2-20240709',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-07-05',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Khởi tạo',
      listOrder: [
        {
          id: '12.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240709',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Khởi tạo',
          disks: [
            {
              id: 160,
              name: 'Thịt lợn om nấm 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 305,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 306,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 307,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 308,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 309,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 310,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 311,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 312,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 313,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 314,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 315,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 316,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 161,
              name: 'Đậu phụ sốt cà chua thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 168,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 169,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 170,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 171,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 172,
                  name: 'Đậu phụ',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 173,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 174,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 175,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 176,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 177,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 178,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 179,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 180,
                  name: 'Nước mắm',
                  amount: 1.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 181,
                  name: 'Thịt vai, mông',
                  amount: 5.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 162,
              name: 'Khoai tây xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 212,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 213,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 214,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 215,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 216,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 217,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 218,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 219,
                  name: 'Khoai tây cả vỏ',
                  amount: 80.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 220,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 221,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 222,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 163,
              name: 'Canh rau ngót nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 129,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 130,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 131,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 132,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 133,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 134,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 135,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 136,
                  name: 'Rau ngót cành',
                  amount: 20.0,
                  usageRatio: 77,
                  protein: 0.04081,
                  lipid: 0.0,
                  glucid: 0.04543,
                  calorie: 0.3465,
                },
                {
                  id: 137,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 164,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '12.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240709',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Khởi tạo',
          disks: [
            {
              id: 165,
              name: 'Thịt lợn om nấm 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 305,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 306,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 307,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 308,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 309,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 310,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 311,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 312,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 313,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 314,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 315,
                  name: 'Thịt lợn nạc',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
                {
                  id: 316,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
              ],
            },
            {
              id: 166,
              name: 'Đậu phụ sốt cà chua thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 168,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 169,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 170,
                  name: 'Cà chua',
                  amount: 15.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 171,
                  name: 'Dầu ăn',
                  amount: 13.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 172,
                  name: 'Đậu phụ',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 173,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 174,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 175,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 176,
                  name: 'Hạt đIều màu',
                  amount: 0.05,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 177,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 178,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 179,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 180,
                  name: 'Nước mắm',
                  amount: 1.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 181,
                  name: 'Thịt vai, mông',
                  amount: 5.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 167,
              name: 'Khoai tây xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 212,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 213,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 214,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 215,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 216,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 217,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 218,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 219,
                  name: 'Khoai tây cả vỏ',
                  amount: 80.0,
                  usageRatio: 87,
                  protein: 0.0174,
                  lipid: 0.000609,
                  glucid: 0.190791,
                  calorie: 0.8352,
                },
                {
                  id: 220,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 221,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 222,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 168,
              name: 'Canh rau ngót nấu thịt',
              usageRatio: 100,
              ingredient: [
                {
                  id: 129,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 130,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 131,
                  name: 'Hành khô cả vỏ',
                  amount: 0.2,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 132,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 133,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 134,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 135,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 136,
                  name: 'Rau ngót cành',
                  amount: 20.0,
                  usageRatio: 77,
                  protein: 0.04081,
                  lipid: 0.0,
                  glucid: 0.04543,
                  calorie: 0.3465,
                },
                {
                  id: 137,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 169,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '12.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240709',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Khởi tạo',
          disks: [
            {
              id: 170,
              name: 'Sữa chua ăn mộc châu milk có đường',
              usageRatio: 100,
              ingredient: [],
            },
          ],
        },
      ],
    },
    {
      id: 13,
      serveDate: '2024-07-10',
      codeSetOrder: 'BTD-KH-VHC2-20240710',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-07-05',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Khởi tạo',
      listOrder: [
        {
          id: '13.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240710',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Khởi tạo',
          disks: [
            {
              id: 171,
              name: 'Cá ba sa tẩm bột chiên xù 60g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 22,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 23,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 24,
                  name: 'Bột chiên xù',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.09,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 25,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 26,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 27,
                  name: 'Dầu ăn',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 28,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 29,
                  name: 'Fille basa t1',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.67,
                },
                {
                  id: 30,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 31,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 32,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 33,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 34,
                  name: 'Mỳ chính',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 35,
                  name: 'Ngũ vị hương',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 36,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 172,
              name: 'Thịt lợn om ngô 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 317,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 318,
                  name: 'Dầu hào 700ml',
                  amount: 0.35,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 319,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 320,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 321,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 322,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 323,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 324,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 325,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 326,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 327,
                  name: 'Ngô bắp cả vỏ',
                  amount: 50.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 328,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 329,
                  name: 'Thịt lợn nạc',
                  amount: 30.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 173,
              name: 'Bắp cảI xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 1,
                  name: 'Bắp cảI',
                  amount: 70.0,
                  usageRatio: 90,
                  protein: 0.0162,
                  lipid: 0.00081,
                  glucid: 0.06219,
                  calorie: 0.324,
                },
                {
                  id: 2,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 3,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 4,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 5,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 6,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 7,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 8,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 9,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 10,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 11,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 174,
              name: 'Canh bí nấu xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 62,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 63,
                  name: 'Bí xanh cả vỏ',
                  amount: 40.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 64,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 65,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 66,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 67,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 68,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 69,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 70,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 71,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 72,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 175,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '13.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240710',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Khởi tạo',
          disks: [
            {
              id: 176,
              name: 'Thịt gà rang gừng 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 266,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 267,
                  name: 'Bột đao',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 268,
                  name: 'Bột nghệ',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 269,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 270,
                  name: 'ĐùI tháo khớp phi lê (có da, ko xương)',
                  amount: 20.0,
                  usageRatio: 100,
                  protein: 0.18,
                  lipid: 0.068,
                  glucid: 0.0089,
                  calorie: 1.3699999999999999,
                },
                {
                  id: 271,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 272,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 273,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 274,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 275,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 276,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 277,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 278,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 279,
                  name: 'Ức phi lê không da',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -0.0001,
                  calorie: 1.3399999999999999,
                },
              ],
            },
            {
              id: 177,
              name: 'Vò viên ba sa sốt cà chua 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 384,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 385,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 386,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 387,
                  name: 'Cà chua',
                  amount: 6.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 388,
                  name: 'Chả cá sông đốc viên',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.11099999999999999,
                  lipid: 0.13,
                  glucid: 0.0612,
                  calorie: 1.8599999999999999,
                },
                {
                  id: 389,
                  name: 'Dầu ăn',
                  amount: 6.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 390,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 391,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 392,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 393,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 394,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 395,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 396,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 397,
                  name: 'Thì là',
                  amount: 0.2,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
              ],
            },
            {
              id: 178,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 179,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 180,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '13.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240710',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Khởi tạo',
          disks: [
            {
              id: 181,
              name: 'Bánh mỳ kem th - hảI hà kotobuki',
              usageRatio: 100,
              ingredient: [
                {
                  id: 399,
                  name: '',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.077,
                  lipid: 0.153,
                  glucid: 0.542,
                  calorie: 3.853,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 14,
      serveDate: '2024-07-11',
      codeSetOrder: 'BTD-KH-VHC2-20240711',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-07-05',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Khởi tạo',
      listOrder: [
        {
          id: '14.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240711',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Khởi tạo',
          disks: [
            {
              id: 182,
              name: 'Thịt gà rang gừng 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 266,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 267,
                  name: 'Bột đao',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.8559999999999999,
                  calorie: 3.43,
                },
                {
                  id: 268,
                  name: 'Bột nghệ',
                  amount: 0.05,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 269,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 270,
                  name: 'ĐùI tháo khớp phi lê (có da, ko xương)',
                  amount: 20.0,
                  usageRatio: 100,
                  protein: 0.18,
                  lipid: 0.068,
                  glucid: 0.0089,
                  calorie: 1.3699999999999999,
                },
                {
                  id: 271,
                  name: 'Đường trắng',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 272,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 273,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 274,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 275,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 276,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 277,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 278,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 279,
                  name: 'Ức phi lê không da',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.2286,
                  lipid: 0.046799999999999994,
                  glucid: -0.0001,
                  calorie: 1.3399999999999999,
                },
              ],
            },
            {
              id: 183,
              name: 'Vò viên ba sa sốt cà chua 35g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 384,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 385,
                  name: 'Magi 700ml',
                  amount: 0.7,
                  usageRatio: 100,
                  protein: 0.0275,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.525,
                },
                {
                  id: 386,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 387,
                  name: 'Cà chua',
                  amount: 6.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 388,
                  name: 'Chả cá sông đốc viên',
                  amount: 35.0,
                  usageRatio: 100,
                  protein: 0.11099999999999999,
                  lipid: 0.13,
                  glucid: 0.0612,
                  calorie: 1.8599999999999999,
                },
                {
                  id: 389,
                  name: 'Dầu ăn',
                  amount: 6.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 390,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 391,
                  name: 'Hành lá',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 392,
                  name: 'Hạt đIều màu',
                  amount: 0.03,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 393,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 394,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 395,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 396,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 397,
                  name: 'Thì là',
                  amount: 0.2,
                  usageRatio: 75,
                  protein: 0.019499999999999997,
                  lipid: 0.0072,
                  glucid: 0.05205,
                  calorie: 0.3524999999999999,
                },
              ],
            },
            {
              id: 184,
              name: 'Giá đỗ cà rốt xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 200,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 201,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 202,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 203,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 204,
                  name: 'Giá đỗ',
                  amount: 60.0,
                  usageRatio: 95,
                  protein: 0.05225,
                  lipid: 0.001235,
                  glucid: 0.068115,
                  calorie: 0.494,
                },
                {
                  id: 205,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 206,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 207,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 208,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 209,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 210,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 211,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 185,
              name: 'Canh mồng tơI tôm',
              usageRatio: 100,
              ingredient: [
                {
                  id: 121,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 122,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 123,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 124,
                  name: 'Mùng tơI',
                  amount: 30.0,
                  usageRatio: 83,
                  protein: 0.0166,
                  lipid: 0.0,
                  glucid: 0.032369999999999996,
                  calorie: 0.1992,
                },
                {
                  id: 125,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 126,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 127,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 128,
                  name: 'Tôm nõn khô',
                  amount: 0.6,
                  usageRatio: 95,
                  protein: 0.7182000000000001,
                  lipid: 0.0361,
                  glucid: 0.02375,
                  calorie: 3.2965,
                },
              ],
            },
            {
              id: 186,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '14.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240711',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Khởi tạo',
          disks: [
            {
              id: 187,
              name: 'Thịt lợn kho tàu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 294,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 295,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 296,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 297,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 298,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 299,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 300,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 301,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 302,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 303,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 304,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 188,
              name: 'Trứng kho',
              usageRatio: 100,
              ingredient: [
                {
                  id: 378,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 379,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 380,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 381,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 382,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 383,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 189,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 190,
              name: 'Canh đậu thịt cà chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 110,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 111,
                  name: 'Cà chua',
                  amount: 13.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 112,
                  name: 'Đậu phụ',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 113,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 114,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 115,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 116,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 117,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 118,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 119,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 120,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 191,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '14.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240711',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Khởi tạo',
          disks: [
            {
              id: 192,
              name: 'ChuốI tây',
              usageRatio: 100,
              ingredient: [
                {
                  id: 400,
                  name: '',
                  amount: 120.0,
                  usageRatio: 76,
                  protein: 0.00684,
                  lipid: 0.00228,
                  glucid: 0.114,
                  calorie: 0.5016,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 15,
      serveDate: '2024-07-12',
      codeSetOrder: 'BTD-KH-VHC2-20240712',
      customerName: 'THCS Vân Hồ',
      customerCodeName: 'KH-VHC2-THCS',
      orderName: 'Thực đơn cấp 2 Vân Hồ',
      approveAt: '2024-07-05',
      createdAt: '2024-07-01',
      createdBy: 'Nguyễn Lan Anh',
      status: 'Khởi tạo',
      listOrder: [
        {
          id: '15.1',
          codeOrder: 'TD-KH-VHC2-BCHINH-GVIEN-20240712',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Giáo viên',
          status: 'Khởi tạo',
          disks: [
            {
              id: 193,
              name: 'Trứng kho',
              usageRatio: 100,
              ingredient: [
                {
                  id: 378,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 379,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 380,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 381,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 382,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 383,
                  name: 'Trứng gà đỏ (61-65gr)',
                  amount: 63.0,
                  usageRatio: 86,
                  protein: 0.10999400000000001,
                  lipid: 0.07026199999999999,
                  glucid: 0.016254,
                  calorie: 1.1352,
                },
              ],
            },
            {
              id: 194,
              name: 'CảI ngọt xào tỏI',
              usageRatio: 100,
              ingredient: [
                {
                  id: 52,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 53,
                  name: 'CảI ngọt',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.014,
                  lipid: 0.001,
                  glucid: 0.0262,
                  calorie: 0.17,
                },
                {
                  id: 54,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 55,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 56,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 57,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 58,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 59,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 60,
                  name: 'TỏI khô',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.048,
                  lipid: 0.0028,
                  glucid: 0.1972,
                  calorie: 1.008,
                },
                {
                  id: 61,
                  name: 'Magi 700ml',
                  amount: 0.56,
                  usageRatio: 100,
                  protein: 0.027499999999999997,
                  lipid: 0.0,
                  glucid: 0.1025,
                  calorie: 0.5249999999999999,
                },
              ],
            },
            {
              id: 195,
              name: 'Canh đậu thịt cà chua',
              usageRatio: 100,
              ingredient: [
                {
                  id: 110,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 111,
                  name: 'Cà chua',
                  amount: 13.0,
                  usageRatio: 95,
                  protein: 0.0057,
                  lipid: 0.002185,
                  glucid: 0.045315,
                  calorie: 0.228,
                },
                {
                  id: 112,
                  name: 'Đậu phụ',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.109,
                  lipid: 0.054000000000000006,
                  glucid: 0.011000000000000001,
                  calorie: 0.9700000000000001,
                },
                {
                  id: 113,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 114,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 115,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 116,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 117,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 118,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 119,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 120,
                  name: 'Thịt vai, mông',
                  amount: 3.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3621999999999999,
                },
              ],
            },
            {
              id: 196,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
          ],
        },
        {
          id: '15.2',
          codeOrder: 'TD-KH-VHC2-BCHINH-HSINH-20240712',
          channelDistribution: 'Ăn trưa',
          orderClassifications: 'Học sinh',
          status: 'Khởi tạo',
          disks: [
            {
              id: 197,
              name: 'Cá ba sa tẩm bột chiên xù 60g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 22,
                  name: 'Bột canh ( hc)',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 23,
                  name: 'Bột chiên giòn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0825,
                  lipid: 0.0098,
                  glucid: 0.7771,
                  calorie: 3.53,
                },
                {
                  id: 24,
                  name: 'Bột chiên xù',
                  amount: 10.0,
                  usageRatio: 100,
                  protein: 0.09,
                  lipid: 0.025,
                  glucid: 0.77,
                  calorie: 3.7,
                },
                {
                  id: 25,
                  name: 'Bột mỳ',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.11,
                  lipid: 0.011000000000000001,
                  glucid: 0.7320000000000001,
                  calorie: 3.47,
                },
                {
                  id: 26,
                  name: 'Bột nghệ',
                  amount: 0.1,
                  usageRatio: 100,
                  protein: 0.063,
                  lipid: 0.051000000000000004,
                  glucid: 0.721,
                  calorie: 3.5999999999999996,
                },
                {
                  id: 27,
                  name: 'Dầu ăn',
                  amount: 15.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 28,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 29,
                  name: 'Fille basa t1',
                  amount: 60.0,
                  usageRatio: 100,
                  protein: 0.097,
                  lipid: 0.008,
                  glucid: 0.053200000000000004,
                  calorie: 0.67,
                },
                {
                  id: 30,
                  name: 'Gừng',
                  amount: 0.4,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.0045899999999999995,
                  glucid: 0.07730999999999999,
                  calorie: 0.369,
                },
                {
                  id: 31,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 32,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 33,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.07400000000000001,
                  glucid: 0.676,
                  calorie: 3.6500000000000004,
                },
                {
                  id: 34,
                  name: 'Mỳ chính',
                  amount: 0.04,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 35,
                  name: 'Ngũ vị hương',
                  amount: 0.03,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 36,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 198,
              name: 'Thịt lợn om ngô 30g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 317,
                  name: 'Bột canh ( hc)',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 318,
                  name: 'Dầu hào 700ml',
                  amount: 0.35,
                  usageRatio: 100,
                  protein: 0.012,
                  lipid: 0.0,
                  glucid: 0.228,
                  calorie: 0.9600000000000001,
                },
                {
                  id: 319,
                  name: 'Bột đao',
                  amount: 0.5,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.43,
                },
                {
                  id: 320,
                  name: 'Cà rốt cả vỏ',
                  amount: 5.0,
                  usageRatio: 85,
                  protein: 0.012750000000000001,
                  lipid: 0.0014449999999999999,
                  glucid: 0.07675499999999999,
                  calorie: 0.374,
                },
                {
                  id: 321,
                  name: 'Dầu ăn',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.000000000000002,
                },
                {
                  id: 322,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 323,
                  name: 'Hành lá',
                  amount: 0.2,
                  usageRatio: 80,
                  protein: 0.010399999999999998,
                  lipid: 0.0,
                  glucid: 0.04159999999999999,
                  calorie: 0.208,
                },
                {
                  id: 324,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 325,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 326,
                  name: 'Mỳ chính',
                  amount: 0.02,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 327,
                  name: 'Ngô bắp cả vỏ',
                  amount: 50.0,
                  usageRatio: 55,
                  protein: 0.02255,
                  lipid: 0.01265,
                  glucid: 0.22440000000000002,
                  calorie: 1.1,
                },
                {
                  id: 328,
                  name: 'Nước mắm',
                  amount: 0.2,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 329,
                  name: 'Thịt lợn nạc',
                  amount: 30.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.0686,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
            {
              id: 199,
              name: 'Bắp cảI xào',
              usageRatio: 100,
              ingredient: [
                {
                  id: 1,
                  name: 'Bắp cảI',
                  amount: 70.0,
                  usageRatio: 90,
                  protein: 0.0162,
                  lipid: 0.00081,
                  glucid: 0.06219,
                  calorie: 0.324,
                },
                {
                  id: 2,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 3,
                  name: 'Cà rốt cả vỏ',
                  amount: 4.0,
                  usageRatio: 85,
                  protein: 0.01275,
                  lipid: 0.001445,
                  glucid: 0.076755,
                  calorie: 0.374,
                },
                {
                  id: 4,
                  name: 'Dầu ăn',
                  amount: 3.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 5,
                  name: 'Đường trắng',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 6,
                  name: 'Hành khô cả vỏ',
                  amount: 0.3,
                  usageRatio: 76,
                  protein: 0.00988,
                  lipid: 0.002128,
                  glucid: 0.039672,
                  calorie: 0.2204,
                },
                {
                  id: 7,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 8,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 9,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 10,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 11,
                  name: 'Nước mắm',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
              ],
            },
            {
              id: 200,
              name: 'Canh bí nấu xương',
              usageRatio: 100,
              ingredient: [
                {
                  id: 62,
                  name: 'Bột canh ( hc)',
                  amount: 1.6,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 63,
                  name: 'Bí xanh cả vỏ',
                  amount: 40.0,
                  usageRatio: 73,
                  protein: 0.00438,
                  lipid: 0.0,
                  glucid: 0.024820000000000002,
                  calorie: 0.11679999999999999,
                },
                {
                  id: 64,
                  name: 'Đường trắng',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 65,
                  name: 'Gừng',
                  amount: 0.5,
                  usageRatio: 90,
                  protein: 0.0036,
                  lipid: 0.00459,
                  glucid: 0.07731,
                  calorie: 0.369,
                },
                {
                  id: 66,
                  name: 'Hành lá',
                  amount: 0.5,
                  usageRatio: 80,
                  protein: 0.0104,
                  lipid: 0.0,
                  glucid: 0.0416,
                  calorie: 0.208,
                },
                {
                  id: 67,
                  name: 'Hạt nêm',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.5850000000000002,
                },
                {
                  id: 68,
                  name: 'MùI tầu',
                  amount: 0.3,
                  usageRatio: 80,
                  protein: 0.016800000000000002,
                  lipid: 0.0044,
                  glucid: 0.034,
                  calorie: 0.24,
                },
                {
                  id: 69,
                  name: 'MuốI hạt',
                  amount: 0.8,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 70,
                  name: 'Mỳ chính',
                  amount: 0.12,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 71,
                  name: 'Nước mắm',
                  amount: 1.5,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21,
                },
                {
                  id: 72,
                  name: 'Xương lợn',
                  amount: 10.0,
                  usageRatio: 0,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
              ],
            },
            {
              id: 201,
              name: 'Cơm gạo dẻo',
              usageRatio: 100,
              ingredient: [
                {
                  id: 148,
                  name: 'Gạo bắc hương',
                  amount: 30.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.763,
                  calorie: 3.46,
                },
                {
                  id: 149,
                  name: 'Gạo pc',
                  amount: 70.0,
                  usageRatio: 100,
                  protein: 0.079,
                  lipid: 0.01,
                  glucid: 0.7629999999999999,
                  calorie: 3.46,
                },
              ],
            },
            {
              id: 202,
              name: 'Thịt lợn kho tàu 55g',
              usageRatio: 100,
              ingredient: [
                {
                  id: 294,
                  name: 'Bột canh ( hc)',
                  amount: 1.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 295,
                  name: 'Bột đao',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.002,
                  lipid: 0.0,
                  glucid: 0.856,
                  calorie: 3.4299999999999997,
                },
                {
                  id: 296,
                  name: 'Dầu ăn',
                  amount: 0.4,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 1.0,
                  glucid: 0.0,
                  calorie: 9.0,
                },
                {
                  id: 297,
                  name: 'Đường trắng',
                  amount: 2.0,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.993,
                  calorie: 3.97,
                },
                {
                  id: 298,
                  name: 'Hành khô cả vỏ',
                  amount: 0.4,
                  usageRatio: 76,
                  protein: 0.009879999999999998,
                  lipid: 0.0021279999999999997,
                  glucid: 0.03967199999999999,
                  calorie: 0.22039999999999998,
                },
                {
                  id: 299,
                  name: 'Hạt nêm',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.13,
                  lipid: 0.0,
                  glucid: 0.265,
                  calorie: 1.585,
                },
                {
                  id: 300,
                  name: 'Hạt tiêu đen xay',
                  amount: 0.01,
                  usageRatio: 100,
                  protein: 0.06999999999999999,
                  lipid: 0.074,
                  glucid: 0.676,
                  calorie: 3.65,
                },
                {
                  id: 301,
                  name: 'MuốI hạt',
                  amount: 0.3,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 302,
                  name: 'Mỳ chính',
                  amount: 0.08,
                  usageRatio: 100,
                  protein: 0.0,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.0,
                },
                {
                  id: 303,
                  name: 'Nước mắm',
                  amount: 0.6,
                  usageRatio: 100,
                  protein: 0.052,
                  lipid: 0.0,
                  glucid: 0.0,
                  calorie: 0.21000000000000002,
                },
                {
                  id: 304,
                  name: 'Thịt vai, mông',
                  amount: 55.0,
                  usageRatio: 98,
                  protein: 0.1862,
                  lipid: 0.06860000000000001,
                  glucid: 0.0,
                  calorie: 1.3622,
                },
              ],
            },
          ],
        },
        {
          id: '15.3',
          codeOrder: 'TD-KH-VHC2-BPHU-QCHIEU-20240712',
          channelDistribution: 'Bữa phụ',
          orderClassifications: 'Quà chiều',
          status: 'Khởi tạo',
          disks: [
            {
              id: 203,
              name: 'Dưa hấu',
              usageRatio: 100,
              ingredient: [
                {
                  id: 401,
                  name: '',
                  amount: 120.0,
                  usageRatio: 52,
                  protein: 0.00624,
                  lipid: 0.00104,
                  glucid: 0.01456,
                  calorie: 0.09359999999999999,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  meta: {
    currentPage: 1,
    perPage: 50,
    total: 1,
  },
};
