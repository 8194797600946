import { CheckCircleRounded, PlayCircle } from '@mui/icons-material';
import { Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { OperationStatus, ResponseDataList } from '../../../common/types';
import FilterBox from './FilterBox';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import { useState } from 'react';
import {
  ProcessingFoodFilterModel,
  ProcessingFoodModel,
} from '../../processing-food/types';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../common/components/ReasonDialog';
import useMutationReason from '../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../common/components/GenerateCodeBox/types';
import useGetReason, {
  ReasonDataModel,
} from '../../../common/hook/useGetReason';
import { ProcessingFoodConfirmStatus } from '../utils';

const ProcessingFoodConfirmListPage = () => {
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { mutationReason } = useMutationReason();
  const hookPagination = usePaginationHook<ProcessingFoodFilterModel>();

  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<ProcessingFoodModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingFoodModel>>({
          url: API_PATHS.processingFood.index,
          params: {
            ...params,
            filters: {
              ...params.filters,
              commodityGroup: params.filters.commodityGroup?.id,
              commodityClassification:
                params.filters.commodityClassification?.id,
              operationStatus: ProcessingFoodConfirmStatus,
            },
          },
        })
      );
      return json.data;
    },
  });

  const onActive = async (data: ProcessingFoodModel) => {
    const json = await dispatch(
      fetchThunk<ReasonDataModel>({
        url: API_PATHS.processingFood.reason(data?.id),
        method: 'get',
      })
    );
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingFood.detail(data?.id),
              method: 'put',
              data: {
                ...data,
                operationStatus: OperationStatus.confirm,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingFood.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.TPCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.confirm,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
          enqueueSnackbar({
            message: <FormattedMessage id={'updateSuccess'} />,
            variant: 'success',
          });
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'activate',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingFood.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name}
              <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id={data.operationStatus} />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.confirm} />,
        },
        {
          label: 'stopReason',
          value: json.data?.reason
            ? json.data?.reason
            : listType.TPCB?.reasons.stop?.find((v) => v.id === json.data?.key)
                ?.name,
        },
      ],
      reasons: listType.TPCB?.reasons?.activate,
    });
  };

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <FilterBox hookPagination={hookPagination} />
      </Box>
      <MuiTable<ProcessingFoodModel>
        data={data?.data ?? []}
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.data.length,
          page: params.pageIndex,
          rowsPerPage: params.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => {
          const isCreate = [OperationStatus.create].includes(
            row.original.operationStatus
          );
          return [
            isCreate ? (
              <MyLink to={row.original.id.toString()}>
                <IconButtonTooltip
                  tooltipProps={{ title: <FormattedMessage id="confirm" /> }}
                >
                  <CheckCircleRounded />
                </IconButtonTooltip>
              </MyLink>
            ) : (
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="confirm" /> }}
                disabled
              >
                <CheckCircleRounded />
              </IconButtonTooltip>
            ),
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="activate" /> }}
              disabled={isCreate}
              onClick={() => onActive(row.original)}
            >
              <PlayCircle />
            </IconButtonTooltip>,
          ];
        }}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.code" />,
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingFood.name" />,
            Cell: ({ renderedCellValue, row }) => {
              return (
                <MyLink
                  to={row.original.id.toString()}
                  underline="always"
                  color="inherit"
                >
                  {renderedCellValue}
                </MyLink>
              );
            },
          },
          {
            accessorKey: 'processingUnit.name',
            header: 'unit',
            Header: () => (
              <FormattedMessage id="processingFood.processingUnit" />
            ),
          },
          {
            accessorKey: 'commodityClassification.name',
            header: 'commodityClassification.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityClassification" />
            ),
          },
          {
            accessorKey: 'commodityGroup.name',
            header: 'commodityGroup.name ',
            Header: () => (
              <FormattedMessage id="processingFood.commodityGroup" />
            ),
          },
          {
            accessorKey: 'preliminaryType',
            header: 'preliminaryType',
            Header: () => (
              <FormattedMessage id="processingFood.preliminaryType" />
            ),
            Cell: ({ renderedCellValue }) => {
              const preliminaryType = listType.TPCB?.preliminaryType?.find(
                (v) => v.id === renderedCellValue
              );
              return preliminaryType?.name;
            },
          },
          {
            accessorKey: 'standardFood.name',
            header: 'standardFood.name ',
            Header: () => <FormattedMessage id="processingFood.standardFood" />,
          },
          {
            accessorKey: 'operationStatus',
            header: 'operationStatus',
            Header: () => <FormattedMessage id="status" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.TPCB?.operationStatus?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'status',
            header: 'status',
            Header: () => <FormattedMessage id="statusAdmin" />,
            Cell: ({ renderedCellValue }) => {
              const status = STATUS_OPTIONS.find(
                (v) => v.id === renderedCellValue
              );

              return (
                status && (
                  <Chip
                    label={<FormattedMessage id={status.name} />}
                    variant="outlined"
                    color={status.color as any}
                    size="small"
                  />
                )
              );
            },
          },
        ]}
      />
      <ReasonDialog {...model} />
    </Paper>
  );
};
export default ProcessingFoodConfirmListPage;
