import { OperationStatus } from '../../common/types';
import { ProcessingFoodModel } from '../processing-food/types';

export const ProcessingFoodConfirmStatus = [
  OperationStatus.create,
  OperationStatus.stop,
];
export const checkReadOnlyProcessingFoodConfirm = (
  data: ProcessingFoodModel
) => {
  return !ProcessingFoodConfirmStatus.includes(data.operationStatus);
};
