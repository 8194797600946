import {
  ExpandMoreOutlined,
  RefreshRounded,
  SearchRounded,
  TuneOutlined,
} from '@mui/icons-material';
import { Badge, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import SchemaForm from '../../../common/SchemaForm';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { UnProcessedDishFilterModel } from './types';
import { STATUS_OPTIONS } from '../../../common/constants';

interface Props {
  hookPagination: HookPaginationProps<UnProcessedDishFilterModel>;
}
const FilterBox = (props: Props) => {
  const { hookPagination } = props;
  const { setParams, clearParams, filters } = hookPagination;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <SchemaForm<UnProcessedDishFilterModel>
        formHookProps={{ defaultValues: filters }}
        globalProps={{
          noHelperText: true,
        }}
        onSubmit={(val) => setParams(val)}
        schema={{
          fields: ({ formProps: { intl, isMobile } }) => {
            return {
              searchText: {
                mode: 'node',
                hidden: isMobile,
                render: (
                  <Typography sx={{ mt: 1 }}>
                    {intl.formatMessage({
                      id: 'search',
                    })}
                    :
                  </Typography>
                ),
                propsWrapper: { mobile: undefined },
              },
              name: {
                mode: 'text-field',
                placeholder: intl.formatMessage({
                  id: 'unProcessingDish.search',
                }),
                propsWrapper: { mobile: true },
              },
              // statusText: {
              //   mode: 'node',
              //   hidden: isMobile,
              //   render: (
              //     <Typography sx={{ mt: 1 }}>
              //       {intl.formatMessage({
              //         id: 'status',
              //       })}
              //       :
              //     </Typography>
              //   ),
              //   propsWrapper: { mobile: undefined },
              // },
              // status: {
              //   mode: 'select',
              //   hidden: isMobile,
              //   options: STATUS_OPTION,
              //   hasAllOptions: true,
              //   disableClearBtn: true,
              //   propsWrapper: { mobile: 2 },
              // },
              submit: {
                mode: 'button',
                type: 'submit',
                variant: 'contained',
                startIcon: <SearchRounded />,
                children:
                  !isMobile &&
                  intl.formatMessage({
                    id: 'search',
                  }),
                propsWrapper: { mobile: undefined },
              },
              filter: {
                mode: 'button',
                variant: 'outlined',
                children:
                  !isMobile &&
                  intl.formatMessage({
                    id: 'filter',
                  }),
                startIcon: (
                  <Badge
                    badgeContent={Object.keys(filters).length}
                    variant="standard"
                    color="primary"
                  >
                    <TuneOutlined />
                  </Badge>
                ),
                endIcon: !isMobile && <ExpandMoreOutlined />,
                onClick: handleClick,
                propsWrapper: { mobile: undefined },
              },
              reset: {
                mode: 'button',
                hidden: isMobile,
                variant: 'outlined',
                children: <RefreshRounded />,
                onClick: () => clearParams(),
                propsWrapper: { mobile: undefined },
              },
            };
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            variant: 'outlined',
            elevation: 0,
            sx: { borderColor: 'primary.main', p: 2, mt: 2, width: 280 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SchemaForm<UnProcessedDishFilterModel>
          formHookProps={{ defaultValues: filters }}
          globalProps={{
            noHelperText: true,
          }}
          onChange={(val) => setParams(val)}
          schema={{
            fields: ({ formProps: { intl, listType } }) => {
              return {
                operationStatus: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  options: listType.MKCB?.operationStatus,
                  rawOptions: true,
                  hasAllOptions: true,
                  disableClearBtn: true,
                },
                status: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'statusAdmin',
                  }),
                  options: STATUS_OPTIONS,
                  hasAllOptions: true,
                  disableClearBtn: true,
                },
                objectType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'processingDish.objectType',
                  }),
                  options: listType.MKCB?.objectType,
                  rawOptions: true,
                  disableClearBtn: true,
                },
              };
            },
          }}
        />
      </Popover>
    </>
  );
};
export default FilterBox;
