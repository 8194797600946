import Dialog, { DialogProps } from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogTitle, DialogTitleProps } from '@mui/material';

export interface ParamsMessagePrompt {
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
  okId?: React.ReactNode | string;
  onClose?: () => void;
  slotProps?: {
    dialog?: Omit<DialogProps, 'open'>;
    title?: DialogTitleProps;
    content?: DialogContentProps;
    action?: DialogActionsProps;
  };
}
export interface MessageDialogParams {
  openDialog: (params: ParamsMessagePrompt) => void;
}

interface Props extends ParamsMessagePrompt {
  children?: (params: MessageDialogParams) => React.ReactNode;
}

const MessageDialog = forwardRef((props: Props, ref) => {
  const [open, setOpen] = useState(false);
  const [settings, setOptions] = useState<Props>(props);
  const { children, title, content, slotProps, okId } = settings;

  const onClose = useCallback(() => {
    setOpen(false);
    props.onClose && props.onClose();
    settings.onClose && settings.onClose();
  }, [props, settings]);

  const onOpen = useCallback((setting?: Omit<Props, 'children'>) => {
    setting && setOptions((old) => ({ ...old, ...setting }));
    setOpen(true);
  }, []);

  const params: MessageDialogParams = useMemo(
    () => ({
      open: open,
      openDialog: onOpen,
      onClose: onClose,
    }),
    [onClose, onOpen, open]
  );

  useImperativeHandle(ref, () => params);

  return (
    <>
      {children && children(params)}
      <Dialog
        maxWidth="tablet"
        fullWidth
        {...slotProps?.dialog}
        open={open}
        // classes={{ paper: classes.paper, ...dialogProps?.classes }}
        onClose={() => {
          onClose();
          setOptions(props);
        }}
        keepMounted={false}
      >
        {title && (
          <DialogTitle
            sx={{ padding: 4, textAlign: 'center' }}
            variant="h5"
            color="primary"
            {...slotProps?.title}
          >
            {title}
          </DialogTitle>
        )}
        {content && (
          <DialogContent sx={{ padding: 2 }} {...slotProps?.content}>
            {content}
          </DialogContent>
        )}
        <DialogActions
          sx={{ padding: 2, justifyContent: 'center' }}
          {...slotProps?.action}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{ minWidth: 120 }}
            onClick={onClose}
            title="ok"
          >
            {typeof okId !== 'string' && typeof okId !== 'undefined' ? (
              okId
            ) : (
              <FormattedMessage id={okId || 'ok'} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default MessageDialog;
