import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { AxiosResponse } from 'axios';

import { InputBaseProps } from '@mui/material/InputBase';
import React, { ReactNode, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  QueryFunction,
  QueryKey,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useDebounce } from 'usehooks-ts';
import { some } from '../../../constants';
import TextFieldElement from '../text-field/TextFieldElement';
import { ResponseDataList } from '../../../types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from '../../../../redux/store';
import { useDispatch } from 'react-redux';
import fetchThunk from '../../../fetchThunk';
import { ChipTypeMap } from '@mui/material';

export interface FormControlAutoCompletePropsBase<T extends some = any> {
  mainRef?: any;
  options?: T[];
  label?: React.ReactNode;
  formControlStyle?: React.CSSProperties;
  InputStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  error?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  required?: boolean;
  loadOptions?: {
    queryKey: (input: string) => QueryKey;
    mapped?: (value: T[]) => some[];
    config?: UndefinedInitialDataInfiniteOptions<any, any, any, any>;
    queryFn?: QueryFunction<AxiosResponse<ResponseDataList<T>>, any, any>;
  };
  loadKey?: any;
  disableSearchByText?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  autoFocus?: boolean;
  onChangeInput?: InputBaseProps['onChange'];
  windowScroll?: boolean;
  initialSearch?: string;
  name?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

export interface FormControlAutoCompleteProps<
  T extends some = any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> extends Omit<
      AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
      'renderInput' | 'options'
    >,
    FormControlAutoCompletePropsBase<T> {
  innerRef?: React.Ref<any>;
}

export const FormControlAutoComplete = <
  T extends some = any,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: FormControlAutoCompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >
) => {
  const {
    label,
    placeholder,
    error,
    formControlStyle,
    required,
    renderInput,
    options = [],
    loadOptions,
    startAdornment,
    endAdornment,
    InputStyle,
    inputStyle,
    labelStyle,
    innerRef,
    readOnly,
    onChangeInput,
    autoFocus,
    windowScroll,
    initialSearch,
    loadKey,
    disableSearchByText,
    loading: loadingProps,
    name,
    mainRef,
    ...rest
  } = props;

  const dispatch: ThunkDispatch<AppState, null, Action> = useDispatch();

  const [text, setText] = useState('');
  const textDebounce = useDebounce(text, 350);
  const { fetchNextPage, isFetching, data } = useInfiniteQuery<
    AxiosResponse<ResponseDataList<T>>
  >({
    queryKey: loadOptions?.queryKey
      ? loadOptions?.queryKey(textDebounce)
      : [textDebounce],
    queryFn: loadOptions?.queryFn
      ? loadOptions?.queryFn
      : async ({ queryKey }) => {
          const json = await dispatch(
            fetchThunk<ResponseDataList<T>>({
              url: queryKey[0] as string,
              params: queryKey[1],
            })
          );
          return json;
        },
    initialPageParam: 1,
    enabled: !!loadOptions,
    getNextPageParam: (lastPage) => {
      return lastPage.data?.meta?.currentPage < lastPage.data?.meta?.lastPage
        ? lastPage.data?.meta?.currentPage + 1
        : null;
    },
    getPreviousPageParam: (lastPage) => {
      return lastPage.data?.meta?.currentPage > 1
        ? lastPage.data?.meta?.currentPage - 1
        : 1;
    },
    ...loadOptions?.config,
  });

  const loading = isFetching || loadingProps;

  const FlattenData = useMemo(() => {
    return loadOptions
      ? data?.pages.reduce(
          (
            value: some[],
            current: AxiosResponse<ResponseDataList<some>> | any
          ) => {
            if (Array.isArray(current?.data?.data)) {
              return [...value, ...(current?.data?.data || [])];
            } else if (Array.isArray(current?.data)) {
              return [...value, ...(current?.data || [])];
            } else if (Array.isArray(current))
              return [...value, ...(current || [])];
            else return value;
          },
          []
        )
      : loadOptions;
  }, [data?.pages, loadOptions]);

  const optionsTmp = useMemo(() => {
    if (loadOptions) {
      return FlattenData || ([] as any);
    }
    return options;
  }, [FlattenData, loadOptions, options]);

  const mappedOptions = loadOptions?.mapped
    ? loadOptions.mapped(optionsTmp) ?? []
    : optionsTmp;

  const handleScroll = async (event) => {
    const ele = event.currentTarget;

    if (ele.scrollTop === ele.scrollHeight - ele.clientHeight && ele) {
      await fetchNextPage();
    }
  };

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo, ChipComponent>
      ref={mainRef}
      fullWidth
      options={mappedOptions as any}
      onInputChange={(event: object, value: string, reason: string) => {
        if (reason === 'input' && !disableSearchByText && loadOptions)
          setText(value);
      }}
      noOptionsText={<FormattedMessage id="noOption" />}
      disabled={readOnly}
      onFocus={() => setText('')}
      renderInput={
        renderInput ||
        (({ InputProps, inputProps, ...params }) => (
          <TextFieldElement
            {...params}
            disabled={rest.disabled || false}
            fullWidth
            error={error}
            label={label}
            inputRef={innerRef}
            placeholder={placeholder}
            inputProps={{
              ...inputProps,
              autoComplete: 'off',
              style: inputStyle,
            }}
            required={required}
            InputProps={{
              ...InputProps,
              readOnly,
              autoFocus,
              sx: InputStyle,
              startAdornment: (
                <>
                  {startAdornment}
                  {InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading && (
                    <InputAdornment position="end">
                      <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                  )}
                  {endAdornment}
                  {InputProps.endAdornment}
                </>
              ),
              error,
            }}
            onChange={onChangeInput}
            variant="outlined"
            size="medium"
          />
        ))
      }
      getOptionLabel={(option) =>
        (typeof option === 'string' ? option : option?.name || ' ') as string
      }
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      autoComplete
      onMouseDownCapture={(e) => !optionsTmp?.length && e.stopPropagation()}
      disableCloseOnSelect={!!rest.multiple}
      ListboxProps={{
        role: 'list-box',
        onScroll: handleScroll,
      }}
      // PopperComponent={(val) => <Popper {...val} disablePortal />} dùng khi để fullscreen
      {...(loadOptions && !disableSearchByText
        ? { filterOptions: (option) => option }
        : {})}
      {...rest}
    />
  );
};

export default FormControlAutoComplete;
