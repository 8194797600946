import { RefreshRounded, SearchRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import SchemaForm from '../../../common/SchemaForm';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { QualityStandardFilterModel } from './types';
import { STATUS_OPTIONS } from '../../../common/constants';

interface Props {
  hookPagination: HookPaginationProps<QualityStandardFilterModel>;
}
const FilterBox = (props: Props) => {
  const { hookPagination } = props;

  const { setParams, clearParams, filters } = hookPagination;

  return (
    <>
      <SchemaForm<QualityStandardFilterModel>
        formHookProps={{ defaultValues: filters }}
        onSubmit={(val) => setParams(val)}
        schema={{
          fields: ({ formProps: { intl, isMobile } }) => {
            return {
              searchText: {
                mode: 'node',
                hidden: isMobile,
                render: (
                  <Typography sx={{ mt: 1 }}>
                    {intl.formatMessage({
                      id: 'search',
                    })}
                    :
                  </Typography>
                ),
                noHelperText: true,

                propsWrapper: { mobile: undefined },
              },
              name: {
                mode: 'text-field',
                placeholder: intl.formatMessage({
                  id: 'qualityStandard.name',
                }),
                'data-testid': 'search-input',
                propsWrapper: { mobile: true },
              },
              statusText: {
                mode: 'node',
                hidden: isMobile,
                render: (
                  <Typography sx={{ mt: 1 }}>
                    {intl.formatMessage({
                      id: 'status',
                    })}
                    :
                  </Typography>
                ),
                propsWrapper: { mobile: undefined },
              },
              status: {
                mode: 'select',
                hidden: isMobile,
                options: STATUS_OPTIONS,
                hasAllOptions: true,
                disableClearBtn: true,
                propsWrapper: { mobile: 2 },
              },
              submit: {
                mode: 'button',
                type: 'submit',
                variant: 'contained',
                startIcon: <SearchRounded />,
                children:
                  !isMobile &&
                  intl.formatMessage({
                    id: 'search',
                  }),
                propsWrapper: { mobile: undefined },
              },
              reset: {
                mode: 'button',
                hidden: isMobile,
                variant: 'outlined',
                children: <RefreshRounded />,
                onClick: () => clearParams(),
                propsWrapper: { mobile: undefined },
              },
            };
          },
        }}
      />
    </>
  );
};
export default FilterBox;
