import { Box, BoxProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { ProcessingFoodModel } from '../../../processing-food/types';

interface Props {
  data?: ProcessingFoodModel;
  boxProps?: BoxProps;
}
const ProcessingFoodInfoBox = (props: Props) => {
  const { data, boxProps } = props;
  const { listType } = useGeneralHook();
  return (
    <>
      <Box className="flex flex-col gap-2" {...boxProps}>
        {[
          {
            label: <FormattedMessage id={'processingFood.fullName'} />,
            value: data?.name,
          },

          {
            label: <FormattedMessage id={'processingFood.operationCode'} />,
            value: data?.operationCode,
          },
          {
            label: (
              <FormattedMessage id={'processingFood.commodityClassification'} />
            ),
            value: data?.commodityClassification?.name,
          },
          {
            label: <FormattedMessage id={'processingFood.commodityGroup'} />,
            value: [
              data?.commodityGroup?.name,
              listType.NMH?.type.find(
                (v) => v.id === data?.commodityGroup?.type
              )?.name,
            ]
              .filter(Boolean)
              .join(' - '),
          },
          {
            label: <FormattedMessage id={'processingFood.preliminaryType'} />,
            value: listType.TPCB?.preliminaryType?.find(
              (v) => v.id === data?.preliminaryType
            )?.name,
          },
          {
            label: <FormattedMessage id={'processingFood.processingUnit'} />,
            value: data?.processingUnit?.name,
          },
          {
            label: <FormattedMessage id={'processingFood.conversionRatio'} />,
            value: data?.conversionRatio,
          },
          {
            label: <FormattedMessage id={'status'} />,
            value: listType.TPCB?.operationStatus?.find(
              (v) => v.id === data?.operationStatus
            )?.name,
          },
          {
            label: <FormattedMessage id={'description'} />,
            value: data?.description,
          },
        ].map((item, index) => {
          return (
            <Box className="flex break-inside-avoid mb-1" key={index}>
              <Typography
                variant="subtitle2"
                component={'div'}
                flexShrink={0}
                whiteSpace={'nowrap'}
                color={'inherit'}
              >
                {item.label}
              </Typography>
              <Typography
                variant="body2"
                color={'inherit'}
                sx={{ wordBreak: 'break-all', display: 'flex' }}
              >
                {item.value ? <>:&nbsp;{item.value}</> : item.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};
export default ProcessingFoodInfoBox;
