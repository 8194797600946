/* eslint-disable react/style-prop-object */
import { AddRounded, EditRounded } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import { UnProcessedDishFilterModel, UnProcessingDishModel } from './types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import { useState } from 'react';
import FilterBox from './FilterBox';
import UpdatePriceDialog, { UpdatePriceDialogProps } from './UpdatePriceDialog';

const SetupPriceUnProcessingDishTab = () => {
  const { dispatch, fetchThunk, API_PATHS, listType } = useGeneralHook();
  const hookPagination = usePaginationHook<UnProcessedDishFilterModel>();
  const [form, setForm] = useState<UpdatePriceDialogProps>({
    open: false,
    onClose: () => setForm((old) => ({ ...old, open: false })),
  });

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;
  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<UnProcessingDishModel>
  >({
    queryKey: [KEYS_QUERY.processingFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<UnProcessingDishModel>>({
          url: API_PATHS.unProcessingDish.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <FilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.unProcessingDish.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<UnProcessingDishModel>
        data={
          data?.data?.slice(
            (params.pageIndex - 1) * params.pageSize,
            params.pageIndex * params.pageSize
          ) ?? []
        }
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.data.length,
          page: params.pageIndex,
          rowsPerPage: params.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => {
          return [
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="view" /> }}
              onClick={() => {
                setForm((old) => ({
                  ...old,
                  open: true,
                  data: row.original,
                }));
              }}
            >
              <EditRounded />
            </IconButtonTooltip>,
          ];
        }}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => (
              <FormattedMessage id="unProcessingDish.operationCode" />
            ),
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="unProcessingDish.name" />,
          },
          {
            accessorKey: 'originalDish.name',
            header: 'originalDish.name',
            Header: () => (
              <FormattedMessage id="unProcessingDish.originalDish" />
            ),
          },
          {
            accessorKey: 'objectType',
            header: 'objectType ',
            Header: () => <FormattedMessage id="unProcessingDish.objectType" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.MKCB?.objectType?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'mainFoodQuantity',
            header: 'mainFoodQuantity',
            Header: () => (
              <FormattedMessage id="unProcessingDish.mainFoodQuantity" />
            ),
          },

          {
            accessorKey: 'price',
            header: 'price',
            Header: () => <FormattedMessage id="setupPrice.commonPrice" />,
            Cell: ({ renderedCellValue }) => {
              return (
                <FormattedNumber
                  value={80000}
                  style="currency"
                  currency="VND"
                />
              );
            },
          },
        ]}
      />
      <UpdatePriceDialog {...form} />
    </Paper>
  );
};
export default SetupPriceUnProcessingDishTab;
