import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { OriginalDishModel } from './types';
import { ResponseDataDetail } from '../../../common/types';

const useOriginDishDetail = (id?: string) => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isLoading } = useQuery<OriginalDishModel>({
    queryKey: [id, KEYS_QUERY.channelDistributionsDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<OriginalDishModel>>({
          url: API_PATHS.originDish.detail(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  return { data, isLoading };
};
export default useOriginDishDetail;
