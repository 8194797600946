import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../constants';

export interface PropsMultipleRadioElement
  extends Omit<RadioGroupProps, 'defaultValue'> {
  label?: React.ReactNode;
  onChange?: (value) => void;
  options?: some[];
  error?: boolean;
  readOnly?: boolean;
  rawOptions?: boolean;
  radioProps?: RadioProps;
  required?: boolean;
  formControlProps?: FormControlProps;
  formControlLabelProps?: FormControlLabelProps;
}

interface Props extends PropsMultipleRadioElement {}

const MultipleRadioElement = React.forwardRef<FormControlProps['ref'], Props>(
  (props: Props, ref: any) => {
    const {
      options,
      label,
      row = false,
      onChange,
      value,
      error,
      rawOptions,
      radioProps,
      required,
      formControlProps,
      formControlLabelProps,
      readOnly,
      ...rest
    } = props;

    const handleChange = (event) => {
      let tmp = event.target.value;
      if (typeof value === 'number') {
        tmp = Number.parseInt(tmp, 10);
      }
      onChange && onChange(tmp);
    };

    return (
      <FormControl error={!!error} ref={ref} {...formControlProps}>
        {label && (
          <FormLabel component="legend">
            <InputLabel
              required={required}
              style={{
                height: 'auto',
                display: 'unset',
              }}
            >
              {label}
            </InputLabel>
          </FormLabel>
        )}
        <RadioGroup {...rest} value={value} row={row} onChange={handleChange}>
          {options?.map(
            ({ name: labelOption = '', id }: some, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Radio style={{ padding: 2 }} {...radioProps} />}
                  style={{ marginLeft: 0 }}
                  label={
                    typeof labelOption === 'string' ? (
                      <Typography variant={'body2'}>
                        {rawOptions ? (
                          labelOption
                        ) : labelOption ? (
                          <FormattedMessage id={labelOption} />
                        ) : (
                          ''
                        )}
                      </Typography>
                    ) : (
                      labelOption
                    )
                  }
                  value={id}
                  disabled={readOnly}
                  {...formControlLabelProps}
                />
              );
            }
          )}
        </RadioGroup>
      </FormControl>
    );
  }
);
export default memo(MultipleRadioElement);
