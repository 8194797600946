import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import useGetProcessingDishDetail from './useGetProcessingDishDetail';

import { ProcessingDishModel } from '../types';
import { OperationStatus, ResponseDataDetail } from '../../../common/types';
import OriginalDishInfoBox from './OriginalDishInfoBox';
import { checkReadOnlyProcessingDishQuota } from '../utils';

const ProcessingDishDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, fetchThunk, API_PATHS, listType, enqueueSnackbar } =
    useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useGetProcessingDishDetail(id);

  const onCreateUpdate = useMutation({
    mutationFn: async (values: ProcessingDishModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<ResponseDataDetail<ProcessingDishModel>>({
            url: API_PATHS.processingDish.index,
            method: 'post',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
        if (key === 'default') {
          navigate(
            [
              '',
              ROUTES_PATH.techniques.index,
              ROUTES_PATH.processingDish.index,
            ].join('/')
          );
        } else {
          navigate(-1);
        }
      } else {
        await dispatch(
          fetchThunk({
            url: API_PATHS.processingDish.detail(values.id),
            method: 'put',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
        if (key === 'default') {
          navigate(
            [
              '',
              ROUTES_PATH.techniques.index,
              ROUTES_PATH.processingDish.index,
            ].join('/')
          );
        } else {
          navigate(-1);
        }
      }
    },
  });

  if (isLoading) {
    return <MuiLoading />;
  }
  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <SchemaForm<ProcessingDishModel>
          formData={data}
          formHookProps={{
            defaultValues: {
              operationStatus: OperationStatus.create,
              status: true,
              nutritionRequired: false,
            },
          }}
          globalProps={{
            readOnly: data?.id ? checkReadOnlyProcessingDishQuota(data) : false,
          }}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          schema={{
            fields: ({
              formProps: { intl, isMobile, readOnly },
              valuesField,
            }) => {
              return {
                name: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'processingDish.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationCode: {
                  mode: 'raw',
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      objectType={valuesField?.objectType}
                      module="MCB"
                      label={intl.formatMessage({
                        id: 'processingDish.operationCode',
                      })}
                    />
                  ),
                  propsWrapper: { tablet: 6 },
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                originalDish: {
                  mode: 'auto-complete',
                  label: intl.formatMessage({
                    id: 'processingDish.originalDish',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  loadOptions: {
                    queryKey: (text) => [API_PATHS.originDish.index, text],
                    queryFn: async ({ pageParam = 1, queryKey }) => {
                      const json = await dispatch(
                        fetchThunk({
                          url: queryKey[0],
                          params: {
                            pageIndex: pageParam,
                            filters: { name: queryKey[1] },
                          },
                        })
                      );
                      return json;
                    },
                  },
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                display: {
                  mode: 'node',
                  hidden: !isMobile,
                  render: () => {
                    return (
                      <OriginalDishInfoBox data={valuesField.originalDish} />
                    );
                  },
                  propsWrapper: {
                    style: { paddingTop: 0 },
                    tablet: 6,
                  },
                },
                displayName: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'processingDish.displayName',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                display2: {
                  mode: 'node',
                  hidden: isMobile,
                  render: () => {
                    return (
                      <OriginalDishInfoBox data={valuesField.originalDish} />
                    );
                  },
                  propsWrapper: {
                    style: { paddingTop: 0 },
                    tablet: 6,
                  },
                },
                nutritionRequired: {
                  mode: 'checkbox',
                  label: intl.formatMessage({
                    id: 'processingDish.nutritionRequired',
                  }),
                  labelPlacement: 'top',
                  propsWrapper: { style: { paddingTop: 0 }, tablet: 6 },
                  noHelperText: true,
                },
                mainFoodQuantity: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'processingDish.mainFoodQuantity',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  type: 'number',
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                objectType: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'processingDish.objectType',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  options: listType.MCB?.objectType,
                  rules: { required: true },
                  rawOptions: true,
                  propsWrapper: { tablet: 6 },
                },
                operationStatus: {
                  mode: 'select',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'select',
                  }),
                  options: listType.MCB?.operationStatus,
                  rawOptions: true,
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                  readOnly: true,
                },
                blank: { mode: 'node', propsWrapper: { tablet: 6 } },
                description: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                  propsWrapper: { tablet: 6 },
                },
                blank2: { mode: 'node', propsWrapper: { tablet: 6 } },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.techniques.index,
                          ROUTES_PATH.processingDish.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    tablet: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  hidden: readOnly,
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
          }}
        />
      </Paper>
    </>
  );
};
export default ProcessingDishDetailPage;
