import { SaveRounded, UndoRounded } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import SchemaForm from '../../../common/SchemaForm';
import GenerateCodeBox from '../../../common/components/GenerateCodeBox';
import MuiLoading from '../../../common/components/MuiLoading';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES_PATH } from '../../../layout/constants';
import { QualityStandardModel } from './types';
import { ResponseDataDetail } from '../../../common/types';

const QualityStandardDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { dispatch, fetchThunk, API_PATHS, enqueueSnackbar } = useGeneralHook();
  const { key } = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<QualityStandardModel>({
    queryKey: [id, KEYS_QUERY.qualityStandardDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<QualityStandardModel>>({
          url: API_PATHS.qualityStandard.detail(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  const onCreateUpdate = useMutation({
    mutationFn: async (values: QualityStandardModel) => {
      if (!values.id) {
        await dispatch(
          fetchThunk<ResponseDataDetail<QualityStandardModel>>({
            url: API_PATHS.qualityStandard.index,
            method: 'post',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'createSuccess'} />,
          variant: 'success',
        });
      } else {
        await dispatch(
          fetchThunk({
            url: API_PATHS.qualityStandard.detail(values.id),
            method: 'put',
            data: values,
          })
        );
        enqueueSnackbar({
          message: <FormattedMessage id={'updateSuccess'} />,
          variant: 'success',
        });
      }
      if (key === 'default') {
        navigate(
          ['', ROUTES_PATH.list.index, ROUTES_PATH.qualityStandard.index].join(
            '/'
          )
        );
      } else {
        navigate(-1);
      }
    },
  });

  if (!isLoading && !data && id) {
    return <Navigate to={ROUTES_PATH[404]} replace />;
  }

  return (
    <Paper sx={{ p: 2 }}>
      {isLoading ? (
        <MuiLoading />
      ) : (
        <SchemaForm<QualityStandardModel>
          formData={data}
          onSubmit={(val) => onCreateUpdate.mutateAsync(val)}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                name: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'qualityStandard.name',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  rules: { required: true },
                  propsWrapper: { tablet: 6 },
                },
                operationCode: {
                  mode: 'raw',
                  render: (params) => (
                    <GenerateCodeBox
                      {...params}
                      initialValue={data?.operationCode}
                      module="TCCL"
                      label={intl.formatMessage({
                        id: 'qualityStandard.operationCode',
                      })}
                    />
                  ),
                  propsWrapper: { tablet: 6 },
                  rules: {
                    validate: (val: string) => {
                      return val?.split('-').every((v) => !!v.trim())
                        ? true
                        : 'required';
                    },
                  },
                },
                description: {
                  mode: 'text-field',
                  label: intl.formatMessage({
                    id: 'description',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'enter',
                  }),
                  multiline: true,
                  rows: 3,
                },
                status: {
                  mode: 'switch',
                  label: intl.formatMessage({
                    id: 'status',
                  }),
                  defaultValue: true,
                },
                back: {
                  mode: 'button',
                  variant: 'outlined',
                  startIcon: <UndoRounded />,
                  children: intl.formatMessage({
                    id: 'back',
                  }),
                  onClick: () => {
                    if (key === 'default') {
                      navigate(
                        [
                          '',
                          ROUTES_PATH.list.index,
                          ROUTES_PATH.qualityStandard.index,
                        ].join('/')
                      );
                    } else {
                      navigate(-1);
                    }
                  },
                  propsWrapper: {
                    mobile: true,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                },
                submit: {
                  mode: 'button',
                  type: 'submit',
                  variant: 'contained',
                  startIcon: <SaveRounded />,
                  children: intl.formatMessage({
                    id: 'save',
                  }),
                  propsWrapper: {
                    mobile: undefined,
                  },
                },
              };
            },
          }}
        />
      )}
    </Paper>
  );
};
export default QualityStandardDetailPage;
