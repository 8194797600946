import { useQuery } from '@tanstack/react-query';
import { KEYS_QUERY } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ResponseDataDetail } from '../../../common/types';
import { ProcessingDishModel } from '../types';

const useGetProcessingDishDetail = (id?: string) => {
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, isLoading } = useQuery<ProcessingDishModel>({
    queryKey: [id, KEYS_QUERY.processingDishDetail],
    queryFn: async ({ queryKey }) => {
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<ProcessingDishModel>>({
          url: API_PATHS.processingDish.detail(queryKey[0]),
        })
      );
      return json.data.data;
    },
    enabled: !!id,
  });

  return { data, isLoading };
};
export default useGetProcessingDishDetail;
