import { Box, Card, IconButton, Paper, Radio, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { CancelRounded } from '@mui/icons-material';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import {
  StandardFoodFilterModel,
  StandardFoodModel,
} from '../../standard-food/types';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { ResponseDataList } from '../../../common/types';
import { KEYS_QUERY, some } from '../../../common/constants';
import StandardFoodFilterBox from './StandardFoodFilterBox';
import MuiTable from '../../../common/components/MuiTable';
import { IControllerRenderProps } from '../../../common/SchemaForm/utils';
import { useMemo } from 'react';
import clsx from 'clsx';

interface Props<T extends some = any> extends IControllerRenderProps<T> {
  readOnly?: boolean;
}

const StandardFoodSelectTable = <T extends some = any>(props: Props<T>) => {
  const { field, readOnly } = props;
  const { value, onChange } = field;
  const { dispatch, fetchThunk, API_PATHS, listType } = useGeneralHook();

  const hookPagination = usePaginationHook<StandardFoodFilterModel>({
    disableLink: true,
    defaultFilter: { pageSize: 20 },
  });

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;

  const { data, isLoading } = useQuery<ResponseDataList<StandardFoodModel>>({
    queryKey: [KEYS_QUERY.standardFood, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<StandardFoodModel>>({
          url: API_PATHS.standardFood.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  const StandardFoodCard = useMemo(() => {
    if (!value) return null;
    return (
      <Card
        variant="outlined"
        className={clsx(
          readOnly ? 'w-full' : '',
          'flex flex-col justify-center items-center my-4 w-fit mx-auto py-4 px-8 relative'
        )}
      >
        {!readOnly && (
          <IconButton
            color="primary"
            sx={{ position: 'absolute', top: 1, right: 1 }}
            onClick={() => onChange(undefined)}
          >
            <CancelRounded />
          </IconButton>
        )}
        <Typography>
          <FormattedMessage id="processedFoodConfirm.selectedFood" />
          :&nbsp;
          {[
            value.operationCode,
            value.name,
            listType?.TPTC?.type?.find((val) => val.id === value.sourceType)
              ?.name,
          ]
            .filter(Boolean)
            .join(' - ')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            px: 6,
            mt: 1,
          }}
        >
          {['usageRatio', 'protein', 'lipid', 'glucid', 'calorie'].map(
            (val, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: 200,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'primary.main',
                      py: 1,
                      height: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="subtitle2">
                      <FormattedMessage id={`standardFood.${val}`} />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: '1px dashed',
                      borderColor: 'primary.main',
                      px: 2,
                      py: 1,
                      textAlign: 'center',
                    }}
                  >
                    {value[val]}
                  </Box>
                </Box>
              );
            }
          )}
        </Box>
      </Card>
    );
  }, [listType?.TPTC?.type, onChange, readOnly, value]);

  if (readOnly) {
    return (
      <>
        <Typography variant="body2">
          <FormattedMessage id="standardFood.name" />
        </Typography>
        {StandardFoodCard}
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" mb={1}>
        <FormattedMessage id="unProcessingDish.standardFood" />
      </Typography>
      <Paper variant="outlined">
        <Box className="p-2">
          <StandardFoodFilterBox hookPagination={hookPagination} />
          {StandardFoodCard}
        </Box>

        <MuiTable<StandardFoodModel>
          boxProps={{ sx: { p: 0 } }}
          data={data?.data ?? []}
          state={{
            isLoading: isLoading,
            sorting,
          }}
          muiTableContainerProps={{ style: { height: 320, overflow: 'auto' } }}
          enableStickyHeader
          onSortingChange={onSortingChange}
          paginationProps={{
            count: data?.meta.total,
            page: data?.meta.currentPage,
            rowsPerPage: data?.meta.perPage,
            onPageChange: onPageChange,
            onRowsPerPageChange: onRowsPerPageChange,
            boxProps: { px: 2 },
          }}
          columns={[
            {
              header: 'select',
              Header: () => <FormattedMessage id="unProcessingDish.select" />,
              size: 80,
              Cell: ({ row }) => {
                const checked = value?.id === row.original.id;
                return (
                  <Radio
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        onChange(row.original);
                      } else {
                        onChange(undefined);
                      }
                    }}
                    sx={{ p: 0.5 }}
                  />
                );
              },
            },
            {
              accessorKey: 'id',
              header: 'id',
              enableSorting: true,
              Header: () => <FormattedMessage id="id" />,
              size: 70,
            },
            {
              accessorKey: 'operationCode',
              header: 'operationCode',
              enableSorting: true,
              Header: () => (
                <FormattedMessage id="standardFood.operationCode" />
              ),
              size: 240,
            },
            {
              accessorKey: 'name',
              header: 'name',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.name" />,
            },
            {
              accessorKey: 'sourceType',
              header: 'sourceType',
              Header: () => <FormattedMessage id="standardFood.sourceType" />,
              Cell: ({ renderedCellValue }) => {
                return listType?.TPTC?.sourceType?.find(
                  (val) => val.id === renderedCellValue
                )?.name;
              },
            },
            {
              accessorKey: 'usageRatio',
              header: 'usageRatio',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.usageRatio" />,
            },
            {
              accessorKey: 'protein',
              header: 'protein',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.protein" />,
              size: 100,
            },
            {
              accessorKey: 'lipid',
              header: 'lipid',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.lipid" />,
              size: 100,
            },
            {
              accessorKey: 'glucid',
              header: 'glucid',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.glucid" />,
              size: 100,
            },

            {
              accessorKey: 'calorie',
              header: 'calorie',
              enableSorting: true,
              Header: () => <FormattedMessage id="standardFood.calorieList" />,
              size: 100,
            },
          ]}
        />
      </Paper>
    </>
  );
};
export default StandardFoodSelectTable;
