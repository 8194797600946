import { useMutation } from '@tanstack/react-query';
import { ModuleCode } from '../components/GenerateCodeBox/types';
import useGeneralHook from './useGeneralHook';

interface IParams {
  objectId: string | number;
  objectType: ModuleCode;
  key: string;
  reason: string;
  data?: any;
  statusBefore: string | boolean;
  statusAfter: string | boolean;
}
const useMutationReason = () => {
  const { dispatch, fetchThunk } = useGeneralHook();

  const mutationReason = useMutation({
    mutationKey: ['update-reason'],
    mutationFn: async (value: { url: string; params: IParams }) => {
      dispatch(
        fetchThunk({
          url: value.url,
          method: 'post',
          data: value.params,
        })
      );
    },
  });
  return { mutationReason };
};
export default useMutationReason;
