import { ProcessingFoodModel } from '../../processing-food/types';

export const mappedProcessingDishQuotaData = (
  values: ProcessingFoodModel[] = []
): ProcessingFoodModel[] => {
  return values.map((value: ProcessingFoodModel) => {
    return {
      ...value,
      standardFood: {
        ...value.standardFood,
        calorie:
          (Number(value.standardFood.calorie || 0) *
            ((Number(value.quantity || 0) || 0) / 100) *
            Number(value.conversionRatio || 0) *
            (Number(value.usageRatio || value.standardFood.usageRatio || 0) /
              100)) /
          100,
        lipid:
          (Number(value.standardFood.lipid || 0) *
            ((Number(value.quantity || 0) || 0) / 100) *
            Number(value.conversionRatio || 0) *
            (Number(value.usageRatio || value.standardFood.usageRatio || 0) /
              100)) /
          100,
        protein:
          (Number(value.standardFood.protein || 0) *
            ((Number(value.quantity || 0) || 0) / 100) *
            Number(value.conversionRatio || 0) *
            (Number(value.usageRatio || value.standardFood.usageRatio || 0) /
              100)) /
          100,
        glucid:
          (Number(value.standardFood.glucid || 0) *
            ((Number(value.quantity || 0) || 0) / 100) *
            Number(value.conversionRatio || 0) *
            (Number(value.usageRatio || value.standardFood.usageRatio || 0) /
              100)) /
          100,
      },
    };
  });
};
