import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

interface Props {}
const InfoBoxTitle = (props: Props) => {
  return (
    <Box className={'flex gap-1 w-full'}>
      {[
        {
          label: 'processingDish.name',
          className: 'w-1/6',
        },
        {
          label: 'processingDish.operationCode',
          className: 'w-1/6',
        },
        {
          label: 'processingDish.mainFoodQuantity',
          className: 'w-1/6',
        },
        {
          label: 'processingDish.originalDish',
          className: 'w-1/6',
        },
        {
          label: 'processingDish.objectType',
          className: 'flex-1',
        },
        {
          label: 'processingDish.nutritionRequired',
          className: 'w-20',
        },
      ].map((item, index) => {
        return (
          <Typography
            key={index}
            variant="subtitle2"
            flexShrink={0}
            color={'inherit'}
            className={clsx(
              item.className,
              'whitespace-pre-wrap text-center shrink-0'
            )}
          >
            <FormattedMessage id={item.label} />
          </Typography>
        );
      })}
    </Box>
  );
};
export default InfoBoxTitle;
