import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Box, Typography } from '@mui/material';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import {
  ProcessingDishModel,
  QuotaModel,
} from '../../../processing-dish/types';
import MuiTable from '../../../../common/components/MuiTable';
import { ProcessingFoodModel } from '../../../processing-food/types';
import { mappedProcessingDishQuotaData } from '../../../processing-dish/quota/utils';
import { OperationStatus } from '../../../../common/types';
import { STATUS_OPTIONS } from '../../../../common/constants';

interface Props {
  processingDish: ProcessingDishModel;
  quotas: QuotaModel;
}
const ConfirmDataBox = (props: Props) => {
  const { processingDish, quotas } = props;
  const { listType } = useGeneralHook();

  const mappedData = mappedProcessingDishQuotaData(quotas.items);

  const referenceContent = (
    <Box className={'flex flex-col tablet:flex-row tablet:gap-4'}>
      <Box flex={1}>
        {[
          {
            label: 'processingDish.name',
            value: processingDish?.name,
          },
          {
            label: 'processingDish.mainFoodQuantity',
            value: processingDish?.mainFoodQuantity,
          },
          {
            label: 'processingDish.objectType',
            value: listType.MCB?.objectType?.find(
              (v) => v.id === processingDish.objectType
            )?.name,
          },
          {
            label: 'status',
            value: processingDish?.operationStatus && (
              <FormattedMessage id={processingDish?.operationStatus} />
            ),
          },
          {
            label: 'statusAdmin',
            value: (
              <FormattedMessage
                id={
                  STATUS_OPTIONS.find((v) => v.id === processingDish?.status)
                    ?.name || 'deactive'
                }
              />
            ),
          },
          {
            label: 'processingDish.originalDish',
            value: processingDish?.originalDish.name,
          },
          {
            label: 'originDish.type',
            value:
              processingDish?.originalDish &&
              listType.MG?.type?.find(
                (v) => v.id === processingDish?.originalDish?.type
              )?.name,
            italic: true,
          },
          {
            label: 'originDish.cookingType',
            value:
              processingDish?.originalDish &&
              listType.MG?.cookingType?.find(
                (v) => v.id === processingDish?.originalDish?.cookingType
              )?.name,
            italic: true,
          },
          {
            label: 'originDish.processingUnit',
            value:
              processingDish?.originalDish &&
              listType.MG?.processingUnit?.find(
                (v) => v.id === processingDish?.originalDish?.processingUnit
              )?.name,
            italic: true,
          },
        ].map((item, index) => {
          return (
            <Typography
              key={index}
              variant="inherit"
              component={'div'}
              flexShrink={0}
              color={'inherit'}
            >
              <Typography
                variant="inherit"
                fontWeight={700}
                component={'span'}
                whiteSpace={'nowrap'}
                fontStyle={item.italic ? 'italic' : undefined}
              >
                <FormattedMessage id={item.label} />
                :&nbsp;
              </Typography>
              {item.value}
            </Typography>
          );
        })}
      </Box>
      <Box flex={1}>
        {[
          {
            label: 'processingDish.operationCode',
            value: processingDish?.operationCode,
          },

          {
            label: 'processingDish.displayName',
            value: processingDish?.displayName,
          },

          {
            label: 'processingDish.nutritionRequired',
            value: (
              <FormattedMessage
                id={processingDish?.nutritionRequired ? 'yes' : 'no'}
              />
            ),
          },

          {
            label: 'description',
            value: processingDish?.description,
          },
        ].map((item, index) => {
          return (
            <Typography
              key={index}
              variant="inherit"
              component={'div'}
              flexShrink={0}
              color={'inherit'}
            >
              <Typography
                variant="inherit"
                fontWeight={700}
                component={'span'}
                whiteSpace={'nowrap'}
              >
                <FormattedMessage id={item.label} />
                :&nbsp;
              </Typography>
              {item.value}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
  return (
    <>
      {[
        {
          label: 'type',
          value: <FormattedMessage id="processingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {processingDish?.name}
              <br />
              {processingDish?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id={processingDish.operationStatus} />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id={OperationStatus.confirm} />,
        },
        {
          label: 'unProcessingDish.referenceInfo',
          node: referenceContent,
        },
      ].map((item, index) => {
        if (item.node) {
          return (
            <Box key={index}>
              <Typography variant="body2" component={'div'} mt={2}>
                <FormattedMessage id={item.label} />:
              </Typography>
              <Typography variant="body2" component={'div'}>
                {item.node}
              </Typography>
            </Box>
          );
        }
        return (
          <Typography
            key={index}
            variant="body2"
            component={'div'}
            className="flex gap-1"
          >
            <Box sx={{ minWidth: 160 }}>
              {item.label && <FormattedMessage id={item.label} />}
            </Box>
            :&emsp;{item.value}
          </Typography>
        );
      })}
      <MuiTable<ProcessingFoodModel>
        boxProps={{
          sx: {
            p: 0,
            mt: 4,
          },
        }}
        data={mappedData || []}
        enableTableFooter={mappedData?.length > 0}
        getRowId={(originalRow: any) => originalRow.key}
        columns={[
          {
            accessorKey: 'name',
            header: 'name',
            Header: () => <FormattedMessage id="quota.name" />,
            muiTableFooterCellProps: {
              sx: { display: 'none' },
            },
            Cell: ({ row }) => {
              return (
                <Box className="text-center">
                  <Typography variant="inherit">{row.original.name}</Typography>
                  <Typography variant="inherit">
                    {row.original.operationCode}
                  </Typography>
                </Box>
              );
            },
          },
          {
            accessorKey: 'standardFood.name',
            header: 'standardFood.name',
            size: 100,
            Header: () => <FormattedMessage id="quota.standardFood" />,
            muiTableFooterCellProps: {
              sx: { display: 'none' },
            },
          },
          {
            accessorKey: 'preliminaryType',
            header: 'preliminaryType',
            size: 100,
            Header: () => (
              <FormattedMessage id="processingFood.preliminaryType" />
            ),
            Cell: ({ renderedCellValue }) => {
              const preliminaryType = listType.TPCB?.preliminaryType?.find(
                (v) => v.id === renderedCellValue
              );
              return preliminaryType?.name;
            },
            muiTableFooterCellProps: {
              sx: { display: 'none' },
            },
          },
          {
            accessorKey: 'usageRatio',
            header: 'usageRatio',
            Header: () => <FormattedMessage id="quota.usageRatio" />,
            size: 80,
            muiTableFooterCellProps: {
              sx: { display: 'none' },
            },
            Cell: ({ row }) => {
              return (
                <>
                  <FormattedNumber value={row.original.usageRatio || 0} />
                </>
              );
            },
          },
          {
            accessorKey: 'quantity',
            header: 'quantity',
            size: 100,
            Header: () => <FormattedMessage id="quota.quantity_1" />,
            Cell: ({ row }) => {
              return (
                <FormattedNumber
                  value={
                    ((row.original.quantity || 0) *
                      (row.original.processingUnit?.conversionRatio || 0)) /
                    100
                  }
                />
              );
            },
            muiTableFooterCellProps: {
              colSpan: 5,
            },
            Footer: () => {
              return (
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  sx={{ textAlign: 'center', textTransform: 'uppercase' }}
                >
                  <FormattedMessage id="total" />
                </Typography>
              );
            },
          },
          {
            accessorKey: 'standardFood.protein',
            header: 'standardFood.protein',
            Header: () => <FormattedMessage id="quota.protein" />,
            size: 50,
            Cell: ({ row }) => {
              return (
                <FormattedNumber value={row.original.standardFood?.protein} />
              );
            },
            Footer: () => {
              return (
                <Typography
                  variant="inherit"
                  color="secondary"
                  sx={{ textAlign: 'center' }}
                >
                  <FormattedNumber
                    value={
                      mappedData?.reduce(
                        (v, c) => v + Number(c.standardFood?.protein) || 0,
                        0
                      ) || 0
                    }
                  />
                </Typography>
              );
            },
          },
          {
            accessorKey: 'standardFood.lipid',
            header: 'standardFood.lipid',
            Header: () => <FormattedMessage id="quota.lipid" />,
            size: 50,
            Cell: ({ row }) => {
              return (
                <FormattedNumber value={row.original.standardFood?.lipid} />
              );
            },
            Footer: () => {
              return (
                <Typography
                  variant="inherit"
                  color="secondary"
                  sx={{ textAlign: 'center' }}
                >
                  <FormattedNumber
                    value={mappedData?.reduce(
                      (v, c) => v + Number(c.standardFood?.lipid) || 0,
                      0
                    )}
                  />
                </Typography>
              );
            },
          },
          {
            accessorKey: 'standardFood.glucid',
            header: 'standardFood.glucid',
            Header: () => <FormattedMessage id="quota.glucid" />,
            size: 50,
            Cell: ({ row }) => {
              return (
                <FormattedNumber value={row.original.standardFood?.glucid} />
              );
            },
            Footer: () => {
              return (
                <Typography
                  variant="inherit"
                  color="secondary"
                  sx={{ textAlign: 'center' }}
                >
                  <FormattedNumber
                    value={
                      mappedData?.reduce(
                        (v, c) => v + Number(c.standardFood?.glucid) || 0,
                        0
                      ) || 0
                    }
                  />
                </Typography>
              );
            },
          },

          {
            accessorKey: 'standardFood.calorie',
            header: 'standardFood.calorie',
            Header: () => <FormattedMessage id="quota.calorie" />,
            size: 50,
            Cell: ({ row }) => {
              return (
                <FormattedNumber value={row.original.standardFood?.calorie} />
              );
            },
            Footer: () => {
              return (
                <Typography
                  variant="inherit"
                  color="secondary"
                  sx={{ textAlign: 'center' }}
                >
                  <FormattedNumber
                    value={
                      mappedData?.reduce(
                        (v, c) => v + Number(c.standardFood?.calorie) || 0,
                        0
                      ) || 0
                    }
                  />
                </Typography>
              );
            },
          },
        ]}
      />
    </>
  );
};
export default ConfirmDataBox;
