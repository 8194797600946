export enum ModuleEnum {
  'TPCB' = 'TPCB',
  'TPTC' = 'TPTC',
  'MG' = 'MG',
  'MCB' = 'MCB',
  'DVT' = 'DVT',
  'PNTPTC' = 'PNTPTC',
  'KPP' = 'KPP',
  'PLDH' = 'PLDH',
  'PLHH' = 'PLHH',
  'NMH' = 'NMH',
  'TCCL' = 'TCCL',
  'MKCB' = 'MKCB',
}

export type ModuleCode =
  | 'TPCB'
  | 'TPTC'
  | 'MG'
  | 'MCB'
  | 'DVT'
  | 'PNTPTC'
  | 'KPP'
  | 'PLDH'
  | 'PLHH'
  | 'NMH'
  | 'TCCL'
  | 'MKCB';

export enum GenCodeGroupType {
  TP = 'TP',
  LT = 'LT',
  MA = 'MA',
}

export interface GenerateCodeRequest {
  module: ModuleCode;
  name?: string;
  type?: string;
  businessArea?: string;
  sourceType?: string;
  commodityGroup?: string;
  objectType?: string;
}
export interface GenerateCodeResponse {
  data: {
    operationCode: string;
  };
  status: 'success';
  code: 200;
  message: 'Success';
}
