import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import MyLink from '../common/components/MyLink';
import { ROUTES_PATH } from '../layout/constants';

const NotFoundPage = () => {
  return (
    <Box className="flex flex-col gap-4 items-center justify-center w-full h-full">
      <Typography variant="h1" color="error">
        404
      </Typography>
      <Typography variant="h4">
        <FormattedMessage id="notFoundPage" />
      </Typography>
      <MyLink to={'/' + ROUTES_PATH.dashboard}>
        <Button startIcon={<ArrowBack />} variant="outlined">
          <FormattedMessage id="backToHomePage" />
        </Button>
      </MyLink>
    </Box>
  );
};
export default NotFoundPage;
