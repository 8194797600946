import {
  AddRounded,
  DeleteRounded,
  EditRounded,
  ListAltRounded,
  PauseCircle,
  PlayCircle,
  Visibility,
} from '@mui/icons-material';
import { Button, Chip, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import MuiTable from '../../../common/components/MuiTable';
import MyLink from '../../../common/components/MyLink';
import { KEYS_QUERY, STATUS_OPTIONS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import {
  OperationStatus,
  ResponseDataDetail,
  ResponseDataList,
} from '../../../common/types';
import { ROUTES_PATH } from '../../../layout/constants';
import FilterBox from './FilterBox';
import { ProcessingDishFilterModel, ProcessingDishModel } from '../types';
import IconButtonTooltip from '../../../common/components/IconButtonTooltip';
import ReasonDialog, {
  ReasonDialogProps,
} from '../../../common/components/ReasonDialog';
import { useState } from 'react';
import RestartProgressDialog from '../../../common/components/RestartProgressDialog';
import { useNavigate } from 'react-router';
import useGetReason from '../../../common/hook/useGetReason';
import useMutationReason from '../../../common/hook/useMutationReason';
import { ModuleEnum } from '../../../common/components/GenerateCodeBox/types';
import { checkReadOnlyProcessingDishQuota } from '../utils';

const ProcessingDishListPage = () => {
  const {
    dispatch,
    fetchThunk,
    API_PATHS,
    confirmDialog,
    messageDialog,
    intl,
    listType,
  } = useGeneralHook();
  const navigate = useNavigate();
  const { mutationReason } = useMutationReason();
  const hookPagination = usePaginationHook<ProcessingDishFilterModel>();

  const [openRestartProgress, setOpenRestartProgress] = useState<
    ProcessingDishModel | undefined
  >(undefined);
  const reasons = useGetReason(
    openRestartProgress
      ? API_PATHS.processingDish.reason(openRestartProgress?.id)
      : undefined
  );
  const [model, setModel] = useState<ReasonDialogProps | undefined>(undefined);

  const {
    params,
    sorting,
    onSortingChange,
    onPageChange,
    onRowsPerPageChange,
  } = hookPagination;

  const { data, isLoading, refetch } = useQuery<
    ResponseDataList<ProcessingDishModel>
  >({
    queryKey: [KEYS_QUERY.quotaDish, params],
    queryFn: async () => {
      const json = await dispatch(
        fetchThunk<ResponseDataList<ProcessingDishModel>>({
          url: API_PATHS.processingDish.index,
          params: params,
        })
      );
      return json.data;
    },
  });

  const onRestartProcess = useMutation({
    mutationKey: ['onRestartProcess'],
    mutationFn: async () => {
      if (!openRestartProgress) {
        return;
      }
      const json = await dispatch(
        fetchThunk<ResponseDataDetail<ProcessingDishModel>>({
          url: API_PATHS.processingDish.detail(openRestartProgress?.id),
          method: 'put',
          data: {
            ...openRestartProgress,
            operationStatus: OperationStatus.create,
          },
        })
      );
      setOpenRestartProgress(undefined);
      navigate({ pathname: json.data.data?.id + '' });
    },
  });

  const onStopProcess = (data: ProcessingDishModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingDish.detail(data?.id),
              method: 'put',
              data: {
                ...data,
                operationStatus: OperationStatus.stop,
              },
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingDish.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'paused',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: <FormattedMessage id="confirmed" />,
        },
        {
          label: 'updateStatus',
          value: <FormattedMessage id="stop" />,
        },
      ],
      reasons: listType.MCB?.reasons?.stop,
    });
  };

  const onDelete = (data: ProcessingDishModel) => {
    setModel({
      open: true,
      onSave: async ({ reasonId, reasonText }) => {
        Promise.all([
          await dispatch(
            fetchThunk({
              url: API_PATHS.processingDish.detail(data.id),
              method: 'delete',
            })
          ),
          await mutationReason.mutateAsync({
            url: API_PATHS.processingDish.reason(data?.id),
            params: {
              objectId: data.id,
              key: reasonId,
              reason: reasonText,
              objectType: ModuleEnum.MCB,
              statusBefore: data.operationStatus,
              statusAfter: OperationStatus.stop,
            },
          }),
        ]).then(() => {
          setModel((old) => ({ ...old, open: false }));
          refetch();
        });
      },
      onClose: () => setModel((old) => ({ ...old, open: false })),
      title: 'cancel',
      infos: [
        {
          label: 'type',
          value: <FormattedMessage id="processingDish.title" />,
        },
        {
          label: 'subjectChange',
          value: (
            <Box>
              {data?.name} <br />
              {data?.operationCode}
            </Box>
          ),
        },
        {
          label: 'currentStatus',
          value: (
            <>
              <FormattedMessage id={data.operationStatus} />
              &nbsp; (<FormattedMessage id={'active'} />)
            </>
          ),
        },
        {
          label: 'updateStatus',
          value: (
            <>
              <FormattedMessage id={OperationStatus.cancel} />
              &nbsp; (<FormattedMessage id={'deactive'} />)
            </>
          ),
        },
      ],
      reasons: listType.MCB?.reasons?.cancel,
    });
  };

  return (
    <Paper>
      <Box className="flex flex-col p-2 pb-0 tablet:p-4 gap-2">
        <FilterBox hookPagination={hookPagination} />
        <MyLink
          to={ROUTES_PATH.qualityStandard.create}
          sx={{ alignSelf: 'end' }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddRounded />}
          >
            <FormattedMessage id="add" />
          </Button>
        </MyLink>
      </Box>
      <MuiTable<ProcessingDishModel>
        data={
          data?.data?.slice(
            (params.pageIndex - 1) * params.pageSize,
            params.pageIndex * params.pageSize
          ) ?? []
        }
        state={{
          isLoading: isLoading,
          sorting,
        }}
        onSortingChange={onSortingChange}
        paginationProps={{
          count: data?.data.length,
          page: params.pageIndex,
          rowsPerPage: params.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        enableRowActions
        renderRowActions={({ row }) => [
          <MyLink to={'quota/' + row.original.id.toString()}>
            <IconButtonTooltip
              tooltipProps={{
                title: (
                  <FormattedMessage
                    id={
                      checkReadOnlyProcessingDishQuota(row.original)
                        ? 'processingDish.viewQuota'
                        : 'processingDish.updateQuota'
                    }
                    values={{ name: row.original.name }}
                  />
                ),
              }}
            >
              <ListAltRounded />
            </IconButtonTooltip>
          </MyLink>,
          [OperationStatus.deny].includes(row.original.operationStatus) ? (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              onClick={() => setOpenRestartProgress(row.original)}
            >
              <EditRounded />
            </IconButtonTooltip>
          ) : [OperationStatus.stop, OperationStatus.confirm].includes(
              row.original.operationStatus
            ) ? (
            <MyLink to={row.original.id.toString()}>
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="view" /> }}
              >
                <Visibility />
              </IconButtonTooltip>
            </MyLink>
          ) : (
            <MyLink to={row.original.id.toString()}>
              <IconButtonTooltip
                tooltipProps={{ title: <FormattedMessage id="edit" /> }}
              >
                <EditRounded />
              </IconButtonTooltip>
            </MyLink>
          ),
          <IconButtonTooltip
            tooltipProps={{ title: <FormattedMessage id="delete" /> }}
            onClick={() => onDelete(row.original)}
            disabled={[
              OperationStatus.cancel,
              OperationStatus.stop,
              OperationStatus.confirm,
            ].includes(row.original.operationStatus)}
          >
            <DeleteRounded />
          </IconButtonTooltip>,
          row.original.operationStatus === OperationStatus.confirm ? (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="paused" /> }}
              onClick={() => onStopProcess(row.original)}
            >
              <PlayCircle />
            </IconButtonTooltip>
          ) : (
            <IconButtonTooltip
              tooltipProps={{ title: <FormattedMessage id="play" /> }}
              disabled
            >
              <PauseCircle />
            </IconButtonTooltip>
          ),
        ]}
        columns={[
          {
            accessorKey: 'id',
            header: 'id',
            enableSorting: true,
            Header: () => <FormattedMessage id="id" />,
            size: 70,
          },
          {
            accessorKey: 'operationCode',
            header: 'operationCode',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingDish.code" />,
          },
          {
            accessorKey: 'name',
            header: 'name',
            enableSorting: true,
            Header: () => <FormattedMessage id="processingDish.name" />,
          },
          {
            accessorKey: 'originalDish.name',
            header: 'originalDish.name',
            Header: () => <FormattedMessage id="processingDish.originalDish" />,
          },

          {
            accessorKey: 'objectType',
            header: 'objectType ',
            Header: () => <FormattedMessage id="processingDish.objectType" />,
            Cell: ({ renderedCellValue }) => {
              const objectType = listType.MCB?.objectType?.find(
                (v) => v.id === renderedCellValue
              )?.name;

              return objectType;
            },
          },
          {
            accessorKey: 'mainFoodQuantity',
            header: 'mainFoodQuantity ',
            Header: () => (
              <FormattedMessage id="processingDish.mainFoodQuantity" />
            ),
          },
          {
            accessorKey: 'operationStatus',
            header: 'operationStatus',
            Header: () => <FormattedMessage id="status" />,
            Cell: ({ renderedCellValue }) => {
              const status = listType.MCB?.operationStatus?.find(
                (v) => v.id === renderedCellValue
              );
              return status?.name;
            },
          },
          {
            accessorKey: 'status',
            header: 'status',
            Header: () => <FormattedMessage id="statusAdmin" />,
            Cell: ({ renderedCellValue }) => {
              const status = STATUS_OPTIONS.find(
                (v) => v.id === renderedCellValue
              );

              return (
                status && (
                  <Chip
                    label={<FormattedMessage id={status.name} />}
                    variant="outlined"
                    color={status.color as any}
                    size="small"
                  />
                )
              );
            },
          },
        ]}
      />
      <ReasonDialog {...model} />
      <RestartProgressDialog
        open={!!openRestartProgress}
        onClose={() => setOpenRestartProgress(undefined)}
        onSubmit={onRestartProcess.mutateAsync}
        title="re_initialize"
        infos={[
          {
            label: 'type',
            value: <FormattedMessage id="processingDish.title" />,
          },
          {
            label: 'subjectChange',
            value: (
              <Box>
                {openRestartProgress?.name}
                <br />
                {openRestartProgress?.operationCode}
              </Box>
            ),
          },
          {
            label: 'currentStatus',
            value: openRestartProgress?.operationStatus && (
              <FormattedMessage id={openRestartProgress?.operationStatus} />
            ),
          },
          {
            label: 'updateStatus',
            value: <FormattedMessage id={OperationStatus.create} />,
          },
          {
            label: 'denyReason',
            value: reasons?.reason
              ? reasons?.reason
              : listType.MCB?.reasons.deny?.find((v) => v.id === reasons?.key)
                  ?.name,
          },
        ]}
      />
    </Paper>
  );
};
export default ProcessingDishListPage;
